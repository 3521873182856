import React from "react";
import RangeDataOption from "./RangeDataOption";
import { getRUCEmpresa } from "redux/actions/userActions";
import { useSelector } from "react-redux";
//libreria de consulta de apis
import { httpReportes } from "configurations/axios";
import { useSnackbar } from "notistack";
import { makeStyles, CircularProgress, Backdrop } from "@material-ui/core";
import { parseDate } from "utils/checkDate";

const useStyles = makeStyles((theme) => ({
  input: {
    width: "350px",
  },
  backdrop: {
    zIndex: "3000",
    color: "#fff",
  },
}))
const ReportAts = () => {
  const tokenUser = useSelector((state) => state.user.tk);
  const { enqueueSnackbar } = useSnackbar();
  var fileSaver = require("file-saver");
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  function generateReport(startDate, endDate, optionSelect) {
    if (startDate && endDate) {
      let objReport = {};
      objReport = {
        anio: startDate,
        mes: endDate,
        rucEmpresa: tokenUser ? getRUCEmpresa(tokenUser) : "",
        tipoArchivo: optionSelect.value,
      };
      getReportIssued(objReport);
    } else {
      if (startDate == null) {
        enqueueSnackbar(`Seleccione una año`, {
          variant: "info",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          autoHideDuration: 4000,
        });
      }
      if (endDate == null) {
        enqueueSnackbar(`Seleccione un mes`, {
          variant: "info",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          autoHideDuration: 4000,
        });
      }
      if (optionSelect.value == null) {
        enqueueSnackbar(`Seleccione el tipo de archivo a descargar`, {
          variant: "info",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          autoHideDuration: 4000,
        });
      }
    }
  }

  const getReportIssued = async (objReport) => {
    setOpen(true);
    await httpReportes
      .post("/reportes/ats", objReport, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        if (response.status === 200) {
          const filename = response.headers['content-disposition'].split('filename=');
          var blob = new Blob([response.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          fileSaver.saveAs(blob, filename[1]);
          setOpen(false);
        }
      })
      .catch(() => {
        enqueueSnackbar(`No existen datos para los filtros seleccionados`, {
          variant: "info",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        setOpen(false);
      });
  };

  return (
    <div>
      <Backdrop open={open} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <RangeDataOption
        generateReport={generateReport}
        title={"Reporte ATS"}
      ></RangeDataOption>
    </div>
  );
};

export default ReportAts;
