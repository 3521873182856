import checkInputs from "utils/checkInputs";

const validations = {
    checkClient: function checkClient(company) {
        const errors = {};

        if (!checkInputs.notNull(company.identificacionUsuario)) {
            errors.identificacionUsuario = "El RUC/ID/CI es necesario."
        } else if (!checkInputs.isAlphanumeric(company.identificacionUsuario)) {
            errors.identificacionUsuario = 'No puede contener caracteres especiales, solo letras o números.';
        } else if (company.identificacionUsuario.length > 300) {
            errors.identificacionUsuario = 'No ingrese más de 300 caracteres.';
        }


        if (!checkInputs.notNull(company.nombreUsuario)) {
            errors.nombreUsuario = "El nombre del usuario es necesario."
        } else if (!checkInputs.isAlphanumeric(company.nombreUsuario)) {
            errors.nombreUsuario = 'No puede contener caracteres especiales, solo letras o números.';
        } else if (company.nombreUsuario.length > 300) {
            errors.nombreUsuario = 'No ingrese más de 300 caracteres.';
        }

        if (checkInputs.notNull(company.direccionUsuario)) {
            if (!checkInputs.isAlphanumeric(company.direccionUsuario)) {
                errors.direccionUsuario = "No puede contener caracteres especiales, solo: '(',')', '#', letras y números .";
            } else if (company.direccionUsuario.length > 300) {
                errors.direccionUsuario = 'No ingrese más de 300 caracteres.';
            }
        }

        if (!checkInputs.notNull(company.correoPrincipalUsuario)) {
            errors.correoPrincipalUsuario = "El correo electrónico es necesario."
        } else if (!checkInputs.validationEmail(company.correoPrincipalUsuario)) {
            errors.correoPrincipalUsuario = "No puede contener caracteres especiales, solo: '(',')', '#', letras y números .";
        } else if (company.correoPrincipalUsuario.length > 300) {
            errors.correoPrincipalUsuario = 'No ingrese más de 300 caracteres.';
        }

        if (checkInputs.notNull(company.telefonoPrincipalUsuario)) {
            if (!checkInputs.isNumeric(company.telefonoPrincipalUsuario)) {
                errors.telefonoPrincipalUsuario = 'No puede contener caracteres especiales, solo números.';
            } else if (company.telefonoPrincipalUsuario.length > 300) {
                errors.telefonoPrincipalUsuario = 'No ingrese más de 300 caracteres.';
            }
        }


        return errors;
    },

    checkCompany: function checkCompany(company) {
        const errors = {};

        if (!checkInputs.notNull(company.rucEmpresa)) {
            errors.rucEmpresa = "El RUC es necesario."
        } else if (false
            // !checkDocument.validarRucPersonaNatural(company.rucEmpresa) &&
            // !checkDocument.validarRucSociedadPrivada(company.rucEmpresa) &&
            // !checkDocument.validarRucSociedadPublica(company.rucEmpresa)
        ) {
            errors.rucEmpresa = " RUC inválido."
        } else if (!checkInputs.isAlphanumeric(company.rucEmpresa)) {
            errors.rucEmpresa = 'No puede contener caracteres especiales, solo letras o números.';
        } else if (company.rucEmpresa.length > 300) {
            errors.rucEmpresa = 'No ingrese más de 300 caracteres.';
        }
        if (!checkInputs.notNull(company.razonSocial)) {
            errors.razonSocial = "La Razón Social es necesaria."
        } else if (!checkInputs.isAlphanumeric(company.razonSocial)) {
            errors.razonSocial = 'No puede contener caracteres especiales, solo letras o números.';
        } else if (company.razonSocial.length > 300) {
            errors.razonSocial = 'No ingrese más de 300 caracteres.';
        }
        if (checkInputs.notNull(company.nombreComercial)) {
            if (!checkInputs.isAlphanumeric(company.nombreComercial)) {
                errors.nombreComercial = 'No puede contener caracteres especiales, solo letras o números.';
            } else if (company.nombreComercial.length > 300) {
                errors.nombreComercial = 'No ingrese más de 300 caracteres.';
            }
        }

        if (!checkInputs.notNull(company.direccionEmpresa)) {
            errors.direccionEmpresa = "La Dirección es necesaria."
        } else if (!checkInputs.isAlphanumeric(company.direccionEmpresa)) {
            errors.direccionEmpresa = "No puede contener caracteres especiales, solo: '(',')', '#', letras y números .";
        } else if (company.direccionEmpresa.length > 300) {
            errors.direccionEmpresa = 'No ingrese más de 300 caracteres.';
        }

        if (!checkInputs.notNull(company.correoElectronico)) {
            errors.correoElectronico = "El correo electrónico es necesario."
        } else if (!checkInputs.validationEmail(company.correoElectronico)) {
            errors.correoElectronico = "No puede contener caracteres especiales, solo: '(',')', '#', letras y números .";
        } else if (company.correoElectronico.length > 300) {
            errors.correoElectronico = 'No ingrese más de 300 caracteres.';
        }


        if (checkInputs.notNull(company.telefono)) {
            if (!checkInputs.isNumeric(company.telefono)) {
                errors.telefono = 'No puede contener caracteres especiales, solo números.';
            } else if (company.telefono.length > 300) {
                errors.telefono = 'No ingrese más de 300 caracteres.';
            }
        }


        if (checkInputs.notNull(company.numeroResolucion)) {
            if (!checkInputs.isNumeric(company.numeroResolucion)) {
                errors.numeroResolucion = 'No puede contener caracteres especiales, solo números.';
            } else if (company.numeroResolucion.length > 13) {
                errors.numeroResolucion = 'No ingrese más de 13 caracteres.';
            }
        }

        return errors;
    }


}

export default validations;