import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { palette } from '../../../../../configurations/styles/theme';
import { ConfirmAlert, iconsAlert } from 'utils/AlertsCustom';

const useStyles = makeStyles(theme => ({
    IconButton: {
        marginTop: "0%",
        marginBottom: "0%",
        padding: "0px",
    },
    divInfoUser: {
        borderStyle: 'solid',
        borderColor: palette.primary.main,
        borderWidth: '1px',
        borderRadius: '8px',
        marginTop: '1%'
    },
    divlabel: {
        marginTop: '1%'
    },
    buttonClose: {
        width: '75%'
    }
}))

const DeleteTaxBtn = ({ impuestoRetencions, setImpuestoRetencions, taxDetail, setTaxDetail, viewDetalle, setViewDetalle, data }) => {

    const classes = useStyles();


    const deleteDetailTax = () => {
        ConfirmAlert(
            `¿Está seguro de eliminar este impuesto`,
            "",
            iconsAlert.question,
            true,
            "Sí",
            "Cancelar",
            () => {
                setTaxDetail(taxDetail.filter(tax => tax.id !== data.id))
                setImpuestoRetencions(impuestoRetencions.filter(tax => tax.id !== data.id))
                if (viewDetalle === 1) {
                    setViewDetalle(2)
                } else {
                    setViewDetalle(1)
                }
            },
            () => {
                if (viewDetalle === 1) {
                    setViewDetalle(2)
                } else {
                    setViewDetalle(1)
                }
            }
        );

    }

    return (
        <>

            <IconButton color="primary" aria-label="upload picture" component="span" onClick={deleteDetailTax} className={classes.IconButton}>
                <DeleteForeverIcon style={{ color: palette.error.main }} />
            </IconButton>
        </>
    );
}

export default DeleteTaxBtn;


// import React from 'react';
// import { IconButton, makeStyles } from '@material-ui/core';
// import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
// import { palette } from '../../../../../configurations/styles/theme';
// import { ConfirmAlert, iconsAlert } from 'utils/AlertsCustom';

// const useStyles = makeStyles(theme => ({
//     IconButton: {
//         marginTop: "0%",
//         marginBottom: "0%",
//         padding: "0px",
//     },
//     divInfoUser: {
//         borderStyle: 'solid',
//         borderColor: palette.primary.main,
//         borderWidth: '1px',
//         borderRadius: '8px',
//         marginTop: '1%'
//     },
//     divlabel: {
//         marginTop: '1%'
//     },
//     buttonClose: {
//         width: '75%'
//     }
// }))

// const DeleteTaxBtn = ({ impuestoRetencions, setImpuestoRetencions, taxDetail, setTaxDetail, viewDetalle, setViewDetalle, data }) => {

//     const classes = useStyles();


//     const deleteDetailTax = () => {
//         ConfirmAlert(
//             `¿Está seguro de eliminar este impuesto`,
//             "",
//             iconsAlert.question,
//             true,
//             "Sí",
//             "Cancelar",
//             () => {
//                 setTaxDetail(taxDetail.filter(tax => tax.id !== data.id))
//                 setImpuestoRetencions(impuestoRetencions.filter(tax => tax.id !== data.id))
//                 if (viewDetalle === 1) {
//                     setViewDetalle(2)
//                 } else {
//                     setViewDetalle(1)
//                 }
//             },
//             () => {
//                 if (viewDetalle === 1) {
//                     setViewDetalle(2)
//                 } else {
//                     setViewDetalle(1)
//                 }
//             }
//         );

//     }

//     return (
//         <>

//             <IconButton color="primary" aria-label="upload picture" component="span" onClick={deleteDetailTax} className={classes.IconButton}>
//                 <DeleteForeverIcon style={{ color: palette.error.main }} />
//             </IconButton>
//         </>
//     );
// }

// export default DeleteTaxBtn;