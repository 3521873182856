import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Card,
  Typography,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { palette } from "configurations/styles/theme";
import validations from "./validations";
import useForm from "hooks/useFrom";
import { clientPublic } from "configurations/axios";
import Alert from "widgets/alert";
//estilos
import "../Login/login.css";
import apis from "./apis";
import UseTittle from "hooks/useTitle";
import { apiKey } from "configurations/constant"
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formLogin: {
    borderStyle: "solid",
    borderWidth: "1px",
    padding: "1%",
    borderRadius: "8px",
    marginLeft: "10px",
    marginRigth: "10px",
    borderColor: "#d9d9d9",
  },
  from: {
    width: "100%",
    margin: "1%",
  },
  formControl: {
    marginTop: theme.spacing(0),
    minWidth: 120,
  },
  radio: {
    display: "-webkit-box",
    marginTop: "0px",
  },
  radioLable: {
    color: palette.primary.main,
    display: "flex",
  },
  radioButton: {
    marginTop: "0px",
    paddingTop: "0px",
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 999,
    color: palette.primary.main,
  },
  imgRide: {
    paddingLeft: "25%",
    paddingRight: "25%",
  },
  btn: {
    paddingLeft: "25%",
    paddingRight: "25%",
  },
  section: {
    margin: "1%",
  },
  title: {
    display: "flex",
    justifyContent: "center",
    background: "#FFFFFF",
    color: "#fafbfb",
    borderBottomColor: "#3f51b5",
    borderBottomStyle: "solid",
    marginTop: "10px",
    marginBottom: "10px"
  },
  large: {
    width: '200px',
    height: '55px',
    marginBottom: '10px'
  },
  space: {
    margin: '10px'
  }

}));

const Register = () => {
  const classes = useStyles();
  const history = useHistory();
  const [company, setCompany] = useState({
    correoPrincipalUsuario: "",
    direccionUsuario: "",
    nombreUsuario: "",
    identificacionUsuario: "",
    telefonoPrincipalUsuario: "",
    idEmpresa: "1751154715001",
  });
  const [openDialog, setOpenDiaglog] = useState(false);
  const [msgError, setMsg] = useState({ show: false, msg: null, type: null });
  const [openLoader, setOpenLoader] = useState(false);

  function closeAlert() {
    setMsg({ show: false });
  }
  UseTittle({ title: "Registro Clientes" });

  const handleClose = () => {
    setOpenDiaglog(false);
    setCompany({
      correoPrincipalUsuario: "",
      direccionUsuario: "",
      nombreUsuario: "",
      identificacionUsuario: "",
      telefonoPrincipalUsuario: "",
      idEmpresa: "1751154715001",
    });
    history.push("/");
  };

  const existError = () => { };

  const handleSave = async () => {
    setOpenLoader(true);
    let request = { codigoSistema: apiKey, ...company };
    await clientPublic
      .post(apis.post_save_client, company)
      .then((response) => {
        if (response.status >= 200 && response.status <= 300) {
          setMsg({ show: true, msg: "Cliente registrado", type: "success" });
          setOpenDiaglog(true);
        }
        setOpenLoader(false);
      })
      .catch((exception) => {
        if (exception.response) {
          if (exception.response.status === 400) {
            setMsg({
              show: true,
              msg:
                "No se pudo registrar al cliente. Contáctese con el administrador del Sistema.",
              type: "error",
            });
          } else if (exception.response.status === 404) {
            setMsg({
              show: true,
              msg:
                exception.response.data.apierror.mensaje +
                ". Contáctese con el administrador del Sistema.",
              type: "error",
            });
          } else if (exception.response.status === 409) {
            setMsg({
              show: true,
              msg:
                "El cliente ya existe. Contáctese con el administrador del Sistema.",
              type: "error",
            });
          } else {
            setMsg({
              show: true,
              msg:
                "Ocurrió un error interno. Contáctese con el administrador del Sistema.",
              type: "error",
            });
          }
        } else {
          setMsg({
            show: true,
            msg:
              "Existen problemas en la red. Contáctese con el administrador del Sistema.",
            type: "error",
          });
        }
        setOpenLoader(false);
      });
  };

  const { errors, handleSubmit, getObject } = useForm(
    handleSave,
    validations.checkClient,
    existError
  );

  useEffect(() => {
    getObject({
      correoPrincipalUsuario: "",
      direccionUsuario: "",
      nombreUsuario: "",
      identificacionUsuario: "",
      telefonoPrincipalUsuario: "",
      idEmpresa: "1751154715001",
    });
  }, []);

  const handleShowAlert = (alert) => {
    setMsg(alert);
  };

  const handleChange = (event) => {
    setCompany({
      ...company,
      [event.target.name]: event.target.value,
    });
    getObject({
      ...company,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeResolucion = (event) => {
    event.preventDefault();
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      setCompany({
        ...company,
        [event.target.name]: event.target.value,
      });
      getObject({
        ...company,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleReturnLogin = () => {
    history.push("/");
  };

  return (
    <>
      <Card>
        <section className={classes.title}>
          <Grid
            container
            justify="center"
            align="center"
            direction="row"
            spacing={2}
          >
            <Grid item md={12}>
              {/* <Avatar
                alt="Remy Sharp"
                variant="square"
                src="../facturar12020.png"
                className={classes.large}
              /> */}
              <img src="../LogoEbox.png" alt="logo" className={classes.large} />
            </Grid>
          </Grid>
        </section>
        {msgError.show ? (
          <Alert
            tipo={msgError.type}
            mensaje={msgError.msg}
            mesajeSubrayado={null}
            titulo={null}
            mostrar={msgError.show}
            cerrarAlerta={closeAlert}
          />
        ) : null}
        <section>
          <form onSubmit={handleSubmit}>
            <Grid container justify="center" spacing={2}>
              <Grid item sm={5} className={classes.formLogin}>
                <Grid container spacing={1}>
                  <Grid item md={12}>
                    <Typography variant="h6">
                      <strong>Registro de cliente</strong>
                    </Typography>
                  </Grid>
                  <Grid item md={12}>
                    <Divider></Divider>
                  </Grid>
                  <Grid item md={12}>
                    <label>
                      Los campos marcados con ({" "}
                      <font color={palette.error.main}> *</font> ) son
                      obligatorios:
                    </label>
                  </Grid>
                  <Grid item sm={3}>
                    <label>
                      RUC/CI/Pasaporte: <font color={palette.error.main}> *</font>
                    </label>
                  </Grid>
                  <Grid item sm={9}>
                    <input
                      type="text"
                      className={
                        errors.identificacionUsuario
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      onChange={handleChangeResolucion}
                      name="identificacionUsuario"
                      value={company.identificacionUsuario}
                      placeholder="Ej. 0123456789001"
                      autoComplete="off"
                      maxLength='13'
                    />
                    {errors.identificacionUsuario ? (
                      <small style={{ color: palette.error.main }}>
                        {errors.identificacionUsuario}
                      </small>
                    ) : null}
                  </Grid>
                  <Grid item sm={3}>
                    <label>
                      Razón social / Nombre completos: <font color={palette.error.main}> *</font>
                    </label>
                  </Grid>
                  <Grid item sm={9}>
                    <input
                      type="text"
                      className={
                        errors.nombreUsuario
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      onChange={handleChange}
                      name="nombreUsuario"
                      value={company.nombreUsuario}
                      placeholder="Ej. Mario Salazar"
                      autoComplete="off"
                      maxLength='300'
                    />
                    {errors.nombreUsuario ? (
                      <small style={{ color: palette.error.main }}>
                        {errors.nombreUsuario}
                      </small>
                    ) : null}
                  </Grid>
                  <Grid item sm={3}>
                    <label>
                      Dirección:
                    </label>
                  </Grid>
                  <Grid item sm={9}>
                    <input
                      type="text"
                      className={
                        errors.direccionUsuario
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      onChange={handleChange}
                      name="direccionUsuario"
                      value={company.direccionUsuario}
                      placeholder="Ej. Av. y Calle 2"
                      autoComplete="off"
                    />
                    {errors.direccionUsuario ? (
                      <small style={{ color: palette.error.main }}>
                        {errors.direccionUsuario}
                      </small>
                    ) : null}
                  </Grid>
                  <Grid item sm={3}>
                    <label>
                      Correo Electrónico:
                      <font color={palette.error.main}> *</font>{" "}
                    </label>
                  </Grid>
                  <Grid item sm={9}>
                    <input
                      type="email"
                      className={
                        errors.correoPrincipalUsuario
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      onChange={handleChange}
                      name="correoPrincipalUsuario"
                      value={company.correoPrincipalUsuario}
                      placeholder="Ej. info@ejemplo.com.ec"
                      autoComplete="off"
                    />
                    {errors.correoPrincipalUsuario ? (
                      <small style={{ color: palette.error.main }}>
                        {errors.correoPrincipalUsuario}
                      </small>
                    ) : null}
                  </Grid>
                  <Grid item sm={3}>
                    <label>Teléfono: </label>
                  </Grid>
                  <Grid item sm={9}>
                    <input
                      type="text"
                      className={
                        errors.telefonoPrincipalUsuario
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      onChange={handleChangeResolucion}
                      name="telefonoPrincipalUsuario"
                      value={company.telefonoPrincipalUsuario}
                      placeholder="Ej. 021234567"
                      autoComplete="off"
                    />
                    {errors.telefonoPrincipalUsuario ? (
                      <small style={{ color: palette.error.main }}>
                        {errors.telefonoPrincipalUsuario}
                      </small>
                    ) : null}
                  </Grid>
                  <br></br>
                  <Grid container justify="center" align="center" spacing={1} className={classes.space} >
                    <Grid item md={6} xs={12} >
                      <Button variant="contained" color="primary" type="submit">
                        Registrarse
                      </Button>
                    </Grid>
                    <Grid item md={6} xs={12}  >
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleReturnLogin}
                      >
                        Cancelar
                      </Button>
                    </Grid>
                    <br></br>
                  </Grid>
                  <br />
                  <br />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </section>
        <Backdrop className={classes.backdrop} open={openLoader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Card>
      <Dialog fullWidth maxWidth="sm" open={openDialog} onClose={handleClose}>
        <DialogTitle>Registro Exitoso</DialogTitle>
        <br />
        <DialogContent>
          <Grid container spacing={2} justify="center" alignItems="center" align="center" direction="row">
            <Grid item sm={12} className="alert alert-primary">
              <strong>Gracias por registrarse.</strong>
              <br />
              La información necesaria para iniciar sesión ha sido enviada al correo:{" "}
              {company.correoPrincipalUsuario}
            </Grid>
            <Grid item sm={12}  >
              <Button variant="contained" color="primary" onClick={handleClose}>
                Aceptar
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Register;
