import React, { Fragment } from "react";
import { TextField, Grid } from "@material-ui/core";
import { palette, useStylesGlobal } from "configurations/styles/theme";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextError from "componets/TextError";
import { useSelector } from "react-redux";
import AddCostumer from 'views/administración/Clients/Buttons/AddCostumer'
//styles
import { useStyles } from "./styles";
//custom hook
import useClientForm from "./useClientForm";

const costumerNull = {
  nombres: "",
  telefono: "",
  correo: "",
  direccion: "",
};

const DataClient = ({
  stateRedux = {},
  action,
  ErrorRedux = null,
  saveCache,
}) => {
  const classes = useStyles();
  const classesGlobales = useStylesGlobal();
  const tk = useSelector((state) => state.user.tk);

  const {
    handleAutocompleteInputChange,
    handleStyle,
    handleFlagLoadingAutoComplete,
    handleChangeAutocomplete,
    inputValue,
    existClient,
    data,
    isSelectClient,
    optionsCostumers
  } = useClientForm(stateRedux, action, saveCache, costumerNull, tk);


  return (
    <div className={classesGlobales.containerRoot}>
      <Grid container spacing={1} className={classesGlobales.containerForm}>
        <Grid item xs={12} md={12}>
          <label>
            Los campos marcados con ({" "}
            <font color={palette.error.main}> *</font> ) son obligatorios:
            </label>
        </Grid>
        <Grid item xs={12} md={2}>
          <label>
            {" "}
              CI/ RUC/ Pasaporte:<font color={palette.error.main}> *</font>{" "}
          </label>
        </Grid>
        <Grid item xs={12} md={4}>
          <Autocomplete
            onChange={handleChangeAutocomplete}
            getOptionDisabled={(option) =>
              option.label.includes(
                "Ingrese por lo menos 3 caracteres para iniciar la busqueda."
              ) ||
              option.label.includes(
                "No existen clientes con la identificación:"
              )
            }
            noOptionsText="No  existen clientes con la identificación ingresada..."
            inputValue={inputValue}
            onInputChange={handleAutocompleteInputChange}
            options={optionsCostumers}
            loading={handleFlagLoadingAutoComplete()}
            loadingText="Buscando..."
            getOptionLabel={(option) => option.label}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                className="form-control"
                {...params}
                variant="outlined"
                fullWidth
                error={ErrorRedux ? true : false}
              />
            )}
          />
          <TextError text={ErrorRedux} />
        </Grid>

        {(existClient && !isSelectClient) ?
          <Grid item xs={12} md={6}  >
            <AddCostumer updateView={() => { }}></AddCostumer>
          </Grid> :
          <Grid item xs={0} md={6} ></Grid>
        }
        <Grid item xs={12} md={2} className={classes.divForm}>
          <label> Razón Social / Nombres:</label>
        </Grid>
        <Grid item xs={12} md={4} className={classes.divInput}>
          <input
            className={handleStyle("nombres")}
            value={data.nombres}
            disabled
          />
        </Grid>

        <Grid item xs={12} md={2}>
          <label> Teléfono:</label>
        </Grid>
        <Grid item xs={12} md={4}>
          <input
            className={handleStyle("telefono")}
            value={data.telefono ? data.telefono : ''}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <label> Correo Principal:</label>
        </Grid>
        <Grid item xs={12} md={4}>
          <input
            className={handleStyle("correo")}
            value={data.correo}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <label> Dirección:</label>
        </Grid>
        <Grid item xs={12} md={4}>
          <input
            className={handleStyle("direccion")}
            value={data.direccion ? data.direccion : ''}
            disabled
          />
        </Grid>

      </Grid>
    </div>
  );
};

export default DataClient;
