import React, { useState, useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux'
import { getRUCEmpresa } from '../../../redux/actions/userActions';
import { TableCell } from '@material-ui/core';
import PDFVoucherBtn from '../AuthorizedIssuedVouchers/Buttons/PDFVoucherBtn';
//componentes del sistema
import TablePaginatedVoucher from '../../../widgets/Tables/TablePaginatedVoucher'
import { API_GET_VOUCHER_PENDING, API_GET_SEARCH_PENDING } from './apis';
import tableStyle from '../../../configurations/styles/table'
import SearchVoucher from '../Forms/SearchVoucher';
import { Grid, Button, CircularProgress, Accordion, AccordionSummary, Typography, AccordionDetails } from '@material-ui/core';
import TablePaginatedVoucherSearch from 'widgets/Tables/TablePaginatedVoucherSearch';
import TablePaginatedSearchVoucher from 'widgets/Tables/TablePaginatedSearchVoucher';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: '50px',
        flexGrow: 1,
        paddingLeft: '3%',
        paddingRight: '3%',
        marginBottom: '5%'
    },
}));


const PendingVouchers = () => {
    const tokenUser = useSelector(state => state.user.tk);
    const classesView = useStyles();
    //state para actualizar la vista
    const [update, setUpdate] = useState(1);
    const [paramSearch, setParamSearch] = useState('');
    //State para la búsqueda
    const [foundVouchers, setfoundVouchers] = useState([]);

    const [expanded, setExpanded] = React.useState('panel1');
    const columns = [
        { title: 'Número', field: 'comprobanteElectronico.secuencial', cellStyle: tableStyle.cellTable, width: '10%', render: rowData => `${rowData.comprobanteElectronico.codigoEstablecimiento}-${rowData.comprobanteElectronico.puntoEmision}-${rowData.comprobanteElectronico.secuencial}` },
        { title: 'Fecha envío', field: 'comprobanteElectronico.fechaEmision', cellStyle: tableStyle.cellTable, width: '30%', render: rowData => rowData.comprobanteElectronico.fechaEmision.substring(0, 10) },
        { title: 'Estado', field: 'comprobanteElectronico.estadoAutorizacion', cellStyle: tableStyle.cellTable, width: '15%' },
        { title: 'Mensaje', field: 'comprobanteElectronico.mensajeAutorizacion', cellStyle: tableStyle.cellLeft, width: '10%' },
        { title: 'Mensaje', field: 'comprobanteElectronico.mensajeAutorizacion', cellStyle: tableStyle.cellLeft, width: '10%' },
        {
            title: "PDF",
            render: rowData => (
                <TableCell
                    style={{
                        ...tableStyle.cellTable,
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <PDFVoucherBtn voucher={rowData} />
                </TableCell >
            ),
        }
    ];

    const requestConfig = {
        uri: API_GET_VOUCHER_PENDING + (tokenUser ? getRUCEmpresa(tokenUser) : "0"),
        metodo: 'get',
        body: null,
        page: 0,
        elementos: 15,
        sort: 'idAsignacionComprobanteElectronico,desc'
    }

    const requestConfigSeacrh = {
        uri: `${API_GET_SEARCH_PENDING}${tokenUser ? getRUCEmpresa(tokenUser) : "0"}`,
        metodo: 'get',
        body: null,
        page: 0,
        elementos: 15,
        sort: 'idAsignacionComprobanteElectronico,desc',
    }

    useEffect(() => {
    }, [update])

    const headerBottons = () => {
        if (update === 6) {
            return (
                <Grid container direction="row" justify="flex-start" alignItems="center" >

                    <Grid item xs={2}>
                        <Button variant="contained" color="primary" onClick={() => setUpdate(2)}>
                            Mostrar Todos
                        </Button>
                    </Grid>
                </Grid>
            );
        }
    }

    const renderTable = () => {
        switch (update) {
            case 1:
                setUpdate(2);
                return (
                    <div align='center'>
                        <CircularProgress />
                    </div>
                );
            case 2:
                return (

                    <TablePaginatedVoucher
                        title=""
                        numeroFilas={5}
                        columns={columns}
                        requestConfig={requestConfig}
                        BotonesHeader={headerBottons}
                        seleccion={false}
                    />
                );
            case 3:
                return (
                    <Fragment>
                        <TablePaginatedVoucherSearch
                            title=""
                            columns={columns}
                            numeroFilas={5}
                            requestConfig={requestConfigSeacrh}
                            BotonesHeader={headerBottons}
                            paramSearch={paramSearch}
                            seleccion={false}
                        />

                    </Fragment>
                );
            case 4:
                return (
                    <Fragment>
                        <TablePaginatedSearchVoucher
                            title=""
                            columns={columns}
                            numeroFilas={5}
                            requestConfig={requestConfigSeacrh}
                            BotonesHeader={headerBottons}
                            paramSearch={paramSearch}
                            seleccion={false}
                        />

                    </Fragment>
                );
            default:
                break;
        }
    }

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    return (
        <div className={classesView.root}>
            <SearchVoucher
                update={update}
                setUpdate={setUpdate}
                setParamSearch={setParamSearch}
            />
            <Accordion square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <Typography variant="h3" color="primary"  ><strong>Importante</strong></Typography>
                </AccordionSummary>
                <AccordionDetails>

                    <Typography variant="subtitle1" gutterBottom>
                        <h6>En esta página se mostrarán los comprobantes que se hayan generado, pero no tienen autorización del SRI, los posibles estados y motivos son  los siguientes:</h6>
                        <strong>4:</strong> El servicio del SRI no está disponible
                        <br />
                        <strong>5:</strong> Existe demora en la Autorización del Comprobante
                        <br />
                        <strong>10:</strong> Comprobante con Clave de Acceso en procesamiento
                    </Typography>
                </AccordionDetails>
            </Accordion>
            {renderTable()}
        </div>
    );
}
export default PendingVouchers;