import React, { Fragment, useState } from "react";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Grid,
  Button,
  Backdrop,
  CircularProgress,
  Divider,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

//Notificacion errores https://iamhosseindhv.com/notistack/demos#variants
import { useSnackbar } from "notistack";
//componentes del sistema
import { palette, useStylesGlobal } from "configurations/styles/theme";
import { httpReportes } from "configurations/axios";
import { API_PUT_UPDATE_VOUCHERS_ATS } from "../apis";
import Alert from "widgets/alert";
import useForm from "hooks/useFrom";
import validations from "./validations";
import { useStylesApp } from "hooks/useStyles";

const useStyles = makeStyles((theme) => ({
  IconButton: {
    marginTop: "0%",
    marginBottom: "0%",
    padding: "0px",
  },
  textFieldComboBox: {
    minWidth: "75%",
    maxWidth: "95%",
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: palette.primary.main,
  },
}));


const EditSupportCode = ({ voucher, updateView }) => {
  const [open, setOpen] = useState(false);
  const classesGlobal = useStylesGlobal();
  const [updateVoucherAts, setUpdateSupportCode] = useState(voucher);
  const [openLoader, setOpenLoader] = useState(false);
  const classes = useStyles();
  const classesApp = useStylesApp();
  const { enqueueSnackbar } = useSnackbar();
  const [msgError, setMsg] = useState({ show: false, msg: null, type: null });

  const existError = () => { };

  const handleSave = async () => {
    setOpenLoader(true);
    await httpReportes
      .put(
        API_PUT_UPDATE_VOUCHERS_ATS + "?codigoSustento=" + updateVoucherAts.codigoSustento + "&idComprobanteRecibido=" + updateVoucherAts.id
      )
      .then((response) => {
        if (response.status === 200) {
          enqueueSnackbar("Código de sustento editado.", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            autoHideDuration: 10000,
          });
          updateView();
          setOpenLoader(false);
          setOpen(false);
        }
      })
      .catch((exception) => {
        if (exception.response) {
          if (exception.response.status === 400) {
            setMsg({ show: true, msg: "No se pudo actualizar el cliente." });
          } else if (exception.response.status === 404) {
            setMsg({
              show: true,
              msg: "No se encontró el cliente a actualizar.",
            });
          } else {
            setMsg({
              show: true,
              msg:
                "Error al registrar el usuario. Contáctese con el administrador del Sistema.",
            });
          }
        } else {
          setMsg({
            show: true,
            msg:
              "Existen problemas en la red. Contáctese con el administrador del Sistema.",
          });
        }
        setOpenLoader(false);
      });
  };

  const { errors, handleSubmit, getObject } = useForm(
    handleSave,
    validations.editVoucherAts,
    existError
  );

  const handleClickOpen = () => {
    setOpen(true);
    setUpdateSupportCode(voucher);
    getObject(voucher);
    cleanErrors();
  };

  const cleanErrors = () => {
    errors.codigoSustento = null;
    errors.correo = null;
    errors.telefono = null;
    errors.direccion = null;
  };

  const handleClose = () => {
    setOpen(false);
  };

  function closeAlert() {
    setMsg({ show: false });
  }

  const handleChangeCode = (event) => {
    setUpdateSupportCode({
      ...updateVoucherAts,
      [event.target.name]: event.target.value,
    });

    getObject({
      ...updateVoucherAts,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Fragment>
      <IconButton
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={handleClickOpen}
        className={classes.IconButton}
      >
        <EditIcon style={{ color: palette.primary.main }} />
      </IconButton>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="max-width-dialog-title">Editar código sustento</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <label>
                  Los campos marcados con (
                  <font color={palette.error.main}> *</font> ) son obligatorios:
                </label>
                {msgError.show ? (
                  <Alert
                    tipo={msgError.type}
                    mensaje={msgError.msg}
                    mesajeSubrayado={null}
                    titulo={null}
                    mostrar={msgError.show}
                    cerrarAlerta={closeAlert}
                  />
                ) : null}
              </Grid>

              <Grid item xs={12} md={3}>
                Código sustento: <font color={palette.error.main}>*</font>
              </Grid>
              <Grid item xs={12} md={4} className={classes.divInfoUser}>
                <input
                  type="text"
                  className={
                    errors.codigoSustento ? "form-control is-invalid" : "form-control"
                  }
                  value={updateVoucherAts.codigoSustento}
                  name="codigoSustento"
                  onChange={handleChangeCode}
                  autoComplete="off"
                />
                {errors.codigoSustento ? (
                  <p className={classesApp.errorLetters}>{errors.codigoSustento}</p>
                ) : null}
              </Grid>
              <Grid container spacing={3} justify="center">
                <Grid item md={12} xs={12}>
                  <Divider />
                </Grid>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={classesGlobal.marginButton}
                >
                  Guardar
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleClose}
                  className={classesGlobal.marginButton}
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      <Backdrop className={classes.backdrop} open={openLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
};

export default EditSupportCode;
