import React from 'react';

//estilos
import '../assets/css/base.css'

const Footer = () => {
    const date= new Date();
    return (  
        <footer >
            Derechos Reservados  BIGDATA C.A. &copy; {date.getFullYear()}
        </footer>
    );
}

export default Footer;