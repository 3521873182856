import React, { useState, Fragment } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Divider,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  registro: {
    textAlign: "center",
    justifyContent: "space-around",
  },
  texto: {
    fontSize: "13px",
  },
  link: {
    fontSize: "13px",
    marginBottom: "20px",
    textDecoration: "none",
    display: "flex",
    justifyContent: "center",
    color: '#007bff',
  },
}));

const ManualesModal = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const abrirModalManuales = () => {
    window.open('https://facturar.ec/manuales');
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <Grid item md={12} className={classes.registro}>
        {/* <Link
          onClick={abrirModalManuales}
          variant="contained"
          color="primary"
          fullWidth
          underline="always"
          className={classes.link}
         
        >
          Manual
        </Link> */}
        <Divider></Divider>
      </Grid>
      <Dialog
        maxWidth="xs"
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="primer logeo"
      >
        <DialogTitle id="responsive-dialog-title">
          <h6> Seleccione un manual </h6>
        </DialogTitle>
        <DialogContent>
          <div>
            <Link>Manuales</Link>
            <div className="mt-2" align="left">
              <Link>Manual Registro y Recuperación Contraseña Usuario</Link>
            </div>
            <div className="mt-2" align="left">
              <Link>Manual Usuario Administrador - Versión 1.0</Link>
            </div>
            <div className="mt-2" align="left">
              <Link>Manual Usuario Interno (Facturador) - Versión 1.0</Link>
            </div>
          </div>
          <br></br>
          <Grid item md={12}>
            <Divider  ></Divider>
          </Grid>
          <br></br>
          <Grid item align="center" md={12}>
            <Button variant="contained" color="secondary" onClick={handleClose}>
              <CloseIcon
                style={{
                  marginRight: "2%",
                }}
              />
              Cerrar
            </Button>
          </Grid>
          <br></br>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default ManualesModal;
