import React, { Fragment } from "react";

//mostrar empresa
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Card,
  Divider,
  CardHeader,
} from "@material-ui/core";

//material-ui.com
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
//componentes del sistema

import { palette, useStylesGlobal } from "configurations/styles/theme";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SomeVoucher from "./SomeVoucher";
import OneToOne from "./OneToOne";
import { useSelector } from "react-redux";
import { getRUCEmpresa } from "redux/actions/userActions";
const useStyles = makeStyles((theme) => ({
  cardContent: {
    width: "94%",
    height: "300px",
    margin: "3%",
  },
  fragment: {
    padding: "10px",
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  accordionSumary: {
    backgroundColor: palette.primary.light,
  },
}));

const UploadVoucher = (props) => {
  const classes = useStyles();
  const classesGlobales = useStylesGlobal();
  const tokenUser = useSelector((state) => state.user.tk);

  const [value, setValue] = React.useState("uno");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (

    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon //style={{ color: palette.primary.contrastText }}
            />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        // classes={{ root: classes.accordionSumary }}
        >
          <label className={classesGlobales.titleCard}>Cargar Comprobantes Recibidos: </label>
          <label className={classesGlobales.click}><label>{" "}</label> (Clic Aquí)</label>
        </AccordionSummary>
        <AccordionDetails>

          <Fragment >
            <Card className={classes.cardContent}>
              <CardHeader
                className={classesGlobales.cabecera}
                title={"Carga de comprobantes recibidos"}
                avatar={<CloudUploadIcon></CloudUploadIcon>}
              />
              <Grid>
                <Divider className={classes.divider} />
              </Grid>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={2}
              >
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    defaultValue="top"
                    value={value}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="uno"
                      control={<Radio color="primary" />}
                      label="Uno a uno"
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      value="varios"
                      control={<Radio color="primary" />}
                      label="Carga masiva"
                      labelPlacement="start"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid>
                <Divider className={classes.divider} />
              </Grid>
              {value === "uno" ? (
                <OneToOne data={tokenUser ? getRUCEmpresa(tokenUser) : ''}></OneToOne>
              ) : (
                <SomeVoucher data={tokenUser ? getRUCEmpresa(tokenUser) : ''}></SomeVoucher>
              )}

              <Grid>
                <Divider className={classes.divider} />
              </Grid>
            </Card>
          </Fragment >


        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default UploadVoucher;
