import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from 'notistack';
import React, { useState } from 'react'; import {
    Grid,
    Button,
    Card
} from "@material-ui/core";
import { palette } from 'configurations/styles/theme';
import PageviewIcon from "@material-ui/icons/Pageview";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import moment from 'moment'


const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginBottom: "0.5%",
    },
    heading: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightRegular,
        color: palette.primary.menuHeader,
    },
    accordionSumary: {
        backgroundColor: palette.primary.light,
    },
    margin: {
        margin: theme.spacing(0),
        width: "100%",
        marginBottom: "1%",
        marginTop: '2%',
        marginLeft: '5px'
    },
}));

export default function FilterVoucherCount({ update, setUpdate, getReportCount }) {
    const classes = useStyles();
    const [dateStart, setDateStart] = useState(new Date());
    const [dateEnd, setDateEnd] = useState(new Date());
    const { enqueueSnackbar } = useSnackbar();

    const validationInputs = () => {
        let cadena = "";
        if (
            dateStart.toString().trim() === "" &&
            dateEnd.toString().trim() === ""

        ) {
            return enqueueSnackbar(`Ingrese todos los parámetros`, {
                variant: "warning",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            });

        } else {
            if (dateStart.toString().trim() !== "" && dateEnd.toString().trim() !== "") {
                cadena = `${cadena}&fechaInicio=${dateStart}&fechaFin=${dateEnd}`;
            }
        }
        //esto es con tabla paginada

        //limpiar buscador
        //cleanInputs();
        //llamo al afuncion de búsqueda
        dateStart.setHours(0, 0, 0);
        dateEnd.setHours(23, 59, 59);

        getReportCount({
            fechaInicio: moment(dateStart).format('YYYY-MM-DD HH:mm:ss'),
            fechaFin: moment(dateEnd).format('YYYY-MM-DD HH:mm:ss'),
        });
        //setUpdate(3);
    };

    // const cleanInputs = () => {
    //     setDateStart("");
    //     setDateEnd("");
    // };

    return (
        <div className={classes.root}>

            <Card >

                <form className={classes.margin}>
                    <Grid container spacing={1} justify="center">
                        <Grid item xs={1}>
                            <label >Fecha inicial: </label>
                        </Grid>
                        <Grid item xs={5}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                <DatePicker
                                    className={dateStart ? "form-control  is-valid" : "form-control "}
                                    disableToolbar
                                    autoOk
                                    disableFuture
                                    invalidDateMessage={"Formato invalido"}
                                    placeholder="Ej. 20-01-2020"
                                    //minDate={dateToday}
                                    InputAdornmentProps={{ position: "start" }}
                                    variant="dialog"
                                    inputVariant="outlined"
                                    cancelLabel="Cancelar"
                                    okLabel="Seleccionar"
                                    format="dd-MM-yyyy"
                                    id="date-picker-inline"
                                    value={dateStart}
                                    onChange={event => setDateStart(event)}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        {/** Fecha final */}
                        <Grid item xs={1}>
                            <label>Fecha final:</label>
                        </Grid>
                        <Grid item xs={5}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                <DatePicker
                                    className={dateEnd ? "form-control  is-valid" : "form-control "}
                                    disableToolbar
                                    autoOk
                                    disableFuture
                                    invalidDateMessage={"Formato invalido"}
                                    placeholder="Ej. 20-01-2020"
                                    minDate={dateStart}
                                    InputAdornmentProps={{ position: "start" }}
                                    variant="dialog"
                                    inputVariant="outlined"
                                    cancelLabel="Cancelar"
                                    okLabel="Seleccionar"
                                    format="dd-MM-yyyy"
                                    id="date-picker-inline"
                                    value={dateEnd}
                                    onChange={event => setDateEnd(event)}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>


                        <Grid item xs={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                align="center"
                                size='large'
                                startIcon={<PageviewIcon />}
                                onClick={validationInputs}
                            >
                                {" "}
                                Generar Reporte
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Card>
        </div>
    );
}