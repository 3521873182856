import React, { Fragment, useState } from 'react';
import {
    Button, Dialog, DialogTitle, DialogContent, makeStyles, Grid,
    Radio, RadioGroup, FormControlLabel, FormControl, Backdrop, CircularProgress, Divider
} from '@material-ui/core';
//Notificacion errores https://iamhosseindhv.com/notistack/demos#variants
import { useSnackbar } from 'notistack';
//componentes del sistema
import { palette, useStylesGlobal } from 'configurations/styles/theme';
import { useSelector } from 'react-redux';
import { getRUCEmpresa } from 'redux/actions/userActions';
import { httpReportes } from 'configurations/axios';
import Alert from 'widgets/alert';
import useForm from 'hooks/useFrom';
import validations from '../validations';
import { API_POST_SAVE_PROVIDERS } from '../apis';
import { useStylesApp } from 'hooks/useStyles';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginTop: theme.spacing(0),
        minWidth: 120,
    },
    radio: {
        display: '-webkit-box',
        marginTop: '0px'
    },
    radioLable: {
        color: palette.primary.main,
        display: 'flex'
    },
    radioButton: {
        marginTop: '0px',
        paddingTop: '0px'
    },
    divForm: {
        marginBottom: '1%',
    },
    divInput: {
        marginBottom: '1%',
        paddingRight: '1%',
        paddingLeft: '0.5%'
    },
    divButton: {
        paddingLeft: '3%',
        paddingRight: '3%'
    },
    backdrop: {
        zIndex: theme.zIndex.modal + 999,
        color: palette.primary.main,
    },
}));

const AddProviderBtn = ({ updateView }) => {
    const classes = useStyles();
    const classesApp = useStylesApp();
    const classesGlobal = useStylesGlobal();
    const [openLoader, setOpenLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const [pasaporteCheck, setPasaporteCheck] = useState("C");
    const userToken = useSelector(state => state.user.tk);
    const [msgError, setMsg] = useState({ show: false, msg: null, type: null });
    const { enqueueSnackbar } = useSnackbar();
    const [provider, setProvider] = useState({
        rucEmpresa: userToken ? getRUCEmpresa(userToken) : "0",
        convenioDobleTributacion: false,
        pagoExterior: false,
        pagoExteriorConNormativaLegal: false,
        paraisoFiscal: false,
        parteRelacionada: false,
    });

    const existError = () => {
        setOpenLoader(false);
    }

    const handleSave = async () => {
        setOpenLoader(true);
        console.log(provider)
        await httpReportes.post(API_POST_SAVE_PROVIDERS, provider).then(
            response => {
                if (response.status === 200) {
                    enqueueSnackbar("Proveedor almacenado.", {
                        variant: 'success',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                        autoHideDuration: 10000,
                    });
                    updateView();
                    setOpenLoader(false);
                    setOpen(false);
                }
            }
        ).catch(
            exception => {
                if (exception.response) {
                    if (exception.response.status === 400) {
                        setMsg({ show: true, msg: 'No se pudo almacenar el proveedor.' });
                    } else if (exception.response.status === 404) {
                        setMsg({ show: true, msg: 'No se encontró la empresa enviada para relacionarla con el proveedor.' });
                    } else if (exception.response.status === 409) {
                        setMsg({ show: true, msg: 'El proveedor ya existe.' });
                    } else {
                        setMsg({ show: true, msg: 'Error al registrar el proveedor. Contáctese con el administrador del Sistema.' });
                    }
                } else {
                    setMsg({ show: true, msg: 'Existen problemas en la red. Contáctese con el administrador del Sistema.' });
                }
                setOpenLoader(false);
            }
        );
    }

    const { errors, handleSubmit, getObject } = useForm(handleSave, validations.addProvider, existError);

    function closeAlert() { setMsg({ show: false }); }


    const handleClickOpen = () => {
        setOpen(true);
        setProvider({
            rucEmpresa: userToken ? getRUCEmpresa(userToken) : "0",
            convenioDobleTributacion: false,
            pagoExterior: false,
            pagoExteriorConNormativaLegal: false,
            paraisoFiscal: false,
            parteRelacionada: false,
        });
        setMsg({ show: false, msg: null, type: null });
        errors.rucProveedor = null;
        errors.razonSocial = null;
        errors.correoCliente = null;
        errors.telefonoCliente = null;
        errors.direccionCliente = null;
    };

    const handleClose = () => {
        setOpen(false);
    };



    const handleChangeCheck = event => {
        setProvider({
            ...provider,
            [event.target.name]: event.target.value === "S" ? true : false,
        });
        getObject({
            ...provider,
            typeDocument: event.target.value
        });
    }

    const handleChange = event => {
        setProvider({
            ...provider,
            [event.target.name]: event.target.value
        });
        getObject({
            ...provider,
            [event.target.name]: event.target.value,
            typeDocument: pasaporteCheck
        });
    }

    return (
        <Fragment>
            <Button variant="contained" color="primary" onClick={handleClickOpen}>
                <PersonAddIcon style={{ color: palette.primary.contrastText, paddingRight: '3%' }} fontSize="small" />  Agregar Proveedor
            </Button>
            <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle id="max-width-dialog-title">Agregar proveedor</DialogTitle>
                <DialogContent>
                    <form onSubmit={event => { setOpenLoader(true); handleSubmit(event); }}>
                        <Grid container direction="row" justify="flex-start" alignItems="center">
                            <Grid item xs={12} md={12}>
                                <label>Los campos marcados con (  <font color={palette.error.main}> *</font> ) son obligatorios:</label>
                            </Grid>
                            {msgError.show ?
                                <Alert
                                    tipo={msgError.type}
                                    mensaje={msgError.msg}
                                    mesajeSubrayado={null}
                                    titulo={null}
                                    mostrar={msgError.show}
                                    cerrarAlerta={closeAlert}
                                /> : null
                            }
                            <Grid item xs={12} md={3} className={classes.divForm}>
                                <label>Identificación: <font color={palette.error.main}>*</font></label>
                            </Grid>
                            <Grid item xs={12} md={3} className={classes.divInput}>
                                <input type="text" className={errors.rucProveedor ? 'form-control is-invalid' : 'form-control'}
                                    placeholder="Ej. 1737199872"
                                    name="rucProveedor"
                                    value={provider.rucProveedor}
                                    onChange={handleChange}
                                    autoComplete="off"
                                />
                                {errors.rucProveedor ?
                                    <p className={classesApp.errorLetters}>{errors.rucProveedor}</p>
                                    :
                                    null
                                }
                            </Grid>

                            <Grid item xs={12} md={1} className={classes.divForm}>
                                <label>Nombre: <font color={palette.error.main}>*</font></label>
                            </Grid>
                            <Grid item xs={12} md={5} className={classes.divInput}>
                                <input type="text" className={errors.razonSocial ? 'form-control is-invalid' : 'form-control'}
                                    placeholder="Ej. Juan Mauricio Pérez Calle"
                                    name="razonSocial"
                                    value={provider.razonSocial}
                                    onChange={handleChange}
                                    autoComplete="off" />
                                {errors.razonSocial ?
                                    <p className={classesApp.errorLetters}>{errors.razonSocial}</p>
                                    :
                                    null
                                }
                            </Grid>

                            <Grid item xs={12} md={3} className={classes.divlabel}>
                                Tipo: <font color={palette.error.main}> *</font>
                            </Grid>
                            <Grid item md={3} xs={12} className={classes.divInput}>
                                <select
                                    name="tipo"
                                    className={
                                        errors.tipo
                                            ? "form-control is-invalid"
                                            : "form-control"
                                    }
                                    value={provider.tipo}
                                    onChange={handleChange}
                                >
                                    <option value="">--Seleccione--</option>
                                    <option value="01">Persona natural</option>
                                    <option value="02">Sociedad</option>
                                </select>
                                {errors.tipo ? (
                                    <p className={classesApp.errorLetters}>
                                        {errors.tipo}
                                    </p>
                                ) : null}
                            </Grid>

                            <Grid item xs={12} md={3} className={classes.divlabel}>
                                Tipo régimen fiscal exterior: <font color={palette.error.main}> *</font>
                            </Grid>
                            <Grid item md={3} xs={12} className={classes.divInput}>
                                <select
                                    name="tipoRegimenFiscal"
                                    className={
                                        errors.tipoRegimenFiscal
                                            ? "form-control is-invalid"
                                            : "form-control"
                                    }
                                    value={provider.tipoRegimenFiscal}
                                    onChange={handleChange}
                                >
                                    <option value="">--Seleccione--</option>
                                    <option value="01">Régimen general</option>
                                    <option value="02">Paraíso fiscal</option>
                                    <option value="03">Régimen fiscal preferente o jurisdicción de menor imposición</option>
                                </select>
                                {errors.tipoRegimenFiscal ? (
                                    <p className={classesApp.errorLetters}>
                                        {errors.tipoRegimenFiscal}
                                    </p>
                                ) : null}
                            </Grid>

                            <Grid item xs={12} md={3} className={classes.divlabel}>
                                Código país: <font color={palette.error.main}> *</font>
                            </Grid>
                            <Grid item xs={12} md={3} className={classes.divInput}>
                                <input type="text" className={errors.codigoPais ? 'form-control is-invalid' : 'form-control'}
                                    value={provider.codigoPais}
                                    name="codigoPais"
                                    onChange={handleChange}
                                    autoComplete="off"
                                />
                                {errors.codigoPais ?
                                    <p className={classesApp.errorLetters}>{errors.codigoPais}</p>
                                    :
                                    null
                                }
                            </Grid>

                            <Grid item xs={12} md={3} className={classes.divlabel}>
                                Código régimen: <font color={palette.error.main}>*</font>
                            </Grid>
                            <Grid item xs={12} md={3} className={classes.divInput}>
                                <input type="text" className={errors.codigoRegimen ? 'form-control is-invalid' : 'form-control'}
                                    value={provider.codigoRegimen}
                                    name="codigoRegimen"
                                    onChange={handleChange}
                                    autoComplete="off"
                                />
                                {errors.codigoRegimen ?
                                    <p className={classesApp.errorLetters}>{errors.codigoRegimen}</p>
                                    :
                                    null
                                }
                            </Grid>

                            <Grid item xs={12} md={3} >
                                Convenio doble tributación: <font color={palette.error.main}>*</font>
                            </Grid>
                            <Grid item xs={12} md={3}   >
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <RadioGroup
                                        name="convenioDobleTributacion"
                                        className={classes.radio}
                                        value={provider.convenioDobleTributacion ? "S" : "N"}
                                        onChange={handleChangeCheck}
                                    >
                                        <FormControlLabel
                                            value="S"
                                            control={<Radio color="primary" className={classes.radioButton} />}
                                            label="Si"
                                            labelPlacement="start"
                                            className={classes.radioLable}
                                        />
                                        <FormControlLabel
                                            value="N"
                                            control={<Radio color="primary" className={classes.radioButton} />}
                                            label="No"
                                            labelPlacement="start"
                                            className={classes.radioLable}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={3} >
                                Pago exterior: <font color={palette.error.main}>*</font>
                            </Grid>
                            <Grid item xs={12} md={3}   >
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <RadioGroup
                                        name="pagoExterior"
                                        className={classes.radio}
                                        value={provider.pagoExterior ? "S" : "N"}
                                        onChange={handleChangeCheck}
                                    >
                                        <FormControlLabel
                                            value="S"
                                            control={<Radio color="primary" className={classes.radioButton} />}
                                            label="Si"
                                            labelPlacement="start"
                                            className={classes.radioLable}
                                        />
                                        <FormControlLabel
                                            value="N"
                                            control={<Radio color="primary" className={classes.radioButton} />}
                                            label="No"
                                            labelPlacement="start"
                                            className={classes.radioLable}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>



                            <Grid item xs={12} md={3} >
                                Paraíso fiscal: <font color={palette.error.main}>*</font>
                            </Grid>
                            <Grid item xs={12} md={3}   >
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <RadioGroup
                                        name="paraisoFiscal"
                                        className={classes.radio}
                                        value={provider.paraisoFiscal ? "S" : "N"}
                                        onChange={handleChangeCheck}
                                    >
                                        <FormControlLabel
                                            value="S"
                                            control={<Radio color="primary" className={classes.radioButton} />}
                                            label="Si"
                                            labelPlacement="start"
                                            className={classes.radioLable}
                                        />
                                        <FormControlLabel
                                            value="N"
                                            control={<Radio color="primary" className={classes.radioButton} />}
                                            label="No"
                                            labelPlacement="start"
                                            className={classes.radioLable}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={3} >
                                Parte relacionada: <font color={palette.error.main}>*</font>
                            </Grid>
                            <Grid item xs={12} md={3}   >
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <RadioGroup
                                        name="parteRelacionada"
                                        className={classes.radio}
                                        value={provider.parteRelacionada ? "S" : "N"}
                                        onChange={handleChangeCheck}
                                    >
                                        <FormControlLabel
                                            value="S"
                                            control={<Radio color="primary" className={classes.radioButton} />}
                                            label="Si"
                                            labelPlacement="start"
                                            className={classes.radioLable}
                                        />
                                        <FormControlLabel
                                            value="N"
                                            control={<Radio color="primary" className={classes.radioButton} />}
                                            label="No"
                                            labelPlacement="start"
                                            className={classes.radioLable}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={3} >
                                Pago exterior con normativa legal: <font color={palette.error.main}>*</font>
                            </Grid>
                            <Grid item xs={12} md={3}   >
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <RadioGroup
                                        name="pagoExteriorConNormativaLegal"
                                        className={classes.radio}
                                        value={provider.pagoExteriorConNormativaLegal ? "S" : "N"}
                                        onChange={handleChangeCheck}
                                    >
                                        <FormControlLabel
                                            value="S"
                                            control={<Radio color="primary" className={classes.radioButton} />}
                                            label="Si"
                                            labelPlacement="start"
                                            className={classes.radioLable}
                                        />
                                        <FormControlLabel
                                            value="N"
                                            control={<Radio color="primary" className={classes.radioButton} />}
                                            label="No"
                                            labelPlacement="start"
                                            className={classes.radioLable}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                            <Grid container spacing={3} justify='center' >
                                <Grid item md={12} xs={12} >
                                    <Divider></Divider>
                                </Grid>
                                <Button variant="contained" color="primary" type="submit" className={classesGlobal.marginButton} >
                                    Agregar
                                </Button>
                                <Button variant="outlined" color="secondary" onClick={handleClose} className={classesGlobal.marginButton} >
                                    Cancelar
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>
            <Backdrop className={classes.backdrop} open={openLoader} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Fragment>
    );
}

export default AddProviderBtn;