import React, { Fragment,  } from 'react';
import { makeStyles, Grid, Paper } from '@material-ui/core';
import { palette, useStylesGlobal } from 'configurations/styles/theme';
import bigDecimal from 'js-big-decimal';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.primary,
        margin: theme.spacing(1),
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: '1%',
        borderStyle: 'solid',
        boxShadow: "5px 3px 3px #aaaaaa",
        borderRadius: '8px',
        borderWidth: '1px',
        alignItems: 'baseline',
    },
    formControl: {
        marginTop: theme.spacing(0),
        minWidth: 120,

    },
    radio: {
        display: '-webkit-box',
        marginTop: '0px'
    },
    radioLable: {
        color: palette.primary.main,
        display: 'flex'
    },
    radioButton: {
        marginTop: '0px',
        paddingTop: '0px'
    },
    divForm: {
        marginBottom: '1%',
        textAlign: 'justify',
    },
    divInput: {
        marginBottom: '1%',
        paddingRight: '1%',
        paddingLeft: '0.5%',
        textAlign: 'justify',

    },
    divButton: {
        paddingLeft: '3%',
        paddingRight: '3%',
        marginTop:'1%',
    },
    backdrop: {
        zIndex: theme.zIndex.modal + 999,
        color: palette.primary.main,
    },
    haedClient: {
        backgroundColor: palette.primary.main,
        width: '1%',
        marginBottom: '2%',
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: '1%',
        borderStyle: 'solid',
        borderBottomWidth: '5px',
        borderRadius: '8px',
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'inherit',
        maxWidth: 570,
        //marginBottom: '1%',
        borderStyle: 'solid',
        borderRadius: '8px',
        borderWidth: '1px',
        display: 'flex',
        flexWrap: 'wrap',
    },
    totalLabel: {
        padding: "2%",
        borderRadius: "8px",
        borderWidth: "2px",
        borderStyle: "solid",
        marginBottom: "1%"
    }
}));

const Calculations = ({ totals, setTotals }) => {


    const classes = useStyles();
    const classesGlobales = useStylesGlobal();

    const handleTip = (event) => {
        setTotals({
            ...totals,
            totalPropina: Number(event.target.value),
        })

    }
    const handleChange = event => {
        event.preventDefault();
        const re = /^[0-9\b.]{1,14}$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            setTotals({ ...totals, ['totalPropina']: event.target.value });
        }
    }
    const sumTip = (totalFactura, totalPropina) => {
        return (totalFactura + totalPropina);
    }

    return (
        <Fragment>
            <div className={classes.root} >
                <Paper className={classes.paper}>
                    <Grid container fullWidth maxWidth="xs" spacing={2}>
                        { bigDecimal.round(totals.subTotal_5, 2, bigDecimal.RoundingModes.HALF_UP) > 0 &&
                        (
                            <React.Fragment>
                                <Grid item xs={12} md={5} className={classes.divForm}>
                                    <label>Subtotal 5%:</label>
                                </Grid>
                                <Grid item xs={12} md={7} className={classes.divInput}>
                                    <input className={classesGlobales.contInput}

                                        type="text"
                                        disabled
                                        placeholder="0.0"
                                        name="subTotal_5"
                                        value={bigDecimal.round(totals.subTotal_5, 2, bigDecimal.RoundingModes.HALF_UP)}
                                    />
                                </Grid>
                            </React.Fragment>
                        )}
                        <Grid item xs={12} md={5} className={classes.divForm}>
                            <label>Subtotal 12%:</label>
                        </Grid>
                        <Grid item xs={12} md={7} className={classes.divInput}>
                            <input className={classesGlobales.contInput}
                                type="text"
                                disabled
                                placeholder="0.0"
                                name="subTotal_12"
                                value={bigDecimal.round(totals.subTotal_12, 2, bigDecimal.RoundingModes.HALF_UP)}
                                textAlign='left'

                            />
                        </Grid>

                        <Grid item xs={12} md={5} className={classes.divForm}>
                            <label>Subtotal 15%:</label>
                        </Grid>
                        <Grid item xs={12} md={7} className={classes.divInput}>
                            <input className={classesGlobales.contInput}
                                type="text"
                                disabled
                                placeholder="0.0"
                                name="subtotal_15"
                                value={bigDecimal.round(totals.subtotal_15, 2, bigDecimal.RoundingModes.HALF_UP)}
                            />
                        </Grid>

                        <Grid item xs={12} md={5} className={classes.divForm}>
                            <label>Subtotal 0%:</label>
                        </Grid>
                        <Grid item xs={12} md={7} className={classes.divInput}>
                            <input className={classesGlobales.contInput}
                                type="text"
                                disabled
                                placeholder="0.0"
                                name="subTotal_0"
                                value={bigDecimal.round(totals.subTotal_0, 2, bigDecimal.RoundingModes.HALF_UP)}
                            />
                        </Grid>
                        <Grid item xs={12} md={5} className={classes.divForm}>
                            <label>Subtotal no IVA:</label>
                        </Grid>
                        <Grid item xs={12} md={7} className={classes.divInput}>
                            <input className={classesGlobales.contInput}
                                type="text"
                                disabled
                                placeholder="0.0"
                                name="subTotal_noIva"
                                value={bigDecimal.round(totals.subTotal_noIva, 2, bigDecimal.RoundingModes.HALF_UP)}
                            />
                        </Grid>

                        <Grid item xs={12} md={5} className={classes.divForm}>
                            <label>Total Descuento:</label>
                        </Grid>
                        <Grid item xs={12} md={7} className={classes.divInput}>
                            <input className={classesGlobales.contInput}
                                type="text"
                                disabled
                                placeholder="0.0"
                                name="totalDescuento"
                                value={bigDecimal.round(totals.totalDescuento, 2, bigDecimal.RoundingModes.HALF_UP)}
                            />
                        </Grid>
                    </Grid>
                </Paper>

                <Paper className={classes.paper}>
                    <Grid container
                        fullWidth maxWidth="xs" spacing={2}>
                        <Grid item xs={12} md={5} className={classes.divForm}>
                            <label>Subtotal sin Impuestos:</label>
                        </Grid>
                        <Grid item xs={12} md={7} className={classes.divInput}>
                            <input className={classesGlobales.contInput}
                                type="text"
                                disabled
                                placeholder="0.0"
                                name="subTotalSinImpuestos"
                                value={bigDecimal.round(totals.subTotalSinImpuestos, 2, bigDecimal.RoundingModes.HALF_UP)}
                            />
                        </Grid>

                        {/*<Grid item xs={12} md={5} className={classes.divForm}>
                            <label>ICE:</label>
                        </Grid>
                        <Grid item xs={12} md={7} className={classes.divInput}>
                            <input className={classesGlobales.contInput}
                                type="text"
                                disabled
                                placeholder="0.0"
                                name="totalIce"
                                value={totals.totalIce.toFixed(2)}
                            />
    </Grid>*/}

                        {/*<Grid item xs={12} md={5} className={classes.divForm}>
                            <label>Propina:</label>
                        </Grid>
                        <Grid item xs={12} md={7} className={classes.divInput}>
                            <input className={classesGlobales.contInput}
                                type="text"
                                placeholder="0.0"
                                name="totalPropina"
                                value={totals.totalPropina.toFixed(2)}
                                onChange={handleChange}
                            />
    </Grid>*/}
                        {
                            bigDecimal.round(totals.totalIva_5, 2, bigDecimal.RoundingModes.HALF_UP) > 0 &&
                            <React.Fragment>
                                <Grid item xs={12} md={5} className={classes.divForm}>
                                    <label>IVA 5%:</label>
                                </Grid>
                                <Grid item xs={12} md={7} className={classes.divInput} >
                                    <input className={classesGlobales.contInput}
                                        type="text"
                                        disabled
                                        placeholder="0.0"
                                        name="totalIva_5"
                                        value={bigDecimal.round(totals.totalIva_5, 2, bigDecimal.RoundingModes.HALF_UP)}
                                        justtify='flex-end'
                                    />
                                </Grid>
                            </React.Fragment>
                        }
                        <Grid item xs={12} md={5} className={classes.divForm}>
                            <label>IVA 12%:</label>
                        </Grid>
                        <Grid item xs={12} md={7} className={classes.divInput}>
                            <input className={classesGlobales.contInput}
                                type="text"
                                disabled
                                placeholder="0.0"
                                name="totalIva_12"
                                value={bigDecimal.round(totals.totalIva_12, 2, bigDecimal.RoundingModes.HALF_UP)}
                            />
                        </Grid>

                        <Grid item xs={12} md={5} className={classes.divForm}>
                            <label>IVA 15%:</label>
                        </Grid>
                        <Grid item xs={12} md={7} className={classes.divInput} >
                            <input className={classesGlobales.contInput}
                                type="text"
                                disabled
                                placeholder="0.0"
                                name="totalIva_15"
                                value={bigDecimal.round(totals.totalIva_15, 2, bigDecimal.RoundingModes.HALF_UP)}
                                justtify='flex-end'
                            />
                        </Grid>
                    </Grid>
                </Paper>
                <Grid container justify='center' fullWidth maxWidth="xs" spacing={2}  className={classes.divButton}>
                    <Grid item xs={12} md={10} className={classes.totalLabel} align="center">
                        <strong>Importe Total: {bigDecimal.round(bigDecimal.add(totals.totalFactura, totals.totalPropina), 2, bigDecimal.RoundingModes.HALF_UP)}</strong>
                    </Grid>
                </Grid>
            </div>
        </Fragment>

    );
}

export default Calculations;
