/* eslint-disable no-nested-ternary */
import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Collapse from "@material-ui/core/Collapse";
import ListItemText from "@material-ui/core/ListItemText";
import { palette } from "configurations/styles/theme";
import { Divider } from "@material-ui/core";
import { useDispatch } from "react-redux";
import {
  selectedResourceAction,
  addResourceDeniedAction,
} from "redux/actions/resourcesActions";
import { useSelector } from "react-redux";
import usePermissions from "hooks/usePermissions";
//para los íconos
import HomeIcon from "@material-ui/icons/Home";
import DescriptionIcon from "@material-ui/icons/Description";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import NoteAddOutlinedIcon from "@material-ui/icons/NoteAddOutlined";
import PlaylistAddOutlinedIcon from "@material-ui/icons/PlaylistAddOutlined";
import TocOutlinedIcon from "@material-ui/icons/TocOutlined";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
import LocalAtmRoundedIcon from '@material-ui/icons/LocalAtmRounded';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import ExposureOutlinedIcon from '@material-ui/icons/ExposureOutlined';
import VpnLockOutlinedIcon from '@material-ui/icons/VpnLockOutlined';
import { AddToQueue, CloudUpload } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: 360,
  },
  lists: {
    backgroundColor: theme.palette.primary.menuHeader,
    marginTop: theme.spacing(1),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  iconoMenu: {
    color: palette.primary.main,
    marginRight: "3%",
  },
  iconoSubMenu: {
    color: "black",
    marginRight: "3%",
    fontSize: "medium",
  },
}));

function ListItemLink(props) {
  const classes = useStyles();
  const { nameItem, open, id, ...other } = props;

  const obtenerIconoPagina = (id) => {
    switch (id) {
      case "INI":
        return <HomeIcon className={classes.iconoMenu} fontSize="small" />;
      case "ADM":
        return (
          <SupervisorAccountIcon
            className={classes.iconoMenu}
            fontSize="small"
          />
        );
      case "CLI":
      case "USU":
        return <PersonOutlineOutlinedIcon className={classes.iconoSubMenu} />;
      case "PRO":
        return <ListAltOutlinedIcon className={classes.iconoSubMenu} />;
      case "DCE":
        return <ExposureOutlinedIcon className={classes.iconoSubMenu} />
      case "PRV":
        return <PeopleAltOutlinedIcon className={classes.iconoSubMenu} />;
      case "REP":
        return (
          <InsertDriveFileOutlinedIcon
            className={classes.iconoMenu}
            fontSize="small"
          />
        );
      case "AUT":
      case "CRA":
      // case "CAC":
      case "NAU":
      case "ANU":
      case "PEN":
        return <DescriptionOutlinedIcon className={classes.iconoSubMenu} />;
      case "GEN":
        return (
          <NoteAddOutlinedIcon className={classes.iconoMenu} fontSize="small" />
        );
      case "ATS":
        return (
          <AddToQueue className={classes.iconoMenu} fontSize="small" />
        );
      case "CFM":
        return (
          <CloudUpload className={classes.iconoSubMenu} />
        );
      case "RAT":
        return <AssessmentOutlinedIcon className={classes.iconoSubMenu} />;
      case "ACR":
        return <DescriptionOutlinedIcon className={classes.iconoSubMenu} />;
      case "RRT":
        return <AssessmentOutlinedIcon className={classes.iconoSubMenu} />;
      case "APR":
        return <PeopleAltOutlinedIcon className={classes.iconoSubMenu} />;
      case "ACL":
        return <PersonOutlineOutlinedIcon className={classes.iconoSubMenu} />;
      case "FAC":
      case "LIQ":
      case "GUI":
      case "RET":
      case "DEB":
      case "CRE":
        return <DescriptionOutlinedIcon className={classes.iconoSubMenu} />;

      case "CSE":
        return <PlaylistAddOutlinedIcon className={classes.iconoSubMenu} />;
      case "ASE":
        return <TocOutlinedIcon className={classes.iconoSubMenu} />;
      case "AEM":
        return <BusinessRoundedIcon className={classes.iconoSubMenu} />
      case "CRCO":
        return <VpnLockOutlinedIcon className={classes.iconoSubMenu} />
      /*
      case "APQ":
        return <MonetizationOnOutlinedIcon className={classes.iconoSubMenu} />
        */
      case 40:
        return <DescriptionIcon className={classes.iconoSubMenu} />;
      case 10028:
        return <LocalAtmIcon className={classes.iconoSubMenu} />;
      case 10031:
        return <LocalAtmIcon className={classes.iconoSubMenu} />;
      case "RCE":
        return <AssessmentOutlinedIcon className={classes.iconoSubMenu} />;
      case "RCR":
        return <AssessmentOutlinedIcon className={classes.iconoSubMenu} />;
      case "REPO":
        return <AssessmentOutlinedIcon className={classes.iconoMenu} />;
      default:
        break;
    }
  };

  return (
    <li>
      <ListItem button {...other}>
        {obtenerIconoPagina(id)}
        <ListItemText primary={nameItem} />
        {open != null ? open ? <ExpandLess /> : <ExpandMore /> : null}
      </ListItem>
    </li>
  );
}

const HomePermisson = {
  "id": "INI",
  "nameMenu": "Inicio",
  "titlePage": "Inicio",
  titleTab: "Inicio",
  "menuPadre": "null"
}

export default function RouterBreadcrumbs({ onClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedResource = (resource) =>
    dispatch(selectedResourceAction(resource));
  const addResourceDenied = (resource) =>
    dispatch(addResourceDeniedAction(resource));
  const [open, setOpen] = useState(-1);

  const { getPermissons } = usePermissions(useSelector, dispatch);

  const resources = getPermissons();

  const handleClick = (idResource) => {
    if (idResource !== open) {
      setOpen(idResource);
    } else {
      setOpen(-1);
    }

    if (idResource === 1) {
      selectedResource({
        id: "INI",
        nameMenu: "Inicio",
        titlePage: "Inicio",
        titleTab: "Inicio",
        menuPadre: null,
      });
    }
  };

  const handleClickOptionMenu = (resource) => {
    if (resource.menuPadre === "GEN") {
      selectedResource({
        id: -4,
        nameMenu: "",
        titlePage: "Verificando pre-requisitos",
        menuPadre: null,
      });
      addResourceDenied(resource);
    } else if (resource.id === "INI") {
      selectedResource(resource);
    } else if (resource.menuPadre) {
      selectedResource(resource);
    }

    if (resource.menuPadre) {
      onClose();
    }

    if (resource.id === "INI") {
      onClose();
    }
  };

  const getSubMenu = (idSubMenu) => {
    for (let j = 0; j < resources.length; j++) {
      if (resources[j].id === idSubMenu) {
        return true;
      }
    }
    return false;
  };

  const buildMenu = () => {
    let menu = [{ ...HomePermisson }];
    let subMenu = [];

    menu = menu.concat(resources.filter((item) => item.menuPadre === null))
    subMenu = resources.filter(function (item) {
      if (getSubMenu(item.id)) {
        return true;
      } else {
        return false;
      }
    });

    for (let j = 0; j < menu.length; j++) {
      menu[j] = { ...menu[j], items: [] };
    }

    for (let index = 0; index < subMenu.length; index++) {
      for (let i = 0; i < menu.length; i++) {
        if (subMenu[index].menuPadre === menu[i].id) {
          menu[i] = {
            ...menu[i],
            items: [...menu[i].items, subMenu[index]],
          };
        }
      }
    }

    return menu.map((item) => (
      <Fragment key={item.id}>
        <ListItemLink
          nameItem={item.nameMenu}
          id={item.id}
          open={open === item.id}
          onClick={() => {
            handleClick(item.id);
            handleClickOptionMenu(item);
          }}
          key={item.id}
        />
        <Divider></Divider>
        <Collapse
          component="li"
          in={open === item.id}
          timeout="auto"
          unmountOnExit
        >
          <List disablePadding>
            {item.items.map((optionsMenu) => (
              <Fragment>
                <ListItemLink
                  nameItem={optionsMenu.nameMenu}
                  id={optionsMenu.id}
                  className={classes.nested}
                  key={optionsMenu.id}
                  onClick={() => {
                    handleClick(optionsMenu.id);
                    handleClickOptionMenu(optionsMenu);
                  }}
                />
              </Fragment>
            ))}
          </List>
        </Collapse>
      </Fragment>
    ));
  };

  return (
    <div className={classes.root}>
      <List>{buildMenu()}</List>
    </div>
  );
}
