import {
    AUTH_USER_SUCCESS,
    AUTH_USER_ERROR,
    SIGN_OFF_USER,
    AUTH_USER_TMP,
    UPDATE_STATE_SESSION
} from '../types/userType';

export const typesStatesSession = {
    not_auth: 1,
    auth: 2,
    bad_credentials: 3,
    error_internal: 4,
    blocked: 5,
    disable: 6,
    temp_credendiales: 7
}

const initialState = {
    tk: null,
    ruc: null,
    error: null,
    tmp: false,
    stateSession: typesStatesSession.not_auth
}

export default function (state = initialState, action) {
    switch (action.type) {
        case AUTH_USER_SUCCESS:
            return ({
                tk: action.payload.tk,
                ruc: action.payload.ruc,
                type: action.payload.type,
                error: null,
                stateSession: typesStatesSession.auth
            });
        case AUTH_USER_ERROR:
            return ({
                tk: null,
                error: action.payload,
                stateSession: typesStatesSession.bad_credentials
            });
        case SIGN_OFF_USER:
            return ({
                tk: "",
                error: null,
                stateSession: typesStatesSession.not_auth
            });
        case AUTH_USER_TMP:
            return ({
                tk: "",
                error: null,
                tmp: true,
                stateSession: typesStatesSession.temp_credendiales
            });
        case UPDATE_STATE_SESSION:
            return ({
                ...state,
                stateSession: action.payload,
            });
        default:
            return state;
    }
}