import React, { useEffect, useState } from "react";
import { makeStyles, Grid, Paper } from "@material-ui/core";
import servicesTaxes from "utils/checkTaxes";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    marginTop: "1%",
  },
  container: {
    padding: "2%",
    borderRadius: "8px",
    borderWidth: "1px",
    borderStyle: "solid",
  },
  totalLabel: {
    padding: "2%",
    borderRadius: "8px",
    borderWidth: "2px",
    borderStyle: "solid",
    marginBottom: "1%",
  },
});
const TotalsDocument = ({ listOfProducts, update }) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    Subtotal12: 0,
    SubTotal5: 0,
    Subtotal15: 0,
    Subtotal0: 0,
    SubtotalNoIVA: 0,
    SubtotalSinImpuestos: 0,
    SubtotalICE: 0,
    totalIVA12: 0,
    totalIVA5: 0,
    totalIVA15: 0,
    totalDescuentos: 0,
    total: 0,
    isDescuentoPorcentaje: false
  });

  useEffect(() => {
    if (!update) {
      setValues(servicesTaxes.reportTotalsDetail(listOfProducts));
    }
  }, [update, listOfProducts]);

  return (
    <Grid
      spacing={2}
      className={classes.root}
      container
      direction="row"
      justify="center"
      alignItems="flex-start"
    >
      <Grid item xs={12} md={5}>
        <Paper>
          <Grid container className={classes.container} spacing={1}>
          { values.SubTotal5 > 0 && (
              <React.Fragment>
                <Grid item xs={12} md={3}>
                  <label>Subtotal 5%:</label>
                </Grid>
                <Grid item xs={12} md={7}>
                  <input
                    className={
                      values.SubTotal5 > 0
                        ? "form-control  text-right"
                        : "form-control  text-right"
                    }
                    value={values.SubTotal5}
                    disabled
                  />
                </Grid>
              </React.Fragment>
            )}
            <Grid item xs={12} md={3}>
              <label>Subtotal 12%:</label>
            </Grid>
            <Grid item xs={12} md={7}>
              <input
                className={
                  values.Subtotal12 > 0
                    ? "form-control  text-right"
                    : "form-control  text-right"
                }
                value={values.Subtotal12}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <label>Subtotal 15%:</label>
            </Grid>
            <Grid item xs={12} md={7}>
              <input
                className={
                  values.Subtotal15 > 0
                    ? "form-control  text-right"
                    : "form-control  text-right"
                }
                value={values.Subtotal15}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <label>Subtotal 0%:</label>
            </Grid>
            <Grid item xs={12} md={7}>
              <input
                className={
                  values.Subtotal0 > 0
                    ? "form-control  text-right"
                    : "form-control  text-right"
                }
                value={values.Subtotal0}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <label>Subtotal No IVA:</label>
            </Grid>
            <Grid item xs={12} md={7}>
              <input
                className={
                  values.SubtotalNoIVA > 0
                    ? "form-control  text-right"
                    : "form-control  text-right"
                }
                value={values.SubtotalNoIVA}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <label>Total Descuento:</label>
            </Grid>
            <Grid item xs={12} md={7}>
              <input
                className={
                  values.totalDescuentos > 0
                    ? "form-control   text-right"
                    : "form-control  text-right"
                }
                value={values.totalDescuentos}
                disabled
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper>
          <Grid container className={classes.container} spacing={1}>
            <Grid item xs={12} md={4}>
              <label>Subtotal Sin Impuesto:</label>
            </Grid>
            <Grid item xs={12} md={7}>
              <input
                className={
                  values.SubtotalSinImpuestos > 0
                    ? "form-control  text-right"
                    : "form-control  text-right"
                }
                value={values.SubtotalSinImpuestos}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <label>ICE</label>
            </Grid>
            <Grid item xs={12} md={7}>
              <input
                className={
                  values.SubtotalICE > 0
                    ? "form-control  text-right"
                    : "form-control  text-right"
                }
                value={values.SubtotalICE}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <label>IVA (5%):</label>
            </Grid>
            <Grid item xs={12} md={7}>
              <input
                className={
                  values.totalIVA5 > 0
                    ? "form-control  text-right"
                    : "form-control  text-right"
                }
                value={values.totalIVA5}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <label>IVA (12%):</label>
            </Grid>
            <Grid item xs={12} md={7}>
              <input
                className={
                  values.totalIVA12 > 0
                    ? "form-control  text-right"
                    : "form-control  text-right"
                }
                value={values.totalIVA12}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <label>IVA (15%):</label>
            </Grid>
            <Grid item xs={12} md={7}>
              <input
                className={
                  values.totalIVA15 > 0
                    ? "form-control  text-right"
                    : "form-control  text-right"
                }
                value={values.totalIVA15}
                disabled
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} md={10} className={classes.totalLabel} align="center">
        <strong>Importe Total: {values.total}</strong>
      </Grid>
    </Grid>
  );
};

export default TotalsDocument;
