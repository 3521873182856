import React, { Fragment, useState } from 'react';
import {
    IconButton, Dialog, DialogTitle, DialogContent, makeStyles, Grid,
    Button,
    CircularProgress,
    Avatar,
    Backdrop
} from '@material-ui/core';
import { palette } from '../../../../configurations/styles/theme';
import { clientBackendPDF } from 'configurations/axios';

const useStyles = makeStyles(theme => ({
    IconButton: {
        marginTop: "1%",
        marginBottom: "1%",
        padding: "1px",
    },
    divInfoUser: {
        borderStyle: 'solid',
        borderColor: palette.primary.main,
        borderWidth: '1px',
        borderRadius: '8px',
        marginTop: '1%'
    },
    divlabel: {
        marginTop: '1%'
    },
    buttonClose: {
        width: '75%'
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    backdrop: {
        zIndex: "3000",
        color: "#fff",
    },
}))
const PDFVoucherBtn = ({ voucher }) => {
    const [open, setOpen] = useState(false);
    const [openLoader, setOpenLoader] = useState(false);
    const classes = useStyles();


    const handleClose = () => {
        setOpen(false);
    };



    const obtenerDatos = async () => {
        setOpenLoader(true);
        return await clientBackendPDF.get(`/core/utilidades/ebox/generar/ride/${voucher.comprobanteElectronico.idComprobanteElectronico}`)
            .then(
                response => {
                    //Create a Blob from the PDF Stream
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });

                    //Build a URL from the file
                    //esto esta sirviendo sin nombre
                    ////////////
                    //setFileURL(anchor);
                    //handleClickOpen();
                    ////////////////////////////////
                    //Open the URL on new Window                    
                    //window.open(fileURL + "2");
                    ///esto es otro 
                    //Esta segunda opción
                    const url = window.URL.createObjectURL(file);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                        'download',
                        `${voucher.comprobanteElectronico.claveAcceso}.pdf`,
                    );

                    // Append to html link element page
                    document.body.appendChild(link);
                    //setFileURL(link);
                    //handleClickOpen()
                    // Start download
                    link.click();

                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                    setOpenLoader(false);

                }
            ).catch(
                error => {
                    if (error.response) {
                        setOpenLoader(false)
                        console.error('error', error.response.data);
                        setOpen(true)
                    } else {
                        console.error('error', error);
                        setOpenLoader(false)
                        setOpen(true)
                    }
                }
            )



    }


    return (
        <Fragment>

            <IconButton color="primary" aria-label="upload picture" component="span" onClick={obtenerDatos} className={classes.IconButton}>
                <Avatar variant="square" src="../pdf.png" className={classes.small} />
            </IconButton>

            {<Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle id="max-width-dialog-title">Mensaje</DialogTitle>
                <DialogContent>

                    <div className="alert alert-danger" role="alert">
                        <label>

                            {(`No se pudo generar el comprobante con la clave de acceso: ${voucher.comprobanteElectronico.claveAcceso ?
                                voucher.comprobanteElectronico.claveAcceso
                                :
                                ''} `
                            )}
                        </label>
                    </div>

                    <Grid container justify='center'>
                        <Button variant="contained" color="secondary" onClick={handleClose} >
                            Cerrar
                        </Button>
                    </Grid>


                </DialogContent>

            </Dialog>}
            <Backdrop open={openLoader} className={classes.backdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Fragment>
    );
}

export default PDFVoucherBtn;