import React, { Fragment, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Backdrop,
  CircularProgress,
  Divider,
} from "@material-ui/core";
//Notificacion errores https://iamhosseindhv.com/notistack/demos#variants
import { useSnackbar } from "notistack";
//componentes del sistema
import { palette, useStylesGlobal } from "configurations/styles/theme";
import { useSelector } from "react-redux";
import { getRUCEmpresa } from "redux/actions/userActions";
import clientBackend from "configurations/axios";
import Alert from "widgets/alert";
import useForm from "hooks/useFrom";
import validations from "../validations";
import { API_POST_SAVE_PROVIDERS } from "../apis";
import { useStylesApp } from "hooks/useStyles";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(0),
    minWidth: 120,
  },
  radio: {
    display: "-webkit-box",
    marginTop: "0px",
  },
  radioLable: {
    color: palette.primary.main,
    display: "flex",
  },
  radioButton: {
    marginTop: "0px",
    paddingTop: "0px",
  },
  divForm: {
    marginBottom: "1%",
  },
  divInput: {
    marginBottom: "1%",
    paddingRight: "1%",
    paddingLeft: "0.5%",
  },
  divButton: {
    paddingLeft: "3%",
    paddingRight: "3%",
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 999,
    color: palette.primary.main,
  },
}));

const AddProviderBtn = ({ updateView }) => {
  const classes = useStyles();
  const classesApp = useStylesApp();
  const classesGlobal = useStylesGlobal();
  const [openLoader, setOpenLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [pasaporteCheck, setPasaporteCheck] = useState("C");
  const userToken = useSelector((state) => state.user.tk);
  const [msgError, setMsg] = useState({ show: false, msg: null, type: null });
  const { enqueueSnackbar } = useSnackbar();
  const [provider, setProvider] = useState({
    idEmpresa: userToken ? getRUCEmpresa(userToken) : "0",
  });

  const existError = () => {
    setOpenLoader(false);
  };

  const handleSave = async () => {
    setOpenLoader(true);
    await clientBackend
      .post(API_POST_SAVE_PROVIDERS, provider)
      .then((response) => {
        if (response.status === 200) {
          enqueueSnackbar("Proveedor almacenado.", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            autoHideDuration: 10000,
          });
          updateView();
          setOpenLoader(false);
          setOpen(false);
        }
      })
      .catch((exception) => {
        if (exception.response) {
          if (exception.response.status === 400) {
            setMsg({ show: true, msg: "No se pudo almacenar el proveedor." });
          } else if (exception.response.status === 404) {
            setMsg({
              show: true,
              msg: "No se encontró la empresa enviada para relacionarla con el proveedor.",
            });
          } else if (exception.response.status === 409) {
            setMsg({ show: true, msg: "El proveedor ya existe." });
          } else {
            setMsg({
              show: true,
              msg: "Error al registrar el proveedor. Contáctese con el administrador del Sistema.",
            });
          }
        } else {
          setMsg({
            show: true,
            msg: "Existen problemas en la red. Contáctese con el administrador del Sistema.",
          });
        }
        setOpenLoader(false);
      });
  };

  const { errors, handleSubmit, getObject } = useForm(
    handleSave,
    validations.addProvider,
    existError
  );

  function closeAlert() {
    setMsg({ show: false });
  }

  const handleClickOpen = () => {
    setOpen(true);
    setProvider({ idEmpresa: userToken ? getRUCEmpresa(userToken) : "0" });
    setMsg({ show: false, msg: null, type: null });
    errors.identificacionCliente = null;
    errors.nombreCliente = null;
    errors.correoCliente = null;
    errors.telefonoCliente = null;
    errors.direccionCliente = null;
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeCheck = (event) => {
    setPasaporteCheck(event.target.value);
    getObject({
      ...provider,
      typeDocument: event.target.value,
    });
  };

  const handleChange = (event) => {
    if (event.target.name === "identificacionCliente") {
      if (event.target.value.length > 13) {
        return;
      }
    }

    if (event.target.name === "telefonoCliente") {
      if (event.target.value.length > 10) {
        return;
      }
    }

    setProvider({
      ...provider,
      [event.target.name]: event.target.value,
    });
    getObject({
      ...provider,
      [event.target.name]: event.target.value,
      typeDocument: pasaporteCheck,
    });
  };

  return (
    <Fragment>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        <PersonAddIcon
          style={{ color: palette.primary.contrastText, paddingRight: "3%" }}
          fontSize="small"
        />{" "}
        Agregar Proveedor
      </Button>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="max-width-dialog-title">Nuevo Proveedor</DialogTitle>
        <DialogContent>
          <form
            onSubmit={(event) => {
              setOpenLoader(true);
              handleSubmit(event);
            }}
          >
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
            >
              <Grid item xs={12} md={12}>
                <label>
                  Los campos marcados con ({" "}
                  <font color={palette.error.main}> *</font> ) son obligatorios:
                </label>
              </Grid>
              {msgError.show ? (
                <Alert
                  tipo={msgError.type}
                  mensaje={msgError.msg}
                  mesajeSubrayado={null}
                  titulo={null}
                  mostrar={msgError.show}
                  cerrarAlerta={closeAlert}
                />
              ) : null}
              <Grid item xs={12} md={3} className={classes.divForm}>
                <label>
                  Identificación: <font color={palette.error.main}>*</font>
                </label>
              </Grid>
              <Grid item xs={12} md={3} className={classes.divInput}>
                <input
                  type={pasaporteCheck == "C" ? "number" : "text"}
                  className={
                    errors.identificacionCliente
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  placeholder="Ej. 1737199872"
                  name="identificacionCliente"
                  value={provider.identificacionCliente}
                  onChange={handleChange}
                  autoComplete="off"
                />
                {errors.identificacionCliente ? (
                  <p className={classesApp.errorLetters}>
                    {errors.identificacionCliente}
                  </p>
                ) : null}
              </Grid>
              <Grid item xs={12} md={6} className={classes.divForm}>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <RadioGroup
                    aria-label="tipoDocumento"
                    name="pasaporteCheck"
                    className={classes.radio}
                    required
                    value={pasaporteCheck}
                    onChange={handleChangeCheck}
                  >
                    <FormControlLabel
                      value="P"
                      control={
                        <Radio
                          color="primary"
                          className={classes.radioButton}
                        />
                      }
                      label="Pasaporte"
                      labelPlacement="start"
                      className={classes.radioLable}
                    />
                    <FormControlLabel
                      value="C"
                      control={
                        <Radio
                          color="primary"
                          className={classes.radioButton}
                        />
                      }
                      label="Cédula/RUC"
                      labelPlacement="start"
                      className={classes.radioLable}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={1} className={classes.divForm}>
                <label>
                  Nombre: <font color={palette.error.main}>*</font>
                </label>
              </Grid>
              <Grid item xs={12} md={5} className={classes.divInput}>
                <input
                  type="text"
                  className={
                    errors.nombreCliente
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  placeholder="Ej. Juan Mauricio Pérez Calle"
                  name="nombreCliente"
                  value={provider.nombreCliente}
                  onChange={handleChange}
                  autoComplete="off"
                />
                {errors.nombreCliente ? (
                  <p className={classesApp.errorLetters}>
                    {errors.nombreCliente}
                  </p>
                ) : null}
              </Grid>
              <Grid item xs={12} md={1} className={classes.divForm}>
                <label>
                  Correo: <font color={palette.error.main}>*</font>
                </label>
              </Grid>
              <Grid item xs={12} md={5} className={classes.divInput}>
                <input
                  type="email"
                  className={
                    errors.correoCliente
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  placeholder="Ej. user@ejemplo.com"
                  name="correoCliente"
                  value={provider.correoCliente}
                  onChange={handleChange}
                  autoComplete="off"
                />
                {errors.correoCliente ? (
                  <p className={classesApp.errorLetters}>
                    {errors.correoCliente}
                  </p>
                ) : null}
              </Grid>
              <Grid item xs={12} md={1} className={classes.divForm}>
                <label>Teléfono:</label>
              </Grid>
              <Grid item xs={12} md={5} className={classes.divInput}>
                <input
                  type="number"
                  className={
                    errors.telefonoCliente
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  placeholder="Ej. 3824290"
                  name="telefonoCliente"
                  value={provider.telefonoCliente}
                  onChange={handleChange}
                  autoComplete="off"
                />
                {errors.telefonoCliente ? (
                  <p className={classesApp.errorLetters}>
                    {errors.telefonoCliente}
                  </p>
                ) : null}
              </Grid>
              <Grid item xs={12} md={1} className={classes.divForm}>
                <label>Dirección:</label>
              </Grid>
              <Grid item xs={12} md={5} className={classes.divInput}>
                <input
                  type="text"
                  className={
                    errors.direccionCliente
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  placeholder="Ej. Av. República y Pradera"
                  name="direccionCliente"
                  value={provider.direccionCliente}
                  onChange={handleChange}
                  autoComplete="off"
                />
                {errors.direccionCliente ? (
                  <p className={classesApp.errorLetters}>
                    {errors.direccionCliente}
                  </p>
                ) : null}
              </Grid>
              <Grid container spacing={3} justify="center">
                <Grid item md={12} xs={12}>
                  <Divider></Divider>
                </Grid>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={classesGlobal.marginButton}
                >
                  Agregar
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleClose}
                  className={classesGlobal.marginButton}
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      <Backdrop className={classes.backdrop} open={openLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
};

export default AddProviderBtn;
