import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { palette } from "configurations/styles/theme";
import useForm from "hooks/useFrom";
import validations from "../validations";
import middleware from "configurations/axios";
import apis from "../apis";
const useStyles = makeStyles((theme) => ({
  form: {
    borderWidth: "1px",
    border: "1px solid  #aaaaaa",
    borderRadius: "8px",
    width: "100%",
    padding: "1%",
  },
  button: {
    marginTop: "0.5%",
    width: "80%",
    maxWidth: "80%",
    padding: "0.5%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: palette.primary.dark,
    borderRadius: "8px",
    "&:hover": {
      color: palette.primary.contrastText,
      background: palette.primary.main,
    },
  },
  formControl: {
    marginTop: theme.spacing(0),
    minWidth: 120,
  },
  radio: {
    display: "-webkit-box",
    marginTop: "0px",
  },
  radioLable: {
    color: palette.primary.main,
    display: "flex",
  },
  radioButton: {
    marginTop: "0px",
    paddingTop: "0px",
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 999,
    color: palette.primary.main,
  },
}));

const CompanyForm = ({ data, onShowAlert, handleCloseReload = () => {} }) => {
  const classes = useStyles();
  const [company, setCompany] = useState(data);
  const [openLoader, setOpenLoader] = useState(false);
  const [contribuyenteEspecial, setContribuyenteEspecial] = useState(false);
  const existError = () => {};

  const handleSave = async () => {
    console.log(company);
    setOpenLoader(true);
    await middleware
      .post(apis.update_company, company)
      .then((response) => {
        if (response.status >= 200 && response.status <= 300) {
          onShowAlert({
            show: true,
            msg: "Empresa actualizada.",
            type: "success",
          });
        }
        handleCloseReload();
        setOpenLoader(false);
      })
      .catch((exception) => {
        if (exception.response) {
          if (exception.response.status === 400) {
            onShowAlert({
              show: true,
              msg: "No se pudo actualizar la empresa.",
              type: "error",
            });
          }
        } else {
          onShowAlert({
            show: true,
            msg: "Existen problemas en la red. Contáctese con el administrador del Sistema.",
            type: "error",
          });
        }
        setOpenLoader(false);
      });
  };

  const { errors, handleSubmit, getObject } = useForm(
    handleSave,
    validations.updateCompany,
    existError
  );

  useEffect(() => {
    console.log(data);
    getObject(data);
  }, []);

  const handleChane = (event) => {
    setCompany({
      ...company,
      [event.target.name]: event.target.value,
    });
    getObject({
      ...company,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeCheck = (event) => {
    console.log(company.negocioPopular);
    if (event.target.name !== "S") {
      setCompany({
        ...company,
        [event.target.name]: event.target.value === "S" ? true : false,
        negocioPopular: false,
        leyendaRimpeEmprendedor: false,
      });
      getObject({
        ...company,
        [event.target.name]: event.target.value === "S" ? true : false,
        negocioPopular: false,
        leyendaRimpeEmprendedor: false,
      });
    } else {
      setCompany({
        ...company,
        [event.target.name]: event.target.value === "S" ? true : false,
      });
      getObject({
        ...company,
        [event.target.name]: event.target.value === "S" ? true : false,
      });
    }
  };

  const handleChangeRimpeEmprendedor = (event) => {
    if (event.target.name !== "S") {
      setCompany({
        ...company,
        [event.target.name]: event.target.value === "S" ? true : false,
      });
      getObject({
        ...company,
        [event.target.name]: event.target.value === "S" ? true : false,
      });
    }
  };

  const handleChangeCheckNegPopular = (event) => {
    console.log(event.target.name, event.target.value);
    setCompany({
      ...company,
      [event.target.name]: event.target.value === "S" ? true : false,
    });
    getObject({
      ...company,
      [event.target.name]: event.target.value === "S" ? true : false,
    });
  };

  const handleChangeCheckAgent = (event) => {
    setCompany({
      ...company,
      [event.target.name]:
        event.target.value === "S" ? "NAC-DNCRASC20-00000001" : "",
    });
    getObject({
      ...company,
      [event.target.name]:
        event.target.value === "S" ? "NAC-DNCRASC20-00000001" : "",
    });
  };

  const handleChangeNumber = (event) => {
    event.preventDefault();
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      setCompany({
        ...company,
        [event.target.name]: event.target.value,
      });
      getObject({
        ...company,
        [event.target.name]: event.target.value,
      });
    }
  };

  return (
    <>
      <form className={classes.form} onSubmit={handleSubmit}>
        <div class="row form-group">
          <div class="col-sm-12 mb-1">
            <label>
              Los campos marcados con ({" "}
              <font color={palette.error.main}> *</font> )son obligatorios:
            </label>
          </div>
          <div class="col-sm-4 mb-1">
            <label>RUC:</label>
          </div>
          <div class="col-sm-8 mb-1">
            <input
              class="form-control is-valid"
              disabled
              value={company.idempresa}
            />
          </div>
          <div class="col-sm-4 mb-1">
            <label>
              Razón social: <font color={palette.error.main}> *</font>{" "}
            </label>
          </div>
          <div class="col-sm-8 mb-1">
            <input
              class={
                errors.razonSocialempresa
                  ? " form-control is-invalid"
                  : "form-control"
              }
              type="text"
              name="razonSocialempresa"
              value={company.razonSocialempresa}
              onChange={handleChane}
              autoComplete="off"
              required
            />
            {errors.razonSocialempresa ? (
              <small style={{ color: palette.error.main }}>
                {errors.razonSocialempresa}
              </small>
            ) : null}
          </div>

          <div class="col-sm-4 mb-1">
            <label>Nombre comercial:</label>
          </div>
          <div class="col-sm-8 mb-1">
            <input
              class={
                errors.nombreComercial
                  ? " form-control is-invalid"
                  : "form-control"
              }
              type="text"
              name="nombreComercial"
              value={company.nombreComercial}
              onChange={handleChane}
              autoComplete="off"
            />
            {errors.nombreComercial ? (
              <small style={{ color: palette.error.main }}>
                {errors.nombreComercial}
              </small>
            ) : null}
          </div>

          <div class="col-sm-4 mb-1">
            <label>
              Dirección Matriz: <font color={palette.error.main}> *</font>{" "}
            </label>
          </div>
          <div class="col-sm-8 mb-1">
            <input
              class={
                errors.direccion ? " form-control is-invalid" : "form-control"
              }
              type="text"
              name="direccion"
              value={company.direccion}
              onChange={handleChane}
              autoComplete="off"
              required
            />
            {errors.direccion ? (
              <small style={{ color: palette.error.main }}>
                {errors.direccion}
              </small>
            ) : null}
          </div>
          <div class="col-sm-4 mb-1">
            <label>
              Correo principal <font color={palette.error.main}> *</font> :
            </label>
          </div>
          <div class="col-sm-8 mb-1">
            <input
              class={
                errors.correoPrincipal
                  ? " form-control is-invalid"
                  : "form-control"
              }
              type="email"
              name="correoPrincipal"
              value={company.correoPrincipal}
              onChange={handleChane}
              autoComplete="off"
              required
            />
            {errors.correoPrincipal ? (
              <small style={{ color: palette.error.main }}>
                {errors.correoPrincipal}
              </small>
            ) : null}
          </div>
          <div class="col-sm-4 mb-1">
            <label>Teléfono principal:</label>
          </div>
          <div class="col-sm-8 mb-1">
            <input
              class={
                errors.telefonoPrincipal
                  ? " form-control is-invalid"
                  : "form-control"
              }
              type="text"
              name="telefonoPrincipal"
              value={company.telefonoPrincipal}
              onChange={handleChangeNumber}
              autoComplete="off"
            />
            {errors.telefonoPrincipal ? (
              <small style={{ color: palette.error.main }}>
                {errors.telefonoPrincipal}
              </small>
            ) : null}
          </div>

          {/* <div class="col-sm-4 mb-1">
            <label>
              Sub dominio: <font color={palette.error.main}> *</font>{" "}
            </label>
          </div>
          <div class="col-sm-8 mb-1">
            <input
              class={
                errors.subdominioEmpresa ? " form-control is-invalid" : "form-control"
              }
              type="text"
              name="subdominioEmpresa"
              value={company.subdominioEmpresa}
              onChange={handleChane}
              autoComplete="off"
              required
            />
            {errors.subdominioEmpresa ? (
              <small style={{ color: palette.error.main }}>
                {errors.subdominioEmpresa}
              </small>
            ) : null}
          </div> */}

          <div class="col-sm-4 mb-1">
            <label>Es contribuyente especial?</label>
          </div>
          <div class="col-sm-8 mb-1">
            <FormControl component="fieldset" className={classes.formControl}>
              <RadioGroup
                aria-label="tipoContabilidad"
                name="contribuyenteEspecial"
                className={classes.radio}
                value={contribuyenteEspecial === true ? "S" : "N"}
                onClick={() => setContribuyenteEspecial(!contribuyenteEspecial)}
              >
                <FormControlLabel
                  value="S"
                  control={
                    <Radio color="primary" className={classes.radioButton} />
                  }
                  label="Si"
                  labelPlacement="start"
                  className={classes.radioLable}
                />
                <FormControlLabel
                  value="N"
                  control={
                    <Radio color="primary" className={classes.radioButton} />
                  }
                  label="No"
                  labelPlacement="start"
                  className={classes.radioLable}
                />
              </RadioGroup>
            </FormControl>
          </div>
          {contribuyenteEspecial && (
            <>
              <div class="col-sm-4 mb-1">
                <label>Número de resolución:</label>
              </div>
              <div class="col-sm-8 mb-1">
                <input
                  class={
                    errors.numeroResolucion
                      ? " form-control is-invalid"
                      : "form-control"
                  }
                  type="text"
                  name="numeroResolucion"
                  value={contribuyenteEspecial ? company.numeroResolucion : ""}
                  onChange={handleChangeNumber}
                  autoComplete="off"
                />
                {errors.numeroResolucion ? (
                  <small style={{ color: palette.error.main }}>
                    {errors.numeroResolucion}
                  </small>
                ) : null}
              </div>
            </>
          )}

          <div class="col-sm-4 mb-1">
            <label>Resolución Agente de Retención:</label>
          </div>
          <div class="col-sm-8 mb-1">
            <FormControl component="fieldset" className={classes.formControl}>
              <RadioGroup
                name="agenteRetencion"
                className={classes.radio}
                required
                value={company.agenteRetencion === "" ? "N" : "S"}
                onChange={handleChangeCheckAgent}
              >
                <FormControlLabel
                  value="S"
                  control={
                    <Radio color="primary" className={classes.radioButton} />
                  }
                  label="Si"
                  labelPlacement="start"
                  className={classes.radioLable}
                />
                <FormControlLabel
                  value="N"
                  control={
                    <Radio color="primary" className={classes.radioButton} />
                  }
                  label="No"
                  labelPlacement="start"
                  className={classes.radioLable}
                />
              </RadioGroup>
            </FormControl>
          </div>

          <div class="col-sm-4 mb-1">
            <label>
              Obligado a llevar contabilidad{" "}
              <font color={palette.error.main}> *</font> :
            </label>
          </div>
          <div class="col-sm-8 mb-1">
            <FormControl component="fieldset" className={classes.formControl}>
              <RadioGroup
                aria-label="tipoContabilidad"
                name="obligadoContabilidad"
                className={classes.radio}
                required
                value={company.obligadoContabilidad ? "S" : "N"}
                onChange={handleChangeCheck}
              >
                <FormControlLabel
                  value="S"
                  control={
                    <Radio color="primary" className={classes.radioButton} />
                  }
                  label="Si"
                  labelPlacement="start"
                  className={classes.radioLable}
                />
                <FormControlLabel
                  value="N"
                  control={
                    <Radio color="primary" className={classes.radioButton} />
                  }
                  label="No"
                  labelPlacement="start"
                  className={classes.radioLable}
                />
              </RadioGroup>
            </FormControl>
          </div>

          <div class="col-sm-4 mb-1">
            <label>
              Contribuyente Régimen Simplificado para Emprendedores y Negocios
              Populares (RIMPE): <font color={palette.error.main}> *</font> :
            </label>
          </div>
          <div class="col-sm-8 mb-1">
            <FormControl component="fieldset" className={classes.formControl}>
              <RadioGroup
                aria-label="tipoContabilidad"
                name="contribuyenteMicroempresa"
                className={classes.radio}
                required
                value={company.contribuyenteMicroempresa === true ? "S" : "N"}
                onChange={handleChangeCheck}
              >
                <FormControlLabel
                  value="S"
                  control={
                    <Radio color="primary" className={classes.radioButton} />
                  }
                  label="Si"
                  labelPlacement="start"
                  className={classes.radioLable}
                />
                <FormControlLabel
                  value="N"
                  control={
                    <Radio color="primary" className={classes.radioButton} />
                  }
                  label="No"
                  labelPlacement="start"
                  className={classes.radioLable}
                />
              </RadioGroup>
            </FormControl>
          </div>

          {company.contribuyenteMicroempresa ? (
            <>
              <div class="col-sm-4 mb-1">
                <label>
                  Seleccione el tipo de (RIMPE):{" "}
                  <font color={palette.error.main}> *</font> :
                </label>
              </div>
              <div class="col-sm-8 mb-1">
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <RadioGroup
                    aria-label="tipoContabilidad"
                    name="negocioPopular"
                    className={classes.radio}
                    required
                    value={company.negocioPopular === true ? "S" : "N"}
                    onChange={handleChangeCheckNegPopular}
                  >
                    <FormControlLabel
                      value="S"
                      control={
                        <Radio
                          color="primary"
                          className={classes.radioButton}
                        />
                      }
                      label="Negocio Popular"
                      labelPlacement="start"
                      className={classes.radioLable}
                    />
                    <FormControlLabel
                      value="N"
                      control={
                        <Radio
                          color="primary"
                          className={classes.radioButton}
                        />
                      }
                      label="Emprendedores"
                      labelPlacement="start"
                      className={classes.radioLable}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </>
          ) : (
            <></>
          )}

          {company.negocioPopular === false &&
          company.contribuyenteMicroempresa === true ? (
            <>
              <div class="col-sm-4 mb-1">
                <label>
                  Desea que se muestre la leyenda: "Contribuyente Régimen RIMPE
                  EMPRENDEDOR"? <font color={palette.error.main}> *</font> :
                </label>
              </div>
              <div class="col-sm-8 mb-1">
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <RadioGroup
                    aria-label="tipoContabilidad"
                    name="leyendaRimpeEmprendedor"
                    className={classes.radio}
                    required
                    value={company.leyendaRimpeEmprendedor === true ? "S" : "N"}
                    onChange={handleChangeRimpeEmprendedor}
                  >
                    <FormControlLabel
                      value="S"
                      control={
                        <Radio
                          color="primary"
                          className={classes.radioButton}
                        />
                      }
                      label="Si"
                      labelPlacement="start"
                      className={classes.radioLable}
                    />
                    <FormControlLabel
                      value="N"
                      control={
                        <Radio
                          color="primary"
                          className={classes.radioButton}
                        />
                      }
                      label="No"
                      labelPlacement="start"
                      className={classes.radioLable}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </>
          ) : null}

          <div class="col-sm-12 mb-1" align="center">
            <button className={classes.button} type="submit">
              Actualizar Información
            </button>
          </div>
        </div>
      </form>
      <Backdrop className={classes.backdrop} open={openLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default CompanyForm;
