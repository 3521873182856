import * as React from "react";

import { useStylesGlobal } from "configurations/styles/theme";
import { Grid, Backdrop, CircularProgress, Card, Avatar } from '@material-ui/core';
import SearchVoucher from '../Forms/SearchVoucher';
import { useSnackbar } from 'notistack';
import useIndexAuthorizedIssuedVoucher from "./Context/useIndexAuthorizedIssuedVoucher";


export default function Filters() {
    const classesGlobales = useStylesGlobal();
    const { enqueueSnackbar } = useSnackbar();
    const { classesView, getZip, setParamSearch, setUpdate, update, openLoader } = useIndexAuthorizedIssuedVoucher(enqueueSnackbar);


    return (
        <>
            <Backdrop open={openLoader} className={classesView.backdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <SearchVoucher
                update={update}
                setUpdate={setUpdate}
                setParamSearch={setParamSearch}
            />

            <Card className={classesView.margin}>
                <Grid container className={classesView.zip} direction="row" alignItems="center" onClick={getZip} >
                    <Grid item xs={6} >
                        <label className={classesGlobales.titleCard}>
                            Para descargar varios comprobantes a la vez, seleccione y dé clic en este botón:
                </label>
                    </Grid>
                    <Grid item container item xs={6} direction="row-reverse" justify="flex-start" alignItems="center">
                        <Avatar variant="square" src="../zip.png" className={classesView.small} />
                    </Grid>
                </Grid>
            </Card>
        </>
    )
}