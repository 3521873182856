import React, { Fragment, useState } from 'react';
import {
    IconButton, Dialog, DialogTitle, DialogContent, makeStyles, Grid, Button,
    Backdrop, CircularProgress, Radio, RadioGroup, FormControlLabel, FormControl, Divider
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
//Notificacion errores https://iamhosseindhv.com/notistack/demos#variants
import { useSnackbar } from 'notistack';
//componentes del sistema
import { API_PUT_UPDATE_SECUENCIAL } from '../apis';
import { palette, useStylesGlobal } from 'configurations/styles/theme';
import clientBackend from 'configurations/axios';
import Alert from 'widgets/alert';
import useForm from 'hooks/useFrom';
import validations from '../validations';
import { useStylesApp } from 'hooks/useStyles';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
const useStyles = makeStyles(theme => ({
    IconButton: {
        marginTop: "0%",
        marginBottom: "0%",
        padding: "0px",
    },
    textFieldComboBox: {
        minWidth: '75%',
        maxWidth: '95%'
    },
    backdrop: {
        zIndex: theme.zIndex.modal + 1,
        color: palette.primary.main,
    },
    radio: {
        display: '-webkit-box',
        marginTop: '0px'
    },
    radioLable: {
        color: palette.primary.main,
        display: 'flex'
    },
    radioButton: {
        marginTop: '0px',
        paddingTop: '0px'
    },
}));

/**
 * @author {Dany_Lasso}
 * @param {Object} user
 */
const EditUserBtn = ({ secuencial, updateView }) => {
    const [open, setOpen] = useState(false);    
    const classesGlobal = useStylesGlobal();
    const [openLoader, setOpenLoader] = useState(false);
    const classes = useStyles();
    const classesApp = useStylesApp();
    const [updateSecuencial, setSecuencial] = useState(secuencial);
    const [stateSecuencial, setStateSecuencial] = useState(secuencial.estado ? 'A' : 'C');
    const [msgError, setMsg] = useState({ show: false, msg: null, type: null });
    const { enqueueSnackbar } = useSnackbar();
    const existError = () => {
    }

    const handleSave = async () => {
        setOpenLoader(true);
        updateSecuencial.codigoEstablecimiento = updateSecuencial.codigoEstablecimiento.toString();
        updateSecuencial.puntoEmision = updateSecuencial.puntoEmision.toString();
        await clientBackend.put(API_PUT_UPDATE_SECUENCIAL + updateSecuencial.idSecuencial, updateSecuencial).then(
            response => {
                enqueueSnackbar("Punto de emisión editado.", {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    autoHideDuration: 10000,
                });
                updateView();
                setOpenLoader(false);
                setOpen(false);
            }
        ).catch(
            exception => {
                if (exception.response) {
                    if (exception.response.status === 400) {
                        setMsg({ show: true, msg: 'No se pudo actualizar el punto de emisión.' });
                    } else if (exception.response.status === 404) {
                        setMsg({ show: true, msg: 'No se encontró el punto de emisión a actualizar.' });
                    } else {
                        setMsg({ show: true, msg: 'Error al registrar el usuario. Contáctese con el administrador del Sistema.' });
                    }
                } else {
                    setMsg({ show: true, msg: 'Existen problemas en la red. Contáctese con el administrador del Sistema.' });
                }
                setOpenLoader(false);

            }
        );
    }

    const { errors, handleSubmit, getObject } = useForm(handleSave, validations.editSecuencial, existError);

    const handleClickOpen = () => {
        setOpen(true);
        getObject(secuencial);
        errors.codigoEstablecimiento = null;
        errors.puntoEmision = null;
        errors.direccion = null;
        setSecuencial(secuencial);
        setMsg({ show: false, msg: null, type: null });
    };

    const handleClose = () => {
        setOpen(false);
    };

    function closeAlert() { setMsg({ show: false }); }

    const handleChange = event => {
        setSecuencial({
            ...updateSecuencial,
            [event.target.name]: event.target.value
        })
        getObject({
            ...updateSecuencial,
            [event.target.name]: event.target.value
        });
    }

    const handleChangeCheck = event => {
        setStateSecuencial(event.target.value);
        if (event.target.value === 'A') {
            setSecuencial({
                ...updateSecuencial,
                estado: true
            })
            getObject({
                ...updateSecuencial,
                estado: true
            });
        } else {
            setSecuencial({
                ...updateSecuencial,
                estado: false
            })
            getObject({
                ...updateSecuencial,
                estado: false
            });
        }

    }

    return (
        <Fragment>
            <IconButton color="primary" aria-label="upload picture" component="span" onClick={handleClickOpen} className={classes.IconButton}>
                <EditIcon style={{ color: palette.primary.main }} />
            </IconButton>
            <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle id="max-width-dialog-title">Editar Punto de Emisión</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} >
                                <label>Los campos marcados con (  <font color={palette.error.main}> *</font> ) son obligatorios:</label>
                            </Grid>
                            {msgError.show ?
                                <Alert
                                    tipo={msgError.type}
                                    mensaje={msgError.msg}
                                    mesajeSubrayado={null}
                                    titulo={null}
                                    mostrar={msgError.show}
                                    cerrarAlerta={closeAlert}
                                /> : null
                            }
                            <Grid item md={4}  xs={12}>
                                Establecimiento:<font color={palette.error.main}> *</font>
                            </Grid>
                            <Grid item xs={12} md={8} >
                                <input type="text" className={errors.codigoEstablecimiento ? 'form-control is-invalid' : 'form-control'}
                                    placeholder="001"
                                    disabled
                                    name="codigoEstablecimiento"
                                    value={updateSecuencial.codigoEstablecimiento}
                                    onChange={handleChange}
                                    autoComplete="off"
                                    step="1"
                                    min="1"
                                    max="999"
                                />
                                {errors.codigoEstablecimiento ?
                                    <p className={classesApp.errorLetters}>{errors.codigoEstablecimiento}</p>
                                    :
                                    null
                                }
                            </Grid>
                            <Grid item xs={12} md={4} >
                                Punto de Emisión:<font color={palette.error.main}> *</font>
                            </Grid>
                            <Grid item xs={12} md={8} >
                                <input type="text" className={errors.puntoEmision ? 'form-control is-invalid' : 'form-control'}
                                    placeholder="001"
                                    disabled
                                    name="puntoEmision"
                                    value={updateSecuencial.puntoEmision}
                                    onChange={handleChange}
                                    autoComplete="off"
                                    step="1"
                                    min="1"
                                    max="999"
                                />
                                {errors.puntoEmision ?
                                    <p className={classesApp.errorLetters}>{errors.puntoEmision}</p>
                                    :
                                    null
                                }
                            </Grid>
                            <Grid item xs={12} md={4} >
                                Estado:<font color={palette.error.main}> *</font>
                            </Grid>
                            <Grid item xs={12} md={8} >
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <RadioGroup aria-label="estado"
                                        name="estado"
                                        className={classes.radio} required
                                        value={stateSecuencial}
                                        onChange={handleChangeCheck}
                                    >

                                        <FormControlLabel
                                            value='A'
                                            control={<Radio color="primary" className={classes.radioButton} />}
                                            label="Abierto"
                                            labelPlacement="start"
                                            className={classes.radioLable}
                                        />
                                        <FormControlLabel
                                            value='C'
                                            control={<Radio color="primary" className={classes.radioButton} />}
                                            label="Cerrado"
                                            labelPlacement="start"
                                            className={classes.radioLable}
                                        />

                                    </RadioGroup>
                                </FormControl>
                                {errors.estado ?
                                    <p className={classesApp.errorLetters}>{errors.estado}</p>
                                    :
                                    null
                                }
                            </Grid>
                            <Grid item xs={12} md={4} >
                                Dirección:<font color={palette.error.main}> *</font>
                            </Grid>
                            <Grid item xs={12} md={8} >
                                <input type="text" className={errors.direccion ? 'form-control is-invalid' : 'form-control'}
                                    placeholder="Secuancial Dirección"
                                    name="direccion"
                                    value={updateSecuencial.direccion}
                                    onChange={handleChange}
                                    autoComplete="off"
                                />
                                {errors.direccion ?
                                    <p className={classesApp.errorLetters}>{errors.direccion}</p>
                                    :
                                    null
                                }
                            </Grid>
                            <Grid item xs={12} md={4} >
                                Secuencial Factura:<font color={palette.error.main}> *</font>
                            </Grid>
                            <Grid item xs={12} md={8} >
                                <input type="number" min={1} className={errors.direccion ? 'form-control is-invalid' : 'form-control'}
                                    placeholder="Secuencial Factura"
                                    name="secuencialFactura"
                                    value={updateSecuencial.secuencialFactura}
                                    onChange={handleChange}
                                    autoComplete="off"
                                />
                                {errors.direccion ?
                                    <p className={classesApp.errorLetters}>{errors.direccion}</p>
                                    :
                                    null
                                }
                            </Grid>
                            <Grid item xs={12} md={4} >
                                Secuencial Nota Crédito:<font color={palette.error.main}> *</font>
                            </Grid>
                            <Grid item xs={12} md={8} >
                                <input type="number" min={1} className={errors.direccion ? 'form-control is-invalid' : 'form-control'}
                                    placeholder="Secuencial Nota de Crédito"
                                    name="secuencialNotaCredito"
                                    value={updateSecuencial.secuencialNotaCredito}
                                    onChange={handleChange}
                                    autoComplete="off"
                                />
                                {errors.direccion ?
                                    <p className={classesApp.errorLetters}>{errors.direccion}</p>
                                    :
                                    null
                                }
                            </Grid>
                            <Grid item xs={12} md={4} >
                                Secuencial Nota Débito:<font color={palette.error.main}> *</font>
                            </Grid>
                            <Grid item xs={12} md={8} >
                                <input type="number" min={1} className={errors.direccion ? 'form-control is-invalid' : 'form-control'}
                                    placeholder="Secuencial Nota de Débito"
                                    name="secuencialNotaDebito"
                                    value={updateSecuencial.secuencialNotaDebito}
                                    onChange={handleChange}
                                    autoComplete="off"
                                />
                                {errors.direccion ?
                                    <p className={classesApp.errorLetters}>{errors.direccion}</p>
                                    :
                                    null
                                }
                            </Grid>
                            <Grid item xs={12} md={4} >
                                Secuencial Guía Remisión:<font color={palette.error.main}> *</font>
                            </Grid>
                            <Grid item xs={12} md={8} >
                                <input type="number" min={1} className={errors.direccion ? 'form-control is-invalid' : 'form-control'}
                                    placeholder="Secuencial Remisión"
                                    name="secuencialGuiaRemision"
                                    value={updateSecuencial.secuencialGuiaRemision}
                                    onChange={handleChange}
                                    autoComplete="off"
                                />
                                {errors.direccion ?
                                    <p className={classesApp.errorLetters}>{errors.direccion}</p>
                                    :
                                    null
                                }
                            </Grid>
                            <Grid item xs={12} md={4} >
                                Secuencial Retención:<font color={palette.error.main}> *</font>
                            </Grid>
                            <Grid item xs={12} md={8} >
                                <input type="number" min={1} className={errors.direccion ? 'form-control is-invalid' : 'form-control'}
                                    placeholder="Secuencial Retención"
                                    name="secuencialRetencion"
                                    value={updateSecuencial.secuencialRetencion}
                                    onChange={handleChange}
                                    autoComplete="off"
                                />
                                {errors.direccion ?
                                    <p className={classesApp.errorLetters}>{errors.direccion}</p>
                                    :
                                    null
                                }
                            </Grid>
                            <Grid item xs={12} md={4} >
                                Secuencial Liquidación de Compra:<font color={palette.error.main}> *</font>
                            </Grid>
                            <Grid item xs={12} md={8} >
                                <input type="number" min={1} className={errors.direccion ? 'form-control is-invalid' : 'form-control'}
                                    placeholder="Secuencial Liquidación de Compra"  
                                    name="secuencialLiquidacionCompra"
                                    value={updateSecuencial.secuencialLiquidacionCompra}
                                    onChange={handleChange}
                                    autoComplete="off"
                                />
                                {errors.direccion ?
                                    <p className={classesApp.errorLetters}>{errors.direccion}</p>
                                    :
                                    null
                                }
                            </Grid>
                            <Grid container spacing={3} justify='center' >
                                <Grid item md={12} xs={12} >
                                    <Divider></Divider>
                                </Grid>
                                <Button variant="contained" color="primary" type="submit" className={classesGlobal.marginButton}>
                         
                                <SaveIcon></SaveIcon>
                                Guardar
                                </Button>
                                <Button variant="outlined" color="secondary" onClick={handleClose}
                                    className={classes.buttonClose} className={classesGlobal.marginButton}>
                                        <CancelIcon></CancelIcon>
                                Cerrar
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>
            <Backdrop className={classes.backdrop} open={openLoader} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Fragment>
    );
}

export default EditUserBtn;