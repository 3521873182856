import React, { Fragment, useState } from "react";
import {
  Grid,
  makeStyles,
  FormLabel,
  Button,
  CircularProgress,
  CardHeader,
} from "@material-ui/core";
import { palette, useStylesGlobal } from "configurations/styles/theme";
import tableStyle from "configurations/styles/table";
import Calculations from "./Calculations";
import bigDecimal from "js-big-decimal";
import EditDetail from "../Buttons/EditDetail";
import clientBackend from "configurations/axios";
import { useSelector } from "react-redux";
import { getSub } from "redux/actions/userActions";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import AddDetail from "../Buttons/AddDetail";
import InfoAdditionalBtn from "../Buttons/InfoAdditionalBtn";
import InfoTaxesBtn from "../Buttons/InfoTaxesBtn";
import DeleteProductDetail from "../Buttons/DeleteProductDatailBtn";
import PaymentMethods from "./PaymentMethods";
import ElectronicVoucherOptions from "./ElectronicVoucherOptions";
import AdditionalInfo from "./AdditionalInfo";
import AssignmentIcon from "@material-ui/icons/Assignment";
import TableDataSimple from "widgets/Tables/TableDataSimple";
import TableDataSimplePag from "widgets/Tables/TableDataSimplePag";
import checkInputs from "utils/checkInputs";
import { calculateDiscount } from "../../invoices/validations";
import { ivaCodigo, ivaValores } from "views/facturacion/Forms/typeVoucher";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.primary,
    margin: theme.spacing(1),
    display: "flex",
    flexWrap: "wrap",
    marginBottom: "1%",
    borderStyle: "solid",
    borderRadius: "8px",
    boxShadow: "5px 3px 3px #aaaaaa",
    borderWidth: "2px",
  },
  formControl: {
    marginTop: theme.spacing(0),
    minWidth: 120,
  },
  radio: {
    display: "-webkit-box",
    marginTop: "0px",
  },
  radioLable: {
    color: palette.primary.main,
    display: "flex",
  },
  radioButton: {
    marginTop: "0px",
    paddingTop: "0px",
  },
  divForm: {
    marginBottom: "1%",
    textAlign: "justify",
  },
  divInput: {
    marginBottom: "1%",
    paddingRight: "1%",
    paddingLeft: "0.5%",
    textAlign: "justify",
  },
  divButton: {
    paddingLeft: "3%",
    paddingRight: "3%",
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 999,
    color: palette.primary.main,
  },
  haedClient: {
    backgroundColor: palette.primary.main,
    width: "10%",
    display: "flex",
    flexWrap: "wrap",
    borderStyle: "solid",
    borderBottomWidth: "5px",
    borderRadius: "8px",
  },
}));
const PerchaseSettlementData = ({
  setIdAsgCliente,
  idAsgCliente,
  setFoundClient,
  setOptions,
}) => {
  //para la fecha
  const dateToday = new Date();
  dateToday.setDate(dateToday.getDate() - 30);
  //para el los datos del la empresa
  const tokenUser = useSelector((state) => state.user.tk);
  const classes = useStyles();
  const classesGlobales = useStylesGlobal();
  //state para actualizar la vista
  const [update, setUpdate] = useState(1);
  //para los cálculos
  const [totals, setTotals] = useState({
    subTotal_12: 0,
    subTotal_5: 0,
    subtotal_15: 0,
    subTotal_0: 0,
    subTotal_noIva: 0,
    totalDescuento: 0,
    subTotalSinImpuestos: 0,
    totalIce: 0,
    totalPropina: 0,
    totalIva_12: 0,
    totalIva_5: 0,
    titalIva_15: 0,
    totalFactura: 0,
  });
  //para visualización del detalle
  const [viewDetalle, setViewDetalle] = useState(1);
  //Para la vista del cálculo
  const [viewCalculations, setViewCalculations] = useState(0);
  //State para la búsqueda
  const [productsDetalle, setProductsDetalle] = useState([]);

  //para la fecha del comprobante
  const [fechaEmision, setFechaEmision] = useState(new Date());
  const [numeracionSecuencial, setNumeracionSecuencial] = useState("");

  const getDiscount = (prodDetail) => {
    let discount = calculateDiscount(prodDetail);
    return discount.toFixed(2);
  };

  const columns = [
    {
      title: "Cantidad",
      field: `cantidadProducto`,
      cellStyle: tableStyle.cellTable,
      width: "10%",
      render: (rowData) => <RoundNumber data={rowData.cantidadProducto} />,
    },
    {
      title: "Descripción",
      field: "descripcion",
      cellStyle: tableStyle.cellLeft,
      width: "30%",
    },
    {
      title: "Valor unitario",
      field: "precioUnitario",
      cellStyle: tableStyle.cellTable,
      width: "10%",
      render: (rowData) => <RoundNumber data={rowData.precioUnitario} />,
    },
    {
      title: "Descuento",
      field: "descuentoProducto",
      cellStyle: tableStyle.cellTable,
      width: "10%",
      render: getDiscount,
    },
    {
      title: "Total",
      field: "total",
      cellStyle: tableStyle.cellTable,
      width: "10%",
      render: (rowData) => Number(rowData.total).toFixed(2),
    },
    {
      title: "Det. Adicionales",
      cellStyle: tableStyle.cellTable,
      width: "10%",
      render: (rowData) => <InfoAdditionalBtn data={rowData} />,
    },
    {
      title: "Impuestos",
      cellStyle: tableStyle.cellTable,
      width: "10%",
      render: (rowData) => <InfoTaxesBtn data={rowData} />,
    },
    {
      title: "Editar",
      field: "",
      cellStyle: tableStyle.cellTable,
      width: "10%",
      render: (rowData) => (
        <EditDetail
          data={rowData}
          productsDetalle={productsDetalle}
          setProductsDetalle={setProductsDetalle}
          setViewDetalle={setViewDetalle}
          viewDetalle={viewDetalle}
          detalles={detalles}
        />
      ),
    },
    {
      title: "Eliminar",
      cellStyle: tableStyle.cellTable,
      width: "10%",
      render: (rowData) => (
        <DeleteProductDetail
          productsDetalle={productsDetalle}
          setProductsDetalle={setProductsDetalle}
          data={rowData}
          setViewDetalle={setViewDetalle}
          viewDetalle={viewDetalle}
          detalles={detalles}
          setDetalles={setDetalles}
        />
      ),
    },
  ];

  const RoundNumber = (data) => {
    if (checkInputs.countDecimals(data.data) <= 2) {
      return parseFloat(data.data).toFixed(2);
    } else {
      return data.data;
    }
  };
  //para lo spagos
  const [pagos, setPagos] = useState([]);
  //para la información adicional del la factura
  const [additionalInfoInvoice, setAdditionalInfoInvoice] = useState([]);

  //para los deatlles de la Factura
  const [detalles, setDetalles] = useState([]);

  //Objeto para crear la factura
  const [factura, setFactura] = useState({});

  const getNumeration = async () => {
    await clientBackend
      .get(
        `/asignacion/secuencial/usuario/secuencial/${
          tokenUser ? getSub(tokenUser) : null
        }/03`
      )
      .then((response) => {
        if (response.status === 200) {
          setNumeracionSecuencial(response.data);
          return response.data;
        }
      })
      .catch((exception) => {
        console.log({ exception });
        return exception;
      });
  };

  const requestConfig = {
    page: 0,
    elementos: 5,
  };

  const addCalculs = () => {
    if (productsDetalle.length > 0) {
      let subTotIva_12 = productsDetalle.reduce(function (
        subTotIva_12,
        product
      ) {
        if (product.impuestoIva[0].CODIGO === ivaCodigo.IVA_12) {
          return subTotIva_12 + product.total + product.totalIceProducto;
        } else {
          return subTotIva_12;
        }
      },
      0);

      let subTotIva_5 = productsDetalle.reduce(function (subTotIva_5, product) {
        if (product.impuestoIva[0].CODIGO === ivaCodigo.IVA_5) {
            return Number(subTotIva_5) + Number(product.total) + Number(product.totalIceProducto);
        } else {
            return subTotIva_5;
        }
    },0)

      let subTotIva_15 = productsDetalle.reduce(function (
        subTotIva_15,
        product
      ) {
        if (product.impuestoIva[0].CODIGO === ivaCodigo.IVA_15) {
          return (
            Number(subTotIva_15) +
            Number(product.total) +
            Number(product.totalIceProducto)
          );
        } else {
          return subTotIva_15;
        }
      },
      0);

      let subTotIva_0 = productsDetalle.reduce(function (subTotIva_0, product) {
        if (product.impuestoIva[0].CODIGO === "0") {
          return subTotIva_0 + product.total + Number(product.totalIceProducto);
        } else {
          return subTotIva_0;
        }
      }, 0);

      let subTotNoIva = productsDetalle.reduce(function (subTotNoIva, product) {
        if (product.impuestoIva[0].CODIGO === "6") {
          return subTotNoIva + product.total + Number(product.totalIceProducto);
        } else {
          return subTotNoIva;
        }
      }, 0);

      let totDescuento = productsDetalle.reduce(function (
        totalDescuento,
        product
      ) {
        return totalDescuento + calculateDiscount(product);
      },
      0);

      let subTotSinImpuestos = productsDetalle.reduce(function (
        subTotSinImpuestos,
        product
      ) {
        return Number(subTotSinImpuestos) + Number(product.total);
      },
      0);

      let totIce = productsDetalle.reduce(function (totIce, product) {
        if (product.impuestoIce.length > 0) {
          return totIce + (product.total * product.impuestoIce[0].VALOR) / 100;
        } else {
          return totIce;
        }
      }, 0);

      let totIva_12 = bigDecimal.multiply(subTotIva_12.toString(), ivaValores.IVA_12);

      let totIva_5 = bigDecimal.multiply(subTotIva_5.toString(), ivaValores.IVA_5);

      let totIva_15 = bigDecimal.multiply(subTotIva_15.toString(), ivaValores.IVA_15);

      let totFactura =
        Number(subTotIva_12) +
        Number(totIva_12) +
        Number(subTotIva_5) +
        Number(totIva_5) +
        Number(subTotIva_15) +
        Number(totIva_15) +
        Number(subTotIva_0) +
        Number(subTotNoIva);

      setTotals({
        ...totals,
        subTotal_12: Number(
          bigDecimal.round(subTotIva_12, 2, bigDecimal.RoundingModes.HALF_UP)
        ),
        subTotal_5: Number(
          bigDecimal.round(subTotIva_5, 2, bigDecimal.RoundingModes.HALF_UP)
        ),
        subtotal_15: Number(
          bigDecimal.round(subTotIva_15, 2, bigDecimal.RoundingModes.HALF_UP)
        ),
        subTotal_0: Number(
          bigDecimal.round(subTotIva_0, 2, bigDecimal.RoundingModes.HALF_UP)
        ),
        subTotal_noIva: Number(
          bigDecimal.round(subTotNoIva, 2, bigDecimal.RoundingModes.HALF_UP)
        ),
        totalDescuento: Number(
          bigDecimal.round(totDescuento, 2, bigDecimal.RoundingModes.HALF_UP)
        ),
        subTotalSinImpuestos: Number(
          bigDecimal.round(
            subTotSinImpuestos,
            2,
            bigDecimal.RoundingModes.HALF_UP
          )
        ),
        totalIce: Number(
          bigDecimal.round(totIce, 2, bigDecimal.RoundingModes.HALF_UP)
        ),
        totalIva_12: Number(
          bigDecimal.round(totIva_12, 2, bigDecimal.RoundingModes.HALF_UP)
        ),
        totalIva_5: Number(
          bigDecimal.round(totIva_5, 2, bigDecimal.RoundingModes.HALF_UP)
        ),
        totalIva_15: Number(
          bigDecimal.round(totIva_15, 2, bigDecimal.RoundingModes.HALF_UP)
        ),
        totalFactura: Number(
          bigDecimal.round(totFactura, 2, bigDecimal.RoundingModes.HALF_UP)
        ),
      });
    }
  };

  const clearAllData = () => {
    setTotals({
      subTotal_12: 0,
      subTotal_5: 0,
      subtotal_15: 0,
      subTotal_0: 0,
      subTotal_noIva: 0,
      totalDescuento: 0,
      subTotalSinImpuestos: 0,
      totalIce: 0,
      totalPropina: 0,
      totalIva_12: 0,
      totalIva_5: 0,
      titalIva_15: 0,
      totalFactura: 0,
    });
    setProductsDetalle([]);
    setDetalles([]);
    setPagos([]);
    setAdditionalInfoInvoice([]);
    setNumeracionSecuencial("");
    setIdAsgCliente({});
    setFoundClient({});
    setOptions([]);
    setFechaEmision(new Date());
  };

  React.useEffect(() => {
    if (productsDetalle.length > 0) {
      addCalculs();
      setViewCalculations(1);
    } else {
      addCalculs();
      setViewCalculations(0);
    }
    if (numeracionSecuencial === "") {
      getNumeration();
    }
  }, [productsDetalle, viewDetalle, pagos, numeracionSecuencial]);

  return (
    <Fragment>
      <div className={classesGlobales.rootDiv}>
        <Grid container xs={12} md={12} justify="center">
          <CardHeader
            className={classesGlobales.cabecera}
            title={"Datos Liquidación de Compra"}
            avatar={<AssignmentIcon></AssignmentIcon>}
          />
        </Grid>
        <Grid container spacing={1} className={classesGlobales.containerForm}>
          <Grid item xs={12} md={2} className={classes.divForm}>
            <label>
              Numeración: <font color={palette.error.main}>*</font>
            </label>
          </Grid>
          <Grid item xs={12} md={4} className={classes.divInput}>
            <input
              className={
                numeracionSecuencial
                  ? "form-control  is-valid"
                  : "form-control "
              }
              type="text"
              disabled
              placeholder="Ej. 001-001-00000234"
              name="telefonoCliente"
              value={numeracionSecuencial}
            />
          </Grid>
          <Grid item xs={12} md={2} className={classes.divForm}>
            <label>
              Fecha: <font color={palette.error.main}>*</font>
            </label>
          </Grid>
          <Grid item xs={12} md={4} className={classes.divInput}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <KeyboardDatePicker
                className={
                  fechaEmision ? "form-control  is-valid" : "form-control "
                }
                disableToolbar
                disableFuture
                autoOk
                placeholder="Ej. 20-01-2020"
                minDate={dateToday}
                InputAdornmentProps={{ position: "start" }}
                variant="dialog"
                inputVariant="outlined"
                cancelLabel="Cancelar"
                okLabel="Seleccionar"
                format="dd-MM-yyyy"
                //margin="normal"
                id="date-picker-inline"
                value={fechaEmision ? fechaEmision : ""}
                onChange={setFechaEmision}
                invalidDateMessage="Formato inválido."
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </div>

      <div className={classesGlobales.rootDiv}>
        <Grid container xs={12} md={12} justify="center">
          <CardHeader
            className={classesGlobales.cabecera}
            title={"Detalle"}
            avatar={<AssignmentIcon></AssignmentIcon>}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <AddDetail
            productsDetalle={productsDetalle}
            setProductsDetalle={setProductsDetalle}
            viewDetalle={viewDetalle}
            setViewDetalle={setViewDetalle}
            detalles={detalles}
            setDetalles={setDetalles}
          />
          {productsDetalle.length > 0 ? (
            <Grid
              item
              xs={12}
              md={12}
              className={classesGlobales.containerForm}
            >
              {viewDetalle === 1 ? (
                <TableDataSimplePag
                  title=""
                  columns={columns}
                  data={productsDetalle}
                  buscar={false}
                ></TableDataSimplePag>
              ) : (
                <TableDataSimple
                  title=""
                  columns={columns}
                  data={productsDetalle}
                  buscar={false}
                ></TableDataSimple>
              )}
            </Grid>
          ) : null}
        </Grid>

        {viewCalculations === 1 ? (
          <Fragment>
            <Calculations totals={totals} setTotals={setTotals} />
            <Grid container>
              <Grid xs={12} md={6}>
                <PaymentMethods
                  totals={totals}
                  pagos={pagos}
                  setPagos={setPagos}
                  productsDetalle={productsDetalle}
                  setProductsDetalle={setProductsDetalle}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <AdditionalInfo
                  additionalInfoInvoice={additionalInfoInvoice}
                  setAdditionalInfoInvoice={setAdditionalInfoInvoice}
                />
              </Grid>
            </Grid>
            <ElectronicVoucherOptions
              clearAllData={clearAllData}
              fechaEmision={fechaEmision}
              factura={factura}
              setFactura={setFactura}
              detalles={detalles}
              pagos={pagos}
              additionalInfoInvoice={additionalInfoInvoice}
              propina={totals.totalPropina}
              idCliente={idAsgCliente}
              totals={totals}
            />
          </Fragment>
        ) : null}
      </div>
    </Fragment>
  );
};

export default PerchaseSettlementData;
