import React, { Fragment, useState } from "react";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Grid,
  Button,
  Backdrop,
  CircularProgress,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  Divider,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

//Notificacion errores https://iamhosseindhv.com/notistack/demos#variants
import { useSnackbar } from "notistack";
//componentes del sistema
import { API_PUT_UPDATE_PROVIDERS } from "../apis";
import {
  palette,
  useStylesGlobal,
  msmSwalInformacion,
} from "configurations/styles/theme";
import clientBackend from "configurations/axios";
import Alert from "widgets/alert";
import useForm from "hooks/useFrom";
import validations from "../validations";
import { useStylesApp } from "hooks/useStyles";

const useStyles = makeStyles((theme) => ({
  IconButton: {
    marginTop: "0%",
    marginBottom: "0%",
    padding: "0px",
  },
  textFieldComboBox: {
    minWidth: "75%",
    maxWidth: "95%",
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: palette.primary.main,
  },
}));

/**
 * @author {Dany_Lasso}
 * @param {Object} costumer
 */
const EditUserBtn = ({ costumer, updateView }) => {
  const [open, setOpen] = useState(false);
  const classesGlobal = useStylesGlobal();
  const [updateCostumer, serUpdateCostumer] = useState(costumer);
  const [openLoader, setOpenLoader] = useState(false);
  const classes = useStyles();
  const classesApp = useStylesApp();
  const { enqueueSnackbar } = useSnackbar();
  const [msgError, setMsg] = useState({ show: false, msg: null, type: null });
  const [stateCustomer, setStateCustomer] = useState(costumer.estado);

  const existError = () => {};

  const handleSave = async () => {
    setOpenLoader(true);
    await clientBackend
      .put(
        API_PUT_UPDATE_PROVIDERS + updateCostumer.idAsignacionEmpresaCliente,
        updateCostumer
      )
      .then((response) => {
        enqueueSnackbar("Proveedor editado.", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          autoHideDuration: 10000,
        });
        updateView();
        setOpenLoader(false);
        setOpen(false);
      })
      .catch((exception) => {
        if (exception.response) {
          if (exception.response.status === 400) {
            setMsg({ show: true, msg: "No se pudo actualizar el proveedor." });
          } else if (exception.response.status === 404) {
            setMsg({
              show: true,
              msg: "No se encontró el proveedor a actualizar.",
            });
          } else {
            setMsg({
              show: true,
              msg: "Error al registrar el proveedor. Contáctese con el administrador del Sistema.",
            });
          }
        } else {
          setMsg({
            show: true,
            msg: "Existen problemas en la red. Contáctese con el administrador del Sistema.",
          });
        }
        setOpenLoader(false);
      });
  };

  const { errors, handleSubmit, getObject } = useForm(
    handleSave,
    validations.editProvider,
    existError
  );

  const handleClickOpen = () => {
    setOpen(true);
    serUpdateCostumer(costumer);
    getObject(costumer);
    setStateCustomer(costumer.estado);
    setMsg({ show: false, msg: null, type: null });
    errors.nombres = null;
    errors.correo = null;
    errors.direccion = null;
    errors.telefono = null;
  };

  const handleClose = () => {
    setOpen(false);
  };

  function closeAlert() {
    setMsg({ show: false });
  }

  const handleChangeUser = (event) => {
    serUpdateCostumer({
      ...updateCostumer,
      [event.target.name]: event.target.value,
    });

    getObject({
      ...updateCostumer,
      [event.target.name]: event.target.value,
      estado: stateCustomer,
    });
  };

  const handleChangeStateProvider = (event) => {
    setStateCustomer(event.target.value);
    const stateCustomer = event.target.value === "A" ? true : false;
    if (!stateCustomer) {
      msmSwalInformacion(
        "¿Estás seguro de inhabilitar el registro? Para habilitarlo nuevamente, busca al final de la tabla el registro y utiliza la opción de editar."
      );
    }
    getObject({
      ...updateCostumer,
      estado: stateCustomer,
    });
    serUpdateCostumer({
      ...updateCostumer,
      estado: stateCustomer,
    });
  };

  const renderStateProvider = () => {
    return (
      <Fragment>
        <FormControl component="fieldset" className={classes.formControl}>
          <RadioGroup
            row
            className={classes.radio}
            value={updateCostumer.estado ? "A" : "I"}
            onChange={handleChangeStateProvider}
          >
            <FormControlLabel
              value="A"
              control={
                <Radio color="primary" className={classes.radioButton} />
              }
              label="Activo"
              labelPlacement="start"
              className={classes.radioLable}
            />
            <FormControlLabel
              value="I"
              control={
                <Radio color="primary" className={classes.radioButton} />
              }
              label="Inactivo"
              labelPlacement="start"
              className={classes.radioLable}
            />
          </RadioGroup>
        </FormControl>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <IconButton
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={handleClickOpen}
        className={classes.IconButton}
      >
        <EditIcon style={{ color: palette.primary.main }} />
      </IconButton>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="max-width-dialog-title">Editar Proveedor</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <label>
                  Los campos marcados con (
                  <font color={palette.error.main}> *</font> ) son obligatorios:
                </label>
                {msgError.show ? (
                  <Alert
                    tipo={msgError.type}
                    mensaje={msgError.msg}
                    mesajeSubrayado={null}
                    titulo={null}
                    mostrar={msgError.show}
                    cerrarAlerta={closeAlert}
                  />
                ) : null}
              </Grid>
              <Grid item xs={12} md={2}>
                Identificación:
              </Grid>
              <Grid item xs={12} md={4}>
                {updateCostumer.cliente.idCliente}
              </Grid>
              <Grid item xs={12} md={6}></Grid>
              <Grid item xs={12} md={3}>
                Nombre: <font color={palette.error.main}> *</font>
              </Grid>
              <Grid item xs={12} md={4} className={classes.divInfoUser}>
                <input
                  type="text"
                  className={
                    errors.nombres ? "form-control is-invalid" : "form-control"
                  }
                  value={updateCostumer.nombres}
                  name="nombres"
                  onChange={handleChangeUser}
                  autoComplete="off"
                />
                {errors.nombres ? (
                  <p className={classesApp.errorLetters}>{errors.nombres}</p>
                ) : null}
              </Grid>
              <Grid item xs={12} md={1} className={classes.divlabel}>
                Correo: <font color={palette.error.main}> *</font>
              </Grid>
              <Grid item xs={12} md={4} className={classes.divInfoUser}>
                <input
                  type="text"
                  className={
                    errors.correo ? "form-control is-invalid" : "form-control"
                  }
                  value={updateCostumer.correo}
                  name="correo"
                  onChange={handleChangeUser}
                  autoComplete="off"
                />
                {errors.correo ? (
                  <p className={classesApp.errorLetters}>{errors.correo}</p>
                ) : null}
              </Grid>
              <Grid item xs={12} md={3} className={classes.divlabel}>
                Dirección:
              </Grid>
              <Grid item xs={12} md={4} className={classes.divInfoUser}>
                <input
                  type="text"
                  className={
                    errors.direccion
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  value={updateCostumer.direccion}
                  name="direccion"
                  onChange={handleChangeUser}
                  autoComplete="off"
                />
                {errors.direccion ? (
                  <p className={classesApp.errorLetters}>{errors.direccion}</p>
                ) : null}
              </Grid>
              <Grid item xs={12} md={1}>
                Teléfono:
              </Grid>
              <Grid item xs={12} md={4}>
                <input
                  type="number"
                  className={
                    errors.telefono ? "form-control is-invalid" : "form-control"
                  }
                  value={updateCostumer.telefono}
                  name="telefono"
                  onChange={handleChangeUser}
                  autoComplete="off"
                />
                {errors.telefono ? (
                  <p className={classesApp.errorLetters}>{errors.telefono}</p>
                ) : null}
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={12} md={2} className={classes.divlabel}>
                  Estado: <font color={palette.error.main}> *</font>
                </Grid>
                <Grid item xs={12} md={4} className={classes.divInfoUser}>
                  {renderStateProvider()}
                </Grid>
              </Grid>

              <Grid container spacing={3} justify="center">
                <Grid item md={12} xs={12}>
                  <Divider></Divider>
                </Grid>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={classesGlobal.marginButton}
                >
                  Guardar
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleClose}
                  className={classesGlobal.marginButton}
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      <Backdrop className={classes.backdrop} open={openLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
};

export default EditUserBtn;
