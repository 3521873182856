import React, { Fragment, useState } from "react";
import { Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import PublishIcon from "@material-ui/icons/Publish";
import CancelIcon from "@material-ui/icons/Cancel";
import Alerta from "widgets/alert";
// import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Grid,
  Button,
  Divider,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { palette } from "../../../../configurations/styles/theme";
import Paper from "@material-ui/core/Paper";
import { msmSwalExito, msmSwalError } from "configurations/styles/theme";
import { getRUCEmpresa, getSub } from "redux/actions/userActions";
import { useSelector } from "react-redux";
import { httpCore } from "configurations/axios";
const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "center",
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(50),
      height: theme.spacing(5),
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
    },
  },
}));

const SomeVoucher = ({ data }) => {
  const classes = useStyles();

  const tokenUser = useSelector((state) => state.user.tk);
  const [file, setFile] = React.useState(null);
  const [notificacion, setNotificacion] = React.useState({
    mostrar: false,
  });

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const procesarArchivo = (event) => {
    let files = event.target.files;

    //Dejar 1mb
    //Archivo cargado exitosamente,En unos minutos les llegara un correo con el resumen del proceso
    //COrregir que solo carga txt
    if (files.length === 1) {
      if (files[0].type === "text/plain") {
        if (files[0].size < "1048576") {
          cerrarAlerta();
          setFile(files[0]);
        } else if (files[0].size > "1048576") {
          setNotificacion({
            mostrar: true,
            tipo: "error",
            mensaje: "El peso máximo del archivo es de",
            mesajeSubrayado: "1 Mb.",
            titulo: null,
          });
          setFile(null);
        }
      } else {
        setNotificacion({
          mostrar: true,
          tipo: "error",
          mensaje: "Solo se admiten archivos en",
          mesajeSubrayado: "formato txt.",
          titulo: null,
        });
      }
    }
  };

  const saveVoucherMicroservice = async () => {
    if (!file) {
      setNotificacion({
        mostrar: true,
        tipo: "info",
        mensaje: "Por favor cargue un archivo en ",
        mesajeSubrayado: "formato txt.",
        titulo: null,
      });
    } else {
      let formData = new FormData();
      formData.append("file", file);

      return await httpCore
        .post(
          `/comprobante/recibido/ebox/archivo/${tokenUser ? getSub(tokenUser) : ""
          }/${data ? data : ""}`,
          formData,
          {
            headers: {
              accept: "application/json",
              "Content-Type": "multipart/form-data",
              "cache-control": "no-cache",
            },
          }
        )
        .then((res) => {
          msmSwalExito("Archivo cargado exitosamente, en unos minutos le llegará un correo con el resumen del proceso");
          setFile(null);
          return res.data;
        })
        .catch((error) => {
          msmSwalError(error.response.data.apierror ? error.response.data.apierror.mensaje : `Existió un error`);
        });
    }
  };
  function cerrarAlerta() {
    setNotificacion({
      mostrar: false,
    });
  }

  const canlcelUploadFile = () => {
    setFile(null);
    cerrarAlerta();
  };

  const renderizarAlerta = () => {
    return (
      <Alerta
        tipo={notificacion.tipo}
        mensaje={notificacion.mensaje}
        mesajeSubrayado={notificacion.mesajeSubrayado}
        titulo={notificacion.titulo}
        mostrar={notificacion.mostrar}
        cerrarAlerta={cerrarAlerta}
      />
    );
  };

  return (
    <Fragment>
      <Grid
        container
        justify="center"
        align="center"
        direction="row"
        spacing={2}
      >
        <Grid item md={4}>
          {notificacion.mostrar ? renderizarAlerta() : null}
        </Grid>
      </Grid>

      <Grid
        container
        justify="center"
        align="center"
        direction="row"
        spacing={2}
      >
        <Grid item md={12} xs={12}>
          <Typography aling="center" color="primary" variant="subtitle2">
          Cargue el archivo .txt de comprobantes recibidos descargado del portal del SRI.
          
          {/* Cuadro de dialogo */}
          <Fragment >
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              onClick={handleClickOpen}
              className={classes.IconButton}
            >
              <InfoIcon style={{ color: palette.primary.main }} />
            </IconButton>
          <Dialog
            open={open}
            maxWidth="sm"
            fullWidthparseStateUser
            onClose={handleClose}
            disableBackdropClick
            disableEscapeKeyDown
          >
          <DialogTitle id="max-width-dialog-title">
            Información
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
              Para generar el archivo .txt deberá acceder al portal del SRI con sus respectivas credenciales,
              dirigirse al menú principal, dar clic en la opción <b>FACTURACIÓN ELECTRÓNICA</b>, 
              sub opción <b>Comprobantes electrónicos recibidos</b>, generar y descargar el reporte.
              </Grid>
            </Grid>
            <Grid container xs={12} justify="center" spacing={1}>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={6} md={3}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleClose}
                  className={classes.buttonClose}
                  fullWidth
                >
                  Cerrar
                </Button>
              </Grid>
            </Grid>
        </DialogContent>
      </Dialog>
    </Fragment>
          </Typography>
        </Grid>
        <Grid item md={12} xs={12}>
          {file ? (
            <Fragment>
              <div className={classes.root}>
                <Paper variant="outlined">
                  <Typography aling="center" variant="subtitle2">
                    {`Archivo cargado: ${file.name} `}
                  </Typography>
                  <Divider orientation="vertical"></Divider>
                  <Typography aling="center" variant="subtitle2">
                    {file.size} Bytes
                  </Typography>
                </Paper>
              </div>
            </Fragment>
          ) : null}
        </Grid>
        <Grid item md={2} xs={4}>
          <Button variant="contained" color="primary" component="label">
            <SearchIcon /> Buscar
            <input
              type="file"
              style={{ display: "none" }}
              onChange={procesarArchivo}
            />
          </Button>
        </Grid>
        <Grid item md={2} xs={4}>
          <Button
            variant="outlined"
            color="primary"
            onClick={saveVoucherMicroservice}
          >
            <PublishIcon /> Cargar
          </Button>
        </Grid>
        <Grid item md={2} xs={4}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={canlcelUploadFile}
          >
            <CancelIcon /> Cancelar
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default SomeVoucher;
