import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import {
  msmSwalError,
  msmSwalExito,
  palette,
  useStylesGlobal,
} from "configurations/styles/theme";
import React, { useState } from "react";
import BusinessRoundedIcon from "@material-ui/icons/BusinessRounded";
import { Alert } from "@material-ui/lab";
import UseForm from "hooks/useFrom";
import validations from "views/registro/validations";
import { clientPublic } from "configurations/axios";
import apis from "views/registro/apis";
import CompanyLogo from "views/administración/UserAccount/CompanyLogo";
import RideLogo from "views/administración/UserAccount/RideLogo";
import { getRUCEmpresa } from "redux/actions/userActions";
import { useSelector } from "react-redux";
import { apiKey } from "configurations/constant";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formLogin: {
    borderStyle: "solid",
    borderWidth: "1px",
    padding: "1%",
    borderRadius: "8px",
    marginLeft: "10px",
    marginRigth: "10px",
    borderColor: "#d9d9d9",
  },
  from: {
    width: "100%",
    margin: "1%",
  },
  formControl: {
    marginTop: theme.spacing(0),
    minWidth: 120,
  },
  radio: {
    display: "-webkit-box",
    marginTop: "0px",
  },
  radioLable: {
    color: palette.primary.main,
    display: "flex",
  },
  radioButton: {
    marginTop: "0px",
    paddingTop: "0px",
  },
  divForm: {
    marginBottom: "1%",
  },
  section: {
    margin: "1%",
  },
  divInput: {
    marginBottom: "1%",
    paddingRight: "1%",
    paddingLeft: "0.5%",
  },
  divButton: {
    paddingLeft: "3%",
    paddingRight: "3%",
  },
  large: {
    width: "200px",
    height: "55px",
    marginBottom: "10px",
  },
  space: {
    margin: "10px",
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 999,
    color: palette.primary.main,
  },
}));

const AddCompanyBtn = ({ updateView }) => {
  const classes = useStyles();
  const classesGlobal = useStylesGlobal();
  const tokenUser = useSelector((state) => state.user.tk);
  const [openLoader, setOpenLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [company, setCompany] = useState({
    correoElectronico: "",
    direccionEmpresa: "",
    logoEmpresa: "",
    logoRide: "",
    nombreComercial: "",
    numeroResolucion: "",
    obligadoContabilidad: false,
    contribuyenteMicroEmpresa: false,
    negocioPopular: false,
    razonSocial: "",
    rucEmpresa: "",
    telefono: "",
    agenteRetencion: "",
    rucEmpresaContadora: "",
    subdominioEmpresa: "",
  });
  const [msgError, setMsg] = useState({ show: false, msg: null, type: null });
  const [contribuyenteEspecial, setContribuyenteEspecial] = useState(false);
  function closeAlert() {
    setMsg({ show: false });
  }
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const existError = () => {};

  const handleSave = async () => {
    setOpenLoader(true);
    await clientPublic
      .post(apis.post_save_company, {
        ...company,
        rucEmpresaContadora: tokenUser ? getRUCEmpresa(tokenUser) : "0",
        codigoSistema: apiKey,
      })
      .then((response) => {
        if (response.status >= 200 && response.status <= 300) {
          setOpen(false);
          updateView();
          msmSwalExito("Empresa registrada");
        }
        setOpenLoader(false);
      })
      .catch((exception) => {
        if (exception.response) {
          if (exception.response.status === 400) {
            msmSwalError(
              exception.response.data.apierror
                ? exception.response.data.apierror.mensaje
                  ? exception.response.data.apierror.mensaje
                  : "No se pudo registrar la empresa. Contáctese con el administrador del Sistema."
                : "No se pudo registrar la empresa. Contáctese con el administrador del Sistema."
            );
          } else if (exception.response.status === 404) {
            msmSwalError(
              exception.response.data.apierror
                ? exception.response.data.apierror.mensaje
                  ? exception.response.data.apierror.mensaje +
                    ". Contáctese con el administrador del Sistema"
                  : "No se pudo registrar la empresa. Contáctese con el administrador del Sistema."
                : "No se pudo registrar la empresa. Contáctese con el administrador del Sistema."
            );
          } else if (exception.response.status === 409) {
            msmSwalError(
              exception.response.data.apierror
                ? exception.response.data.apierror.mensaje
                  ? exception.response.data.apierror.mensaje +
                    ". Contáctese con el administrador del Sistema"
                  : "La empresa ya existe. Contáctese con el administrador del Sistema"
                : "La empresa ya existe. Contáctese con el administrador del Sistema."
            );
            setMsg({
              show: true,
              msg: "La empresa ya existe. Contáctese con el administrador del Sistema.",
              type: "error",
            });
          } else {
            msmSwalError(
              exception.response.data.apierror
                ? exception.response.data.apierror.mensaje
                  ? exception.response.data.apierror.mensaje +
                    ". Contáctese con el administrador del Sistema"
                  : "Ocurrió un error interno. Contáctese con el administrador del Sistema."
                : "Ocurrió un error interno. Contáctese con el administrador del Sistema."
            );
            setMsg({
              show: true,
              msg: "Ocurrió un error interno. Contáctese con el administrador del Sistema.",
              type: "error",
            });
          }
        } else {
          setMsg({
            show: true,
            msg: "Existen problemas en la red. Contáctese con el administrador del Sistema.",
            type: "error",
          });
        }
        setOpenLoader(false);
      });
  };

  const { errors, handleSubmit, getObject } = UseForm(
    handleSave,
    validations.checkCompany,
    existError
  );
  const handleShowAlert = (alert) => {
    setMsg(alert);
  };

  const handleChange = (event) => {
    setCompany({
      ...company,
      [event.target.name]: event.target.value,
    });
    getObject({
      ...company,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeResolucion = (event) => {
    event.preventDefault();
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      setCompany({
        ...company,
        [event.target.name]: event.target.value,
      });
      getObject({
        ...company,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleChangeCheck = (event) => {
    setCompany({
      ...company,
      [event.target.name]: event.target.value === "S" ? true : false,
    });
  };

  const handleChangeCheckNegPopular = (event) => {
    setCompany({
      ...company,
      [event.target.name]: event.target.value === "S" ? true : false,
    });
  };

  const handleSubmitLogoRide = (logoRide) => {
    setCompany({ ...company, logoRide });
    setMsg({ show: false, msg: null, type: null });
  };

  const handleSubmitLogo = (logoEmpresa) => {
    setCompany({ ...company, logoEmpresa });
    setMsg({ show: false, msg: null, type: null });
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        <BusinessRoundedIcon
          style={{ color: palette.primary.contrastText, paddingRight: "3%" }}
        />{" "}
        Agregar Empresa
      </Button>

      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="max-width-dialog-title">Nueva Empresa</DialogTitle>
        <DialogContent>
          {msgError.show ? (
            <Alert
              tipo={msgError.type}
              mensaje={msgError.msg}
              mesajeSubrayado={null}
              titulo={null}
              mostrar={msgError.show}
              cerrarAlerta={closeAlert}
            />
          ) : null}
          <form onSubmit={handleSubmit}>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
            >
              <Grid item md={12} xs={12}>
                <label>
                  Los campos marcados con ({" "}
                  <font color={palette.error.main}> *</font> ) son obligatorios:
                </label>
              </Grid>

              <section>
                <form onSubmit={handleSubmit}>
                  <Grid container justify="center" spacing={2}>
                    <Grid item sm={5} className={classes.formLogin}>
                      <Grid container spacing={1}>
                        <Grid item md={12}>
                          <Typography variant="h6">
                            <strong>Registro de empresa</strong>
                          </Typography>
                        </Grid>
                        <Grid item md={12}>
                          <Divider></Divider>
                        </Grid>
                        <Grid item md={12}>
                          <label>
                            Los campos marcados con ({" "}
                            <font color={palette.error.main}> *</font> ) son
                            obligatorios:
                          </label>
                        </Grid>
                        <Grid item sm={3}>
                          <label>
                            Ruc: <font color={palette.error.main}> *</font>
                          </label>
                        </Grid>
                        <Grid item sm={9}>
                          <input
                            type="text"
                            className={
                              errors.rucEmpresa
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            onChange={handleChangeResolucion}
                            required
                            name="rucEmpresa"
                            value={company.rucEmpresa}
                            placeholder="Ej. 0123456789001"
                            autoComplete="off"
                            maxLength="13"
                          />
                          {errors.rucEmpresa ? (
                            <small style={{ color: palette.error.main }}>
                              {errors.rucEmpresa}
                            </small>
                          ) : null}
                        </Grid>
                        <Grid item sm={3}>
                          <label>
                            Razón Social:{" "}
                            <font color={palette.error.main}> *</font>
                          </label>
                        </Grid>
                        <Grid item sm={9}>
                          <input
                            type="text"
                            className={
                              errors.razonSocial
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            onChange={handleChange}
                            required
                            name="razonSocial"
                            value={company.razonSocial}
                            placeholder="Ej. Empresa C.A."
                            autoComplete="off"
                            maxLength="300"
                          />
                          {errors.razonSocial ? (
                            <small style={{ color: palette.error.main }}>
                              {errors.razonSocial}
                            </small>
                          ) : null}
                        </Grid>
                        <Grid item sm={3}>
                          <label>Nombre Comercial: </label>
                        </Grid>
                        <Grid item sm={9}>
                          <input
                            type="text"
                            className={
                              errors.nombreComercial
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            onChange={handleChange}
                            name="nombreComercial"
                            value={company.nombreComercial}
                            placeholder="Ej. EmpresaCorp."
                            autoComplete="off"
                          />
                          {errors.nombreComercial ? (
                            <small style={{ color: palette.error.main }}>
                              {errors.nombreComercial}
                            </small>
                          ) : null}
                        </Grid>
                        <Grid item sm={3}>
                          <label>
                            Dirección:{" "}
                            <font color={palette.error.main}> *</font>
                          </label>
                        </Grid>
                        <Grid item sm={9}>
                          <input
                            type="text"
                            className={
                              errors.direccionEmpresa
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            onChange={handleChange}
                            name="direccionEmpresa"
                            value={company.direccionEmpresa}
                            placeholder="Ej. Av. y Calle 2"
                            autoComplete="off"
                            required
                          />
                          {errors.direccionEmpresa ? (
                            <small style={{ color: palette.error.main }}>
                              {errors.direccionEmpresa}
                            </small>
                          ) : null}
                        </Grid>
                        <Grid item sm={3}>
                          <label>
                            Correo Electrónico:
                            <font color={palette.error.main}> *</font>{" "}
                          </label>
                        </Grid>
                        <Grid item sm={9}>
                          <input
                            type="email"
                            className={
                              errors.correoElectronico
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            onChange={handleChange}
                            name="correoElectronico"
                            value={company.correoElectronico}
                            placeholder="Ej. info@ejemplo.com.ec"
                            autoComplete="off"
                            required
                          />
                          {errors.correoElectronico ? (
                            <small style={{ color: palette.error.main }}>
                              {errors.correoElectronico}
                            </small>
                          ) : null}
                        </Grid>
                        <Grid item sm={3}>
                          <label>Teléfono: </label>
                        </Grid>
                        <Grid item sm={9}>
                          <input
                            type="text"
                            className={
                              errors.telefono
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            onChange={handleChangeResolucion}
                            name="telefono"
                            value={company.telefono}
                            placeholder="Ej. 021234567"
                            autoComplete="off"
                          />
                          {errors.telefono ? (
                            <small style={{ color: palette.error.main }}>
                              {errors.telefono}
                            </small>
                          ) : null}
                        </Grid>
                        <Grid item sm={3}>
                          <label>
                            Sub dominio:{" "}
                            <font color={palette.error.main}> *</font>
                          </label>
                        </Grid>
                        <Grid item sm={9}>
                          <input
                            type="text"
                            className={
                              errors.direccionEmpresa
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            onChange={handleChange}
                            name="subdominioEmpresa"
                            value={company.subdominioEmpresa}
                            placeholder="Ej. asd@asd.com"
                            autoComplete="off"
                            required
                          />
                          {errors.subdominioEmpresa ? (
                            <small style={{ color: palette.error.main }}>
                              {errors.subdominioEmpresa}
                            </small>
                          ) : null}
                        </Grid>
                        <Grid item sm={6}>
                          <label>
                            Obligado a llevar Contabilidad:
                            <font color={palette.error.main}> *</font>
                          </label>
                        </Grid>
                        <Grid item sm={6}>
                          <FormControl
                            component="fieldset"
                            className={classes.formControl}
                          >
                            <RadioGroup
                              aria-label="tipoContabilidad"
                              name="obligadoContabilidad"
                              className={classes.radio}
                              required
                              value={company.obligadoContabilidad ? "S" : "N"}
                              onChange={handleChangeCheck}
                            >
                              <FormControlLabel
                                value="S"
                                control={
                                  <Radio
                                    color="primary"
                                    className={classes.radioButton}
                                  />
                                }
                                label="Si"
                                labelPlacement="start"
                                className={classes.radioLable}
                              />
                              <FormControlLabel
                                value="N"
                                control={
                                  <Radio
                                    color="primary"
                                    className={classes.radioButton}
                                  />
                                }
                                label="No"
                                labelPlacement="start"
                                className={classes.radioLable}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>

                        <Grid item sm={6}>
                          <label>
                            Contribuyente Régimen Simplificado para
                            Emprendedores y Negocios Populares (RIMPE):
                            <font color={palette.error.main}> *</font>
                          </label>
                        </Grid>
                        <Grid item sm={6}>
                          <FormControl
                            component="fieldset"
                            className={classes.formControl}
                          >
                            <RadioGroup
                              aria-label="tipoContabilidad"
                              name="contribuyenteMicroEmpresa"
                              className={classes.radio}
                              value={
                                company.contribuyenteMicroEmpresa ? "S" : "N"
                              }
                              onChange={handleChangeCheck}
                            >
                              <FormControlLabel
                                value="S"
                                control={
                                  <Radio
                                    color="primary"
                                    className={classes.radioButton}
                                  />
                                }
                                label="Si"
                                labelPlacement="start"
                                className={classes.radioLable}
                              />
                              <FormControlLabel
                                value="N"
                                control={
                                  <Radio
                                    color="primary"
                                    className={classes.radioButton}
                                  />
                                }
                                label="No"
                                labelPlacement="start"
                                className={classes.radioLable}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        {company.contribuyenteMicroEmpresa ? (
                          <>
                            <Grid item sm={6}>
                              <label>
                                Seleccione el tipo de (RIMPE):
                                <font color={palette.error.main}> *</font>
                              </label>
                            </Grid>
                            <Grid item sm={6}>
                              <FormControl
                                component="fieldset"
                                className={classes.formControl}
                              >
                                <RadioGroup
                                  aria-label="tipoContabilidad"
                                  name="negocioPopular"
                                  className={classes.radio}
                                  value={company.negocioPopular ? "S" : "N"}
                                  onChange={handleChangeCheckNegPopular}
                                >
                                  <FormControlLabel
                                    value="S"
                                    control={
                                      <Radio
                                        color="primary"
                                        className={classes.radioButton}
                                      />
                                    }
                                    label="Negocio Popular"
                                    labelPlacement="start"
                                    className={classes.radioLable}
                                  />
                                  <FormControlLabel
                                    value="N"
                                    control={
                                      <Radio
                                        color="primary"
                                        className={classes.radioButton}
                                      />
                                    }
                                    label="Emprendedores"
                                    labelPlacement="start"
                                    className={classes.radioLable}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </>
                        ) : null}

                        <Grid item sm={6}>
                          <label>Es contribuyente especial?</label>
                        </Grid>
                        <Grid item sm={6}>
                          <FormControl
                            component="fieldset"
                            className={classes.formControl}
                          >
                            <RadioGroup
                              aria-label="tipoContabilidad"
                              name="contribuyenteEspecial"
                              className={classes.radio}
                              value={contribuyenteEspecial === true ? "S" : "N"}
                              onClick={() =>
                                setContribuyenteEspecial(!contribuyenteEspecial)
                              }
                            >
                              <FormControlLabel
                                value="S"
                                control={
                                  <Radio
                                    color="primary"
                                    className={classes.radioButton}
                                  />
                                }
                                label="Si"
                                labelPlacement="start"
                                className={classes.radioLable}
                              />
                              <FormControlLabel
                                value="N"
                                control={
                                  <Radio
                                    color="primary"
                                    className={classes.radioButton}
                                  />
                                }
                                label="No"
                                labelPlacement="start"
                                className={classes.radioLable}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        {contribuyenteEspecial && (
                          <>
                            <Grid item sm={4}>
                              <label>Número de Resolución: </label>
                            </Grid>
                            <Grid item sm={8}>
                              <input
                                type="text"
                                className={
                                  errors.numeroResolucion
                                    ? "form-control is-invalid"
                                    : "form-control"
                                }
                                onChange={handleChangeResolucion}
                                name="numeroResolucion"
                                value={
                                  contribuyenteEspecial
                                    ? company.numeroResolucion
                                    : ""
                                }
                                placeholder="Ej. 122"
                                autoComplete="off"
                              />
                              {errors.numeroResolucion ? (
                                <small style={{ color: palette.error.main }}>
                                  {errors.numeroResolucion}
                                </small>
                              ) : null}
                            </Grid>
                          </>
                        )}
                        <Grid item sm={4}>
                          <label>Resolución Agente de Retención: </label>
                        </Grid>
                        <Grid item sm={8}>
                          <input
                            type="text"
                            className={
                              errors.numeroResolucion
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            onChange={handleChange}
                            name="agenteRetencion"
                            value={company.agenteRetencion}
                            placeholder="Ej. NAC-ERCRASC21-000007"
                            autoComplete="off"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item sm={5} className={classes.formLogin}>
                      <Grid
                        container
                        justifyContent="center"
                        align="center"
                        direction="row"
                        spacing={2}
                      >
                        <Grid item sm={12}>
                          <CompanyLogo
                            title={"Logo de la Empresa"}
                            urlImage={company.logoEmpresa}
                            onSubmit={handleSubmitLogo}
                            onShowAlert={handleShowAlert}
                            textBtn="Subir Logo"
                          />
                        </Grid>
                        <Grid item md={12}>
                          <Divider></Divider>
                        </Grid>
                        <Grid item sm={12} className={classes.imgRide}>
                          <RideLogo
                            urlImage={company.logoRide}
                            onSubmit={handleSubmitLogoRide}
                            onShowAlert={handleShowAlert}
                            textBtn="Subir Logo"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </section>
              <Grid container spacing={3} justify="center">
                <Grid item md={12} xs={12}>
                  <br />
                  <Divider></Divider>
                </Grid>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={classesGlobal.marginButton}
                >
                  Agregar
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleClose}
                  className={classesGlobal.marginButton}
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      <Backdrop className={classes.backdrop} open={openLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default AddCompanyBtn;
