import React, { useState, Fragment } from "react";

import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
//mostrar empresa
import { Grid, Card, Divider, CardHeader, Typography } from "@material-ui/core";

//material-ui.com
import { makeStyles } from "@material-ui/core/styles";

//componentes del sistema

//componentes para el boton buscar
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";

import { useStylesGlobal } from "configurations/styles/theme";

import Select from "react-select";
//Color para la advertencia
import { palette } from "configurations/styles/theme";
import deLocale from "date-fns/locale/es";
import AssessmentIcon from "@material-ui/icons/Assessment";
const useStyles = makeStyles((theme) => ({
  cardContent: {
    width: "94%",
    height: "410px",
    margin: "3%",
  },
  gridLable: {
    // paddingRight: '1%'
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  divBotonBuscar: {
    marginTop: "0.5%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  menuSeleccion: {
    zIndex: "999  ! Important",
    width: "60% !Important",
  },
  infoBusqueda: {
    marginTop: "30px",
    marginBottom: "30px",
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  fragment: {
    padding: "10px",
  },
}));

const RangeDataOption = (props) => {
  const classes = useStyles();
  const classesGlobales = useStylesGlobal();

  const [selectedDate, handleDateChange] = useState(new Date());
  const [selectedDateEnd, handleDateChangeEnd] = useState(new Date());
  const [estadoFactura, setEstadoFactura] = useState({
    estadoFactura: "Seleccione",
  });

  const options = [
    { value: "xml", label: "XML" },
    { value: "xlsx", label: "Excel" },
  ];

  const handleChange = (estadoFactura) => {
    setEstadoFactura(estadoFactura);
  };

  function generateReport() {
    let month = selectedDateEnd.getMonth() + 1;
    let monthString = month.toString();
    if (month < 10) {
      monthString = "0" + monthString;
    }
    props.generateReport(selectedDate.getFullYear(), monthString, estadoFactura);
  }

  return (
    <Fragment >
      <Card className={classes.cardContent}>
        <Grid container xs={12} justify="center" alignItems="center">
          <CardHeader
            className={classesGlobales.cabecera}
            title={props.title}
            avatar={<AssessmentIcon></AssessmentIcon>}
          />
        </Grid>

        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          align="center"
        >
          <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
            <Grid container>
              <Grid item xs={12}>
                <Divider className={classes.divider} />
              </Grid>
              <Grid item xs={12}>
                <Typography aling="center" color="primary" variant="subtitle2">
                  Los campos marcados con (<font color={palette.error.main}> *</font>) son obligatorios:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider className={classes.divider} />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item lg={2} xs={12}>
                <Typography aling="center" color="primary" variant="subtitle2">
                  Año:<font color={palette.error.main}> *</font>
                </Typography>
              </Grid>
              <Grid item lg={2} xs={12}>
                <KeyboardDatePicker
                  autoOk
                  disableFuture
                  variant="inline"
                  inputVariant="outlined"
                  format="yyyy"
                  openTo={"year"}
                  value={selectedDate}
                  InputAdornmentProps={{ position: "start" }}
                  onChange={(date) => handleDateChange(date)}
                  invalidDateMessage="Formato inválido."
                />
              </Grid>
              <Grid item lg={2} xs={12}>
                <Typography aling="center" color="primary" variant="subtitle2">
                  Mes:<font color={palette.error.main}> *</font>
                </Typography>
              </Grid>
              <Grid item lg={2} xs={12}>
                <KeyboardDatePicker
                  variant="inline"
                  disableFuture
                  autoOk
                  inputVariant="outlined"
                  openTo={"month"}
                  views={"month"}
                  value={selectedDateEnd}
                  InputAdornmentProps={{ position: "start" }}
                  onChange={(date) => handleDateChangeEnd(date)}
                  invalidDateMessage="Formato inválido."
                />
              </Grid>
            </Grid>
            <br></br>
            <Grid>
              <Divider className={classes.divider} />
            </Grid>

            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={1}
            >
              <Grid item xs={12} lg={1}>
                <Typography aling="center" color="primary" variant="subtitle2">
                  Extensión
                  <font color={palette.error.main}> *</font>
                </Typography>
              </Grid>

              <Grid item xs={12} lg={3} align="center">
                <div className={classes.menuSeleccion}>
                  <Select
                    options={options}
                    isSearchable
                    maxMenuHeight={140}
                    placeholder={"Select..."}
                    value={estadoFactura}
                    onChange={handleChange}
                    menuPlacement="bottom"
                  />
                </div>
              </Grid>
              <Grid item xs={12} lg={2}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={generateReport}
                >
                  <SearchIcon /> Generar
                </Button>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
        <Divider className={classes.divider} />
      </Card>
    </Fragment>
  );
};

export default RangeDataOption;
