import clientBackend from "configurations/axios";
import { bookApis, apiKey} from "configurations/constant"
import { notNull} from "utils/checkInputs"
import {getPerm} from "redux/actions/userActions";
import {useHistory} from "react-router-dom";
import {addResourcesSuccess, addConfigResourcesAction} from "redux/actions/resourcesActions";


export const findPermissons = (tokenUser) => {
    return new Promise((resolve,reject) => {
        const idProfile = getPerm(tokenUser);
        clientBackend
        .get(bookApis.auth.get_all_permissons(idProfile, apiKey))
        .then(result => resolve(result))
        .catch(error => reject(error));
    });
}
/**
 * @description hook para manejar los permisos del usuario
 */
export default function usePermissions(useSelector, dispatch){
    let tokenUser = useSelector(state => state.user.tk);
    tokenUser = notNull(tokenUser) ? tokenUser : "";

    const history = useHistory();
    const permissonCurrent = useSelector(state => state.resources.resources);
    const configPermissons = useSelector(state => state.resources.configResources);

    const savePermissons = (data = []) => {
        let menuPermissons = [];
        let configPermissons = [];

        data.forEach(item => {
            if(item.menuPadre === "CFG" || item.id === "CFG"){
                configPermissons.push(item);
            }else {
                menuPermissons.push(item);
            }
        })
        dispatch(addResourcesSuccess(menuPermissons));
        dispatch(addConfigResourcesAction(configPermissons));
    }

    const updatePermissons = () => {
        
        if(Array.isArray(permissonCurrent) && permissonCurrent.length ===0){
            findPermissons(tokenUser)
                .then(result => savePermissons(result.data))
                .catch(() => history.push("/"));
        }else if(!Array.isArray(permissonCurrent)){
            findPermissons(tokenUser)
                .then(result => dispatch(addResourcesSuccess(result.data)))
                .catch(() => history.push("/"));
        }
    }
    const hasPermisson = () => {

    }
    
    const getPermissons = () => permissonCurrent
    const getConfigPermissons = () => configPermissons

    /**
    * @description Busca un Permiso según un Id
    * @param {*} id 
    * @returns true - Encontro el permiso
    * @returns false - No encontro el permiso
    */
    const getPermissonById = (id = "") => {
        return permissonCurrent.some(item => item.id === id);
    }
    return {
        getPermissons,
        updatePermissons,
        hasPermisson,
        getConfigPermissons,
        getPermissonById,
    }
}
    
    
  
  

