import React, { Fragment, useState } from 'react';
import {
    IconButton, Dialog, DialogTitle, DialogContent, makeStyles, Grid,
    Button,
    CircularProgress,
    Avatar,
    Backdrop
} from '@material-ui/core';

import { clientBackendXML } from 'configurations/axios';
import { palette } from 'configurations/styles/theme';

const useStyles = makeStyles(theme => ({
    IconButton: {
        marginTop: "0%",
        marginBottom: "0%",
        padding: "0px",
    },
    divInfoUser: {
        borderStyle: 'solid',
        borderColor: palette.primary.main,
        borderWidth: '1px',
        borderRadius: '8px',
        marginTop: '1%'
    },
    divlabel: {
        marginTop: '1%'
    },
    buttonClose: {
        width: '75%'
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    backdrop: {
        zIndex: "3000",
        color: "#fff",
    },
}))
const XMLVoucherBtn = ({ voucher }) => {

    const [open, setOpen] = useState(false);
    const classes = useStyles();

    const [openLoader, setOpenLoader] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };



    const obtenerDatos = async () => {
        setOpenLoader(true);
        return await clientBackendXML.get(`/core/utilidades/ebox/generar/xml/${voucher.comprobanteElectronico.idComprobanteElectronico}`)
            //return await clientBackendPDF.get(`${api}${voucher.comprobanteElectronico.idComprobanteElectronico}`)
            .then(
                response => {
                    //Create a Blob from the XML Stream
                    const file = new Blob(
                        [response.data],
                        { type: 'application/x-download' });

                    const url = window.URL.createObjectURL(file);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                        'download',
                        `${voucher.comprobanteElectronico.claveAcceso}.xml`,
                    );

                    // Append to html link element page
                    document.body.appendChild(link);
                    //setFileURL(link);
                    //handleClickOpen()
                    // Start download
                    link.click();

                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                    setOpenLoader(false);



                    ////esto esta valiendo
                    /*
                    var uri = 'data:application/xml;charset=utf-8,' + response.data;
                    var downloadLink = document.createElement("a");
                    downloadLink.href = uri;
                    console.log(voucher.comprobanteElectronico.claveAcceso);
                    downloadLink.download = `${voucher.comprobanteElectronico.claveAcceso}.xml`;
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);*/

                }
            ).catch(
                error => {
                    if (error.response) {
                        setOpenLoader(false);
                        console.error('error', error.response.data);
                        setOpen(true)
                    } else {
                        setOpenLoader(false);
                        console.error('error', error);
                        setOpen(true)
                    }
                }
            )



    }


    return (
        <Fragment>

            <IconButton color="primary" aria-label="upload picture" component="span" onClick={obtenerDatos} className={classes.IconButton}>
                <Avatar variant="square" src="../xml.png" className={classes.small} />
            </IconButton>
            {/*<Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
                <iframe src={fileURL} width="100%" height="650px" />
    </Dialog>*/}

            <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle id="max-width-dialog-title">Mensaje</DialogTitle>
                <DialogContent>

                    <div className="alert alert-danger" role="alert">
                        <label>

                            {(`No se pudo generar el comprobante con la clave de acceso: ${voucher.comprobanteElectronico.claveAcceso ?
                                voucher.comprobanteElectronico.claveAcceso
                                :
                                ''} `
                            )}
                        </label>
                    </div>

                    <Grid container justify='center'>
                        <Button variant="contained" color="secondary" onClick={handleClose} >
                            CERRAR
                        </Button>
                    </Grid>
                </DialogContent>

            </Dialog>
            <Backdrop open={openLoader} className={classes.backdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>

        </Fragment>
    );
}

export default XMLVoucherBtn;