import React, { useEffect, useState, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { getRUCEmpresaRecibidos, isClient } from '../../../redux/actions/userActions';
//componentes del sistema
import TablePaginatedVoucher from '../../../widgets/Tables/TablePaginatedVoucher'

import tableStyle from '../../../configurations/styles/table'
import { useStylesGlobal } from "configurations/styles/theme";
import SearchVoucher from '../Forms/SearchVoucher';
import UploadVoucher from '../Vouchers/Add/UploadVoucher'
//import PDFVoucherBtn from './Buttons/PDFVoucherBtn';
import { CircularProgress, Grid, Button, Card, Avatar, Backdrop } from '@material-ui/core';
import XMLVoucherBtn from '../Buttons/XMLVoucherBtn';
import TablePaginatedVoucherSearch from 'widgets/Tables/TablePaginatedVoucherSearch';
import TablePaginatedSearchVoucher from 'widgets/Tables/TablePaginatedSearchVoucher';
import PDFVoucherBtn from '../AuthorizedIssuedVouchers/Buttons/PDFVoucherBtn';

import { API_GET_VOUCHER_AUTHORIZED, API_GET_SEARCH_AUTHORIZED } from './apis';
import { getZip } from '../Vouchers/validate';
import { useSnackbar } from 'notistack';
const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: '50px',
        flexGrow: 1,
        paddingLeft: '3%',
        paddingRight: '3%',
        marginBottom: '5%'
    },
    backdrop: {
        zIndex: "3000",
        color: "#fff",
    },
    margin: {
        marginBottom: '0.5%',
    },
    zip: {
        padding: "14px",
    },
    iconzip: {
        padding: "50px"
    },
}));


const VoucherReceived = () => {

    const tokenUser = useSelector(state => state.user.tk);
    const classesView = useStyles();
    const classesGlobales = useStylesGlobal();
    const [paramSearch, setParamSearch] = useState('');
    //state para actualizar la vista
    const [update, setUpdate] = useState(1);
    const [openLoader, setOpenLoader] = useState(false);
    const [idComprobantes, setIdComprobantes] = useState([]);
    const { enqueueSnackbar } = useSnackbar();


    const columns = [
        { title: 'Número', field: `comprobanteElectronico.secuencial`, cellStyle: tableStyle.cellTable, width: '10%', render: rowData => `${rowData.comprobanteElectronico.codigoEstablecimiento}-${rowData.comprobanteElectronico.puntoEmision}-${rowData.comprobanteElectronico.secuencial}` },
        { title: 'Fecha Emisión', field: 'comprobanteElectronico.fechaEmision', cellStyle: tableStyle.cellTable, width: '10%', render: rowData => rowData.comprobanteElectronico.fechaEmision.substring(0, 10) },
        //{ title: 'Fecha Autorización', field: 'comprobanteElectronico.fechaAutorizacion', cellStyle: tableStyle.cellTable, width: '15%',render: rowData => rowData?(rowData.comprobanteElectronico.fechaAutorizacion.substring(0, 10)).concat((new Date(rowData.comprobanteElectronico.fechaAutorizacion)).toString().substring(15, 24)):null},
        { title: 'Fecha Autorización', cellStyle: tableStyle.cellTable, width: '15%', render: rowData => rowData.comprobanteElectronico.fechaAutorizacion ? (rowData.comprobanteElectronico.fechaAutorizacion.substring(0, 10)).concat((new Date(rowData.comprobanteElectronico.fechaAutorizacion)).toString().substring(15, 24)) : 'No disponible' },
        //{ title: 'Fecha Autorización', cellStyle: tableStyle.cellTable, width: '15%',render: rowData => 'hola'},
        { title: 'Emisor', field: 'comprobanteElectronico.razonSocialEmisor', cellStyle: tableStyle.cellLeft, width: '30%' },
        { title: 'Tipo', field: 'comprobanteElectronico.tipoComprobanteElectronico.nombreComprobante', cellStyle: tableStyle.cellTable, width: '12%', render: rowData => rowData.comprobanteElectronico.tipoComprobanteElectronico.nombreComprobante.replace("_", ' DE ') },
        { title: 'PDF', cellStyle: tableStyle.cellTable, width: '5%', render: rowData => <PDFVoucherBtn voucher={rowData} /> },
        { title: 'XML', cellStyle: tableStyle.cellTable, width: '5%', render: rowData => <XMLVoucherBtn voucher={rowData} /> },

    ];


    const requestConfig = {
        uri: API_GET_VOUCHER_AUTHORIZED + (tokenUser ? getRUCEmpresaRecibidos(tokenUser) : "0"),
        metodo: 'get',
        body: null,
        page: 0,
        elementos: 15,
        sort: 'idAsignacionComprobanteElectronico,desc'
    }

    const requestConfigSeacrh = {
        uri: `${API_GET_SEARCH_AUTHORIZED}${tokenUser ? getRUCEmpresaRecibidos(tokenUser) : "0"}`,
        metodo: 'get',
        body: null,
        page: 0,
        elementos: 15,
        sort: 'idAsignacionComprobanteElectronico,desc',
    }


    useEffect(() => {

    }, [update])

    const generateZip = () => {
        if (typeof idComprobantes !== 'undefined' && Object.entries(idComprobantes).length !== 0) {
            getZip(setOpenLoader, idComprobantes)

            /* enqueueSnackbar(`No se pudo generar el zip`, {
                 variant: "warning",
                 anchorOrigin: {
                     vertical: "top",
                     horizontal: "right",
                 },
             });*/
        } else {
            enqueueSnackbar(`Seleccione al menos un comprobante`, {
                variant: "warning",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            });
        }
    }

    const setIdVoucher = (dataTable) => {
        let idList = dataTable.map(data => data.comprobanteElectronico.idComprobanteElectronico);
        setIdComprobantes(idList)
    }

    const headerBottons = () => {
        if (update === 6) {
            return (
                <Grid container direction="row" justify="flex-start" alignItems="center" >

                    <Grid item xs={2}>
                        <Button variant="contained" color="primary" onClick={() => setUpdate(2)}>
                            Mostrar Todos
                        </Button>
                    </Grid>
                </Grid>
            );
        }

    }


    const renderTable = () => {
        switch (update) {
            case 1:
                setUpdate(2);
                return (
                    <div align='center'>
                        <CircularProgress />
                    </div>
                );
            case 2:
                return (
                    <TablePaginatedVoucher
                        columns={columns}
                        title=""
                        numeroFilas={5}
                        requestConfig={requestConfig}
                        BotonesHeader={headerBottons}
                        seleccion={true}
                        setIdVoucher={setIdVoucher}
                    />
                );
            case 3:
                return (
                    <Fragment>
                        <TablePaginatedVoucherSearch
                            title=""
                            columns={columns}
                            numeroFilas={5}
                            requestConfig={requestConfigSeacrh}
                            BotonesHeader={headerBottons}
                            paramSearch={paramSearch}
                            seleccion={true}
                            setIdVoucher={setIdVoucher}
                        />

                    </Fragment>
                );
            case 4:
                return (
                    <Fragment>
                        <TablePaginatedSearchVoucher
                            title=""
                            columns={columns}
                            numeroFilas={5}
                            requestConfig={requestConfigSeacrh}
                            BotonesHeader={headerBottons}
                            paramSearch={paramSearch}
                            seleccion={true}
                            setIdVoucher={setIdVoucher}
                        />

                    </Fragment>
                )
            default:
                break;
        }
    }

    return (
        <div className={classesView.root}>
            <Backdrop open={openLoader} className={classesView.backdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <SearchVoucher
                update={update}
                setUpdate={setUpdate}
                setParamSearch={setParamSearch}
            />

            <Backdrop open={openLoader} className={classesView.backdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {!isClient(tokenUser) ?
                <UploadVoucher/>
                :
                null
            }
            

            <Card className={classesView.margin} >
                <Grid container className={classesView.zip} direction="row" alignItems="center" onClick={generateZip} >
                    <Grid item xs={6} >
                        <label className={classesGlobales.titleCard}>
                            Para descargar varios comprobantes a la vez, seleccione y dé clic en este botón:
                        </label>
                    </Grid>
                    <Grid container item xs={6} direction="row-reverse" justify="flex-start" alignItems="center">
                        <Avatar variant="square" src="../zip.png" className={classesView.small} />
                    </Grid>
                </Grid>
            </Card>

            {renderTable()}
        </div>
    );
}
export default VoucherReceived;