import React, { useState, useEffect } from "react";
import { Button, Grid, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector } from "react-redux";
import { getRUCEmpresa } from "redux/actions/userActions";
//componentes del sistema
import TablePaginatedReports from "widgets/Tables/TablePaginatedReports";
import TableSimple from "widgets/Tables/TableSimple";
import { API_GET_ALL_CLIENT, API_GET_SEARCH_CLIENTS } from "./apis";
import { httpReportes } from "configurations/axios";
import tableStyle from "configurations/styles/table";
import AddClientBtn from "././Buttons/AddClient";
import SearchProvider from "./Froms/SearchProvider";
import EditCostumerBtn from "./Buttons/EditClient";
import { CardGeneric } from "../../components/CardGeneric";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "35px",
    flexGrow: 1,
    paddingLeft: "3%",
    paddingRight: "3%",
    marginBottom: "50px"
  },
  headerBottons: {
    padding: "0.5%",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    height: "300px"
  }
}));

const Home = () => {
  const tokenUser = useSelector((state) => state.user.tk);

  const classesView = useStyles();
  const [update, setUpdate] = useState(0);
  const [foundCostumers, setFoundCostumers] = useState([]);
  const [paramSearch, setParamSearch] = useState("");

  const getParam = (data) => {
    setParamSearch(data);
    if (data.length > 2) {
      searchProvider(data);
    } else {
      reload();
    }
  };
  const columns = [
    {
      title: "Identificación",
      field: "cliente.id",
      cellStyle: tableStyle.cellLeft,
      width: "7%",
    },
    {
      title: "Razón Social/Nombres",
      field: "cliente.nombres",
      cellStyle: tableStyle.cellLeft,
      width: "25%",
    },
    {
      title: "Editar",
      cellStyle: tableStyle.cellTable,
      width: "3%",
      render: (rowData) => (
        <EditCostumerBtn costumer={rowData} updateView={reload} />
      ),
    },
  ];

  const requestConfig = {
    uri: API_GET_ALL_CLIENT + (tokenUser ? getRUCEmpresa(tokenUser) : "0"),
    metodo: "get",
    body: null,
    page: 0,
    elementos: 15,
    sort: "id,desc",
  };

  const reload = () => {
    setUpdate(1);
  };

  useEffect(() => {
    if (update === 1) {
      setUpdate(0);
    }
  }, [update]);

  const searchProvider = async (param) => {
    setUpdate(3);
    await httpReportes
      .get(
        API_GET_SEARCH_CLIENTS +
        (tokenUser ? getRUCEmpresa(tokenUser) : "0") +
        "?parametroBusqueda=" +
        param
      )
      .then((response) => {
        if (response.status === 200) {
          setFoundCostumers(response.data.content);
          setUpdate(2);
        }
      })
      .catch((exception) => {
        console.log({ exception });
      });
  };

  const renderTable = () => {
    switch (update) {
      case 0:
        return (
          <TablePaginatedReports
            title=""
            columns={columns}
            requestConfig={requestConfig}
          />
        );

      case 1:
      case 3:
        return (
          <div className={classesView.loader}>
            <CircularProgress />
          </div>
        );
      case 2:
        return (
          <TableSimple
            columns={columns}
            data={foundCostumers}
            title=""
            numberRowsPerPage={15}
          />
        );
      default:
        break;
    }
  };

  return (
    <div className={classesView.root}>
      <CardGeneric>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <AddClientBtn updateView={reload} />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={8} className={classesView.headerBottons}>
            <SearchProvider backupParam={paramSearch} sendData={getParam} />
          </Grid>
        </Grid>
      </CardGeneric>
      {renderTable()}
    </div>
  );
};

export default Home;
