import React, { useState, Fragment } from "react";
import { makeStyles, Grid, Button, CardHeader } from "@material-ui/core";
import { palette, useStylesGlobal } from "configurations/styles/theme";
import CarrierAndTransfer from "./CarrierAndTransfer";
import Addresse from "./Addresse";

import tableStyle from "configurations/styles/table";

import EditAddresseeBtn from "../Buttons/EditAddresseeBtn";
import DeleteAddresseeBtn from "../Buttons/DeleteAddresseeBtn";
import { useSelector, useDispatch } from "react-redux";
import clientBackend from "configurations/axios";
import { getSub } from "redux/actions/userActions";
import AdditionalInfo from "views/facturacion/Vouchers/invoices/Forms/AdditionalInfo";
import ElectronicVoucherOptions from "./ElectronicVoucherOptions";
import AssignmentIcon from "@material-ui/icons/Assignment";
import TableDataSimplePag from "widgets/Tables/TableDataSimplePag";
import TableDataSimple from "widgets/Tables/TableDataSimple";
import { clearReferralGuideAction } from "redux/actions/referralGuideActions";
import { ConfirmAlert, iconsAlert } from "utils/AlertsCustom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.primary,
    margin: theme.spacing(0),
    display: "flex",
    flexWrap: "wrap",
    borderStyle: "solid",
    borderRadius: "8px",
    borderWidth: "0px",
    marginBottom: "2.5%",
  },
  divInput: {
    marginBottom: "1%",
    paddingRight: "1%",
    paddingLeft: "0.5%",
    textAlign: "justify",
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 999,
    color: palette.primary.main,
  },
}));

const ReferralGuideData = () => {
  const classes = useStyles();
  const classesGlobales = useStylesGlobal();
  const tokenUser = useSelector((state) => state.user.tk);
  const [destinatarios, setDestinatarios] = useState([]);

  const [viewAddressee, setViewAddressee] = useState(1);

  const [update, setUpdate] = useState(1);

  const [numeracionSecuencial, setNumeracionSecuencial] = useState("");
  const [rise, setRise] = useState("");
  const dispatch = useDispatch();
  const clearReferralGuide = () => dispatch(clearReferralGuideAction());

  const [additionalInfoInvoice, setAdditionalInfoInvoice] = useState([]);
  const [referralGuide, setReferralGuide] = useState({
    rucTransportista: "",
    razonSocialTransportista: "",
    placa: "",
    fechaIniTransporte: new Date(),
    fechaFinTransporte: new Date(),
    identityCheck: 'C',
  });
  const columns = [
    {
      title: "Identificación",
      field: "identificacionDestinatario",
      cellStyle: tableStyle.cellTable,
    },
    {
      title: "Razón Social",
      field: "razonSocialDestinatario",
      cellStyle: tableStyle.cellTable,
    },
    {
      title: "Dirección",
      field: "dirDestinatario",
      cellStyle: tableStyle.cellTable,
    },
    {
      title: "Motivo Traslado",
      field: "motivoTraslado",
      cellStyle: tableStyle.cellTable,
    },
    {
      title: "Ver / Editar",
      field: "descripcion",
      cellStyle: tableStyle.cellTable,
      render: (rowData) => (
        <EditAddresseeBtn
          data={rowData}
          destinatarios={destinatarios}
          setDestinatarios={setDestinatarios}
          viewAddressee={viewAddressee}
          setViewAddressee={setViewAddressee}
        />
      ),
    },
    {
      title: "Eliminar",
      cellStyle: tableStyle.cellTable,
      width: "10%",
      render: (rowData) => (
        <DeleteAddresseeBtn
          data={rowData}
          destinatarios={destinatarios}
          setDestinatarios={setDestinatarios}
          viewAddressee={viewAddressee}
          setViewAddressee={setViewAddressee}
        />
      ),
    },
  ];

  const getNumeration = async () => {
    await clientBackend
      .get(`/asignacion/secuencial/usuario/secuencial/${getSub(tokenUser)}/06`)
      .then((response) => {
        if (response.status === 200) {
          setNumeracionSecuencial(response.data);
          return response.data;
        }
      })
      .catch((exception) => {
        console.log({ exception });
        return exception;
      });
  };

  React.useEffect(() => {
    if (numeracionSecuencial === "") {
      getNumeration();
    }
  }, [numeracionSecuencial]);

  const handleChangeRise = (event) => {
    event.preventDefault();
    setRise(event.target.value);
  };

  const clearAllData = () => {
    ConfirmAlert(
      "¿Está seguro de limpiar los datos del formulario?",
      "",
      iconsAlert.question,
      true,
      "Sí",
      "Cancelar",
      () => {
        setRise("");
        setAdditionalInfoInvoice([]);
        setNumeracionSecuencial("");
        setDestinatarios([]);
        setReferralGuide({
          rucTransportista: "",
          razonSocialTransportista: "",
          placa: "",
          fechaIniTransporte: new Date(),
          fechaFinTransporte: new Date(),
          identityCheck: 'C',
        });
        return Promise.resolve({
          text: "Los datos del formulario fueron eliminados.",
        });
      },
      () => { }
    );
  };

  const clearOnlyAllData = () => {
    setRise("");
    setAdditionalInfoInvoice([]);
    setNumeracionSecuencial("");
    setDestinatarios([]);
    setReferralGuide({
      rucTransportista: "",
      razonSocialTransportista: "",
      placa: "",
      fechaIniTransporte: new Date(),
      fechaFinTransporte: new Date(),
      identityCheck: 'C',
    });
  };
  return (
    <Fragment>
      <div className={classesGlobales.rootDiv}>
        <Grid container xs={12} justify="center">
          <CardHeader
            className={classesGlobales.cabecera}
            title={"Datos de la Guía de Remisión"}
            avatar={<AssignmentIcon></AssignmentIcon>}
          />
        </Grid>
        <Grid container spacing={1} className={classesGlobales.containerForm}>
          <Grid container justify="center">
            <Grid item xs={2} justify="center">
              <label>
                Numeración: <font color={palette.error.main}>*</font>
              </label>
            </Grid>
            <Grid item xs={4} className={classes.divInput}>
              <input
                className={
                  numeracionSecuencial
                    ? "form-control  is-valid"
                    : "form-control"
                }
                type="text"
                disabled
                name="numeracionSecuencial"
                value={numeracionSecuencial}
              />
            </Grid>

            <Grid item xs={2} justify="center">
              <label>Rise:</label>
            </Grid>
            <Grid item xs={4} className={classes.divInput}>
              <input
                className={rise ? "form-control  is-valid" : "form-control"}
                type="text"
                maxLength="40"
                name="rise"
                value={rise ? rise : ""}
                onChange={handleChangeRise}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid item xs={12}>
          <CarrierAndTransfer
            referralGuide={referralGuide}
            setReferralGuide={setReferralGuide}
            update={update}
          />
        </Grid>
        <Grid item xs={12}>
          <Addresse
            destinatarios={destinatarios}
            setDestinatarios={setDestinatarios}
            viewAddressee={viewAddressee}
            setViewAddressee={setViewAddressee}
          />
          {destinatarios.length > 0 ? (
            viewAddressee === 1 ? (
              <TableDataSimplePag
                title=""
                columns={columns}
                data={destinatarios}
                buscar={false}
              ></TableDataSimplePag>
            ) : (
                <TableDataSimple
                  title=""
                  columns={columns}
                  data={destinatarios}
                  buscar={false}
                ></TableDataSimple>
              )
          ) : null}

          {destinatarios.length > 0 ? (
            <Grid container className={classes.root}>
              <Grid xs={12} md={6} className={classesGlobales.containerForm}>
                <AdditionalInfo
                  additionalInfoInvoice={additionalInfoInvoice}
                  setAdditionalInfoInvoice={setAdditionalInfoInvoice}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <ElectronicVoucherOptions
                  clearAllData={clearAllData}
                  clearOnlyAllData={clearOnlyAllData}
                  destinatarios={destinatarios}
                  referralGuide={referralGuide}
                  setReferralGuide={setReferralGuide}
                  additionalInfoInvoice={additionalInfoInvoice}
                  rise={rise}
                />
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </div>
    </Fragment>
  );
};

export default ReferralGuideData;
