import checkInputs from "utils/checkInputs";

const validations = {
    updateCompany: function updateCompany(company) {
        const errors = {};

        if (checkInputs.notNull(company.nombreComercial)) {
            if (!checkInputs.isAlphanumeric(company.nombreComercial)) {
                errors.nombreComercial = "No ingrese caracteres especiales."
            } else if (company.nombreComercial.length > 300) {
                errors.nombreComercial = "No puede tener más de 300 caracteres."
            }
        }

        if (!checkInputs.notNull(company.razonSocialempresa)) {
            errors.razonSocialempresa = "La razón social es necesaria."
        } else if (!checkInputs.isAlphanumeric(company.razonSocialempresa)) {
            errors.razonSocialempresa = "No ingrese caracteres especiales."
        } else if (company.razonSocialempresa.length > 300) {
            errors.razonSocialempresa = "No puede tener más de 300 caracteres."
        }

        if (!checkInputs.notNull(company.direccion)) {
            errors.direccion = "La dirección es necesaria."
        } else if (!checkInputs.isAlphanumeric(company.direccion)) {
            errors.direccion = "No ingrese caracteres especiales."
        } else if (company.direccion.length > 300) {
            errors.direccion = "No puede tener más de 300 caracteres."
        }

        if (!checkInputs.notNull(company.correoPrincipal)) {
            errors.correoPrincipal = "El correo es necesario."
        } else if (!checkInputs.validationEmail(company.correoPrincipal)) {
            errors.correoPrincipal = "Formato incorrecto. El correo debe tener el formato: usuario@ejemplo.com";
        } else if (company.correoPrincipal.length > 300) {
            errors.correoPrincipal = "No puede tener más de 300 caracteres.";
        }

        // if (checkInputs.notNull(company.telefonoPrincipal)) {
        //     if (!checkInputs.isNumeric(company.telefonoPrincipal)) {
        //         errors.telefonoPrincipal = "Solo ingrese números."
        //     } else if (company.telefonoPrincipal.length < 7 || company.telefonoPrincipal.length > 13) {
        //         errors.telefonoPrincipal = "La cantidad de dígitos debe estar entre 7 (convencionales) a 10 (teléfonos celulares)."
        //     }
        // }

        /*if (!checkInputs.notNull(company.numeroResolucion)) {
            errors.numeroResolucion = "El número de resolución es necesario."
        } else*/
        if (!checkInputs.isNumeric(company.numeroResolucion)) {
            errors.numeroResolucion = "Solo ingrese números."
        } else if (company.numeroResolucion.length > 13) {
            errors.numeroResolucion = "No ingrese más de 13 dígitos."
        }

        return errors;
    },
    updatePassword: function updatePassword(data) {
        const errors = {};
        if (!checkInputs.notNull(data.currentPass)) {
            errors.currentPass = "Ingrese la contraseña actual."
        } else if (!checkInputs.isCorrectPassword(data.currentPass)) {
            errors.currentPass  = "No ingrese los caracteres '<' 0 '>'."
        } else if (data.currentPass.length < 8) {
            errors.currentPass  = "La contraseña debe tener al menos 8 caracteres."
        }


        if (!checkInputs.notNull(data.newPass)) {
            errors.newPass = "Ingrese la contraseña nueva."
        } else if (!checkInputs.isCorrectPassword(data.newPass)) {
            errors.newPass  = "No ingrese los caracteres '<' 0 '>'."
        } else if (data.newPass.length < 8) {
            errors.newPass  = "La contraseña debe tener al menos 8 caracteres."
        }


        if (!checkInputs.notNull(data.confirmPass)) {
            errors.confirmPass = "Confirme la contraseña nueva."
        } else if (!checkInputs.isCorrectPassword(data.confirmPass)) {
            errors.confirmPass  = "No ingrese los caracteres '<' 0 '>'."
        } else if (data.confirmPass.length < 8) {
            errors.confirmPass  = "La contraseña debe tener al menos 8 caracteres."
        }

        if(checkInputs.notNull(data.confirmPass) && checkInputs.notNull(data.newPass)){
            if(data.confirmPass !== data.newPass){
                errors.confirmPass  = "Las contraseñas no coinciden."
                errors.newPass  = "Las contraseñas no coinciden."
            }
        }
        return errors;
    }
}

export default validations;