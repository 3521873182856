import React, { Fragment, useState, useEffect } from "react";
import {
  IconButton,
  Dialog,
  makeStyles,
  Grid,
  Button,
  DialogTitle,
  DialogContent,
  Paper,
  TextField,
  Divider,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";

import {
  palette,
  msmSwalExito,
  msmSwalError,
  msmSwalInformacion,
} from "../../../../configurations/styles/theme";
import MailIcon from "@material-ui/icons/Mail";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import SendIcon from "@material-ui/icons/Send";
import TableDataSimple from "widgets/Tables/TableDataSimple";
import { httpCore } from "configurations/axios";
import tableStyle from "configurations/styles/table";
import DeleteEmail from "./DeleteEmail";
import checkInputs from "utils/checkInputs";

const useStyles = makeStyles((theme) => ({
  IconButton: {
    marginTop: "0%",
    marginBottom: "0%",
    padding: "0px",
  },
  root: {
    justifyContent: "center",
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(125),
      height: theme.spacing(50),
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
    },
  },

  textField: {
    width: "300px",
  },
  backdrop: {
    zIndex: "3000",
    color: "#fff",
  },
}));

const MailForwarding = ({ voucher }) => {
  const [openModal, setOpenModal] = useState(false);
  const classes = useStyles();
  const [update, setUpdate] = useState(false);
  const handleClickOpen = () => {
    setOpenModal(true);
    obtenerDatos();
    setUpdate(true);
  };

  const [emails, setEMails] = useState([]);
  const [email, setEmail] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [viewEmails, setViewEmails] = useState(1);

  //Validación imput correo
  const [inputEmail, setInputEmail] = useState({
    error: false,
    helperText: null,
  });

  const columns = [
    {
      title: "Correo",
      field: `correo`,
      width: "10%",
      filtering: false,
      editable: "never",
      cellStyle: tableStyle.cellTable,
    },
    {
      title: "Eliminar",
      filtering: false,
      editable: "never",
      cellStyle: tableStyle.cellTable,
      width: "5%",
      render: (rowData) => (
        <DeleteEmail
          data={rowData}
          emails={emails}
          setEMails={setEMails}
          viewEmails={viewEmails}
          setViewEmails={setViewEmails}
          setUpdate={setUpdate}
        />
      ),
    },
  ];

  const clearData = () => {
    setEmail("");
  };

  const handleClose = () => {
    clearData();
    setEMails([]);
    setOpenModal(false);
  };

  const addEmail = () => {

    if (validateEmail()) {
      setInputEmail({
        error: false,
        helperText: null,
      });

      let obj = {};
      obj.correo = email;
      let aux = emails;
      let notRepite = false;
      emails.forEach((item) => {
        if (item.correo === email) {
          notRepite = true;
        }
      });
      if (!notRepite) {
        aux.push(obj);
        setEMails(aux);
        setUpdate(true);
      } else {
        setInputEmail({
          error: true,
          helperText: `EL correo ${email} ya está agregado a la lista.`,
        });
      }
    }
  };
  useEffect(() => {
    if (update) {
      for (let index = 0; index < emails.length; index++) {
        const element = emails[index];
      }
      setUpdate(false);
      setEmail("");
    }
  }, [update]);

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const convertEmailArrayObject = (data) => {
    let aux = emails;
    data.forEach((element) => {
      let obj = {};
      obj.correo = element;
      aux.push(obj);
    });
    setEMails(aux);
  };

  const convertEmailArrayString = () => {
    let arrayEmails = [];
    let cont = 0;
    emails.forEach((element) => {
      arrayEmails[cont] = element.correo;
      cont++;
    });

    let objEmails = {
      correos: arrayEmails,
      idComprobanteElectronico:
        voucher.comprobanteElectronico.idComprobanteElectronico,
    };
    saveEmails(objEmails);
  };

  const obtenerDatos = async () => {
    return await httpCore
      .get(
        `/reenvio/comprobante/ebox/correos/${voucher.comprobanteElectronico.idComprobanteElectronico}`
      )
      .then((response) => {
        setUpdate(true);
        if (Array.isArray(response.data)) {
          convertEmailArrayObject(response.data);
        } else {
          convertEmailArrayObject([]);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.error("error", error.response.data);
        } else {
          console.error("error", error);
        }
      });
  };

  const saveEmails = async (objEmails) => {
    setOpen(true);
    if(objEmails.correos.length >0){
      return await httpCore
      .post(`/reenvio/comprobante/ebox/electronico/`, objEmails)
      .then((response) => {
        if (response) {
          setEMails([]);
          setOpen(false);
          msmSwalExito("", "Comprobante reenviado con éxito");
          setOpenModal(false);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.error("error", error.response.data);
          msmSwalError("Error", error.response.data);
          setOpen(false);
        } else {
          setOpenModal(false);
          console.error("error", error);
        }
      });

    }else{
      setEMails([]);
      msmSwalInformacion("","Ingrese al menos un correo electrónico")
      setOpen(false);
      setOpenModal(false);
    }
  
  };

  const showTableEmail = () => {
    if (!update) {
      return (
        <Fragment>
          {viewEmails === 1 ? (
            <TableDataSimple
              title=""
              columns={columns}
              data={emails}
              buscar={false}
            ></TableDataSimple>
          ) : (
            <TableDataSimple
              title=""
              columns={columns}
              data={emails}
              buscar={false}
            ></TableDataSimple>
          )}
        </Fragment>
      );
    } else {
      return <h1>Cargando....</h1>;
    }
  };

  const validateEmail = () => {
    let validate = true;
    if (!checkInputs.notNull(email)) {
      setInputEmail({
        error: true,
        helperText: "El correo es requerido",
      });
      validate = false;
    } else if (!checkInputs.validationEmail(email)) {
      setInputEmail({
        error: true,
        helperText: "El correo es inválido",
      });
      validate = false;
    }

    return validate;
  };

  return (
    <Fragment>
      <IconButton
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={handleClickOpen}
        className={classes.IconButton}
      >
        <MailIcon style={{ color: palette.primary.main }} />
      </IconButton>
      <Dialog fullWidth maxWidth="md" open={openModal} onClose={handleClose}>
        <DialogTitle id="max-width-dialog-title">
          Reenvío de correos
        </DialogTitle>
        <DialogContent>
          <Backdrop className={classes.backdrop} open={open}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <div className={classes.root}>
            <Paper variant="outlined">
              <Grid
                container
                justify="center"
                align="center"
                direction="row"
                spacing={2}
              >
                <Grid item md={5}>
                  <TextField
                    id="outlined-basic"
                    label="Correo electrónico"
                    variant="outlined"
                    onChange={handleChange}
                    className={classes.textField}
                    value={email}
                    error={inputEmail.error}
                    helperText={inputEmail.helperText}
                  />
                </Grid>
                <Grid item md={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={addEmail}
                  >
                    <AddCircleIcon></AddCircleIcon>
                    Agregar
                  </Button>
                </Grid>
                <Grid item md={12}>
                  <Divider></Divider>
                </Grid>
                <Grid item md={12}>
                  {showTableEmail()}
                </Grid>

                <Grid item md={12}>
                  <Divider></Divider>
                </Grid>

                <Grid item md={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.buttonClose}
                    onClick={convertEmailArrayString}
                  >
                    Reenviar
                  </Button>
                </Grid>
                <Grid item md={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleClose}
                  >
                    Cerrar
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </div>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default MailForwarding;
