import React, { useState } from "react";
import { Grid, Divider, makeStyles, CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getRUCEmpresa } from "redux/actions/userActions";
//componentes del sistema
import TablePaginated from "widgets/Tables/TablePaginated";
import { API_GET_ALL_PRODUCTS, API_GET_SEARCH_PRODUCTS } from "./apis";
import tableStyle from "configurations/styles/table";
import TableSimple from "widgets/Tables/TableSimple";
import clientBackend from "configurations/axios";
import AddProductBtn from "./Buttons/AddProduct";
import SearchProduct from "./Froms/SearchProduct";
import EditProductBtn from "./Buttons/EditProduct";
import { CardGeneric } from "../components/CardGeneric";
import { roundTwoDecimal } from "utils/parseInput";
import { countDecimals } from "utils/checkInputs";

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: "35px",
    flexGrow: 1,
    paddingLeft: "3%",
    paddingRight: "3%",
    marginBottom: "50px",
  },
  headerBottons: {
    padding: "0.5%",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    height: "300px",
  },
}));

const ParseStateProduct = (data) => {
  if (data.data.estado) {
    return "Activo";
  } else {
    return "Inactivo";
  }
};

const roundPrice = (data) => {
  if (countDecimals(data) < 2) {
    return roundTwoDecimal(data);
  }
  return data;
};

const columns = (reloadPage) => [
  {
    title: "Código principal",
    field: "codigoPrincipal",
    cellStyle: tableStyle.cellTable,
    width: "5%",
  },
  {
    title: "Código auxiliar",
    field: "codigoAuxiliar",
    cellStyle: tableStyle.cellTable,
    width: "5%",
  },
  {
    title: "Descripción",
    field: "descripcion",
    cellStyle: tableStyle.cellLeft,
    width: "40%",
  },
  {
    title: "Precio Unitario",
    cellStyle: tableStyle.cellRight,
    width: "4%",
    render: (rowData) => roundPrice(rowData.precioUnitario),
  },
  {
    title: "Estado",
    cellStyle: tableStyle.cellTable,
    width: "10%",
    render: (rowData) => <ParseStateProduct data={rowData} />,
  },
  {
    title: "Editar",
    cellStyle: tableStyle.cellTable,
    width: "3%",
    render: (rowData) => (
      <EditProductBtn product={rowData} updateView={reloadPage} />
    ),
  },
];

const requestConfig = (ruc) => ({
  uri: API_GET_ALL_PRODUCTS + ruc,
  metodo: "get",
  body: null,
  page: 0,
  elementos: 15,
  sort: "estado,idProducto,desc",
});

const ProductServicesHome = () => {
  const tokenUser = useSelector((state) => state.user.tk);
  const classesView = useStyles();
  const [update, setUpdate] = useState(0);
  const [foundProducts, setFoundProducts] = useState([]);

  const getParam = (data) => {
    if (data.length > 0) {
      searchProduct(data);
    } else {
      reload();
    }
  };

  const reload = () => {
    setUpdate(1);
  };

  const searchProduct = async (param) => {
    setUpdate(3);
    await clientBackend
      .get(
        API_GET_SEARCH_PRODUCTS +
          (tokenUser ? getRUCEmpresa(tokenUser) : "0") +
          "?parametroBusqueda=" +
          param
      )
      .then((response) => {
        if (response.status === 200) {
          setFoundProducts(response.data);
          setUpdate(2);
        }
      })
      .catch((exception) => {
        console.log({ exception });
      });
  };

  const renderTable = () => {
    switch (update) {
      case 0:
        return (
          <TablePaginated
            title=""
            columns={columns(reload)}
            requestConfig={requestConfig(
              tokenUser ? getRUCEmpresa(tokenUser) : "0"
            )}
          />
        );
      case 1:
        setTimeout(() => {
          setUpdate(0);
        }, 500);
      case 3:
        return (
          <div className={classesView.loader}>
            <CircularProgress />
          </div>
        );
      case 2:
        return (
          <TableSimple
            columns={columns(reload)}
            data={foundProducts}
            title=""
            numberRowsPerPage={15}
          />
        );
      default:
        return (
          <TablePaginated
            title=""
            columns={columns}
            requestConfig={requestConfig(
              tokenUser ? getRUCEmpresa(tokenUser) : "0"
            )}
          />
        );
    }
  };

  return (
    <section className={classesView.root}>
      <CardGeneric>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <AddProductBtn updateView={reload} />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={8}>
            <SearchProduct sendData={getParam} />
          </Grid>
        </Grid>
      </CardGeneric>
      {renderTable()}
    </section>
  );
};

export default React.memo(ProductServicesHome);
