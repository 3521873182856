import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Backdrop,
  CircularProgress,
  Grid,
  TextField,
  Divider,
} from "@material-ui/core";
import { palette } from "configurations/styles/theme";
import { typeSupport, typeInvoice } from "views/facturacion/Forms/typeVoucher";
import { Autocomplete } from "@material-ui/lab";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import InputMask from "react-input-mask";
import useAddTax from "hooks/useAddTax";
import useAddSupportDoc from "hooks/useAddSupportDoc";
import AddTax from "./AddTax";
import TableDataSimplePag from "widgets/Tables/TableDataSimplePag";
import TableDataSimple from "widgets/Tables/TableDataSimple";
import tableStyle from "configurations/styles/table";
import DeleteTaxBtn from "../Buttons/DeleteTaxBtn";
import EditTaxBtn from "../Buttons/EditTaxBtn";
import EditTableRetenciones from "widgets/Tables/EditTableImpuestoRetencions";
import EditTableRetencionesDividends from "widgets/Tables/EditTableImpuestoRetencionsDividends";
import EditTableImpuestos from "widgets/Tables/EditTableImpuesto";
import EditTablePagos from "widgets/Tables/EditTablePagos";
import { RadioGroup, FormControlLabel, Radio } from "@material-ui/core";

import AddExteriorSupportDoc from "./AddExteriorSupportDoc";
import InfoExteriorDetail from "./InfoExteriorDetail";

const AddSupportDoc = ({
  docsSustento,
  setDocsSustento,
  viewDetalle,
  setViewDetalle,
  docSustentoDetail,
  setDocSustentoDetail,
}) => {
  const {
    classes,
    classesApp,
    classesGlobales,
    minDate1,
    open,
    openLoader,
    setOpenLoader,
    retention,
    codigoDocSustento,
    fechaEmisionDocSustento,
    docSustento,
    tipoSustento,
    dataDocSustento,
    setDataDocSustento,
    dataAditional,
    setDataAditional,
    dataAditionalImpuestos,
    setDataAditionalImpuestos,
    dataAditionalPagos,
    setDataAditionalPagos,
    retentionDocSustento,
    empty,

    handleClickOpen,
    handleClose,
    handleChangeData,
    handleChangeDocSustento,
    handleAddDocSustento,
    setCodigoDocSustento,
    setRetentionDocSustento,
    setEmty,
    setRetention,
    setDocSustento,
    setTipoSustento,
    setFechaEmisionDocSustento,
    pagoLocExt,
    setPagoLocExt,
    handleChangePagoLocExt,
    addInformationExterior,
    setAddInformationExterior,
  } = useAddSupportDoc(
    docsSustento,
    setDocsSustento,
    viewDetalle,
    setViewDetalle,
    docSustentoDetail,
    setDocSustentoDetail
  );

  return (
    <>
      <Grid container justifyContent="center" className={classes.divButton}>
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          Agregar Documento Sustento
        </Button>
      </Grid>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="max-width-dialog-title">
          Nuevo Documento Sustento
        </DialogTitle>
        <DialogContent>
          <form
            onSubmit={() => {
              setOpenLoader(true);
            }}
          >
            <Grid container spacing={1} justifyContent="center">
              <Grid item xs={12} md={6}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={12} md={12}>
                    <label>
                      Los campos marcados con ({" "}
                      <font color={palette.error.main}> *</font> ) son
                      obligatorios:
                    </label>
                  </Grid>
                  <Grid item xs={12} md={4} className={classes.divForm}>
                    <label>
                      Código documento Sustento:{" "}
                      <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={8} className={classes.divInput}>
                    {
                      <Autocomplete
                        id="combo-box-demo"
                        size="medium"
                        value={docSustento}
                        options={typeInvoice}
                        onChange={(event, newValue) => {
                          setEmty({
                            ...empty,
                            ["documento"]: true,
                          });
                          setDocSustento(newValue);
                          setRetentionDocSustento({
                            ...retentionDocSustento,
                            ["codigoDocSustento"]: newValue
                              ? newValue.CODIGO
                                ? newValue.CODIGO
                                : ""
                              : "",
                          });
                        }}
                        getOptionLabel={(option) =>
                          `${option.CODIGO}-${option.NOMBRE}`
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="form-control"
                            variant="outlined"
                            placeholder="Seleccione"
                          />
                        )}
                      />
                    }
                    {empty.documento === true ? null : (
                      <p className={classesApp.errorLetters}>
                        Seleccione el tipo de documento sustento
                      </p>
                    )}
                  </Grid>

                  <Grid item xs={12} md={4} className={classes.divForm}>
                    <label>
                      Número Doc. Sustento:{" "}
                      <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid
                    fullWidth
                    item
                    xs={12}
                    md={8}
                    className={classes.divInput}
                  >
                    <InputMask
                      className={
                        retentionDocSustento
                          ? retentionDocSustento.numeroDocSustento !== ""
                            ? "form-control  is-valid"
                            : "form-control"
                          : "form-control"
                      }
                      type="text"
                      placeholder="Ej. 001-001-000000321"
                      mask="999-999-999999999"
                      name="numeroDocSustento"
                      value={
                        retentionDocSustento
                          ? retentionDocSustento.numeroDocSustento
                            ? retentionDocSustento.numeroDocSustento
                            : ""
                          : ""
                      }
                      onChange={handleChangeDocSustento}
                      //inputComponent={TextMaskCustom}
                      autoComplete="off"
                    />
                    {empty.numero === true ? null : (
                      <p className={classesApp.errorLetters}>
                        Ingrese el número de documento sustento válido
                      </p>
                    )}
                  </Grid>
                  <Grid item xs={12} md={4} className={classes.divForm}>
                    <label>
                      Tipo de sustento
                      <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={8} className={classes.divInput}>
                    {
                      <Autocomplete
                        id="combo-box-demo"
                        size="medium"
                        value={tipoSustento}
                        options={typeSupport}
                        onChange={(event, newValue) => {
                          setEmty({
                            ...empty,
                            ["codigo"]: true,
                          });
                          setTipoSustento(newValue);
                          setRetentionDocSustento({
                            ...retentionDocSustento,
                            ["codSustento"]: newValue
                              ? newValue.CODIGO
                                ? newValue.CODIGO
                                : ""
                              : "",
                          });
                        }}
                        getOptionLabel={(option) =>
                          `${option.CODIGO}-${option.NOMBRE}`
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="form-control"
                            variant="outlined"
                            placeholder="Seleccione"
                          />
                        )}
                      />
                    }
                    {empty.codigo === true ? null : (
                      <p className={classesApp.errorLetters}>
                        Seleccione el tipo de sustento
                      </p>
                    )}
                  </Grid>

                  <Grid item xs={12} md={4} className={classes.divForm}>
                    <label>
                      Fecha Emisión Doc. Sustento:{" "}
                      <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={8} className={classes.divInput}>
                    <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      locale={esLocale}
                    >
                      <DatePicker
                        className={
                          fechaEmisionDocSustento
                            ? "form-control  is-valid"
                            : "form-control "
                        }
                        disableToolbar
                        autoOk
                        disableFuture
                        minDate={minDate1}
                        InputAdornmentProps={{ position: "start" }}
                        variant="dialog"
                        inputVariant="outlined"
                        cancelLabel="Cancelar"
                        okLabel="Seleccionar"
                        invalidDateMessage="Formato inválido."
                        format="dd-MM-yyyy"
                        name="fechaEmisionDocSustento"
                        value={fechaEmisionDocSustento}
                        onChange={setFechaEmisionDocSustento}
                      />
                    </MuiPickersUtilsProvider>
                    {empty.fecha === true ? null : (
                      <p className={classesApp.errorLetters}>
                        seleccione la fecha
                      </p>
                    )}
                  </Grid>

                  <Grid item xs={12} md={4} className={classes.divForm}>
                    <label>
                      Pago a residente o no residente?{" "}
                      <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={8} className={classes.divInput}>
                    <RadioGroup
                      value={pagoLocExt}
                      onChange={handleChangePagoLocExt}
                      name="pagoLocExt"
                    >
                      <FormControlLabel
                        value="01"
                        control={<Radio />}
                        label="Local"
                      />
                      <FormControlLabel
                        value="02"
                        control={<Radio />}
                        label="Exterior"
                      />
                    </RadioGroup>
                  </Grid>
                  {pagoLocExt === "02" ? (
                    <>
                      <Grid item xs={12} md={6} className={classes.divForm}>
                        <AddExteriorSupportDoc
                          addInformationExterior={addInformationExterior}
                          setAddInformationExterior={setAddInformationExterior}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} className={classes.divForm}>
                        <InfoExteriorDetail
                          addInformationExterior={addInformationExterior}
                        />
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              </Grid>
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item xs={12} md={5}>
                <Grid
                  // container
                  xs={12}
                  md={12}
                  justifyContent="center"
                  alignItems="center"
                >
                  <EditTablePagos
                    dataAditionalPagos={dataAditionalPagos}
                    setDataAditionalPagos={setDataAditionalPagos}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} md={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} md={12}>
                <Grid
                  // container
                  xs={12}
                  md={12}
                  justifyContent="center"
                  alignItems="center"
                >
                  {retentionDocSustento.codSustento !== "10" ? (
                    <EditTableRetenciones
                      dataAditional={dataAditional}
                      setDataAditional={setDataAditional}
                    />
                  ) : (
                    <EditTableRetencionesDividends
                      dataAditional={dataAditional}
                      setDataAditional={setDataAditional}
                    />
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12} md={12}>
                <Divider />
              </Grid>

              <Grid item xs={12} md={12}>
                <Grid
                  // container
                  xs={12}
                  md={12}
                  justifyContent="center"
                  alignItems="center"
                >
                  <EditTableImpuestos
                    dataAditionalImpuestos={dataAditionalImpuestos}
                    setDataAditionalImpuestos={setDataAditionalImpuestos}
                  />
                </Grid>
              </Grid>

              {/* <Grid item xs={12} md={6}>
                <Grid
                  // container
                  xs={12}
                  md={12}
                  justifyContent="center"
                  alignItems="center"
                >
                  <EditTablePagos
                    dataAditionalPagos={dataAditionalPagos}
                    setDataAditionalPagos={setDataAditionalPagos}
                  />
                </Grid>
              </Grid> */}

              <Grid item xs={12} md={12} className={classes.divButton}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddDocSustento}
                  className={classesGlobales.button}
                >
                  Agregar
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleClose}
                  className={classesGlobales.button}
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      <Backdrop className={classes.backdrop} open={openLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default AddSupportDoc;
