import React, { useEffect } from "react";
import { makeStyles, Backdrop, CircularProgress } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {
  msmSwalError,
  useStylesGlobal,
  msmSwalExito,
} from "configurations/styles/theme";
import { ConfirmAlert, ErrorsAlert, iconsAlert } from "utils/AlertsCustom";
import { CreditNoteProvaider } from "./context";
import validations from "./validations";
import ClientForm from "../General/ClientForm";
import DataCreditNote from "./Forms/DataCreditNote";
import RecoverInvoice from "./Forms/RecoverInvoice";
import FormCrediteNote from "./Forms/NewCreditNote";
import FooterForm from "../General/FooterForm";
//redux
import { getRUCEmpresa, getSub } from "redux/actions/userActions";
import {
  addRUCAction,
  AddUserAction,
  AddCostumerAction,
  SaveCacheAction,
  AddMoreInfoAction,
  clearCreditNoteAction,
  clearFormCostumerAction,
  changeErrorLabelsAction,
} from "redux/actions/CreditNoteActions";
import { useDispatch, useSelector } from "react-redux";
import apis from "./apis";
import { httpCore, clientBackendPDF } from "configurations/axios";
import { selectedResourceAction } from "redux/actions/resourcesActions";
import { checkPreRequirements } from "widgets/menu/preRedirect";
import { getNumberVoucherSuccess } from "redux/actions/numberVouchersAction";
import { validationAddPayment } from "../invoices/validations";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "30px",
    flexGrow: 1,
    paddingLeft: "3%",
    paddingRight: "3%",
    paddingBottom: "10px",
  },
  container: {
    marginTop: "1%",
    marginBottom: "50px",
  },
  msgDialog: {
    padding: "2%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 10,
    color: "#fff",
  },
  notistackMsg: {
    display: "flex",
    flex: 3,
  },
}));

const Content = () => {
  const classesView = useStyles();
  const classesGlobales = useStylesGlobal();
  const tokenUser = useSelector((state) => state.user.tk);
  const formCostumer = useSelector((state) => state.creditNote.formCostumer);
  const creditNote = useSelector((state) => state.creditNote);
  const dispatch = useDispatch();
  const changeErrorLabeles = (errors) =>
    dispatch(changeErrorLabelsAction(errors));
  const saveCache = (cache) => dispatch(SaveCacheAction(cache));
  const addMoreInfo = (moreInfo) => dispatch(AddMoreInfoAction(moreInfo));
  const clearCreditNote = () => dispatch(clearCreditNoteAction());
  const selectedResource = (resource) =>
    dispatch(selectedResourceAction(resource));
  const [update, setUpdate] = React.useState(false);
  const [openLoader, setOpenLoader] = React.useState(false);

  const addCostumer = (costumer) => {
    if (costumer === "0") {
      dispatch(clearFormCostumerAction());
    }
    dispatch(changeErrorLabeles({ idAsignacionEmpresaCliente: null }));
    dispatch(AddCostumerAction(costumer));
  };

  const handleClickCleanForm = () => {
    ConfirmAlert(
      "¿Está seguro de limpiar los datos del formulario?",
      "",
      iconsAlert.question,
      true,
      "Sí",
      "Cancelar",
      () => {
        dispatch(clearCreditNoteAction());
        dispatch(clearFormCostumerAction());
        setUpdate(true);
        return Promise.resolve({
          text: "Los datos del formulario fueron eliminados.",
        });
      },
      () => { }
    );
  };

  useEffect(() => {
    const addRuc = (ruc) => dispatch(addRUCAction(ruc));
    const addUser = (user) => dispatch(AddUserAction(user));
    if (tokenUser) {
      addRuc(getRUCEmpresa(tokenUser));
      addUser(getSub(tokenUser));
    }
  }, []);

  useEffect(() => {
    if (update) {
      setUpdate(false);
    }
  }, [update]);

  const validation = () => {
    const errors = validations.onSubmit(creditNote);
    changeErrorLabeles(errors);
    if (Object.values(errors).length > 0) {
      window.location.href = "#client-data";
    }
    ErrorsAlert(errors);
    return Object.values(errors).length === 0;
  };

  const handleClickPdf = async () => {
    
    if (validation()) {
      setOpenLoader(true);
      return await clientBackendPDF
        .post(apis.post_generate_ride, {
          ...creditNote,
          tipoDocSustento: "01",
        })
        .then((response) => {
          const file = new Blob([response.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          setOpenLoader(false);
          window.open(fileURL);
        })
        .catch((exception) => {
          if (exception.response) {
            msmSwalError(
              "Existen problemas en la red. Contáctese con el administrador del Sistema."
            );
            console.error("error", { exception });
          } else {
            msmSwalError("Error al generar el pdf.");
            console.log({ exception });
          }
          setOpenLoader(false);
        });
    }
    return;
  };

  const handleClickSigned = async () => {
    if (validation()) {
      setOpenLoader(true);
      await httpCore
        .post(apis.post_save_credit_note, {
          ...creditNote,
          tipoDocSustento: "01",
        })
        .then((response) => {
          if (response.status >= 200 && response.status <= 300) {
            //para actualizar el contador de comprobantes
            dispatch(getNumberVoucherSuccess(response.numeroComprobantesDisponibles));
            msmSwalExito(
              "Comprobante generado",
              `En breve será enviado al SRI para su autorización.`,
              { show: true, text: "Ver Comprobantes Pendientes" }
            ).then((response) => {
              if (response.isDismissed) {
                selectedResource({
                  id: "PEN",
                  nameMenu: "Pendientes",
                  titlePage: "Pendientes",
                  titleTab: "Comp. Pendientes",
                  menuPadre: "REP",
                });
              }
            });
            selectedResource({
              "id": -4,
              "nameMenu": "Verificando pre-requisitos",
              "titlePage": "Verificando pre-requisitos",
              titleTab: "Inicio",
              "menuPadre": ""
            })
            clearCreditNote();
            setUpdate(true);
          }
          setOpenLoader(false);
        })
        .catch((excepcion) => {
          if (excepcion.response) {
            if (excepcion.response.status === 400) {
              msmSwalError("Error al registar la Nota de Crédito.");
            } else if (excepcion.response.status === 404) {
              msmSwalError(
                "Verifique que todos los parametros obligatorios fueron ingresados correctamente."
              );
            } else {
              msmSwalError("Error al registar la Nota de Crédito.");
            }
          } else {
            msmSwalError(
              "Existen problemas en la red. Contáctese con el administrador del Sistema"
            );
          }
          setOpenLoader(false);
        });
    }
  };


  const validatePrerequisities = (ride) => {
    const tk = tokenUser ? tokenUser : "";

    if (tk !== "") {
      setOpenLoader(true);
      checkPreRequirements(getSub(tk), getRUCEmpresa(tk), dispatch)
        .then((newErrorsCompany) => {
          console.log(newErrorsCompany)
          if (Array.isArray(newErrorsCompany)) {
            if (newErrorsCompany.length === 0) {
              if (ride) {
                handleClickPdf();
              } else {
                handleClickSigned()
              }
            } else {
              selectedResource({
                "id": -4,
                "nameMenu": "Verificando pre-requisitos",
                "titlePage": "Verificando pre-requisitos",
                titleTab: "Inicio",
                "menuPadre": ""
              })
            }
          }
        })
        .catch(() => {
          //setShowLoader(false);
          // setOpenLoader(false);
        }).finally(()=>{setOpenLoader(false)})
    }
  }

  return (
    <section className={classesView.root}>
      <Paper className={classesGlobales.paperHeader}>
        <h5 className={classesGlobales.titleHeader}>
          Emisión de Nota de Crédito
        </h5>
      </Paper>
      <div className={classesView.container}>
        {update ? (
          <CircularProgress color="primary" />
        ) : (
            <>
              <RecoverInvoice />
              <ClientForm
                id="client-data"
                stateRedux={formCostumer}
                
                saveCache={saveCache}
                action={addCostumer}
                ErrorRedux={creditNote.errors.idAsignacionEmpresaCliente}
              />
              <DataCreditNote />
              <FormCrediteNote id="credit-note" />
              {creditNote.detalles.length > 0 ? (
                <FooterForm
                  dataAditional={creditNote.infromacionAdicional || []}
                  msgMoreInfo="¿Desea agregar Información Adicional a la nota de crédito?"
                  setDataAditional={addMoreInfo}
                  onClickBtnPdf={handleClickPdf}
                  onClickBtnSign={handleClickSigned}
                  validatePrerequisities={validatePrerequisities}
                  id="more-information"
                  onClickCleanForm={handleClickCleanForm}
                />
              ) : null}
            </>
          )}
      </div>

      <Backdrop className={classesView.backdrop} open={openLoader}>
        <CircularProgress color="primary" />
      </Backdrop>
    </section>
  );
};

const CreditNote = () => {
  return (
    <>
      <CreditNoteProvaider>
        <Content />
      </CreditNoteProvaider>
    </>
  );
};

export default CreditNote;
