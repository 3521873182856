import React, { Fragment } from "react";
import { palette } from "configurations/styles/theme";
const TextRequiredAsterisks = () => {
  return (
    <Fragment>
      <label>
        Los campos marcados con ( <font color={palette.error.main}> * </font> )
        son obligatorios:
      </label>
    </Fragment>
  );
};

export default TextRequiredAsterisks;
