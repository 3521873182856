import React, { useState, useEffect, Fragment } from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Card,
  TextField,
  Typography,
} from "@material-ui/core";
import { typesStatesSession } from "redux/reducers/userReducer";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link, useHistory } from "react-router-dom";
//redux
import UseSecurity from "security/useSecurity";
import { useDispatch } from "react-redux";
import { addResourceAction } from "redux/actions/resourcesActions";
import {
  processoFinishedAction,
  processoLoadingAction,
} from "redux/actions/processAsyncAction";
//componentes de la Vista
import AuthUser from "./authUser";
import Loader from "widgets/Loader";
import UseTitle from "hooks/useTitle";
//estilos
import "./login.css";
import { clientPublic } from "configurations/axios";
import { useSelector } from "react-redux";
import {
  signOffUserAction,
  updateStateSessionAction,
} from "redux/actions/userActions";
import ManualesModal from "./ManualesModal.js";
import CancelIcon from "@material-ui/icons/Cancel";

import "./login.css";

//Notificacion errores https://iamhosseindhv.com/notistack/demos#variants
import { useSnackbar } from "notistack";
import { apiKey, logoBase } from "configurations/constant";
import MsmWelcome from "./MsmWelcome";

//import { logo } from 'assets/icons/logo.png'
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "2%",
  },
  registro: {
    textAlign: "center",
    justifyContent: "space-around",
  },
  formLogin: {
    padding: "1%",
    justifyContent: "center",
    display: "flex",
  },
  btnResetPass: {
    paddingLeft: "10%",
    paddingRight: "10%",
  },
  buttonLogin: {
    borderRadius: "20px",
    backgroundColor: "#00e676",
    color: "white",
    marginBottom: "20px",
  },
  titleLogin: {
    color: "black",
    fontFamily: "Arial",
    fontSize: "16px",
    fontWeight: "bold",
    textAlign: "center",
  },
  large: {
    width: "200px",
    height: "55px",
  },
  card: {
    padding: "2%",
    borderRadius: "15px 15px 15px 15px",
    width: "60%",
    height: "100%",
  },
  inputLogin: {
    borderRadius: "5%",
    width: "100%",
    marginBottom: "15 px",
  },
  link: {
    fontSize: "13px",
    marginBottom: "20px",
    textDecoration: "none",
    display: "flex",
    justifyContent: "center",
  },
  questionLogin: {
    marginTop: "20px",
    textAlign: "center",
  },
  media: {
    height: 75,
  },
  input: {
    //position: 'relative',
    borderRadius: "10px",
    width: "100%",
    marginBottom: "15px",
  },
  butonRecovery: {
    marginBottom: "10px",
    marginTop: "15px",
  },
  rootCard: {
    maxWidth: 345,
  },
  rootImage: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const Login = () => {
  const classes = useStyles();
  const [user, setUser] = useState({});
  const [open, setOpen] = useState(false);

  const [errorInputs, setErrorInputs] = useState(false);
  const [userRecovery, setUserRecovery] = useState();
  const [loginImg, setLoginImg] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [msgForgetPass, setmsgForgetPass] = useState({
    msg: "Ingrese el nombre del usuario con el que inicia sesión para recuperar  su contraseña.",
    type: "alert-primary",
  });
  const { isSingIn } = UseSecurity();
  const history = useHistory();
  const dispatch = useDispatch();
  UseTitle({ title: "Login" });
  const addResources = (dataUser) => dispatch(addResourceAction(dataUser));
  const processLoading = () => dispatch(processoLoadingAction());
  const processFinised = () => dispatch(processoFinishedAction());
  const clearProcess = () => dispatch(signOffUserAction());
  // const [previousError, setPreviousError] = useState();
  const error = useSelector((state) => state.user.error);
  const stateSession = useSelector((state) => state.user.stateSession);

  const [openMsm, setOpenMsn] = useState(true);

  useEffect(() => {
    if (
      stateSession != typesStatesSession.auth &&
      stateSession != typesStatesSession.not_auth
    ) {
      showSnackBar(error, "error");
    }

    if (
      stateSession === typesStatesSession.bad_credentials ||
      stateSession === typesStatesSession.blocked ||
      stateSession === typesStatesSession.disable
    ) {
      setTimeout(() => {
        dispatch(updateStateSessionAction(typesStatesSession.not_auth));
      }, 2000);
    }
  }, [stateSession, error]);

  const [inputClave, setInputClave] = useState({
    error: false,
    helperText: null,
  });

  const [errorRecPass, setErrorRecPass] = useState({
    error: false,
    helperText: null,
  });

  const handleChange = (event) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeUserRecovey = (event) => {
    event.preventDefault();
    console.log(event.target.value);

    // const re =
    //   /^[\w|\\(|\\)|\\<|\\>|\s|\\@|\\,|\\;|\\:|\\"|\\[|\\%|\\ç|\\&|\\.]*$/;

    const re = /[^\w.+-@]/;
    const reAux = /[<>+/;:,]/;

    console.log(reAux.test(event.target.value));
    if (!reAux.test(event.target.value)) {
      if (!re.test(event.target.value)) {
        setUserRecovery(event.target.value);
        setErrorRecPass({ error: false, helperText: null });
      } else {
        setErrorRecPass({ error: true, helperText: "El email es incorrecto" });
      }
    } else {
      setErrorRecPass({ error: true, helperText: "El email es incorrecto" });
    }
  };

  const handleAutentication = (event) => {
    event.preventDefault();
    if (user.username.trim() !== "" && user.password.trim() !== "") {
      addResources(user);
    } else {
      setErrorInputs(true);
    }
  };

  const handleRedirectionRegister = () => {
    history.push("/registro");
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  function showSnackBar(msg, type) {
    enqueueSnackbar(msg, {
      variant: type,
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
      autoHideDuration: 2000,
    });
  }

  const handleClose = () => {
    setUserRecovery("");
    setmsgForgetPass({
      msg: "Ingrese el nombre del usuario con el que inicia sesión para recuperar su contraseña.",
      type: "alert-primary",
    });
    setInputClave({
      error: false,
      helperText: null,
    });
    setOpen(false);
  };

  useEffect(() => {
    if (isSingIn()) {
      history.push("/facturacion");
    } else {
      clearProcess();
      if (
        window.location.hostname != "localhost" &&
        window.location.hostname != "test.ebox.ec"
      ) {
        clientPublic
          .get(`/logo/${window.location.hostname}`)
          .then((res) => setLoginImg(res.data))
          .catch((err) => console.error(err));
      } else if (window.location.hostname == "test.ebox.ec") {
        setLoginImg(logoBase);
      }
    }
  }, []);

  const auxRegex =
    /^[\w|\\(|\\)|\\<|\\>|\s|\\@|\\,|\\;|\\:|\\"|\\[|\\%|\\ç|\\&|\\.]*$/;
  //Solo vale una ves
  const handleRestorePass = async () => {
    if (userRecovery !== null) {
      if (userRecovery === undefined) {
        setInputClave({
          error: true,
          helperText: "Ingrese el usuario",
        });
      } else {
        setInputClave({
          error: false,
          helperText: null,
        });

        processLoading();
        const nombreUsuarioAcceso = userRecovery;
        await clientPublic
          .post(
            `/reestablecer/clave?nombreUsuarioAcceso=${nombreUsuarioAcceso}&codigoSistema=${apiKey}`
          )
          .then((response) => {
            if (response.status >= 200 && response.status <= 300) {
              setmsgForgetPass({
                msg:
                  "La información necesaria para poder iniciar sesión fue enviada a su correo: " +
                  response.data.correoPrincipalUsuario,
                type: "alert-success",
              });
            }
            processFinised();
          })
          .catch((exception) => {
            if (exception.response) {
              if (exception.response.status === 400) {
                setmsgForgetPass({
                  msg: "No se pudo reestablecer la contraseña.",
                  type: "alert-danger",
                });
              } else if (exception.response.status === 404) {
                setmsgForgetPass({
                  msg: "No se encontró el usuario que desea recuperar la contraseña.",
                  type: "alert-danger",
                });
              } else {
                setmsgForgetPass({
                  msg: "Error al recuperar la contraseña. Contáctese con el administrador del Sistema.",
                  type: "alert-danger",
                });
              }
            } else {
              setmsgForgetPass({
                msg: "Existen problemas en la red. Contáctese con el administrador del Sistema.",
                type: "alert-danger",
              });
            }
            processFinised();
          });
      }
    }
  };
  const handleCloseMsm = () => {
    setOpenMsn(false);
  };

  return (
    <div className="gradient-background">
      <Fragment>
        <Loader />
        {
          <MsmWelcome
            openMsm={openMsm}
            handleCloseMsm={handleCloseMsm}
          ></MsmWelcome>
        }
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "90vh" }}
        >
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
            className={classes.root}
          >
            <Card className={classes.card}>
              <Grid container>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={1}
                  item
                  xs={8}
                >
                  <Grid className="dot">
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                    >
                      <img
                        src="../LogoEbox.png"
                        alt="logo"
                        className={classes.large}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={4}
                  // style={{paddingLeft: "15%"}}
                >
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    spacing={1}
                  >
                    {loginImg ? (
                      <img
                        src={"data:image/png;charset=utf-8;base64," + loginImg}
                        className={classes.large}
                      />
                    ) : (
                      <CircularProgress />
                    )}
                  </Grid>

                  <form onSubmit={handleAutentication}>
                    <AuthUser />
                    {errorInputs ? <p>Ingrese todos los campos</p> : null}

                    <br></br>
                    <Divider></Divider>
                    <br />
                    {/* {error ? ( 
                  <div className="alert alert-danger" role="alert">
                    <label>{error}</label>
                  </div>
                  
                ) : null} */}
                    <Grid item md={12}>
                      <TextField
                        type="text"
                        classes={{
                          root: classes.input, // class name, e.g. `classes-nesting-root-x`
                        }}
                        onChange={handleChange}
                        required
                        name="username"
                        autoComplete="off"
                        value={user.username}
                        label="Usuario"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={12}>
                      <TextField
                        type="password"
                        classes={{
                          root: classes.input, // class name, e.g. `classes-nesting-root-x`
                        }}
                        //className={classes.inputLogin}
                        onChange={handleChange}
                        required
                        name="password"
                        value={user.password}
                        label="Contraseña"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={12} className={classes.link}>
                      <Link
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleClickOpen}
                        underline="always"
                      >
                        ¿Olvidó su Contraseña?
                      </Link>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.buttonLogin}
                        type="submit"
                        fullWidth
                      >
                        Ingresar
                      </Button>
                      <ManualesModal></ManualesModal>
                      {/* <Grid item md={12}>
                        <Typography className={classes.questionLogin}>
                          ¿Quieres ver tus comprobantes recibidos?
                        </Typography>
                      </Grid>

                      <Grid item md={12} className={classes.registro}>
                        <Link
                          variant="contained"
                          color="primary"
                          fullWidth
                          onClick={handleRedirectionRegister}
                          underline="always"
                          className={classes.link}
                        >
                          Registrate
                        </Link>
                      </Grid> */}
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>

        <Dialog
          fullWidth
          maxWidth="sm"
          open={open}
          onClose={handleClose}
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
        >
          <DialogTitle>Recuperación de contraseña</DialogTitle>
          <DialogContent>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={1}
            >
              <Grid item sm={12}>
                <div className={"alert " + msgForgetPass.type} role="alert">
                  <label> {msgForgetPass.msg}</label>
                </div>
              </Grid>
              <Grid item sm={2}>
                <label>Usuario :</label>
              </Grid>
              <Grid item sm={10}>
                <TextField
                  type="email"
                  className="form-control"
                  required
                  name="emailUser"
                  variant="outlined"
                  placeholder="user@ejemplo.com"
                  onChange={handleChangeUserRecovey}
                  value={userRecovery}
                  autoComplete="off"
                  error={errorRecPass.error}
                  helperText={errorRecPass.helperText}
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>

              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={1}
                className={classes.butonRecovery}
              >
                <Grid item xs={12}>
                  <Divider></Divider>
                </Grid>
                <Grid item sm={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleRestorePass}
                    disabled={msgForgetPass.type === "alert-success"}
                  >
                    Recuperar contraseña
                  </Button>
                </Grid>
                <Grid item sm={6}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    onClick={handleClose}
                  >
                    <CancelIcon></CancelIcon>
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Fragment>
    </div>
  );
};

export default Login;
