import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getRUCEmpresa } from "redux/actions/userActions";
import tableStyle from 'configurations/styles/table'
import clientBackend, { clientPay } from "configurations/axios";
import TableDataSimplePag from "widgets/Tables/TableDataSimplePag";
import { Backdrop, Card, CardContent, CircularProgress, Divider, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { palette, useStylesGlobal } from "configurations/styles/theme";
import UpVoucherImg from "../buttons/UpVoucherImg";
import CancelPackage from "../buttons/CancelPackage";
import TableSimple from "widgets/Tables/TableSimple";
import TableDataSimple from "widgets/Tables/TableDataSimple";

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    containerTitle: {
        paddingTop: "30px",
        flexGrow: 1,
        paddingLeft: "3%",
        paddingRight: "3%",
        marginBottom: "5%",
    },
    root: {
        minWidth: 275,
        //backgroundColor: '#ccddf0',        
        borderWidth: '2px',
        borderStyle: "solid",
        //borderBlockColor:'#ccddf0',
        border: '#ccddf0',
    },
    root2: {
        minWidth: 275,
        borderStyle: "solid",
        borderWidth: '2px',
        //backgroundColor: '#4583c7',        
        border: '#4583c7',

    },
    loader: {
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        height: "300px"
    },
    title: {
        fontSize: 16,
        textAlign: 'center',
        marginBottom: 12,
    },
    subTitle: {
        marginTop: 12,
        fontSize: 14,
        textAlign: 'center',
        marginBottom: 12,

    },
    subTitleBanco: {
        marginTop: '8px',
        fontSize: 14,
        textAlign: 'center',
        marginBottom: '8px',

    },
    backdrop: {
        zIndex: theme.zIndex.modal + 999,
        color: palette.primary.main,
    },
}));

const UploadVoucherPay = () => {


    const classes = useStyles();
    const classesGlobales = useStylesGlobal();
    const [data, setData] = useState();
    const tokenUser = useSelector(state => state.user.tk);
    const [update, setUpdate] = useState(0);
    const [accounts, setAccounts] = useState([]);
    const [openLoader, setOpenLoader] = useState(false);

    const validateState = (key) => {
        switch (key) {
            case '1':
                return 'PRE-CONTRATADO';
            case '2':
                return 'CONTRATADO';
            case '3':
                return 'APROBADO';
            case '4':
                return 'NO APROBADO';
            default:
                break;
        }
    }

    const columns = [
        { title: 'Fecha de Contratación', field: `fechaInicioContratacion`, cellStyle: tableStyle.cellTable, render: rowData => rowData.fechaInicioContratacion.substring(0, 10) },
        { title: 'Plan', field: 'paquete.nombre', cellStyle: tableStyle.cellLeft, },
        { title: 'Cantidad', field: 'cantidadComprobantesContratados', cellStyle: tableStyle.cellTable, },
        { title: 'Total', field: 'valorTotalPaquete', cellStyle: tableStyle.cellTable, render: rowData => rowData.valorTotalPaquete.toFixed(2) },
        { title: "Estado", cellStyle: tableStyle.cellTable, render: (rowData) => rowData ? validateState(rowData.estadoPago) : '' },
        { title: "Observación", field: "observacion", cellStyle: tableStyle.cellTable, render: rowData => rowData.observacion ? rowData.observacion : 'N/A' },
        { title: 'Subir Comprobante', cellStyle: tableStyle.cellTable, render: rowData => <UpVoucherImg plan={rowData} reload={reload} /> },
        { title: 'Anular Paquete', field: '', cellStyle: tableStyle.cellTable, render: rowData => <CancelPackage plan={rowData} reload={reload} /> },

    ];

    const getPreContracted = async () => {
        setOpenLoader(true);
        await clientPay.get(`/pago/precontratados/${tokenUser ? getRUCEmpresa(tokenUser) : '0'}`).
            then(result => {
                if (result.status === 200) {
                    setData(result.data);
                    console.log(result.data)
                }
                setOpenLoader(false)
            }).catch(
                error => {
                    //setData([]);
                    setOpenLoader(false)
                    const exception = { error };
                    if (typeof exception.error.response === 'undefined') {
                        console.error(error);
                    } else {
                        console.error(exception.error.response);
                    }
                }
            )
    }

    useEffect(() => {
        getPreContracted();
        if (typeof accounts != 'undefined' && accounts !== null) {
            getAccounts();
        }
        if (Number(update) === 1) {
            console.log('entra a update')
            setUpdate(0);
        }
    }, [update])


    const reload = () => {
        setUpdate(1);
    };

    const getAccounts = async () => {
        await clientPay
            .get(
                `/pago/cuentas/${tokenUser ? getRUCEmpresa(tokenUser) : ''} `
            )
            .then((response) => {
                if (response.status === 200) {
                    setAccounts(response.data);
                    //return response.data;
                }
            })
            .catch((exception) => {
                console.log({ exception });
                //return exception;
            });
    };

    
    const renderTable = () => {
        switch (update) {
            case 0:
                return (
                    <TableDataSimplePag
                        title=""
                        columns={columns}
                        data={data}
                        buscar={false}
                    />
                );
            case 1:
            case 3:
                return (
                    <div className={classes.loader}>
                        <CircularProgress />
                    </div>
                );
            default:
                break;
        }
    };

    return (
        <div className={classes.containerTitle}>
        <Paper className={classesGlobales.paperHeader}>
                <h5 className={classesGlobales.titleHeader}>Subir Comprobante</h5>
            </Paper>
            <Grid item xs={12} md={12} className={classesGlobales.containerForm} >
                {typeof data !== 'undefined' && data.length > 0 ?
                    renderTable()
                    :
                    <Grid item xs={12} md={12}>
                        <TableDataSimplePag
                            title=""
                            columns={columns}
                            data={[]}
                            buscar={false}
                        ></TableDataSimplePag>
                    </Grid>

                }
                <br />
                <Grid container
                    direction="row"
                    alignItems="center"
                    justify="center"
                    spacing={1}

                >

                    <Grid item xs={12} md={12}>
                        <Paper >
                            <Card className={classes.root2}>

                                <Typography className={classes.subTitleBanco} gutterBottom>
                                    <strong> Pagos mediante transferencia o depósito Bancario</strong>
                                </Typography>

                            </Card>
                        </Paper>

                    </Grid>
                    {
                        accounts.map(account =>
                            <Grid item xs={12} md={6}>
                                <Paper >
                                    <Card className={classes.root3}>
                                        <CardContent>
                                            <Typography className={classes.title} color='textSecondary' gutterBottom>
                                                <strong> {account.nombreBanco}</strong>
                                            </Typography>
                                            <Divider />
                                            <Typography className={classes.subTitle} >
                                                <strong> Razón Social: </strong> {account.razonSocial}
                                            </Typography>
                                            <Typography className={classes.subTitle} >
                                                <strong> RUC: </strong> {account.identificacion}
                                            </Typography>
                                            <Typography className={classes.subTitle} >
                                                <strong> Tipo de Cuenta: </strong>{account.tipoCuenta}
                                            </Typography>
                                            <Typography className={classes.subTitle} >
                                                <strong> Número de Cuenta: </strong>{account.numeroCuenta}
                                            </Typography>
                                        </CardContent>

                                    </Card>
                                </Paper>

                            </Grid>

                        )
                    }
                </Grid>

            </Grid>

            <Backdrop className={classes.backdrop} open={openLoader}>
                <CircularProgress color="inherit" />
            </Backdrop>

        </div>
    );
}

export default UploadVoucherPay;