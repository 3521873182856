import {  clearAll } from "../localStorage";

//clean Actions
import { clearAuthorizedVoucherAction } from "./authorizedVouchersAction"
import {removeResource} from "./resourcesActions";
import {signoutUser} from "./userActions"
import {clearCancelVoucherAction} from "./cancelVoucherAction";
import { clearCreditNoteAction } from "./CreditNoteActions";
import { clearDebitNoteAction } from "./debitNoteActions";
import { clearSearchVouchers } from "./searchVouchersAction";
import { cleanNotificationsActions } from "./notificationsAction";
const cleanStore = (dispatch) => {
    dispatch(signoutUser());
    dispatch(removeResource());
    dispatch(clearAuthorizedVoucherAction());
    dispatch(clearCancelVoucherAction());
    dispatch(clearCreditNoteAction());
    dispatch(clearDebitNoteAction());
    dispatch(clearSearchVouchers());
    dispatch(cleanNotificationsActions());
    clearAll();
}
 
export default cleanStore;