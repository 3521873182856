import React, { useState, Fragment } from "react";
import { useStylesApp } from "hooks/useStyles";

//mostrar empresa
import {
    Grid,
    Card,
    Divider,
    CardHeader,
    Typography,
    Backdrop,
    CircularProgress,
} from "@material-ui/core";

//material-ui.com
import { makeStyles } from "@material-ui/core/styles";

//componentes del sistema

//componentes para el boton buscar
import Button from "@material-ui/core/Button";

import { useStylesGlobal } from "configurations/styles/theme";

//Color para la advertencia
import {
    palette,
    msmSwalError,
    msmSwalExito,
} from "configurations/styles/theme";
import AssessmentIcon from "@material-ui/icons/Assessment";
import { httpReportes } from "configurations/axios";
import { useSelector } from "react-redux";
import { getRUCEmpresa } from "redux/actions/userActions";
const useStyles = makeStyles((theme) => ({
    cardContent: {
        width: "94%",
        height: "400px",
        margin: "3%",
    },
    gridLable: {
        // paddingRight: '1%'
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    divBotonBuscar: {
        marginTop: "0.5%",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    menuSeleccion: {
        zIndex: "3000  ! Important",
        width: "60% !Important",
    },
    infoBusqueda: {
        marginTop: "30px",
        marginBottom: "30px",
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    fragment: {
        padding: "10px",
    },
    backdrop: {
        zIndex: theme.zIndex.modal + 999,
        color: palette.primary.main,
    },
}));

const UploadVoucherExcel = () => {
    const tokenUser = useSelector((state) => state.user.tk);
    const classes = useStyles();
    const classesGlobales = useStylesGlobal();
    const classesApp = useStylesApp();
    const [eventAnnulation, setEventAnnulation] = useState({});
    const [fileData, setFileData] = useState(null);
    const [isOver, setIsOver] = useState(false);
    const [openLoader, setOpenLoader] = useState(false);
    const [errors, setErrors] = useState({});
    const [file, setFile] = useState(null);
    const [typeVoucher, setTypeVoucher] = useState(null);

    const dragOver = (event) => {
        event.preventDefault();
        setIsOver(true);
    };

    const dragEnter = (event) => {
        event.preventDefault();
    };

    const dragLeave = (event) => {
        event.preventDefault();
        setIsOver(false);
    };

    const ButtonPostGetFile = () => {
        const classes = useStyles();
        return (
            <>
                <button className={classes.button}>Cargar Archivo</button>
            </>
        );
    };

    const fileDrop = (event) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        setFileData(files[0]);
    };

    const handleChange = (event) => {
        setFile(event.target.files[0]);
    };
    const uploadExcel = async () => {
        setOpenLoader(true);
        const formData = new FormData();
        formData.append("file", file);
        let rucEmpresa = (tokenUser ? getRUCEmpresa(tokenUser) : "0");
        await httpReportes
            .post(`/comprobante/recibido/archivo/masivo/` + rucEmpresa + '/' + typeVoucher, formData)
            .then((response) => {
                console.log(response.data);
                if (response.status === 200) {
                    msmSwalExito("Comprobantes enviados para autorizar exitosamente.");
                    setOpenLoader(false);
                    setFile(null);
                } else {
                    msmSwalError("Error al enviar los comprobantes.");
                    setOpenLoader(false);
                }
            })
            .catch((exception) => {
                if (exception.response) {
                    msmSwalError(
                        exception.response.data.apierror
                            ? exception.response.data.apierror.mensaje
                                ? exception.response.data.apierror.mensaje
                                : "Error al enviar los comprobantes."
                            : "Error al enviar los comprobantes."
                    );
                    setOpenLoader(false);
                } else {
                    msmSwalError(
                        "Existen problemas en la red. Contáctese con el administrador del Sistema."
                    );
                }
                setOpenLoader(false);
            });
    };

    return (
        <Fragment className={classes.fragment}>
            <Card className={classes.cardContent}>
                <Grid container xs={12} justify="center" alignItems="center">
                    <CardHeader
                        className={classesGlobales.cabecera}
                        title={"Carga de archivo excel para generar comprobantes"}
                        avatar={<AssessmentIcon></AssessmentIcon>}
                    />
                </Grid>

                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    align="center"
                >
                    <Grid container>
                        <Grid item xs={12}>
                            <Divider className={classes.divider} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography aling="center" color="primary" variant="subtitle2">
                                Los campos marcados con (
                                <font color={palette.error.main}> *</font>) son obligatorios:
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider className={classes.divider} />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        spacing={2}
                    >
                        <Grid item lg={2} xs={12}>
                            <Typography aling="center" color="primary" variant="subtitle2">
                                Archivo en formato (.xlsx):
                                <font color={palette.error.main}> *</font>
                            </Typography>
                        </Grid>
                        <Grid item lg={2} xs={12}>
                            <input
                                type="file"
                                name="file"
                                onChange={handleChange}
                                autoComplete="off"
                            />
                            {errors.cufe ? (
                                <p className={classesApp.errorLetters}>{errors.cufe}</p>
                            ) : null}
                        </Grid>
                        <Grid item lg={2} xs={12}>
                            Tipo: <font color={palette.error.main}> *</font>
                        </Grid>
                        <Grid item lg={2} xs={12}>
                            <select
                                name="tipo"
                                className={
                                    errors.tipo
                                        ? "form-control is-invalid"
                                        : "form-control"
                                }
                                value={typeVoucher}
                                onChange={(event) => { setTypeVoucher(event.target.value) }}
                            >
                                <option value="">--Seleccione--</option>
                                <option value="01">Factura</option>
                                <option value="07">Retención</option>
                            </select>
                            {errors.tipo ? (
                                <p className={classesApp.errorLetters}>
                                    {errors.tipo}
                                </p>
                            ) : null}
                        </Grid>
                    </Grid>
                    <br></br>
                    <Grid>
                        <Divider className={classes.divider} />
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item xs={12} lg={2}>
                            <Button variant="outlined" color="primary" onClick={uploadExcel}>
                                Cargar Archivo
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <Backdrop className={classes.backdrop} open={openLoader}>
                    <CircularProgress color="primary" />
                </Backdrop>
            </Card>
        </Fragment>
    );
};

export default UploadVoucherExcel;
