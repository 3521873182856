import { Backdrop, Button, Card, CardActions, CardContent, Checkbox, CircularProgress, Divider, FormControlLabel, Grid, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, withStyles } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import AddShoppingCartOutlinedIcon from '@material-ui/icons/AddShoppingCartOutlined';
import { palette, useStylesGlobal } from 'configurations/styles/theme';
import { yellow } from '@material-ui/core/colors';
import AddPackage from './buttons/AddPackage';
import Plan from './components/Plan';
import { clientPay } from 'configurations/axios';
import { getRUCEmpresa } from 'redux/actions/userActions';
import { useSelector } from 'react-redux';



const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    containerTitle: {
        paddingTop: "30px",
        flexGrow: 1,
        paddingLeft: "3%",
        paddingRight: "3%",
        marginBottom: "5%",
    },
    root: {
        minWidth: 275,
        //backgroundColor: '#ccddf0',        
        borderWidth: '2px',
        borderStyle: "solid",
        //borderBlockColor:'#ccddf0',
        border: '#ccddf0',

    },
    root1: {
        minWidth: 275,
        borderStyle: "solid",
        borderWidth: '2px',
        //backgroundColor: '#87afdb',
        border: '#87afdb',

    },
    root2: {
        //minWidth: 275,
        borderStyle: "solid",
        borderWidth: '2px',
        //backgroundColor: '#4583c7',        
        border: '#4583c7',

    },
    root3: {
        minWidth: 275,
        //backgroundColor: '#FFDD00',
        borderBlockColor: '#FFDD00',


    },
    root4: {
        minWidth: 275,
        backgroundColor: '#0097d3',


    },
    title: {
        fontSize: 16,
        textAlign: 'center',
        marginBottom: 12,
    },
    subTitle: {
        marginTop: 12,
        fontSize: 14,
        textAlign: 'center',
        //marginBottom: 12,

    },
    subTitleBanco: {
        marginTop: '8px',
        fontSize: 14,
        textAlign: 'center',
        marginBottom: '8px',

    },
    pos: {
        marginBottom: 12,
    },
    margin: {
        width: '70%',

    },
    backdrop: {
        zIndex: theme.zIndex.modal,
        color: palette.primary.main,
    },
}));


const BuyPackage = () => {
    const classes = useStyles();
    const classesGlobales = useStylesGlobal();
    const [plans, setPlanes] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const userToken = useSelector((state) => state.user.tk);
    const [openLoader, setOpenLoader] = useState(false);

    const getPlans = async () => {
        await clientPay
            .get(
                `/pago/paquetes`
            )
            .then((response) => {
                if (response.status === 200) {
                    setPlanes(response.data);
                }

            })
            .catch((exception) => {

                console.log({ exception });
            });
    };

    const getAccounts = async () => {
        await clientPay
            .get(
                `/pago/cuentas/${userToken ? getRUCEmpresa(userToken) : '0'}`
            )
            .then((response) => {
                if (response.status === 200) {
                    setAccounts(response.data);
                    return response.data;
                }
                setOpenLoader(false);
            })
            .catch((exception) => {
                setOpenLoader(false);
                console.log({ exception });
                return exception;
            });
    };

    useEffect(() => {
        getPlans();
        getAccounts();
    }, [])

    return (
        <div className={classes.containerTitle}>
            <Paper className={classesGlobales.paperHeader}>
                <h5 className={classesGlobales.titleHeader}>Adquirir Plan</h5>
            </Paper>

            <Grid container
                direction="row"
                alignItems="center"
                justify="center"
                spacing={1}

            >
                {
                    plans.map(plan =>
                        <Plan plan={plan}
                            size={plans.length} />
                    )
                }

                <Grid item xs={12} md={12}>
                    <Paper className={classes.root2}>
                        <Card >
                            <Typography className={classes.subTitleBanco} gutterBottom>
                                <strong> Pagos mediante transferencia o depósito Bancario</strong>
                            </Typography>


                        </Card>
                    </Paper>

                </Grid>
                {
                    accounts.map(account =>
                        <Grid item xs={12} md={6}>
                            <Paper >
                                <Card className={classes.root3}>
                                    <CardContent>
                                        <Typography className={classes.title} color='textSecondary' gutterBottom>
                                            <strong> {account.nombreBanco}</strong>
                                        </Typography>
                                        <Divider />
                                        <Typography className={classes.subTitle} >
                                            <strong> Razón Social: </strong> {account.razonSocial}
                                        </Typography>
                                        <Typography className={classes.subTitle} >
                                            <strong> RUC: </strong> {account.identificacion}
                                        </Typography>
                                        <Typography className={classes.subTitle} >
                                            <strong> Tipo de Cuenta: </strong>{account.tipoCuenta}
                                        </Typography>
                                        <Typography className={classes.subTitle} >
                                            <strong> Número de Cuenta: </strong>{account.numeroCuenta}
                                        </Typography>
                                    </CardContent>

                                </Card>
                            </Paper>

                        </Grid>
                    )
                }

            </Grid >
            <Backdrop className={classes.backdrop} open={openLoader}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div >
    )
}

export default BuyPackage;