import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import tableStyle from "configurations/styles/table";
import { palette } from "configurations/styles/theme";
import { useSnackbar } from "notistack";
import checkInputs from "utils/checkInputs";
import bigDecimal from "js-big-decimal";
import {
  TextField,
  makeStyles,
  FormControl,
  NativeSelect,
  Select,
} from "@material-ui/core";
import { tableIcons } from "./OverrideIcons";
import {
  typeTax,
  taxPercentage,
  typeTaxImp,
  taxIce,
  ivaCodigo,
} from "views/facturacion/Forms/typeVoucher";
import { Autocomplete } from "@material-ui/lab";

import { getRUCEmpresa } from "redux/actions/userActions";
import clientBackend from "configurations/axios";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  editComponent: {
    paddingRight: "5px",
  },
});

export default function EditTableImpuestos({
  dataAditionalImpuestos,
  setDataAditionalImpuestos,
  maxRows = 20,
}) {
  const letterColor = palette.primary.main;
  const classes = useStyles();
  const [dataPorcentaje, setDataPorcentaje] = useState([]);
  const [dataImp, setDataImp] = useState();
  const [tax, setTax] = useState();
  const [taxValue, setTaxValue] = useState();
  const [impuesto, setImpuesto] = useState({
    CODIGO: "",
    VALOR: 0,
    NOMBRE: "",
  });
  const [retentionTax, setRetentionTax] = useState({
    baseImponible: "",
    codigoImpuesto: "",
    codPorcentaje: "",
    valorImpuesto: 0,
  });
  const [empty, setEmty] = useState({
    impuesto: true,
    retencion: true,
    codigo: true,
    base: true,
    documento: true,
    numero: true,
    fecha: true,
  });
  const calculateTaxValue = (base, porcent) => {
    if (validateDecimal(base.toString())) {
      let taxValue = bigDecimal.multiply(
        base,
        bigDecimal.divide(porcent ? porcent : "0", "100")
      );
      setTaxValue(
        bigDecimal.round(taxValue, 2, bigDecimal.RoundingModes.HALF_UP)
      );
    }
  };

  const getCodePercentage = async (code) => {
    const { data } = await clientBackend.get(
      `/admin/impuesto/buscar/codigo/${code}`
    );
    const dataAux = [];
    data.forEach((e) => {
      let constructor = {
        CODIGO: e.codigo,
        VALOR: +e.porcentaje,
        NOMBRE: e.descripcion,
      };
      dataAux.push(constructor);
    });
    setDataPorcentaje(dataAux);
  };

  useEffect(() => {
    getCodePercentage(dataImp);
  }, [dataImp]);

  const handleChangeData = (event) => {
    event.preventDefault();
    const re = /^[0-9\b.]+$/;
    switch (event.target.name) {
      case "baseImponible":
        if (event.target.value === "" || re.test(event.target.value)) {
          if (Number(event.target.value.trim()) >= 0) {
            if (
              checkInputs.countDecimals(event.target.value) <= 2 &&
              checkInputs.countDigit(event.target.value) <= 14
            ) {
              setRetentionTax({
                ...retentionTax,
                ["baseImponible"]: event.target.value,
              });
              let taxValue = bigDecimal.multiply(
                event.target.value,
                bigDecimal.divide(
                  impuesto ? (impuesto.VALOR ? impuesto.VALOR : "0") : "0",
                  "100"
                )
              );
              setTaxValue(
                bigDecimal.round(taxValue, 2, bigDecimal.RoundingModes.HALF_UP)
              );
              if (
                typeof event.target.value !== "undefined" &&
                event.target.value.toString().trim() !== "" &&
                Number(event.target.value !== 0)
              ) {
                setEmty({ ...empty, ["base"]: true });
              }
            }
          }
        }

        break;
      case "VALOR":
        if (event.target.value === "" || re.test(event.target.value)) {
          if (Number(event.target.value.trim()) >= 0) {
            if (
              checkInputs.countDecimals(event.target.value) <= 2 &&
              checkInputs.countDigit(event.target.value) <= 3
            ) {
              setImpuesto({
                ...impuesto,
                ["VALOR"]: event.target.value,
              });

              let taxValue = bigDecimal.multiply(
                retentionTax.baseImponible,
                bigDecimal.divide(event.target.value, "100")
              );
              setTaxValue(
                bigDecimal.round(taxValue, 2, bigDecimal.RoundingModes.HALF_UP)
              );

              if (
                typeof event.target.value !== "undefined" &&
                event.target.value.toString().trim() !== "" &&
                Number(event.target.value) !== 0
              ) {
                setEmty({ ...empty, ["codigo"]: true });
              }
            }
          }
        }

        break;
      default:
        break;
    }
  };

  const validateDecimal = (numberValor) => {
    let splitList = numberValor.split(".");
    if (splitList.length > 2) {
      enqueueSnackbar(
        `El dato ingresado es erroneo utilice punto (.) para separar decimales`,
        {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          autoHideDuration: 4000,
        }
      );
      return false;
    } else if (splitList.length > 1) {
      if (splitList[1].length > 2) {
        enqueueSnackbar(
          `Los datos numéricos debe tener máximo 2 decimales Ej, 12.43`,
          {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            autoHideDuration: 4000,
          }
        );
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const handleSubmit = (data) => {
    let errors = [];

    if (!checkInputs.notNull(data.codigoImpuesto)) {
      errors.push("Ingrese el código del impuesto.");
    }

    if (!checkInputs.notNull(data.codPorcentaje)) {
      errors.push("Ingrese el código del porcentaje.");
    }

    if (!checkInputs.notNull(data.baseImponible)) {
      errors.push("Ingrese la base imponible.");
    } else if (!checkInputs.isDecimal(data.baseImponible)) {
      errors.push("Ingrese solo números y use (.) para separar decimales.");
    }

    errors.forEach((item) => {
      enqueueSnackbar(item, {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        autoHideDuration: 10000,
      });
    });
    return errors.length === 0;
  };

  const columns = [
    {
      title: "Código Impuesto",
      field: "codigoImpuesto",
      cellStyle: tableStyle.cellTable,
      editComponent: (props) => {
        return (
          <FormControl>
            <Autocomplete
              autoComplete={false}
              style={{ width: "150px" }}
              id="combo-box-demo"
              size="medium"
              value={typeof props.value === "undefined" ? null : tax}
              options={typeTaxImp}
              noOptionsText="No se encontró datos con el argumento ingresado"
              onChange={(e, newValue) => {
                props.onChange(newValue.CODIGO);
                setDataImp();
                if (newValue) {
                  if (newValue.CODIGO === ivaCodigo.IVA_12) {
                    setDataImp(2);
                    // setDataImp(taxPercentage);
                  } else if (newValue.CODIGO === ivaCodigo.IVA_15) {
                    setDataImp(4);
                    // setDataImp(taxIce);
                  } else if (newValue.CODIGO === ivaCodigo.IVA_5) {
                    setDataImp(5);
                    // setDataImp(0);
                  }
                } else {
                  setDataImp();
                }

                setTax(newValue);
                setRetentionTax({
                  ...retentionTax,
                  ["codigoImpuesto"]: newValue
                    ? newValue.CODIGO
                      ? newValue.CODIGO
                      : ""
                    : "",
                });
                setEmty({ ...empty, ["impuesto"]: true });
                setImpuesto({
                  CODIGO: "",
                  VALOR: "",
                  NOMBRE: "",
                });
                setTaxValue();
              }}
              getOptionLabel={(option) => `${option.CODIGO}-${option.NOMBRE}`}
              //style={{ width: 700 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="form-control"
                  variant="outlined"
                  placeholder="Seleccione"
                />
              )}
            />
          </FormControl>
        );
      },
    },
    {
      title: "Código Porcentaje",
      field: "codPorcentaje",
      cellStyle: tableStyle.cellTable,
      editComponent: (props) => (
        <FormControl>
          {dataImp ? (
            <Autocomplete
            style={{ width: "300px" }}
              id="combo-box-demo"
              size="medium"
              value={impuesto}
              options={dataPorcentaje}
              // options={dataImp}
              noOptionsText="No se encontró datos con el argumento ingresado"
              onChange={(event, newValue) => {
                props.onChange(newValue.CODIGO);
                if (newValue) {
                  setImpuesto(newValue);
                } else {
                  setImpuesto({
                    CODIGO: "",
                    VALOR: "",
                    NOMBRE: "",
                  });
                }
                setRetentionTax({
                  ...retentionTax,
                  ["codPorcentaje"]: newValue
                    ? newValue.CODIGO
                      ? newValue.CODIGO
                      : ""
                    : "",
                });
                calculateTaxValue(
                  retentionTax
                    ? retentionTax.baseImponible
                      ? retentionTax.baseImponible
                      : 0
                    : 0,
                  newValue ? (newValue.VALOR ? newValue.VALOR : 0) : 0
                );
                setEmty({ ...empty, ["retencion"]: true });
              }}
              getOptionLabel={(option) => `${option.CODIGO}-${option.NOMBRE}`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="form-control"
                  variant="outlined"
                  placeholder="Seleccione"
                />
              )}
            />
          ) : null}
        </FormControl>
      ),
    },
    {
      title: "Base Imponible",
      field: "baseImponible",
      cellStyle: tableStyle.cellTable,
      editComponent: (props) => (
        <TextField
          className={
            retentionTax
              ? retentionTax.baseImponible !== 0 &&
                retentionTax.baseImponible.trim() !== ""
                ? "form-control  is-valid"
                : "form-control"
              : "form-control"
          }
          type="text"
          placeholder="Ej. 123"
          name="baseImponible"
          variant="outlined"
          value={
            typeof props.value === "undefined"
              ? null
              : retentionTax.baseImponible
          }
          onChange={(event) => {
            props.onChange(event.target.value);
            handleChangeData(event);
          }}
          autoFocus="autoFocus"
          autoComplete="off"
        />
      ),
    },
    {
      title: "Valor Impuesto",
      field: "valorImpuesto",
      cellStyle: tableStyle.cellTable,
      editComponent: (props) => (
        // <input
        <TextField
          className={taxValue ? "form-control  is-valid" : "form-control"}
          type="text"
          placeholder="Ej. 123"
          disabled
          name="valor"
          variant="outlined"
          value={taxValue ? taxValue : ""}
          onChange={(event) => {
            props.onChange(event.target.value);
          }}
          autoComplete="off"
        />
      ),
    },
  ];

  const { enqueueSnackbar } = useSnackbar();

  return (
    <MaterialTable
      title={
        <div>
          Impuestos <font color={palette.error.main}> * </font>
          {dataAditionalImpuestos.length > 0 ? null : (
            <span style={{ color: "red" }}>
              por favor ingresa al menos un impuesto
            </span>
          )}
        </div>
      }
      columns={columns}
      data={dataAditionalImpuestos.length > 0 ? dataAditionalImpuestos : []}
      localization={{
        header: {
          actions: "",
        },
        body: {
          editRow: {
            deleteText: "¿Está seguro de eliminar la fila ?",
            saveTooltip: "Guardar cambios.",
            cancelTooltip: "Cancelar cambios.",
          },
          addTooltip: "Agregar",
          editTooltip: "Editar fila",
          deleteTooltip: "Eliminar fila",
          emptyDataSourceMessage: "No existen datos",
        },
        pagination: {
          labelRowsSelect: "Filas por página",
          labelDisplayedRows: "{from}-{to} de {count}",
        },
      }}
      options={{
        search: false,
        sorting: false,
        headerStyle: {
          backgroundColor: letterColor,
          color: "#FFFFFF",
          borderStyle: "solid",
          borderColor: letterColor,
          borderWidth: "1px",
          alignItems: "center",
          textAlign: "center",
          padding: "0px",
        },
        rowStyle: {
          borderStyle: "solid",
          borderColor: letterColor,
          borderWidth: "1px 1px ",
          alignContent: "center",
          textAlign: "center",
          paddingRight: "1px",
        },
      }}
      editable={{
        isEditable: (rowData) =>
          typeof rowData.isEditable === "boolean" ? rowData.isEditable : true,
        isDeletable: (rowData) =>
          typeof rowData.isDeletable === "boolean" ? rowData.isDeletable : true,
        onRowAdd: (newData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              if (dataAditionalImpuestos.length < maxRows) {
                if (typeof newData === "object") {
                  const isValid = handleSubmit(newData);
                  if (isValid) {
                    newData.baseImponible = +retentionTax.baseImponible;
                    newData.valorImpuesto = +taxValue;
                    newData.tarifa = impuesto.VALOR.toString();
                    setDataAditionalImpuestos([
                      ...dataAditionalImpuestos,
                      newData,
                    ]);
                    resolve();
                  } else {
                    reject();
                  }
                }
              } else {
                enqueueSnackbar(`Únicamente puede agregar ${maxRows} filas`, {
                  variant: "warning",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                  autoHideDuration: 4000,
                });
                resolve();
              }
            }, 1000);
          }),

        // onRowUpdate: (newData, oldData) =>
        //   new Promise((resolve, reject) => {
        //     setTimeout(() => {
        //       const dataUpdate = [...dataAditionalImpuestos];
        //       const index = oldData.tableData.id;
        //       const isValid = handleSubmit(newData);
        //       if (isValid) {
        //         dataUpdate[index] = newData;
        //         setDataAditionalImpuestos([...dataUpdate]);
        //         resolve();
        //       } else {
        //         reject();
        //       }
        //     }, 1000);
        //   }),
        onRowDelete: (oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              const dataDelete = [...dataAditionalImpuestos];
              const index = oldData.tableData.id;
              dataDelete.splice(index, 1);
              setDataAditionalImpuestos([...dataDelete]);
              resolve();
            }, 1000);
          }),
      }}
      icons={tableIcons}
    />
  );
}
