import React, { useState, useEffect } from "react";
import { makeStyles, Grid, CardHeader, FormControl, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import {
  palette,
  useStylesGlobal,
  styleInput,
} from "configurations/styles/theme";
import AssignmentIcon from "@material-ui/icons/Assignment";
import InputMask from "react-input-mask";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { useDispatch, useSelector } from "react-redux";
import {
  changeErrorLabelsActionGuide,
  addDirAction,
  addPlacaAction,
} from "redux/actions/referralGuideActions";
import checkInputs from "utils/checkInputs";
import { addRUCAction, addReasonAction } from "redux/actions/CreditNoteActions";
import checkDocument from "utils/checkDocument";
import TextError from "componets/TextError";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    margin: theme.spacing(1),
    display: "flex",
    flexWrap: "wrap",
    marginBottom: "1%",
    borderStyle: "solid",
    boxShadow: "5px 3px 3px #aaaaaa",
    borderRadius: "8px",
    marginTop: "auto",
    borderWidth: "2px",
  },
  formControl: {
    marginTop: theme.spacing(0),
    minWidth: 120,
  },
  radio: {
    display: "-webkit-box",
    marginTop: "0px",
  },
  radioLable: {
    color: palette.primary.main,
    display: "flex",
  },
  radioButton: {
    marginTop: "0px",
    paddingTop: "0px",
  },
  divForm: {
    marginBottom: "1%",
    textAlign: "justify",
    color: theme.palette.text.primary,
  },
  divInput: {
    marginBottom: "1%",
    paddingRight: "1%",
    paddingLeft: "0.5%",
    textAlign: "justify",
  },
  divButton: {
    paddingLeft: "3%",
    paddingRight: "3%",
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 999,
    color: palette.primary.main,
  },
  haedClient: {
    backgroundColor: palette.primary.main,
    width: "10%",
    display: "flex",
    flexWrap: "wrap",
    borderStyle: "solid",
    borderBottomWidth: "5px",
    borderRadius: "8px",
  },
}));
const CarrierAndTransfer = ({ referralGuide, setReferralGuide, update }) => {
  const classes = useStyles();
  const classesGlobales = useStylesGlobal();
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const changeErrorLabels = (errors) =>
    dispatch(changeErrorLabelsActionGuide(errors));

  const addRUC = (reason) => dispatch(addRUCAction(reason));
  const addDir = (reason) => dispatch(addDirAction(reason));
  const addPlaca = (reason) => dispatch(addPlacaAction(reason));
  const addReason = (reason) => dispatch(addReasonAction(reason));
  const [dateMax, setDateMax] = useState(new Date());
  const [identityCheck, setIdentityCheck] = useState("C");

  const errorsRedux = useSelector((state) => state.referralGuide.errors);

  function checkOnChange(nameInput, value) {
    const response = { value: value, error: null, keyWordError: null };

    switch (nameInput) {
      case "rucTransportista":
        if (referralGuide.identityCheck === 'C') {
          if (checkInputs.countLenth(value) > 13) {
            response.keyWordError = "isLong";
            response.error = "No ingrese más de 13 caracteres.";
            changeErrorLabels({ rucTransportista: "No mas de 13 caracteres." });
          } else if (!checkInputs.isNumeric(value)) {
            response.keyWordError = "isNotNumeric";
            response.error = "Solo puede ingresar números.";
            changeErrorLabels({ rucTransportista: "solo ingrese numeros." });
          } else if (!checkDocument.validarCedula(value)
            // !checkDocument.validarCedula(value) &&
            // !checkDocument.validarRucPersonaNatural(value) &&
            // !checkDocument.validarRucSociedadPrivada(value) &&
            // !checkDocument.validarRucSociedadPublica(value)
          ) {
            response.error = "Cédula o RUC inválido.";
            changeErrorLabels({ rucTransportista: "Cédula o RUC inválido" });
          }
        } else {
          if (checkInputs.countLenth(value) > 15) {
            response.keyWordError = "isLong";
            response.error = "No ingrese más de 15 caracteres.";
            changeErrorLabels({ rucTransportista: "No ingrese mas de 15 caracteres." });
          } else if (!checkInputs.isAlphanumeric(value)) {
            response.keyWordError = "isNotNumeric";
            response.error = "No ingrese caracteres especiales.";
            changeErrorLabels({ rucTransportista: "No ingrese caracteres especiales." });
          }
        }

        return response;
      case "dirPartida":
        if (checkInputs.countLenth(value) > 40) {
          response.error = "No ingrese más de 40 caracteres.";
          response.keyWordError = "isLong";
          changeErrorLabels({
            dirPartida: "No ingrese más de 40 caracteres",
          });
        }
        return response;

      case "razonSocialTransportista":
        if (checkInputs.countLenth(value) > 300) {
          response.error = "No ingrese más de 300 caracteres.";
          changeErrorLabels({
            razonSocialTransportista: "No ingrese más de 300 caracteres",
          });
        }
        return response;

      default:
        return response;
    }
  }

  const handleChange = (event) => {
    event.preventDefault();
    const { error, value, keyWordError } = checkOnChange(
      event.target.name,
      event.target.value
    );

    if (event.target.name === "rucTransportista") {
      if (referralGuide.identityCheck !== 'P') {
        if (keyWordError !== "isNotNumeric" && keyWordError !== "isLong") {
          setReferralGuide({
            ...referralGuide,
            [event.target.name]: value,
          });
          changeErrorLabels({ rucTransportista: null });
          addRUC(event.target.value);
        }
        setErrors({ ...errors, [event.target.name]: error });
      } else if (keyWordError !== 'isNotNumeric' && keyWordError !== "isLong" && !/\s/i.test(event.target.value)) {
        setReferralGuide({
          ...referralGuide,
          [event.target.name]: value,
        });
        changeErrorLabels({ rucTransportista: null });
        addRUC(event.target.value);
        setErrors({ ...errors, [event.target.name]: error });
      }
    }

    if (event.target.name === "dirPartida") {
      if (keyWordError !== "isNotNumeric" && keyWordError !== "isLong") {
        setReferralGuide({
          ...referralGuide,
          [event.target.name]: value,
        });
        changeErrorLabels({ dirPartida: null });
        addDir(event.target.value);
      }
      setErrors({ ...errors, [event.target.name]: error });
    }
    if (event.target.name === "placa") {
      setReferralGuide({
        ...referralGuide,
        [event.target.name]: value,
      });
      changeErrorLabels({ placa: null });
      addPlaca(event.target.value);
    }
    if (event.target.name === "razonSocialTransportista") {
      if (error === null) {
        setReferralGuide({
          ...referralGuide,
          [event.target.name]: value,
        });
        changeErrorLabels({ razonSocialTransportista: null });
        addReason(event.target.value);
      }
      setErrors({
        ...errors,
        [event.target.name]: error,
      });
    }
  };

  const handleChangeDate = (name) => (event) => {
    setReferralGuide({
      ...referralGuide,
      [name]: event,
    });

  };


  const maxDatePicker = (fecha) => {
    const minDate = new Date(fecha);
    minDate.setDate(minDate.getDate() + 30);
    setDateMax(minDate)
  };

  const name = (params) => {

  }



  useEffect(() => {

    setReferralGuide({
      ...referralGuide,
      ['fechaFinTransporte']: referralGuide.fechaIniTransporte,
    });
    maxDatePicker(referralGuide.fechaIniTransporte)

  }, [referralGuide.fechaIniTransporte]);

  return (
    <>
      <div className={classesGlobales.rootDiv}>
        <Grid container xs={12} justify="center">
          <CardHeader
            className={classesGlobales.cabecera}
            title={"Transportista y Traslado"}
            avatar={<AssignmentIcon></AssignmentIcon>}
          />
        </Grid>
        <Grid container spacing={1} className={classesGlobales.containerForm}>
          <Grid container justify="center"></Grid>
          <Grid item xs={12} md={2} className={classes.divForm}>
            <label>
              Identificación del transportista:{" "}
              <font color={palette.error.main}>*</font>
            </label>
          </Grid>
          <Grid item xs={12} md={4} className={classes.divInput}>
            <input
              type="text"
              placeholder="Ej. 1722534300"
              name="rucTransportista"
              value={
                referralGuide.rucTransportista
                  ? referralGuide.rucTransportista
                  : ""
              }
              autoComplete="off"
              className={styleInput(
                errors.rucTransportista
                  ? errors.rucTransportista
                  : errorsRedux.rucTransportista,
                referralGuide.rucTransportista
              )}
              onChange={handleChange}
            />
            <TextError
              text={
                errors.rucTransportista
                  ? errors.rucTransportista
                  : errorsRedux.rucTransportista
              }
            ></TextError>
          </Grid>

          <Grid item md={6} xs={12} className={classes.divForm}>
            <FormControl
              component="fieldset"
              className={classes.formControl}
            >
              <RadioGroup
                aria-label="tipoDocumento"
                name="identityCheck"
                className={classes.radio}
                required
                value={referralGuide.identityCheck ? referralGuide.identityCheck : ""}
                onChange={(event) => {
                  setIdentityCheck(event.target.value)
                  setReferralGuide({
                    ...referralGuide,
                    ['identityCheck']: event.target.value,
                  });
                }}
              >
                <FormControlLabel
                  value="P"
                  control={
                    <Radio
                      color="primary"
                      className={classes.radioButton}
                    />
                  }
                  label="Pasaporte"
                  labelPlacement="start"
                  className={classes.radioLable}
                />
                <FormControlLabel
                  value="C"
                  control={
                    <Radio
                      color="primary"
                      className={classes.radioButton}
                    />
                  }
                  label="Cédula/RUC"
                  labelPlacement="start"
                  className={classes.radioLable}
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={2} className={classes.divForm}>
            <label>
              Dirección de partida: <font color={palette.error.main}>*</font>
            </label>
          </Grid>
          <Grid item xs={12} md={4} className={classes.divInput}>
            <input
              type="text"
              placeholder="Ej. Quito"
              name="dirPartida"
              autoComplete="off"
              className={styleInput(
                errors.dirPartida ? errors.dirPartida : errorsRedux.dirPartida,
                referralGuide.dirPartida
              )}
              value={referralGuide.dirPartida ? referralGuide.dirPartida : ""}
              onChange={handleChange}
            />
            <TextError
              text={
                errors.dirPartida ? errors.dirPartida : errorsRedux.dirPartida
              }
            ></TextError>
          </Grid>

          <Grid item xs={12} md={2} className={classes.divForm}>
            <label>
              Razón social del transportista:{" "}
              <font color={palette.error.main}>*</font>
            </label>
          </Grid>
          <Grid item xs={12} md={4} className={classes.divInput}>
            <input
              type="text"
              placeholder="Ej. Transportes S.A."
              name="razonSocialTransportista"
              autoComplete="off"
              className={styleInput(
                errors.razonSocialTransportista
                  ? errors.razonSocialTransportista
                  : errorsRedux.razonSocialTransportista,
                referralGuide.razonSocialTransportista
              )}
              value={
                referralGuide.razonSocialTransportista
                  ? referralGuide.razonSocialTransportista
                  : ""
              }
              onChange={handleChange}
            />
            <TextError
              text={
                errors.razonSocialTransportista
                  ? errors.razonSocialTransportista
                  : errorsRedux.razonSocialTransportista
              }
            ></TextError>
          </Grid>

          <Grid item xs={12} md={2} className={classes.divForm}>
            <label>
              Fecha inicio: <font color={palette.error.main}>*</font>
            </label>
          </Grid>
          <Grid item xs={12} md={4} className={classes.divInput}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <KeyboardDatePicker
                className={"form-control "}
                autoOk
                variant="inline"
                inputVariant="outlined"
                invalidDateMessage="Formato inválido."
                format="dd-MM-yyyy"
                InputAdornmentProps={{ position: "start" }}
                name="fechaIniTransporte"
                value={referralGuide.fechaIniTransporte}
                onChange={handleChangeDate("fechaIniTransporte")}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} md={2} className={classes.divForm}>
            <label>
              Placa del transportista: <font color={palette.error.main}>*</font>
            </label>
          </Grid>
          <Grid item xs={12} md={4} className={classes.divInput}>
            <input
              type="text"
              placeholder="Ej. PPP-1234"
              name="placa"
              autoComplete="off"
              maxLength="20"
              className={styleInput(
                errors.placa ? errors.placa : errorsRedux.placa,
                referralGuide.placa
              )}
              value={referralGuide.placa ? referralGuide.placa : ""}
              onChange={handleChange}
            />
            <TextError
              text={errors.placa ? errors.placa : errorsRedux.placa}
            ></TextError>
          </Grid>

          <Grid item xs={12} md={2} className={classes.divForm}>
            <label>
              Fecha fin: <font color={palette.error.main}>*</font>
            </label>
          </Grid>
          <Grid item xs={12} md={4} className={classes.divInput}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <KeyboardDatePicker
                className={"form-control "}
                autoOk
                variant="inline"
                maxDate={dateMax}
                minDate={referralGuide.fechaIniTransporte}
                minDateMessage="Formato inválido."
                inputVariant="outlined"
                invalidDateMessage="Formato inválido."
                format="dd-MM-yyyy"
                InputAdornmentProps={{ position: "start" }}
                name="fechaIniTransporte"
                value={referralGuide.fechaFinTransporte}
                onChange={handleChangeDate("fechaFinTransporte")}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default CarrierAndTransfer;
