import checkDocument from 'utils/checkDocument';
import checkInputs from 'utils/checkInputs';

const validations = {
    editProvider: function validationEditUser(updatedProvider) {
        const errors = {};

        // if (!checkInputs.notNull(updatedProvider.nombres)) {
        //     errors.nombres = "El nombre es necesario.";
        // } else if (!checkInputs.isAlphanumeric(updatedProvider.nombres)) {
        //     errors.nombres = "No ingrese caracteres especiales.";
        // } else if (updatedProvider.nombres.length > 300) {
        //     errors.nombres = 'No ingrese más de 300 caracteres.';
        // }

        // if (updatedProvider.telefono) {
        //     if (checkInputs.notNull(updatedProvider.telefono.toString())) {
        //         if (!checkInputs.isNumeric(updatedProvider.telefono)) {
        //             errors.telefono = "El teléfono solo puede contener números.";
        //         } else if (updatedProvider.telefono.length < 7 || updatedProvider.telefono.length > 10) {
        //             errors.telefono = "La cantidad de dígitos debe estar entre 7 (convencionales) a 10 (teléfonos celulares).";
        //         } else if (updatedProvider.telefono.length > 300) {
        //             errors.telefono = 'No ingrese más de 300 caracteres.';
        //         }
        //     }
        // }

        // if (checkInputs.notNull(updatedProvider.direccion = "")) {
        //     if (!checkInputs.isAlphanumeric(updatedProvider.direccion)) {
        //         errors.direccion = "Solo ingrese: letras, números, '#', '.' o paréntesis.";
        //     } else if (updatedProvider.direccion.length > 300) {
        //         errors.direccion = 'No ingrese más de 300 caracteres.';
        //     }
        // }

        // if (!checkInputs.notNull(updatedProvider.correo)) {
        //     errors.correo = 'El correo es necesario.'
        // } else if (!checkInputs.validationEmail(updatedProvider.correo)) {
        //     errors.correo = 'El correo es incorrecto. Debe contener el usuario, @ y el dominio.'
        // } else if (updatedProvider.correo.length > 300) {
        //     errors.correo = 'No ingrese más de 300 caracteres.';
        // }

        return errors;
    },
    addProvider: function validationAddProvider(newProvider) {
        const errors = {};

        // if (typeof newProvider.identificacionCliente === 'undefined' || newProvider.identificacionCliente.trim() === '') {
        //     errors.identificacionCliente = 'Ingrese la identificación del usuario.';
        // } else if (newProvider.typeDocument === 'C') {
        //     if (!checkDocument.validarCedula(newProvider.identificacionCliente) &&
        //         !checkDocument.validarRucPersonaNatural(newProvider.identificacionCliente) &&
        //         !checkDocument.validarRucSociedadPrivada(newProvider.identificacionCliente) &&
        //         !checkDocument.validarRucSociedadPublica(newProvider.identificacionCliente)) {
        //         errors.identificacionCliente = 'Cédula o RUC inválido.';
        //     }
        // } else if (!checkInputs.isAlphanumeric(newProvider.identificacionCliente)) {
        //     errors.identificacionCliente = 'No puede contener caracteres especiales, solo letras o números.';
        // }else if(newProvider.identificacionCliente.length > 300){
        //     errors.identificacionCliente = 'No ingrese más de 300 caracteres.';
        // }



        // if (typeof newProvider.nombreCliente === 'undefined' || newProvider.nombreCliente.trim() === '') {
        //     errors.nombreCliente = 'El nombre es necesario.';
        // } else if (!checkInputs.isAlphanumeric(newProvider.nombreCliente)) {
        //     errors.nombreCliente = 'Solo ingrese letras.';
        // }else if(newProvider.nombreCliente.length > 300){
        //     errors.nombreCliente = 'No ingrese más de 300 caracteres.';
        // }

        // if (typeof newProvider.correoCliente === 'undefined' || newProvider.correoCliente.trim() === '') {
        //     errors.correoCliente = 'El correo del usuario es necesario.';
        // } else if (!checkInputs.validationEmail(newProvider.correoCliente)) {
        //     errors.correoCliente = 'El correo del usuario erróneo. Ingrese un correo con el formato user@ejemplo.com';
        // }else if(newProvider.correoCliente.length > 300){
        //     errors.correoCliente = 'No ingrese más de 300 caracteres.';
        // }

        // if (typeof newProvider.telefonoCliente !== 'undefined') {
        //     if (!checkInputs.isNumeric(newProvider.telefonoCliente.toString())) {
        //         errors.telefonoCliente = 'Solo ingrese números.';
        //     }else if(newProvider.telefonoCliente.length > 300){
        //         errors.telefonoCliente = 'No ingrese más de 300 caracteres.';
        //     }
        // }

        // if (typeof newProvider.direccionCliente !== "undefined" ) {

        //     if (!checkInputs.isAlphanumeric(newProvider.direccionCliente)) {
        //         errors.direccionCliente = 'No ingrese caracteres especiales.';
        //     }else if(newProvider.direccionCliente.length > 300){
        //         errors.direccionCliente = 'No ingrese más de 300 caracteres.';
        //     }
        // }

        return errors;
    }
}

export default validations;