import { Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { httpRepo } from "configurations/axios";
import { useSnackbar } from "notistack";
import React from "react";
import { useSelector } from "react-redux";
import FilterVoucherCount from "../components/FilterVoucherCount";

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: "35px",
    paddingBottom: "7%",
    flexGrow: 1,
    paddingLeft: "3%",
    paddingRight: "3%",
  },
  backdrop: {
    zIndex: "3000",
    color: "#fff",
  },
}));

const VoucherCountDetail = () => {
  const classesView = useStyles();
  const [open, setOpen] = React.useState(false);
  const tokenUser = useSelector((state) => state.user.tk);
  var fileSaver = require("file-saver");
  const { enqueueSnackbar } = useSnackbar();

  const getReportCount = async (objReport) => {
    setOpen(true);
    await httpRepo
      .post("reporte/comprobantes/empresa", objReport, {
        timeout: 120000,
        responseType: "arraybuffer",
      })
      .then((response) => {
        if (response.status === 200) {
          var blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          fileSaver.saveAs(blob, "Reporte.xlsx");
          setOpen(false);
        }
      })
      .catch(() => {
        enqueueSnackbar(`No existen datos para los filtros seleccionados`, {
          variant: "info",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        setOpen(false);
      });
  };

  return (
    <div className={classesView.root}>
      <Backdrop open={open} className={classesView.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <FilterVoucherCount getReportCount={getReportCount} />
    </div>
  );
};

export default VoucherCountDetail;
