import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import tableStyle from "configurations/styles/table";
import { palette } from "configurations/styles/theme";
import { useSnackbar } from "notistack";
import checkInputs from "utils/checkInputs";
import bigDecimal from "js-big-decimal";
import {
  TextField,
  makeStyles,
  FormControl,
  NativeSelect,
} from "@material-ui/core";
import { tableIcons } from "./OverrideIcons";
import {
  retentionCode,
  retentionCodeIva,
  retentionCodeIcd,
  typeTax,
} from "views/facturacion/Forms/typeVoucher";
import { Autocomplete } from "@material-ui/lab";
import EditTaxBtn from "views/facturacion/Vouchers/Retention/Buttons/EditTaxBtn";

import { getRUCEmpresa } from "redux/actions/userActions";
import clientBackend from "configurations/axios";
import { useSelector } from "react-redux";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  DatePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import format from "date-fns/format";
const useStyles = makeStyles({
  editComponent: {
    paddingRight: "5px",
  },
});

export default function EditTableRetenciones({
  dataAditional,
  setDataAditional,
  maxRows = 20,
}) {
  const letterColor = palette.primary.main;
  const classes = useStyles();
  const [dataTax, setDataTax] = useState();
  const [tax, setTax] = useState();
  const [taxDetail, setTaxDetail] = useState([]);
  const [impuestoRetencions, setImpuestoRetencions] = useState([]);
  const [viewDetalle, setViewDetalle] = useState(1);
  const [retainedValue, setRetainedValue] = useState();
  const [retention, setRetention] = useState({
    CODIGO: "",
    VALOR: 0,
    NOMBRE: "",
  });
  const [retentionTax, setRetentionTax] = useState({
    baseImponible: "",
    codigoImpuesto: "",
    codigoRetencion: "",
    porcentajeARetener: 0,
    dividendos: {
      fechaPagoDiv: "",
      imRentaSoc: 0,
      ejerFisUtDiv: "",
    },
  });
  const [empty, setEmty] = useState({
    impuesto: true,
    retencion: true,
    codigo: true,
    base: true,
    documento: true,
    numero: true,
    fecha: true,
  });

  const minDate = new Date();
  const minDate1 = minDate.setDate(minDate.getDate() - 90);
  const [fechaPagoDiv, setFechaPagoDiv] = useState(new Date());
  const [imRentaSoc, setImRentaSoc] = useState();
  const userToken = useSelector((state) => state.user.tk);

  const calculateRetainedValue = (base, porcent) => {
    if (validateDecimal(base.toString())) {
      let retainedValue = bigDecimal.multiply(
        base,
        bigDecimal.divide(porcent ? porcent : "0", "100")
      );
      setRetainedValue(
        bigDecimal.round(retainedValue, 2, bigDecimal.RoundingModes.HALF_UP)
      );
    }
  };

  const getCodePercentage = async (code) => {
    const { data } = await clientBackend.get(
      `/admin/impuesto/retencion/buscar/codigo/${code}`
    );
    const dataAux = [];
    data.forEach((e) => {
      let constructor = {
        CODIGO: e.codigo,
        VALOR: +e.porcentaje,
        NOMBRE: e.descripcion,
      };
      dataAux.push(constructor);
    });
    // console.table(dataAux);
    dataAux.sort((a, b) => {
      const nameA = a.CODIGO.toUpperCase();
      const nameB = b.CODIGO.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    setImpuestoRetencions(dataAux);
  };

  useEffect(() => {
    getCodePercentage(dataTax);
  }, [dataTax]);

  const handleChangeData = (event) => {
    event.preventDefault();
    const re = /^[0-9\b.]+$/;
    switch (event.target.name) {
      case "baseImponible":
        if (event.target.value === "" || re.test(event.target.value)) {
          if (Number(event.target.value.trim()) >= 0) {
            if (
              checkInputs.countDecimals(event.target.value) <= 2 &&
              checkInputs.countDigit(event.target.value) <= 14
            ) {
              setRetentionTax({
                ...retentionTax,
                ["baseImponible"]: event.target.value,
              });
              let retainedValue = bigDecimal.multiply(
                event.target.value,
                bigDecimal.divide(
                  retention ? (retention.VALOR ? retention.VALOR : "0") : "0",
                  "100"
                )
              );
              setRetainedValue(
                bigDecimal.round(
                  retainedValue,
                  2,
                  bigDecimal.RoundingModes.HALF_UP
                )
              );
              if (
                typeof event.target.value !== "undefined" &&
                event.target.value.toString().trim() !== "" &&
                Number(event.target.value !== 0)
              ) {
                setEmty({ ...empty, ["base"]: true });
              }
            }
          }
        }

        break;
      case "imRentaSoc":
        if (event.target.value === "" || re.test(event.target.value)) {
          if (Number(event.target.value.trim()) >= 0) {
            if (
              checkInputs.countDecimals(event.target.value) <= 2 &&
              checkInputs.countDigit(event.target.value) <= 14
            ) {
              setImRentaSoc(event.target.value);

              if (
                typeof event.target.value !== "undefined" &&
                event.target.value.toString().trim() !== "" &&
                Number(event.target.value !== 0)
              ) {
                setEmty({ ...empty, ["base"]: true });
              }
            }
          }
        }

        break;

      case "VALOR":
        if (event.target.value === "" || re.test(event.target.value)) {
          if (Number(event.target.value.trim()) >= 0) {
            if (
              checkInputs.countDecimals(event.target.value) <= 2 &&
              checkInputs.countDigit(event.target.value) <= 3
            ) {
              setRetention({
                ...retention,
                ["VALOR"]: event.target.value,
              });

              let retainedValue = bigDecimal.multiply(
                retentionTax.baseImponible,
                bigDecimal.divide(event.target.value, "100")
              );
              setRetainedValue(
                bigDecimal.round(
                  retainedValue,
                  2,
                  bigDecimal.RoundingModes.HALF_UP
                )
              );

              if (
                typeof event.target.value !== "undefined" &&
                event.target.value.toString().trim() !== "" &&
                Number(event.target.value) !== 0
              ) {
                setEmty({ ...empty, ["codigo"]: true });
              }
            }
          }
        }

        break;
      default:
        break;
    }
  };

  const validateDecimal = (numberValor) => {
    let splitList = numberValor.split(".");
    if (splitList.length > 2) {
      enqueueSnackbar(
        `El dato ingresado es erroneo utilice punto (.) para separar decimales`,
        {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          autoHideDuration: 4000,
        }
      );
      return false;
    } else if (splitList.length > 1) {
      if (splitList[1].length > 2) {
        enqueueSnackbar(
          `Los datos numéricos debe tener máximo 2 decimales Ej, 12.43`,
          {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            autoHideDuration: 4000,
          }
        );
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const handleSubmit = (data) => {
    let errors = [];

    if (!checkInputs.notNull(data.codigoImpuesto)) {
      errors.push("Ingrese el código del impuesto.");
    }

    if (!checkInputs.notNull(data.codigoRetencion)) {
      errors.push("Ingrese el código de la retención.");
    }

    if (!checkInputs.notNull(data.imRentaSoc)) {
      errors.push("Ingrese el impuesto a la renta.");
    }

    if (!checkInputs.notNull(data.baseImponible)) {
      errors.push("Ingrese la base imponible.");
    } else if (!checkInputs.isDecimal(data.baseImponible)) {
      errors.push("Ingrese solo números y use (.) para separar decimales.");
    }

    errors.forEach((item) => {
      enqueueSnackbar(item, {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        autoHideDuration: 10000,
      });
    });
    return errors.length === 0;
  };

  function formatDate(date) {
    const day = date.getDate();
    const month = date.getMonth() + 1; // Sumar 1 porque los meses van de 0 a 11
    const year = date.getFullYear();

    // Completar con cero a la izquierda si el día o el mes es menor a 10
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}/${formattedMonth}/${year}`;
  }

  const columns = [
    {
      title: "Fecha Pago Dividendos",
      field: "fechaPagoDiv",
      cellStyle: tableStyle.cellTable,
      render: (rowData) => formatDate(rowData.dividendos.fechaPagoDiv),
      editComponent: () => (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <DatePicker
            className={
              fechaPagoDiv ? "form-control  is-valid" : "form-control "
            }
            disableToolbar
            autoOk
            disableFuture
            minDate={minDate1}
            InputAdornmentProps={{ position: "start" }}
            variant="dialog"
            inputVariant="outlined"
            cancelLabel="Cancelar"
            okLabel="Seleccionar"
            invalidDateMessage="Formato inválido."
            format="dd-MM-yyyy"
            name="fechaPagoDiv"
            value={fechaPagoDiv}
            onChange={setFechaPagoDiv}
          />
        </MuiPickersUtilsProvider>
      ),
    },
    {
      title:
        "Impuesto a la Renta pagado por la sociedad",
      field: "imRentaSoc",
      cellStyle: tableStyle.cellTable,
      editComponent: (props) => (
        <TextField
          className={
            imRentaSoc
              ? imRentaSoc !== 0 && imRentaSoc.trim() !== ""
                ? "form-control  is-valid"
                : "form-control"
              : "form-control"
          }
          type="text"
          placeholder="Ej. 123"
          name="imRentaSoc"
          variant="outlined"
          value={typeof props.value === "undefined" ? null : imRentaSoc}
          onBlur={(event) => {
            props.onChange(event.target.value);
            handleChangeData(event);
          }}
          // autoFocus="autoFocus"
          autoComplete="off"
        />
      ),
    },
    {
      title: "Código Impuesto",
      field: "codigoImpuesto",
      cellStyle: tableStyle.cellTable,
      editComponent: (props) => (
        <FormControl>
          <Autocomplete
            style={{ width: "150px" }}
            id="combo-box-demo"
            size="medium"
            value={typeof props.value === "undefined" ? null : tax}
            options={typeTax}
            noOptionsText="No se encontró datos con el argumento ingresado"
            onChange={(e, newValue) => {
              props.onChange(newValue.CODIGO);
              setDataTax();
              if (newValue) {
                if (newValue.CODIGO === "1") {
                  setDataTax(1); // renta
                  // setDataTax(retentionCode);
                } else if (newValue.CODIGO === "2") {
                  setDataTax(2); // iVA
                  // setDataTax(retentionCodeIva);
                } else if (newValue.CODIGO === "6") {
                  setDataTax(6); // ISD
                  // setDataTax(retentionCodeIcd);
                }
              } else {
                setDataTax();
              }
              setTax(newValue);
              setRetentionTax({
                ...retentionTax,
                ["codigoImpuesto"]: newValue
                  ? newValue.CODIGO
                    ? newValue.CODIGO
                    : ""
                  : "",
              });
              setEmty({ ...empty, ["impuesto"]: true });
              setRetention({
                CODIGO: "",
                VALOR: "",
                NOMBRE: "",
              });
              setRetainedValue();
            }}
            getOptionLabel={(option) => `${option.CODIGO}-${option.NOMBRE}`}
            //style={{ width: 700 }}
            renderInput={(params) => (
              <TextField
                {...params}
                className="form-control"
                variant="outlined"
                placeholder="Seleccione"
              />
            )}
          />
        </FormControl>
      ),
    },
    {
      title: "Código Retención",
      field: "codigoRetencion",
      cellStyle: tableStyle.cellTable,
      editComponent: (props) => (
        <FormControl>
          {dataTax ? (
            <Autocomplete
              style={{ width: "300px" }}
              id="combo-box-demo"
              size="medium"
              value={retention}
              options={impuestoRetencions}
              // options={dataTax}
              noOptionsText="No se encontró datos con el argumento ingresado"
              onChange={(event, newValue) => {
                props.onChange(newValue.CODIGO);
                if (newValue) {
                  setRetention(newValue);
                } else {
                  setRetention({
                    CODIGO: "",
                    VALOR: "",
                    NOMBRE: "",
                  });
                }
                setRetentionTax({
                  ...retentionTax,
                  ["codigoRetencion"]: newValue
                    ? newValue.CODIGO
                      ? newValue.CODIGO
                      : ""
                    : "",
                });
                calculateRetainedValue(
                  retentionTax
                    ? retentionTax.baseImponible
                      ? retentionTax.baseImponible
                      : 0
                    : 0,
                  newValue ? (newValue.VALOR ? newValue.VALOR : 0) : 0
                );
                setEmty({ ...empty, ["retencion"]: true });
              }}
              getOptionLabel={(option) => `${option.CODIGO}-${option.NOMBRE}`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="form-control"
                  variant="outlined"
                  placeholder="Seleccione"
                />
              )}
            />
          ) : null}
        </FormControl>
      ),
    },
    {
      title: "Porcentaje a retener",
      field: "porcentajeARetener",
      cellStyle: tableStyle.cellTable,
      editComponent: (props) => (
        <TextField
          className={retention ? "form-control  is-valid" : "form-control"}
          type="text"
          placeholder="Ej. 123"
          disabled
          variant="outlined"
          value={retention ? retention.VALOR : ""}
        />
      ),
    },
    {
      title: "Base Imponible",
      field: "baseImponible",
      cellStyle: tableStyle.cellTable,
      editComponent: (props) => (
        <TextField
          className={
            retentionTax
              ? retentionTax.baseImponible !== 0 &&
                retentionTax.baseImponible.trim() !== ""
                ? "form-control  is-valid"
                : "form-control"
              : "form-control"
          }
          type="text"
          placeholder="Ej. 123"
          name="baseImponible"
          variant="outlined"
          // value={valueTest ? valueTest : ""}
          // value={retentionTax ? retentionTax.baseImponible : ""}
          value={
            typeof props.value === "undefined"
              ? null
              : retentionTax.baseImponible
          }
          // onChange={handleBaseImponible}
          onChange={(event) => {
            props.onChange(event.target.value);
            handleChangeData(event);
          }}
          autoFocus="autoFocus"
          autoComplete="off"
        />
      ),
    },
    {
      title: "Valor Retenido",
      field: "valorRetenido",
      cellStyle: tableStyle.cellTable,
      editComponent: (props) => (
        <TextField
          className={retainedValue ? "form-control  is-valid" : "form-control"}
          type="text"
          placeholder="Ej. 123"
          disabled
          // name="valor"
          variant="outlined"
          value={retainedValue ? retainedValue : ""}
        />
      ),
    },
  ];

  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <MaterialTable
        title={
          <div>
            Retenciones <font color={palette.error.main}> * </font>
            {dataAditional.length > 0 ? null : (
              <span style={{ color: "red" }}>
                por favor ingresa la/s retencion/es
              </span>
            )}
          </div>
        }
        columns={columns}
        data={dataAditional.length > 0 ? dataAditional : []}
        // data={test.length > 0 ? test : []}
        localization={{
          header: {
            actions: "",
          },
          body: {
            editRow: {
              deleteText: "¿Está seguro de eliminar la fila ?",
              saveTooltip: "Guardar cambios.",
              cancelTooltip: "Cancelar cambios.",
            },
            addTooltip: "Agregar",
            editTooltip: "Editar fila",
            deleteTooltip: "Eliminar fila",
            emptyDataSourceMessage: "No existen datos",
          },
          pagination: {
            labelRowsSelect: "Filas por página",
            labelDisplayedRows: "{from}-{to} de {count}",
          },
        }}
        options={{
          search: false,
          sorting: false,
          headerStyle: {
            backgroundColor: letterColor,
            color: "#FFFFFF",
            borderStyle: "solid",
            borderColor: letterColor,
            borderWidth: "1px",
            alignItems: "center",
            textAlign: "center",
            padding: "0px",
          },
          rowStyle: {
            borderStyle: "solid",
            borderColor: letterColor,
            borderWidth: "1px 1px ",
            alignContent: "center",
            textAlign: "center",
            paddingRight: "1px",
          },
        }}
        editable={{
          isEditable: (rowData) =>
            typeof rowData.isEditable === "boolean" ? rowData.isEditable : true,
          isDeletable: (rowData) =>
            typeof rowData.isDeletable === "boolean"
              ? rowData.isDeletable
              : true,
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                if (dataAditional.length < maxRows) {
                  if (typeof newData === "object") {
                    const isValid = handleSubmit(newData);
                    if (isValid) {
                      newData.porcentajeARetener = retention.VALOR;
                      newData.valorRetenido = +retainedValue;
                      newData.baseImponible = +retentionTax.baseImponible;
                      newData.dividendos = {
                        fechaPagoDiv: fechaPagoDiv,
                        imRentaSoc: imRentaSoc,
                        ejerFisUtDiv: fechaPagoDiv.getFullYear(),
                      };
                      setDataAditional([...dataAditional, newData]);
                      resolve();
                    } else {
                      reject();
                    }
                  }
                } else {
                  enqueueSnackbar(`Únicamente puede agregar ${maxRows} filas`, {
                    variant: "warning",
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "right",
                    },
                    autoHideDuration: 4000,
                  });
                  resolve();
                }
              }, 1000);
            }),
          // onRowUpdate: (newData, oldData) =>
          //   new Promise((resolve, reject) => {
          //     setTimeout(() => {
          //       const dataUpdate = [...dataAditional];
          //       const index = oldData.tableData.id;
          //       const isValid = handleSubmit(newData);
          //       if (isValid) {
          //         dataUpdate[index] = newData;
          //         setDataAditional([...dataUpdate]);
          //         resolve();
          //       } else {
          //         reject();
          //       }
          //     }, 1000);
          //   }),
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                const dataDelete = [...dataAditional];
                const index = oldData.tableData.id;
                dataDelete.splice(index, 1);
                setDataAditional([...dataDelete]);
                resolve();
              }, 1000);
            }),
        }}
        icons={tableIcons}
      />
    </>
  );
}
