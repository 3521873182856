import { Backdrop, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Grid, makeStyles, Typography, TextField, IconButton } from '@material-ui/core';
import { msmSwalError, msmSwalExito, palette, useStylesGlobal } from 'configurations/styles/theme';
import React, { useState } from 'react';
import { httpNotificador } from 'configurations/axios';
import EmailIcon from '@material-ui/icons/Email';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { API_GET_ACTUAL_CREDENTIAL, API_GET_LIST_CREDENTIALS, API_GET_ASIGN_CREDENTIAL } from 'views/administración/email/apis';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    formLogin: {
        borderStyle: "solid",
        borderWidth: "1px",
        padding: "1%",
        borderRadius: "8px",
        marginLeft: "10px",
        marginRigth: "10px",
        borderColor: "#d9d9d9",
    },
    from: {
        width: "100%",
        margin: "1%",
    },
    formControl: {
        marginTop: theme.spacing(0),
        minWidth: 120,
    },
    radio: {
        display: "-webkit-box",
        marginTop: "0px",
    },
    radioLable: {
        color: palette.primary.main,
        display: "flex",
    },
    radioButton: {
        marginTop: "0px",
        paddingTop: "0px",
    },
    divForm: {
        marginBottom: "1%",
    },
    section: {
        margin: "1%",
    },
    divInput: {
        marginBottom: "1%",
        paddingRight: "1%",
        paddingLeft: "0.5%",
    },
    divButton: {
        paddingLeft: "3%",
        paddingRight: "3%",
    },
    large: {
        width: '200px',
        height: '55px',
        marginBottom: '10px'
    },
    space: {
        margin: '10px'
    },
    backdrop: {
        zIndex: theme.zIndex.modal + 999,
        color: palette.primary.main,
    },
}));

const EditEmailBtn = ({ updateView, data }) => {
    const classes = useStyles();
    const classesGlobal = useStylesGlobal();
    const [openLoader, setOpenLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const [msgError, setMsg] = useState({ show: false, msg: null, type: null });
    const [value, setValue] = useState(data.valor);
    const [error, setError] = useState();
    const [actualCredential, setActualCredential] = useState({
        idCredencialesCorreo: "",
        nombre: ""
    });
    const [newCredential, setNewCredential] = useState(
        {
            "cuentaFrom": "",
            "host": "",
            "idCredencialesCorreo": 0,
            "nombre": "",
            "password": "",
            "port": 0,
            "predeterminado": true,
            "propiedades": "",
            "username": ""
        }
    );

    const [credentials, setCredentials] = useState([]);

    const handleClose = () => {
        setOpen(false);
    };

    const getActualCredential = async (ruc) => {
        await httpNotificador
            .get(
                API_GET_ACTUAL_CREDENTIAL + ruc
            )
            .then((response) => {
                if (response.status === 200) {
                    setActualCredential({
                        idCredencialesCorreo: response.data.idCredencialesCorreo,
                        nombre: response.data.nombre
                    });
                }
            })
            .catch((exception) => {
                console.error({ exception });
            });
    };

    const renderCrendential = () => {
        if (credentials.length === 0) {
            getListCredential();
        }
        return (
            <Autocomplete
                value={newCredential}
                onChange={(event, newValue) => {
                    if (newValue != null) {
                        setNewCredential(newValue);
                    } else {
                        setNewCredential({
                            "cuentaFrom": "",
                            "host": "",
                            "idCredencialesCorreo": 0,
                            "nombre": "",
                            "password": "",
                            "port": 0,
                            "predeterminado": true,
                            "propiedades": "",
                            "username": ""
                        })
                    }

                }}
                loadingText={"No dispone de credenciales"}
                options={credentials}
                getOptionLabel={(option) => option.nombre ? option.nombre + " - " + option.cuentaFrom : ""}
                style={{ width: "95%" }}
                loading={credentials.length === 0}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label="Lista de credenciales"
                        classes={{ root: classes.textFieldComboBox }}
                    />
                )}
            />
        );
    };

    const getListCredential = async () => {
        await httpNotificador
            .get(
                API_GET_LIST_CREDENTIALS
            )
            .then((response) => {
                if (response.status === 200) {
                    setCredentials(response.data);
                }
            })
            .catch((exception) => {
                console.error({ exception });
            });
    }

    const handleClickOpen = () => {
        setOpenLoader(true);
        getActualCredential(data.idempresa).then(() => {
            setOpenLoader(false);
            setOpen(true);
        })

    };

    const handleSave = async () => {
        setOpenLoader(true);
        await httpNotificador
            .get(API_GET_ASIGN_CREDENTIAL + newCredential.idCredencialesCorreo + '/' + data.idempresa)
            .then((response) => {
                if (response.status === 200) {
                    setOpen(false);
                    msmSwalExito(
                        "Credencial actualizada",
                    )
                    updateView();
                }
                setOpenLoader(false);
            })
            .catch((exception) => {
                if (exception.response) {
                    if (exception.response.status === 400) {
                        msmSwalError(exception.response.data.apierror ? exception.response.data.apierror.mensaje ? exception.response.data.apierror.mensaje : 'No se pudo registrar la empresa. Contáctese con el administrador del Sistema.' : 'No se pudo registrar la empresa. Contáctese con el administrador del Sistema.')

                    } else if (exception.response.status === 404) {

                        msmSwalError(exception.response.data.apierror ? exception.response.data.apierror.mensaje ? exception.response.data.apierror.mensaje + '. Contáctese con el administrador del Sistema' : 'No se pudo registrar la empresa. Contáctese con el administrador del Sistema.' : 'No se pudo registrar la empresa. Contáctese con el administrador del Sistema.')

                    } else if (exception.response.status === 409) {
                        msmSwalError(exception.response.data.apierror ? exception.response.data.apierror.mensaje ? exception.response.data.apierror.mensaje + '. Contáctese con el administrador del Sistema' : 'La empresa ya existe. Contáctese con el administrador del Sistema' : 'La empresa ya existe. Contáctese con el administrador del Sistema.')
                        setMsg({
                            show: true,
                            msg:
                                "Contáctese con el administrador del Sistema.",
                            type: "error",
                        });
                    } else {
                        msmSwalError(exception.response.data.apierror ? exception.response.data.apierror.mensaje ? exception.response.data.apierror.mensaje + '. Contáctese con el administrador del Sistema' : 'Ocurrió un error interno. Contáctese con el administrador del Sistema.' : 'Ocurrió un error interno. Contáctese con el administrador del Sistema.')
                        setMsg({
                            show: true,
                            msg:
                                "Ocurrió un error interno. Contáctese con el administrador del Sistema.",
                            type: "error",
                        });
                    }
                } else {
                    setMsg({
                        show: true,
                        msg:
                            "Existen problemas en la red. Contáctese con el administrador del Sistema.",
                        type: "error",
                    });
                }
                setOpenLoader(false);
            });
    };

    const handleSubmit = () => {
        setOpenLoader(true)
        handleSave().then(() => {
            setOpenLoader(false)
        })
    }

    const handleChange = (event) => {
        if (event.target.value != null) {
            setValue(
                event.target.value
            );
        } else {
            setError("El campo no puede ser nulo");
        }

    };


    return (
        <div>
            <IconButton
                aria-label="delete"
                className={classes.IconButton}
                variant="outlined"
                color="secondary"
                onClick={handleClickOpen}
            >
                <EmailIcon
                    style={{ color: palette.primary.main }}
                />
            </IconButton>

            <Dialog
                fullWidth
                maxWidth="lg"
                open={open}
                onClose={handleClose}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle id="max-width-dialog-title">Cambio credenciales correo</DialogTitle>
                <DialogContent>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                    >
                        <section>
                            <form >
                                <Grid container justify="center" spacing={2}>
                                    <Grid item sm={5} className={classes.formLogin}>
                                        <Grid container spacing={1}>
                                            <Grid item md={12}>
                                                <Typography variant="h6">
                                                    <strong>Credenciales del correo</strong>
                                                </Typography>
                                            </Grid>
                                            <Grid item md={12}>
                                                <Divider></Divider>
                                            </Grid>
                                            <Grid item md={12}>
                                                <label>
                                                    Los campos marcados con ({" "}
                                                    <font color={palette.error.main}> *</font> ) son
                                                    obligatorios:
                                                </label>
                                            </Grid>
                                            <Grid item sm={3}>
                                                <label>
                                                    Credencial Actual: <font color={palette.error.main}> *</font>
                                                </label>
                                            </Grid>
                                            <Grid item sm={9}>
                                                <input
                                                    type="text"
                                                    value={actualCredential.nombre}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item sm={3}>
                                                <label>
                                                    Credencial Nueva: <font color={palette.error.main}> *</font>
                                                </label>
                                            </Grid>
                                            <Grid item sm={9}>
                                                {renderCrendential()}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        </section>
                        <Grid container spacing={3} justify="center">
                            <Grid item md={12} xs={12}>
                                <br />
                                <Divider></Divider>
                            </Grid>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                className={classesGlobal.marginButton}
                            >
                                Editar
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleClose}
                                className={classesGlobal.marginButton}
                            >
                                Cancelar
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Backdrop className={classes.backdrop} open={openLoader}>
                <CircularProgress color="inherit" />
            </Backdrop>

        </div>
    );
}

export default EditEmailBtn;