import { useState, useEffect } from "react";
//axios
import { httpRepo } from 'configurations/axios';


const initialTableState = {
    page: {
        size: 0,
        content: [],
        numberOfElements: 0,
        pageNumber: 0
    },
    totalPages: 0,
    totalElements: 0,
    currentPage: 0,
    loading: true
}

const dataNotFoundTableState = {
    page: {
        size: 0,
        content: [],
        numberOfElements: 0,
        pageNumber: 0
    },
    totalPages: 0,
    totalElements: 0,
    currentPage: 0,
    loading: false
}

export default function useTablePaginatedVoucher(request) {
    const [tabla, setTabla] = useState(initialTableState);
    const [msgError, setMsg] = useState({ show: false, msg: null, type: null });

    useEffect(() => {
        getData(request.page, request.elementos, request)
    }, [])

    const insertStateTable = (data, sizePage) => {
        setTabla({
            page: {
                size: sizePage,
                content: data.content,
                numberOfElements: data.numberOfElements,
                pageNumber: data.number
            },
            totalPages: data.totalPages,
            totalElements: data.totalElements,
            currentPage: data.number,
            loading: false
        });
    }

    const getData = (numPage, sizePage, requestConfig) => {
        let request = { ...requestConfig, elementos: sizePage, page: numPage }
        setTabla(initialTableState)
        if (request.metodo === 'post') {
            try {
                httpRepo.post(request.uri, request.body);
            } catch (error) {
                console.log({ error });
                setTabla(dataNotFoundTableState);
            }

        } else if (request.metodo === 'get') {
            httpRepo.get(request.uri + "?page=" + request.page + "&size=" + request.elementos + "&sort=" + request.sort).then(
                result => {
                    if (result.status === 200) {
                        insertStateTable(result.data, sizePage);
                    }
                }
            ).catch(
                error => {
                    const exception = { error };
                    console.log(exception)
                    if (typeof exception.error.response === 'undefined') {
                        setMsg({ show: true, msg: 'Ocurrió un problema en la red al consultar los datos', type: "error" });
                    }
                    setTabla(dataNotFoundTableState);
                }
            )
        }
    }

    function closeAlert() {
        setMsg({ show: false });
    }


    return {
        getData,
        msgError,
        tabla,
        closeAlert
    }
}