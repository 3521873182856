import { makeStyles } from "@material-ui/core";
import { palette } from "configurations/styles/theme";
export const useStyles = makeStyles({
    form: {
        width: "100%",
    },
    radio: {
        display: "-webkit-box",
        marginTop: "0px",
    },
    radioLable: {
        color: palette.primary.main,
        display: "flex",
    },
    radioButton: {
        marginTop: "0px",
        paddingTop: "0px",
    },
    divForm: {
        marginBottom: "0%",
        textAlign: "justify",
    },
    divInput: {
        marginBottom: "0%",
        paddingRight: "0%",
        paddingLeft: "0%",
        textAlign: "justify",
    },
});