import { Backdrop, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Grid, makeStyles, Typography, TextField, IconButton } from '@material-ui/core';
import { msmSwalError, msmSwalExito, palette, useStylesGlobal } from 'configurations/styles/theme';
import React, { useState } from 'react';
import clientBackend, { httpNotificador } from 'configurations/axios';
import { API_PUT_CREDENTIALS } from '../apis';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    formLogin: {
        borderStyle: "solid",
        borderWidth: "1px",
        padding: "1%",
        borderRadius: "8px",
        marginLeft: "10px",
        marginRigth: "10px",
        borderColor: "#d9d9d9",
    },
    from: {
        width: "100%",
        margin: "1%",
    },
    formControl: {
        marginTop: theme.spacing(0),
        minWidth: 120,
    },
    radio: {
        display: "-webkit-box",
        marginTop: "0px",
    },
    radioLable: {
        color: palette.primary.main,
        display: "flex",
    },
    radioButton: {
        marginTop: "0px",
        paddingTop: "0px",
    },
    divForm: {
        marginBottom: "1%",
    },
    section: {
        margin: "1%",
    },
    divInput: {
        marginBottom: "1%",
        paddingRight: "1%",
        paddingLeft: "0.5%",
    },
    divButton: {
        paddingLeft: "3%",
        paddingRight: "3%",
    },
    large: {
        width: '200px',
        height: '55px',
        marginBottom: '10px'
    },
    space: {
        margin: '10px'
    },
    backdrop: {
        zIndex: theme.zIndex.modal + 999,
        color: palette.primary.main,
    },
}));

const EditCredentialEmailBtn = ({ updateView, data }) => {
    const classes = useStyles();
    const classesGlobal = useStylesGlobal();
    const [openLoader, setOpenLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const [msgError, setMsg] = useState({ show: false, msg: null, type: null });
    const [email, setEmail] = useState({
        cuentaFrom: data.cuentaFrom,
        host: data.host,
        idCredencialesCorreo: data.idCredencialesCorreo,
        password: data.password,
        port: data.port,
        propiedades: data.propiedades,
        username: data.username
    });
    const [error, setError] = useState();

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleSave = async () => {
        setOpenLoader(true);
        await httpNotificador
            .put(API_PUT_CREDENTIALS, email)
            .then((response) => {
                if (response.status === 200) {
                    setOpen(false);
                    updateView();
                    msmSwalExito(
                        "Credenciales de correo actualizada",
                    )
                }
                setOpenLoader(false);
            })
            .catch((exception) => {
                if (exception.response) {
                    if (exception.response.status === 400) {
                        msmSwalError(exception.response.data.apierror ? exception.response.data.apierror.mensaje ? exception.response.data.apierror.mensaje : 'No se pudo registrar la empresa. Contáctese con el administrador del Sistema.' : 'No se pudo registrar la empresa. Contáctese con el administrador del Sistema.')

                    } else if (exception.response.status === 404) {

                        msmSwalError(exception.response.data.apierror ? exception.response.data.apierror.mensaje ? exception.response.data.apierror.mensaje + '. Contáctese con el administrador del Sistema' : 'No se pudo registrar la empresa. Contáctese con el administrador del Sistema.' : 'No se pudo registrar la empresa. Contáctese con el administrador del Sistema.')

                    } else if (exception.response.status === 409) {
                        msmSwalError(exception.response.data.apierror ? exception.response.data.apierror.mensaje ? exception.response.data.apierror.mensaje + '. Contáctese con el administrador del Sistema' : 'La empresa ya existe. Contáctese con el administrador del Sistema' : 'La empresa ya existe. Contáctese con el administrador del Sistema.')
                        setMsg({
                            show: true,
                            msg:
                                "La configuración ya existe. Contáctese con el administrador del Sistema.",
                            type: "error",
                        });
                    } else {
                        msmSwalError(exception.response.data.apierror ? exception.response.data.apierror.mensaje ? exception.response.data.apierror.mensaje + '. Contáctese con el administrador del Sistema' : 'Ocurrió un error interno. Contáctese con el administrador del Sistema.' : 'Ocurrió un error interno. Contáctese con el administrador del Sistema.')
                        setMsg({
                            show: true,
                            msg:
                                "Ocurrió un error interno. Contáctese con el administrador del Sistema.",
                            type: "error",
                        });
                    }
                } else {
                    setMsg({
                        show: true,
                        msg:
                            "Existen problemas en la red. Contáctese con el administrador del Sistema.",
                        type: "error",
                    });
                }
                setOpenLoader(false);
            });
    };

    const handleSubmit = () => {
        setOpenLoader(true)
        handleSave().then(() => {
            setOpenLoader(false)
        })
    }

    const handleChange = (event) => {
        setEmail({
            ...email,
            [event.target.name]: event.target.value
        });

    };

    return (
        <div>

            <IconButton
                aria-label="delete"
                className={classes.IconButton}
                variant="outlined"
                color="secondary"
                onClick={handleClickOpen}
            >
                <EditIcon
                    style={{ color: palette.primary.main }}
                />
            </IconButton>

            <Dialog
                fullWidth
                maxWidth="lg"
                open={open}
                onClose={handleClose}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle id="max-width-dialog-title">Editar Credenciales de Correo</DialogTitle>
                <DialogContent>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                    >
                        <section>
                            <form >
                                <Grid container justify="center" spacing={2}>
                                    <Grid item sm={5} className={classes.formLogin}>
                                        <Grid container spacing={1}>
                                            <Grid item md={12}>
                                                <Typography variant="h6">
                                                    <strong>Registro de credenciales de correo</strong>
                                                </Typography>
                                            </Grid>
                                            <Grid item md={12}>
                                                <Divider></Divider>
                                            </Grid>
                                            <Grid item md={12}>
                                                <label>
                                                    Los campos marcados con ({" "}
                                                    <font color={palette.error.main}> *</font> ) son
                                                    obligatorios:
                                                </label>
                                            </Grid>
                                            <Grid item sm={3}>
                                                <label>
                                                    Nombre: <font color={palette.error.main}> *</font>
                                                </label>
                                            </Grid>
                                            <Grid item sm={9}>
                                                <input
                                                    type="text"
                                                    value={data.nombre}
                                                    required
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item sm={3}>
                                                <label>
                                                    Cuenta From: <font color={palette.error.main}> *</font>
                                                </label>
                                            </Grid>
                                            <Grid item sm={9}>
                                                <input
                                                    type="text"
                                                    value={email.cuentaFrom}
                                                    name="cuentaFrom"
                                                    required
                                                    onChange={handleChange}
                                                    autoComplete="off"
                                                    maxLength='300'
                                                />
                                            </Grid>
                                            <Grid item sm={3}>
                                                <label>
                                                    Host: <font color={palette.error.main}> *</font>
                                                </label>
                                            </Grid>
                                            <Grid item sm={9}>
                                                <input
                                                    type="text"
                                                    onChange={handleChange}
                                                    name="host"
                                                    required
                                                    value={email.host}
                                                    autoComplete="off"
                                                    maxLength='300'

                                                />
                                            </Grid>
                                            <Grid item sm={3}>
                                                <label>
                                                    ID Credenciales: <font color={palette.error.main}> *</font>
                                                </label>
                                            </Grid>
                                            <Grid item sm={9}>
                                                <input
                                                    type="text"
                                                    onChange={handleChange}
                                                    required
                                                    value={email.idCredencialesCorreo}
                                                    name="idCredencialesCorreo"
                                                    autoComplete="off"
                                                    maxLength='300'

                                                />
                                            </Grid>
                                            <Grid item sm={3}>
                                                <label>
                                                    Password: <font color={palette.error.main}> *</font>
                                                </label>
                                            </Grid>
                                            <Grid item sm={9}>
                                                <input
                                                    type="text"
                                                    onChange={handleChange}
                                                    required
                                                    value={email.password}
                                                    name="password"
                                                    autoComplete="off"
                                                    maxLength='300'

                                                />
                                            </Grid>
                                            <Grid item sm={3}>
                                                <label>
                                                    Port: <font color={palette.error.main}> *</font>
                                                </label>
                                            </Grid>
                                            <Grid item sm={9}>
                                                <input
                                                    type="text"
                                                    onChange={handleChange}
                                                    required
                                                    value={email.port}
                                                    name="port"
                                                    autoComplete="off"
                                                    maxLength='300'

                                                />
                                            </Grid>
                                            <Grid item sm={3}>
                                                <label>
                                                    Propiedades: <font color={palette.error.main}> *</font>
                                                </label>
                                            </Grid>
                                            <Grid item sm={9}>
                                                <input
                                                    type="text"
                                                    onChange={handleChange}
                                                    required
                                                    value={email.propiedades}
                                                    name="propiedades"
                                                    autoComplete="off"
                                                    maxLength='300'

                                                />
                                            </Grid>
                                            <Grid item sm={3}>
                                                <label>
                                                    Username: <font color={palette.error.main}> *</font>
                                                </label>
                                            </Grid>
                                            <Grid item sm={9}>
                                                <input
                                                    type="text"
                                                    onChange={handleChange}
                                                    required
                                                    value={email.username}
                                                    name="username"
                                                    autoComplete="off"
                                                    maxLength='300'

                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        </section>
                        <Grid container spacing={3} justify="center">
                            <Grid item md={12} xs={12}>
                                <br />
                                <Divider></Divider>
                            </Grid>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                className={classesGlobal.marginButton}
                            >
                                Editar
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleClose}
                                className={classesGlobal.marginButton}
                            >
                                Cancelar
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Backdrop className={classes.backdrop} open={openLoader}>
                <CircularProgress color="inherit" />
            </Backdrop>

        </div>
    );
}

export default EditCredentialEmailBtn;