import React from "react";
import RangeDataOption from "./RangeDataOption";
import { getRUCEmpresa } from "redux/actions/userActions";
import { useSelector } from "react-redux";
//libreria de consulta de apis
import { httpRepo } from "configurations/axios";
import { useSnackbar } from "notistack";

import { parseDate } from "utils/checkDate";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  input: {
    width: "350px",
  },
  backdrop: {
    zIndex: "3000",
    color: "#fff",
  },
}))


const VoucherReportReceived = () => {
  const tokenUser = useSelector((state) => state.user.tk);
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  var fileSaver = require("file-saver");

  function generateReport(startDate, endDate, optionSelect){
    if (optionSelect.value && startDate && endDate && (parseDate(startDate, 1).valueOf() < parseDate(endDate, 2).valueOf())) {
      let objReport = {};
      objReport = {
        fechaFin: parseDate(endDate, 2),
        fechaInicio: parseDate(startDate, 1),
        rucEmpresa: tokenUser ? getRUCEmpresa(tokenUser) : "",
        tipoComprobante: optionSelect.value,
      };
      getReportIssued(objReport);
    } else {
      if(startDate == null){
        enqueueSnackbar(`Seleccione una fecha inicial`, {
          variant: "info",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          autoHideDuration: 4000,
        });
      }
      if(endDate == null){
        enqueueSnackbar(`Seleccione una fecha final`, {
          variant: "info",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          autoHideDuration: 4000,
        });
      }
      if(optionSelect.value == null){
        enqueueSnackbar(`Seleccione un tipo de comprobante`, {
          variant: "info",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          autoHideDuration: 4000,
        });
      }
      if(parseDate(startDate, 1).valueOf() > parseDate(endDate, 2).valueOf()){
        enqueueSnackbar(`Ingreso de rango de fechas incorrecto `, {
          variant: "info",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          autoHideDuration: 4000,
        });
      }
    }
  }
  // function generateReport(startDate, endDate, optionSelect) {
  //   if (optionSelect.value) {
  //     let objReport = {};
  //     objReport = {
  //       fechaFin: parseDate(endDate, 2),
  //       fechaInicio: parseDate(startDate, 1),
  //       rucEmpresa: tokenUser ? getRUCEmpresa(tokenUser) : "",
  //       tipoComprobante: optionSelect.value,
  //     };
  //     getReportIssued(objReport);
  //   } else {
  //     enqueueSnackbar(`Seleccione un tipo de comprobante`, {
  //       variant: "info",
  //       anchorOrigin: {
  //         vertical: "top",
  //         horizontal: "right",
  //       },
  //       autoHideDuration: 4000,
  //     });
  //   }
  // }

  const getReportIssued = async (objReport) => {
    setOpen(true);
    await httpRepo
      .post("reporte/comprobantes/recibidos", objReport, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        if (response.status === 200) {
          var blob = new Blob([response.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          fileSaver.saveAs(blob, "Reporte.xlsx");
          setOpen(false);
        }
      })
      .catch(() => {
        enqueueSnackbar(`No existen datos para los filtros seleccionados`, {
          variant: "info",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        setOpen(false);
      });
  };

  return (
    <div>
      <Backdrop open={open} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <RangeDataOption
        generateReport={generateReport}
        title={"Reporte de Comprobantes Recibidos"}
      ></RangeDataOption>
    </div>
  );
};

export default VoucherReportReceived;
