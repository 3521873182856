import checkDocument from "../../../utils/checkDocument";
import checkInputs from "../../../utils/checkInputs";

const validations = {
  editUser: function validationEditUser(updatedUser) {
    const errors = {};

    if (!checkInputs.notNull(updatedUser.nombreUsuario)) {
      errors.nombreUsuario = "El es nombre necesario.";
    } else if (!checkInputs.onlyLetters(updatedUser.nombreUsuario)) {
      errors.nombreUsuario = "Los nombres solo contienen letras.";
    } else if (updatedUser.nombreUsuario.length > 300) {
      errors.nombreUsuario = "No ingrese más de 300 caracteres.";
    }

    if (checkInputs.notNull(updatedUser.telefonoPrincipalUsuario)) {
      if (!checkInputs.isAlphanumeric(updatedUser.telefonoPrincipalUsuario)) {
        errors.telefonoPrincipalUsuario =
          "No puede contener caracteres especiales, solo letras o números.";
      } else if (updatedUser.telefonoPrincipalUsuario.length > 300) {
        errors.telefonoPrincipalUsuario = "No ingrese más de 300 caracteres.";
      }
    }

    if (!checkInputs.notNull(updatedUser.correoPrincipalUsuario)) {
      errors.correoPrincipalUsuario = "El correo es necesario.";
    } else if (
      !checkInputs.validationEmail(updatedUser.correoPrincipalUsuario)
    ) {
      errors.correoPrincipalUsuario =
        "El correo es incorrecto. Debe contener el usuario, @ y el dominio.";
    } else if (updatedUser.correoPrincipalUsuario.length > 300) {
      errors.correoPrincipalUsuario = "No ingrese más de 300 caracteres.";
    }

    if (checkInputs.notNull(updatedUser.direccionUsuario)) {
      if (!checkInputs.isAlphanumeric(updatedUser.direccionUsuario)) {
        errors.direccionUsuario = "No ingrese caracteres especiales.";
      } else if (updatedUser.direccionUsuario.length > 300) {
        errors.direccionUsuario = "No ingrese más de 300 caracteres.";
      }
    }

    if (!checkInputs.notNull(updatedUser.estadoUsuario)) {
      errors.estadoUsuario = "Seleccione un estado de usuario.";
    }

    return errors;
  },
  addUser: function validationAddUser(newUser) {
    const errors = {};

    if (!checkInputs.notNull(newUser.identificacionUsuario)) {
      errors.identificacionUsuario = "Ingrese la identificación del usuario.";
    } else if (newUser.typeDocumente === "C") {
      if (!checkDocument.validarCedula(newUser.identificacionUsuario)
        // !checkDocument.validarCedula(newUser.identificacionUsuario) &&
        // !checkDocument.validarRucPersonaNatural(
        //   newUser.identificacionUsuario
        // ) &&
        // !checkDocument.validarRucSociedadPrivada(
        //   newUser.identificacionUsuario
        // ) &&
        // !checkDocument.validarRucSociedadPublica(newUser.identificacionUsuario)
      ) {
        errors.identificacionUsuario = "Cédula o RUC inválido.";
      }
      // } else if (!checkInputs.isAlphanumeric(newUser.identificacionUsuario)) {
      //   errors.identificacionUsuario =
      //     "No puede contener caracteres especiales, solo letras o números.";
    } else if (newUser.identificacionUsuario.length > 300) {
      errors.identificacionUsuario = "No ingrese más de 300 caracteres.";
    }

    if (!checkInputs.notNull(newUser.nombreUsuario)) {
      errors.nombreUsuario = "El nombre del usuario es necesario.";
    } else if (!checkInputs.notNull(newUser.nombreUsuario)) {
      errors.nombreUsuario = "El nombre del usuario es necesario.";
    } else if (!checkInputs.onlyLetters(newUser.nombreUsuario)) {
      errors.nombreUsuario = "Solo ingrese letras.";
    } else if (newUser.nombreUsuario.length > 300) {
      errors.nombreUsuario = "No ingrese más de 300 caracteres.";
    }

    if (!checkInputs.notNull(newUser.correoPrincipalUsuario)) {
      errors.correoPrincipalUsuario = "El correo del usuario es necesario.";
    } else if (!checkInputs.notNull(newUser.correoPrincipalUsuario)) {
      errors.correoPrincipalUsuario = "El correo del usuario es necesario.";
    } else if (!checkInputs.validationEmail(newUser.correoPrincipalUsuario)) {
      errors.correoPrincipalUsuario =
        "El correo del usuario es erróneo. Ingrese un correo con el formato user@ejemplo.com";
    } else if (newUser.correoPrincipalUsuario.length > 300) {
      errors.correoPrincipalUsuario = "No ingrese más de 300 caracteres.";
    }

    if (checkInputs.notNull(newUser.telefonoPrincipalUsuario)) {
      if (!checkInputs.isAlphanumeric(newUser.telefonoPrincipalUsuario)) {
        errors.telefonoPrincipalUsuario =
          "No puede contener caracteres especiales, solo letras o números.";
      } else if (newUser.telefonoPrincipalUsuario.length > 300) {
        errors.telefonoPrincipalUsuario = "No ingrese más de 300 caracteres.";
      }
    }

    if (checkInputs.notNull(newUser.direccionUsuario)) {
      if (!checkInputs.isAlphanumeric(newUser.direccionUsuario)) {
        errors.direccionUsuario = "No ingrese caracteres especiales.";
      } else if (newUser.direccionUsuario.length > 300) {
        errors.direccionUsuario = "No ingrese más de 300 caracteres.";
      }
    }
    return errors;
  },
};

export default validations;
