import React, { Fragment, useState } from "react";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Grid,
  Button,
  Backdrop,
  CircularProgress,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  Divider,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
//Notificacion errores https://iamhosseindhv.com/notistack/demos#variants
import { useSnackbar } from "notistack";
//componentes del sistema
import { API_PUT_UPDATE_PRODUCTS } from "../apis";
import {
  palette,
  useStylesGlobal,
  msmSwalInformacion,
} from "configurations/styles/theme";
import clientBackend from "configurations/axios";
import Alert from "widgets/alert";
import useForm from "hooks/useFrom";
import validations from "../validations";
import { useStylesApp } from "hooks/useStyles";
import { roundTwoDecimal } from "utils/parseInput";
import { countDecimals } from "utils/checkInputs";

const useStyles = makeStyles((theme) => ({
  IconButton: {
    marginTop: "0%",
    marginBottom: "0%",
    padding: "0px",
  },
  textFieldComboBox: {
    minWidth: "75%",
    maxWidth: "95%",
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: palette.primary.main,
  },
  formControl: {
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  formControl: {
    marginTop: theme.spacing(0),
    minWidth: 120,
  },
  radio: {
    display: "-webkit-box",
    marginTop: "0px",
  },
  radioLable: {
    color: palette.primary.main,
    display: "flex",
  },
  radioButton: {
    marginTop: "0px",
    paddingTop: "0px",
  },
}));

const formatProductObj = (product) => {
  if (typeof product === "object") {
    if (countDecimals(product.precioUnitario) < 2) {
      product.precioUnitario = roundTwoDecimal(product.precioUnitario);
    }
  }
  return product;
};

/**
 * @author {Dany_Lasso}
 * @param {Object} product
 */
const EditProductBtn = ({ product, updateView }) => {
  const [open, setOpen] = useState(false);
  const classesGlobal = useStylesGlobal();
  const [updateProduct, serUpdateProduct] = useState(product);
  const [openLoader, setOpenLoader] = useState(false);
  const classes = useStyles();
  const classesApp = useStylesApp();
  const { enqueueSnackbar } = useSnackbar();
  const [msgError, setMsg] = useState({ show: false, msg: null, type: null });
  const [stateProduct, setStateProduct] = useState(product.estado);

  const existError = () => {};

  const handleSave = async () => {
    setOpenLoader(true);

    await clientBackend
      .put(
        API_PUT_UPDATE_PRODUCTS + updateProduct.idProducto,
        formatProductObj(updateProduct)
      )
      .then((response) => {
        enqueueSnackbar("Producto/Servicio editado.", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          autoHideDuration: 10000,
        });
        updateView();
        setOpenLoader(false);
        setOpen(false);
      })
      .catch((exception) => {
        if (exception.response) {
          if (exception.response.status === 400) {
            setMsg({
              show: true,
              msg: "No se pudo actualizar el producto.",
            });
          } else if (exception.response.status === 404) {
            setMsg({
              show: true,
              msg: "No se encontró el producto que se va a actualizar.",
            });
          } else {
            setMsg({
              show: true,
              msg: "Error al actualizar el producto. Contáctese con el administrador del Sistema.",
            });
          }
        } else {
          setMsg({
            show: true,
            msg: "Existen problemas en la red. Contáctese con el administrador del Sistema.",
          });
        }
        setOpenLoader(false);
      });
  };

  const { errors, handleSubmit, getObject } = useForm(
    handleSave,
    validations.editProduct,
    existError
  );

  const handleClickOpen = () => {
    setOpen(true);
    serUpdateProduct(formatProductObj(product));
    setStateProduct(product.estado);
    getObject(product);
    errors.codigoPrincipal = null;
    errors.codigoAuxiliar = null;
    errors.descripcion = null;
    errors.precioUnitario = null;
    setMsg({ show: false, msg: null, type: null });
  };

  const handleClose = () => {
    setOpen(false);
  };

  function closeAlert() {
    setMsg({ show: false });
  }

  const handleChangeUser = (event) => {
    serUpdateProduct({
      ...updateProduct,
      [event.target.name]: event.target.value,
    });

    getObject({
      ...updateProduct,
      [event.target.name]: event.target.value,
      estado: stateProduct,
    });
  };

  const handleChangeStateProduct = (event) => {
    setStateProduct(event.target.value);
    const stateProduct = event.target.value === "A" ? true : false;
    if (!stateProduct) {
      msmSwalInformacion(
        "¿Estás seguro de inhabilitar el registro? Para habilitarlo nuevamente, busca al final de la tabla el registro y utiliza la opción de editar."
      );
    }
    getObject({
      ...updateProduct,
      estado: stateProduct,
    });
    serUpdateProduct({
      ...updateProduct,
      estado: stateProduct,
    });
  };

  const renderStateProduct = () => {
    return (
      <Fragment>
        <FormControl component="fieldset" className={classes.formControl}>
          <RadioGroup
            className={classes.radio}
            value={updateProduct.estado ? "A" : "I"}
            onChange={handleChangeStateProduct}
          >
            <FormControlLabel
              value="A"
              control={
                <Radio color="primary" className={classes.radioButton} />
              }
              label="Activo"
              labelPlacement="start"
              className={classes.radioLable}
            />
            <FormControlLabel
              value="I"
              control={
                <Radio color="primary" className={classes.radioButton} />
              }
              label="Inactivo"
              labelPlacement="start"
              className={classes.radioLable}
            />
          </RadioGroup>
        </FormControl>
        {errors.estado ? (
          <p className={classesApp.errorLetters}>{errors.estado}</p>
        ) : null}
      </Fragment>
    );
  };

  if (!open) {
    return (
      <IconButton
        color="primary"
        onClick={handleClickOpen}
        className={classes.IconButton}
      >
        <EditIcon style={{ color: palette.primary.main }} />
      </IconButton>
    );
  }

  return (
    <Fragment>
      <IconButton
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={handleClickOpen}
        className={classes.IconButton}
      >
        <EditIcon style={{ color: palette.primary.main }} />
      </IconButton>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="max-width-dialog-title">
          Editar Producto / Servicio
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <label>
                  Los campos marcados con ({" "}
                  <font color={palette.error.main}> *</font> ) son obligatorios:
                </label>
                {msgError.show ? (
                  <Alert
                    tipo={msgError.type}
                    mensaje={msgError.msg}
                    mesajeSubrayado={null}
                    titulo={null}
                    mostrar={msgError.show}
                    cerrarAlerta={closeAlert}
                  />
                ) : null}
              </Grid>
              <Grid item xs={12} md={2}>
                Código principal: <font color={palette.error.main}> *</font>
              </Grid>
              <Grid item xs={12} md={3}>
                <input
                  value={updateProduct.codigoPrincipal}
                  className="form-control is-valid"
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={6}></Grid>
              <Grid item xs={12} md={2}>
                Código auxiliar:
              </Grid>
              <Grid item xs={12} md={3} className={classes.divInfoUser}>
                <input
                  type="text"
                  className={
                    errors.codigoAuxiliar
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  value={updateProduct.codigoAuxiliar}
                  name="codigoAuxiliar"
                  onChange={handleChangeUser}
                  autoComplete="off"
                />
                {errors.codigoAuxiliar ? (
                  <p className={classesApp.errorLetters}>
                    {errors.codigoAuxiliar}
                  </p>
                ) : null}
              </Grid>
              <Grid item xs={12} md={2}>
                Descripción: <font color={palette.error.main}> *</font>
              </Grid>
              <Grid item xs={12} md={5}>
                <input
                  type="text"
                  className={
                    errors.descripcion
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  value={updateProduct.descripcion}
                  name="descripcion"
                  onChange={handleChangeUser}
                  autoComplete="off"
                />
                {errors.descripcion ? (
                  <p className={classesApp.errorLetters}>
                    {errors.descripcion}
                  </p>
                ) : null}
              </Grid>
              <Grid item xs={12} md={2} className={classes.divlabel}>
                Precio Unitario: <font color={palette.error.main}> *</font>
              </Grid>
              <Grid item xs={12} md={3} className={classes.divInfoUser}>
                <input
                  type="number"
                  className={
                    errors.precioUnitario
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  value={updateProduct.precioUnitario}
                  name="precioUnitario"
                  onChange={handleChangeUser}
                  autoComplete="off"
                />
                {errors.precioUnitario ? (
                  <p className={classesApp.errorLetters}>
                    {errors.precioUnitario}
                  </p>
                ) : null}
              </Grid>
              <Grid item xs={12} md={2} className={classes.divlabel}>
                Estado: <font color={palette.error.main}> *</font>
              </Grid>
              <Grid item xs={12} md={5} className={classes.divInfoUser}>
                {renderStateProduct()}
              </Grid>
              <Grid container spacing={3} justify="center">
                <Grid item md={12} xs={12}>
                  <Divider></Divider>
                </Grid>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={classesGlobal.marginButton}
                >
                  Guardar
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleClose}
                  className={classesGlobal.marginButton}
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      <Backdrop className={classes.backdrop} open={openLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
};

export default EditProductBtn;
