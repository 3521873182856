import Swal from "sweetalert2";
import { palette } from "configurations/styles/theme";

/**
 * Determine whether the given `promise` is a Promise.
 *
 * @param {*} promise
 *
 * @returns {Boolean}
 */
function isPromise(promise) {
  return !!promise && typeof promise.then === "function";
}

export const iconsAlert = {
  success: "success",
  error: "error",
  warning: "warning",
  info: "info",
  question: "question",
};

export const ConfirmAlert = (
  title = "",
  text = "",
  icon = iconsAlert.warning,
  showCancelButton = true,
  confirmButtonText = "Sí",
  cancelButtonText = "Cancelar",
  callbackConfirmedAction,
  callbackDenied
) => {
  Swal.fire({
    title: title,
    text: text,
    icon: icon,
    showCancelButton: showCancelButton,
    confirmButtonColor: palette.info.main,
    cancelButtonColor: palette.error.light,
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText,
  })
    .then((result) => {
      if (result.isConfirmed) {
        if (isPromise(callbackConfirmedAction)) {
          return callbackConfirmedAction();
        } else {
          return Promise.resolve(callbackConfirmedAction());
        }
      } else if (result.isDismissed) {
        if (typeof callbackDenied === "function") {
          callbackDenied();
        }
        return Promise.resolve({ show: false });
      }
    })
    .then(
      ({
        text ,
        icon ,
        show ,
      } = {text: "Se eliminó con éxito.", icon: iconsAlert.success, show : true }) => {
        if (show) {
         
          if (typeof text === "string" && typeof icon === "string")
            Swal.fire({
              text,
              icon,
            });
        } else {
        }
      }
    )
    .catch((error = "Ocurio un Error", icon = iconsAlert.error) =>
      Swal.fire(error, "", icon)
    );
};


export const ErrorsAlert = (errors = {}, title = "") => {

  const lstErros = Object.values(errors);
  if(lstErros.length > 0){
    let htmlErros = `<div ><ul>`;
    lstErros.forEach(item => htmlErros  += `<li style="text-align: initial;">${item}</li>`)
    htmlErros += `</ul></div>`
    Swal.fire({
      title: title,
      html: htmlErros,
      icon:"warning"
    });
  }
}