import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, Backdrop, CircularProgress, makeStyles, withStyles, InputBase, Grid, TextField, Input, Divider, FormControl, NativeSelect } from '@material-ui/core';
import { palette, useStylesGlobal } from 'configurations/styles/theme';
import { useStylesApp } from 'hooks/useStyles';
import { retentionCode, typeTax, typeVouchers, retentionCodeIva, retentionCodeIcd, typeInvoice } from 'views/facturacion/Forms/typeVoucher';
import { Autocomplete } from '@material-ui/lab';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import bigDecimal from 'js-big-decimal';
import MaskedInput from 'react-text-mask';
import { useSnackbar } from 'notistack';
import uuid from 'react-uuid';
import InputMask from "react-input-mask";
import checkInputs from 'utils/checkInputs';
import { validationAddRetention } from '../../validate';

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginTop: theme.spacing(3),
        minWidth: 120,
    },
    radio: {
        display: '-webkit-box',
        marginTop: '0px'
    },
    radioLable: {
        color: palette.primary.main,
        display: 'flex'
    },
    radioButton: {
        marginTop: '0px',
        paddingTop: '0px'
    },
    divForm: {
        marginBottom: '1%',
    },
    divInput: {
        marginBottom: '1%',
        paddingRight: '1%',
        paddingLeft: '0.5%'
    },
    divButton: {
        paddingLeft: '3%',
        paddingRight: '3%',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: '1%',
        marginBottom: '1%',
    },
    backdrop: {
        zIndex: theme.zIndex.modal + 999,
        color: palette.primary.main,
    },
}));

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(0),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}

const AddTax = ({ impuestoRetencions, setImpuestoRetencions, viewDetalle, setViewDetalle, taxDetail, setTaxDetail }) => {

    const classes = useStyles();
    const classesApp = useStylesApp();
    const classesGlobales = useStylesGlobal();
    const { enqueueSnackbar } = useSnackbar();
    const [minDate, setMinDate] = useState(new Date());
    const [minDate1, setMinDate1] = useState(minDate.setDate(minDate.getDate() - 30))
    const [open, setOpen] = useState(false);
    const [openLoader, setOpenLoader] = useState(false);
    const [retention, setRetention] = useState({
        CODIGO: '',
        VALOR: 0,
        NOMBRE: ''
    });
    const [tax, setTax] = useState();
    const [retainedValue, setRetainedValue] = useState();
    const [errors, setErrors] = useState({});
    const [fechaEmisionDocSustento, setFechaEmisionDocSustento] = useState(new Date());
    const [docSustento, setDocSustento] = useState();
    const [dataTax, setDataTax] = useState();
    const [retentionTax, setRetentionTax] = useState({
        baseImponible: 0,
        codigoDocSustento: "",
        codigoImpuesto: "",
        codigoRetencion: "",
        fechaEmisionDocSustento: fechaEmisionDocSustento,
        numeroDocSustento: "",
        porcentajeARetener: 0,
    })
    const [empty, setEmty] = useState({
        impuesto: true,
        retencion: true,
        codigo: true,
        base: true,
        documento: true,
        numero: true,
        fecha: true,
    });

    const handleClickOpen = () => {
        setOpen(true);
        //setUser({ idEmpresa: getRUCEmpresa(userToken) });
    };

    const handleClose = () => {
        setOpen(false);
        clearTax();
    };


    const handleChange = event => {
        setRetentionTax({ ...retentionTax, ['baseImponible']: event.target.value })

        let retainedValue = bigDecimal.multiply(event.target.value, bigDecimal.divide(retention.VALOR, '100'));
        setRetainedValue(bigDecimal.round(retainedValue, 2, bigDecimal.RoundingModes.HALF_UP));
    }

    const validateRetentionData = (data) => {
        let newErros = validationAddRetention.onSubmit(
            {
                base: data.base,
                documento: data.documento,
                impuesto: data.impuesto,
                retencion: data.retencion,
                numero: data.numero,
                codigo: data.codigo,
            }
        );
        if (Object.entries(newErros).length > 0) {
            console.log(newErros)
            setErrors(newErros)
            return false;
        } else {
            setErrors(newErros)
            return true;
        }
    }

    const handleChangeData = event => {
        event.preventDefault();
        const re = /^[0-9\b.]+$/;
        switch (event.target.name) {
            case 'baseImponible':
                if (event.target.value === '' || re.test(event.target.value)) {
                    if (Number(event.target.value.trim()) >= 0) {
                        if (checkInputs.countDecimals(event.target.value) <= 2 && checkInputs.countDigit(event.target.value) <= 14) {
                            setRetentionTax({ ...retentionTax, ['baseImponible']: event.target.value })
                            let retainedValue = bigDecimal.multiply(event.target.value, bigDecimal.divide(retention ? retention.VALOR ? retention.VALOR : '0' : '0', '100'));
                            setRetainedValue(bigDecimal.round(retainedValue, 2, bigDecimal.RoundingModes.HALF_UP));
                            if (typeof event.target.value !== 'undefined' && event.target.value.toString().trim() !== '' && Number(event.target.value !== 0)) {
                                setEmty({ ...empty, ['base']: true })
                            }
                        }
                    }
                }

                break;
            case 'VALOR':
                if (event.target.value === '' || re.test(event.target.value)) {
                    if (Number(event.target.value.trim()) >= 0) {
                        if (checkInputs.countDecimals(event.target.value) <= 2 && checkInputs.countDigit(event.target.value) <= 3) {
                            setRetention({
                                ...retention,
                                ['VALOR']: event.target.value,
                            })

                            let retainedValue = bigDecimal.multiply(retentionTax.baseImponible, bigDecimal.divide(event.target.value, '100'));
                            setRetainedValue(bigDecimal.round(retainedValue, 2, bigDecimal.RoundingModes.HALF_UP));

                            if (typeof event.target.value !== 'undefined' && event.target.value.toString().trim() !== '' && Number(event.target.value) !== 0) {
                                setEmty({ ...empty, ['codigo']: true })
                            }
                        }
                    }
                }

                break;
            default:
                break;
        }




    }

    const calculateRetainedValue = (base, porcent) => {
        if (validateDecimal(base.toString())) {
            let retainedValue = bigDecimal.multiply(base, bigDecimal.divide(porcent ? porcent : '0', '100'));
            setRetainedValue(bigDecimal.round(retainedValue, 2, bigDecimal.RoundingModes.HALF_UP));
        }
    }

    const handleChangeDocSustento = event => {
        event.preventDefault();
        //const re = /^[0-9\b-]{1,17}$/;
        //if (event.target.value === '' || re.test(event.target.value)) {
        setRetentionTax({
            ...retentionTax,
            [event.target.name]: event.target.value
        })
        if (typeof retentionTax.numeroDocSustento !== 'undefined' && retentionTax.numeroDocSustento.trim() !== '' && retentionTax.numeroDocSustento.replace(/\D/g, '').length === 14) {
            setEmty({ ...empty, ['numero']: true })
        }
        //}
    }


    const handleAddTax = () => {
        let codigoImpuesto = tax ? tax.CODIGO : null
        if (validateRetentionData({
                base: retentionTax.baseImponible,
                documento: retentionTax.codigoDocSustento,
                impuesto: codigoImpuesto,
                retencion: retentionTax.codigoRetencion,
                numero: retentionTax.numeroDocSustento,
                codigo: retention.VALOR,
        })) {

        if(validateTax()){
            let uuId = uuid();
            setTaxDetail([...taxDetail, {
                id: uuId,
                codigoImpuesto: tax.CODIGO,
                codigoRetencion: retentionTax.codigoRetencion,
                codigoDocSustento: retentionTax.codigoDocSustento,
                porcentaje: retention.VALOR,
                numeroDocSustento: retentionTax.numeroDocSustento,
                baseImponible: retentionTax.baseImponible,
                valorRetenido: retainedValue,
                fechaEmisionDocSustento: fechaEmisionDocSustento

            }])

            setImpuestoRetencions([...impuestoRetencions, {
                ...retentionTax,
                id: uuId,
                porcentajeARetener: retention.VALOR,
                fechaEmisionDocSustento: fechaEmisionDocSustento
            }]);

            if (viewDetalle === 1) {
                setViewDetalle(2)
            } else if (viewDetalle === 2) {
                setViewDetalle(1)
            }

            handleClose();
            clearTax();
            enqueueSnackbar(`Impuesto agregado al comprobante`, {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                autoHideDuration: 4000,
            });
        
        }
    }
    }

    const validateTax = () => {
        let state = true;
        if (typeof tax === 'undefined' || tax === '') {
            setErrors({ ...errors, ['impuesto']: "Debe seleccionar un impuesto" })
            return false;
        } else if (typeof retention === 'undefined' || retention.CODIGO.trim() === '-' || retention.CODIGO.trim() === '') {
            setErrors({ ...errors, ['retencion']: "Debe seleccionar un código de retención" })
            return false;
        } else if (typeof retention === 'undefined' || Number(retention.VALOR.toString().trim()) < 0 || retention.VALOR.toString().trim() === '') {
            setErrors({ ...errors, ['codigo']: "Debe ingresar un valor" })
            return false;
        } else if (typeof retentionTax.baseImponible === 'undefined' || retentionTax.baseImponible.toString().trim() === '' || !validateDecimal(retentionTax.baseImponible.toString())) {
            setErrors({ ...errors, ['base']: "Ingrese un dato válido" })
            return false;
        } else if (typeof retentionTax.codigoDocSustento === 'undefined' || retentionTax.codigoDocSustento.trim() === '') {
            setErrors({ ...errors, ['documento']: "Seleccione el tipo de documento sustento" })
            return false;
        } else if (typeof retentionTax.codigoDocSustento !== 'undefined' && retentionTax.codigoDocSustento.trim() !== '' && retentionTax.codigoDocSustento !== '12') {
            if (typeof retentionTax.numeroDocSustento === 'undefined' || retentionTax.numeroDocSustento.trim() === '' || retentionTax.numeroDocSustento.replace(/\D/g, '').length < 15) {
                setErrors({ ...errors, ['numero']: "Ingrese el número de documento sustento válido" })
                return false;
            } else {
                return true;
            }
        } else if (typeof fechaEmisionDocSustento === 'undefined' || fechaEmisionDocSustento === '') {
            setErrors({ ...errors, ['fecha']: "Seleccione la fecha" })
            return false;
        } else {
            return state;
        }

    }

    const clearTax = () => {
        setRetentionTax({
            baseImponible: 0,
            codigoDocSustento: "",
            codigoImpuesto: "",
            codigoRetencion: "",
            fechaEmisionDocSustento: fechaEmisionDocSustento,
            numeroDocSustento: ""
        })
        setRetention({
            CODIGO: '',
            VALOR: 0,
            NOMBRE: ''
        });
        setRetainedValue();
        setDocSustento();
        setTax();
        setEmty({
            impuesto: true,
            retencion: true,
            codigo: true,
            base: true,
            documento: true,
            numero: true,
            fecha: true,
        })
        setFechaEmisionDocSustento(new Date())
    }

    const validateDecimal = (numberValor) => {
        let splitList = numberValor.split('.');
        if (splitList.length > 2) {
            enqueueSnackbar(`El dato ingresado es erroneo utilice punto (.) para separar decimales`, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                autoHideDuration: 4000,
            });
            return false;
        } else if (splitList.length > 1) {
            if (splitList[1].length > 2) {
                enqueueSnackbar(`Los datos numéricos debe tener máximo 2 decimales Ej, 12.43`, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    autoHideDuration: 4000,
                });
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }

    }

    useEffect(() => {

    }, [tax])

    return (
        <>
            <Grid container justify='center' className={classes.divButton} >
                <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                    Agregar Impuesto
            </Button>
            </Grid>
            <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle id="max-width-dialog-title">Nuevo Impuesto</DialogTitle>
                <DialogContent>
                    <form onSubmit={event => { setOpenLoader(true); }}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" >
                            <Grid item xs={12} md={12}>
                                <label>Los campos marcados con (  <font color={palette.error.main}> *</font> ) son obligatorios:</label>
                            </Grid>

                            <Grid item xs={12} md={4} className={classes.divForm}>
                                <label>Código impuesto: <font color={palette.error.main}>*</font></label>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Autocomplete
                                    id="combo-box-demo"
                                    size='medium'
                                    value={tax}
                                    options={typeTax}
                                    noOptionsText="No se encontró datos con el argumento ingresado"
                                    onChange={(event, newValue) => {
                                        setDataTax();
                                        if (newValue) {
                                            if (newValue.CODIGO === '1') {
                                                setDataTax(retentionCode)
                                            } else if (newValue.CODIGO === '2') {
                                                setDataTax(retentionCodeIva)
                                            } else if (newValue.CODIGO === '6') {
                                                setDataTax(retentionCodeIcd)
                                            }
                                        } else {
                                            setDataTax()
                                        }
                                        setTax(newValue);
                                        setRetentionTax({ ...retentionTax, ['codigoImpuesto']: newValue ? newValue.CODIGO ? newValue.CODIGO : '' : '' })
                                        setEmty({ ...empty, ['impuesto']: true })
                                        setRetention({
                                            CODIGO: '',
                                            VALOR: '',
                                            NOMBRE: ''
                                        });
                                        setRetainedValue();
                                    }}
                                    getOptionLabel={(option) => `${option.CODIGO}-${option.NOMBRE}`}
                                    //style={{ width: 700 }}
                                    renderInput={(params) => <TextField {...params} className='form-control' variant="outlined" placeholder="Seleccione" />}
                                />
                
                                {errors.impuesto ? (
                                            <p className={classesApp.errorLetters}>
                                                {errors.impuesto}
                                            </p>
                                        ) : null}
                            </Grid>

                            <Grid item xs={12} md={4} className={classes.divForm}>
                                <label>Código retención: <font color={palette.error.main}>*</font></label>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                {dataTax ?
                                    <Autocomplete
                                        id="combo-box-demo"
                                        size='medium'
                                        value={retention}
                                        options={dataTax}
                                        noOptionsText="No se encontró datos con el argumento ingresado"
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                setRetention(newValue);
                                            } else {
                                                setRetention({
                                                    CODIGO: '',
                                                    VALOR: '',
                                                    NOMBRE: ''
                                                });
                                            }
                                            setRetentionTax({ ...retentionTax, ['codigoRetencion']: newValue ? newValue.CODIGO ? newValue.CODIGO : '' : '' })
                                            calculateRetainedValue(retentionTax ? retentionTax.baseImponible ? retentionTax.baseImponible : 0 : 0, newValue ? newValue.VALOR ? newValue.VALOR : 0 : 0)
                                            setEmty({ ...empty, ['retencion']: true })
                                        }}
                                        getOptionLabel={(option) => `${option.CODIGO}-${option.NOMBRE}`}
                                        renderInput={(params) => <TextField {...params} className='form-control' variant="outlined" placeholder="Seleccione" />}
                                    />
                                    : null

                                }

                                {errors.retencion ? (
                                            <p className={classesApp.errorLetters}>
                                                {errors.retencion}
                                            </p>
                                        ) : null}
                            </Grid>

                            <Grid item xs={12} md={4} className={classes.divForm}>
                                <label>Porcentaje a Retener: <font color={palette.error.main}>*</font></label>
                            </Grid>
                            <Grid fullWidth item xs={12} md={8} >
                                <input className={retention ? retention.VALOR ? "form-control  is-valid" : "form-control" : "form-control "}
                                    type="text"
                                    placeholder="Ej. 10"
                                    name="VALOR"
                                    value={retention ? retention.VALOR : ''}
                                    onChange={event => handleChangeData(event)}
                                    autoComplete="off"
                                />
                                {errors.codigo ? (
                                            <p className={classesApp.errorLetters}>
                                                {errors.codigo}
                                            </p>
                                        ) : null}
                            </Grid>

                            <Grid item xs={12} md={4} className={classes.divForm}>
                                <label>Base Imponible: <font color={palette.error.main}>*</font></label>
                            </Grid>
                            <Grid fullWidth item xs={12} md={8} >
                                <input className={retentionTax ? retentionTax.baseImponible !== 0 && retentionTax.baseImponible.trim() !== '' ? "form-control  is-valid" : 'form-control' : 'form-control'}
                                    type="text"
                                    placeholder="Ej. 123"
                                    name="baseImponible"
                                    value={retentionTax.baseImponible}
                                    onChange={event => handleChangeData(event)}
                                    autoComplete="off"
                                />
                                {errors.base ? (
                                            <p className={classesApp.errorLetters}>
                                                {errors.base}
                                            </p>
                                        ) : null}
                            </Grid>

                            <Grid item xs={12} md={4} className={classes.divForm}>
                                <label>Valor Retenido: <font color={palette.error.main}>*</font></label>
                            </Grid>
                            <Grid fullWidth item xs={12} md={8} >
                                <input className={retainedValue ? "form-control  is-valid" : 'form-control'}
                                    type="text"
                                    placeholder="Ej. 123"
                                    disabled
                                    name="valor"
                                    value={retainedValue ? retainedValue : ''}
                                    //onChange={handleChange}
                                    autoComplete="off"
                                />
                            </Grid>

                            <Grid item xs={12} md={4} className={classes.divForm}>
                                <label>Código documento Sustento: <font color={palette.error.main}>*</font></label>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                {<Autocomplete
                                    id="combo-box-demo"
                                    size='medium'
                                    value={docSustento}
                                    options={typeInvoice}
                                    onChange={(event, newValue) => {
                                        setEmty({
                                            ...empty, ['documento']: true,
                                            ['numero']: true
                                        })
                                        setDocSustento(newValue);
                                        setRetentionTax({ ...retentionTax, ['codigoDocSustento']: newValue ? newValue.CODIGO ? newValue.CODIGO : '' : '' })
                                    }}
                                    getOptionLabel={(option) => `${option.CODIGO}-${option.NOMBRE}`}
                                    renderInput={(params) => <TextField {...params} className='form-control' variant="outlined" placeholder="Seleccione" />} />}

                                {errors.documento ? (
                                            <p className={classesApp.errorLetters}>
                                                {errors.documento}
                                            </p>
                                        ) : null}
                            </Grid>



                            <Grid item xs={12} md={4} className={classes.divForm}>
                                <label>Número Doc. Sustento: <font color={palette.error.main}>*</font></label>
                            </Grid>
                            <Grid fullWidth item xs={12} md={8} >
                                <InputMask className={retentionTax ? retentionTax.numeroDocSustento !== '' ? "form-control  is-valid" : "form-control" : "form-control"}
                                    type="text"
                                    placeholder="Ej. 001-001-000000321"
                                    mask="999-999-999999999"
                                    name="numeroDocSustento"
                                    value={retentionTax ? retentionTax.numeroDocSustento ? retentionTax.numeroDocSustento : '' : ''}
                                    onChange={handleChangeDocSustento}
                                    //inputComponent={TextMaskCustom}
                                    autoComplete="off"
                                />

                                    {errors.numero ? (
                                            <p className={classesApp.errorLetters}>
                                                {errors.numero}
                                            </p>
                                        ) : null}
                            </Grid>

                            <Grid item xs={12} md={4} className={classes.divForm}>
                                <label>Fecha Emisión Doc. Sustento: <font color={palette.error.main}>*</font></label>
                            </Grid>
                            <Grid item xs={12} md={8} >
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                    <DatePicker
                                        className={fechaEmisionDocSustento ? "form-control  is-valid" : "form-control "}
                                        disableToolbar
                                        autoOk
                                        disableFuture
                                        minDate={minDate1}
                                        InputAdornmentProps={{ position: "start" }}
                                        variant="dialog"
                                        inputVariant="outlined"
                                        cancelLabel="Cancelar"
                                        okLabel="Seleccionar"
                                        invalidDateMessage="Formato inválido."
                                        format='dd-MM-yyyy'
                                        name='fechaEmisionDocSustento'
                                        value={fechaEmisionDocSustento}
                                        onChange={setFechaEmisionDocSustento}
                                    />
                                </MuiPickersUtilsProvider>
                                
                                {errors.fecha ? (
                                            <p className={classesApp.errorLetters}>
                                                {errors.fecha}
                                            </p>
                                        ) : null}
                            </Grid>
                            <Grid item xs={12} md={12} >
                                <Divider />
                            </Grid>
                            <Grid item xs={12} md={12} className={classes.divButton}>
                                <Button variant="contained" color="primary" onClick={handleAddTax} className={classesGlobales.button}>
                                    Agregar
                                </Button>
                                <Button variant="outlined" color="secondary" onClick={handleClose} className={classesGlobales.button}>
                                    Cancelar
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>
            <Backdrop className={classes.backdrop} open={openLoader} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}

export default AddTax;