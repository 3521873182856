import React from 'react';
//material-table
import MaterialTable from 'material-table';
//material-io
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
//componentes del sistema
import { palette } from 'configurations/styles/theme';
import Alert from '../alert';
import ConfigTable from './configTable';
import HeaderTable from './HeaderTable';
import FooterTable from './FooterTable';
import useTablePaginatedVoucher from "hooks/useTablePaginatedVoucherAts";

const useStyles = makeStyles(() => ({
    root: {
        padding: "0px"
    },
    grid: {
        flexGrow: 1,
    },
    titulo: {
        padding: '1%',
        borderStyle: 'solid',
        borderColor: palette.primary.main,
        borderBottomWidth: '2px',
        borderTopWidth: '1px',
        borderLeftWidth: '1px',
        borderRightWidth: '1px',
        background: palette.primary.main
    },
    tabla: {
        padding: '0px',
    },
    backdrop: {
        marginBottom: '10%',
        marginTop: '10%'
    },
}));




/**
 * @author Dany Lasso
 * @version 1.1.0
 * @param {Object} requestConfig  {uri: string, metodo: string,  body: Object, page: number,elementos: number, paginasCargadas: [] }
 * @param {Array} columns [{}]
 * @param {String} title titulo de la tabla (opcional)
 * @param {Number} numeroFilas numeros de filas que tendrá la tabla
 * @param {Component} BotonesFooter recibe un componente para colocarlo en el footer
 * @param {Component} BotonesHeader recibe un componente para colocarlo en el header
 * @param {Boolean} seleccion valor por defecto false
 * @param {Boolean} buscar valor por defecto false
 */
const TablePaginatedVoucher = ({ columns, requestConfig, title, BotonesFooter,
    BotonesHeader, seleccion, buscar = false, setIdVoucher }) => {
    const classes = useStyles();
    const { getData, msgError, tabla, closeAlert } = useTablePaginatedVoucher(requestConfig)


    const handleChangePage = (event, newPage) => {
        getData(newPage, requestConfig.elementos, requestConfig)
    };

    const handleChangeRowsPerPage = event => {
        getData(requestConfig.page, event.target.value, requestConfig)
    };

    const RenderTable = () => {
        if (tabla.loading) {
            return (
                <div align='center' className={classes.backdrop}>
                    <CircularProgress />
                </div>
            );
        } else {
            const page = tabla.page
            return (
                <MaterialTable title='' columns={columns} data={page.content}
                    localization={ConfigTable.localization}
                    options={ConfigTable.optionsSelection(page.numberOfElements, buscar, false, seleccion)}
                    components={{
                        Pagination: props => (
                            <FooterTable
                                totalElements={tabla.totalElements}
                                rowsPerPage={page.size}
                                pageNumber={page.pageNumber}
                                totalPages={tabla.totalPages}
                                handleChangePage={handleChangePage}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                                BotonesFooter={BotonesFooter} />),
                        Toolbar: props => (<HeaderTable showInputSearch={buscar} ComponentsHeader={BotonesHeader} props={props} />)
                    }}
                    onSelectionChange={(data) => {
                        setIdVoucher(data)
                    }}
                />
            );
        }
    }

    return (
        <Grid container spacing={0} className={classes.root}  >
            {!title ? null :
                <Grid item xs={12}>
                    <Typography variant="h6" component="p" align="center" //className={classes.titulo} 
                    >
                        <font color="#FFFFFF">  {title} </font>
                    </Typography>
                </Grid>
            }
            {msgError.show ?
                <Alert
                    tipo={msgError.type}
                    mensaje={msgError.msg}
                    mesajeSubrayado={null}
                    titulo={null}
                    mostrar={msgError.show}
                    cerrarAlerta={closeAlert}
                /> : null
            }
            <Grid item xs={12} className={classes.tabla}>
                <RenderTable />
            </Grid>
        </Grid>
    );
}



export default React.memo(TablePaginatedVoucher);