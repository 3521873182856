import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  makeStyles,
  Backdrop,
  CircularProgress,
  FormControl,
  FormControlLabel,
  NativeSelect,
  withStyles,
  InputBase,
  TextField,
  Checkbox,
  Divider,
  RadioGroup,
  Radio,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import {
  palette,
  useStylesGlobal,
  styleInput,
} from "configurations/styles/theme";
import { Alert, Autocomplete } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useStylesApp } from "hooks/useStyles";
import { getRUCEmpresa } from "redux/actions/userActions";
import clientBackend from "configurations/axios";
import { httpRepo } from "configurations/axios";
import bigDecimal from "js-big-decimal";
import {
  validationReimbursement,
  validationVoucher,
  calculateDiscount,
} from "../validations";
import AddProductsBtn from "views/administración/productsServices/Buttons/AddProduct";
import checkInputs from "utils/checkInputs";

import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import uuid from "react-uuid";

import {
  reimbursementDocumentType,
  providerType,
  countryCodeType,
  tipoRegi01Type,
} from "../../../Forms/typeVoucher";
import EditTableImpuestos from "widgets/Tables/EditTableImpuesto";

import AddProviderBtn from "views/administración/providers/Buttons/AddProvider";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(3),
    minWidth: 120,
  },
  radio: {
    display: "-webkit-box",
    marginTop: "0px",
  },
  radioLable: {
    color: palette.primary.main,
    display: "flex",
  },
  radioButton: {
    marginTop: "0px",
    paddingTop: "0px",
  },
  divForm: {
    marginBottom: "1%",
  },
  divInput: {
    marginBottom: "1%",
    paddingRight: "1%",
    paddingLeft: "0.5%",
  },
  divButton: {
    paddingLeft: "3%",
    paddingRight: "3%",
    textAlign: "center",
    display: "flex",
    justifyContent: "space-around",
    marginTop: "1%",
    marginBottom: "0.5%",
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 999,
    color: palette.primary.main,
  },
}));
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(0),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const AddDetailReimbursement = ({
  reimbursementDetail,
  setReimbursementDetail,
  // viewDetalle,
  // setViewDetalle,
  detalles,
  setDetalles,
  additionalInfoInvoice,
  setAdditionalInfoInvoice,
}) => {
  const dateToday = new Date();
  dateToday.setDate(dateToday.getDate() - 30);
  //para la fecha del comprobante
  const [fechaEmision, setFechaEmision] = useState(new Date());

  const classes = useStyles();
  const classesGlobales = useStylesGlobal();
  const classesApp = useStylesApp();
  const [dataAditional, setDataAditional] = useState([]);
  const [openLoader, setOpenLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const userToken = useSelector((state) => state.user.tk);
  const [msgError, setMsg] = useState({ show: false, msg: null, type: null });
  const { enqueueSnackbar } = useSnackbar();
  const [product, setProduct] = useState({
    descuentoProducto: "",
    cantidadProducto: "1",
    isDescuentoPorcentaje: "false",
    valorDescuento: "",
  });
  const [iva, setIva] = useState("2");
  const [ice, setIce] = useState();
  const [options, setOptions] = useState([]);
  const [foundProduct, setFoundProduct] = useState({
    codigoAuxiliar: "",
    codigoPrincipal: "",
    descripcion: "",
    estado: "",
    idProducto: "",
    precioUnitario: "",
  });
  const [openAutocomplete, setOpenAutocomplete] = useState(false);
  const loading = openAutocomplete && options.length === 0;
  const [empty, setEmty] = useState(true);
  const [emptyCodigo, setEmtyCodigo] = useState(true);
  const [emptyPrecio, setEmtyPrecio] = useState(true);
  const [isAditional, setIsAdditional] = useState(false);
  const [errors, setErrors] = useState({});
  const [existProduct, setExistProduct] = useState(false);
  const [isDescuentoPorcentaje, setIsDescuentoPorcentaje] = useState("false");

  // reimbursement
  const [reimbursement, setReimbursement] = useState([]);
  const [fetchProvider, setFetchProvider] = useState([]);
  const [isThereProvider, setIsThereProvider] = useState(false);
  const [thereIsReimbursement, setThereIsReimbursement] = useState(false);
  const [foundProvider, setFoundProvider] = useState({
    idAsignacionEmpresaCliente: "",
    nombres: "",
    direccion: "",
    correo: "",
    telefono: "",
    tipo: "",
    estado: "",
    fechaRegistro: "",
    cliente: {
      idCliente: "",
      fechaRegistro: "",
      nombres: "",
    },
  });

  const [dataAditionalImpuestos, setDataAditionalImpuestos] = useState([]);
  const [docAdditionalInfo, setDocAdditionalInfo] = useState([]);
  const [codDocReimbursement, setCodDocReimbursement] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    clearData();
    setOpen(false);
  };

  const validateReimbursementData = (data) => {
    let newErros = validationReimbursement.onSubmit({
      idAsignacionEmpresaCliente: data.idAsignacionEmpresaCliente,
      codDocReembolso: data.codDocReembolso,
      tipoProveedorReembolso: data.tipoProveedorReembolso,
      codPais: data.codPais,
      estabDocReembolso: data.estabDocReembolso,
      ptoEmiDocReembolso: data.ptoEmiDocReembolso,
      secuencialDocReembolso: data.secuencialDocReembolso,
      fechaEmisionDocReembolso: data.fechaEmisionDocReembolso,
      numeroautorizacionDocReembolso: data.numeroautorizacionDocReembolso,
      detalleReembolso: data.detalleReembolso,
      impuestos: data.impuestos,
    });
    if (Object.entries(newErros).length > 0) {
      setErrors(newErros);
      return false;
    } else {
      return true;
    }
  };

  const handleAddReimbursement = () => {
    if (
      validateReimbursementData({
        idAsignacionEmpresaCliente: reimbursement.idAsignacionEmpresaCliente,
        codDocReembolso: reimbursement.codDocReembolso,
        tipoProveedorReembolso: reimbursement.tipoProveedorReembolso,
        codPais: reimbursement.codPais,
        estabDocReembolso: reimbursement.estabDocReembolso,
        ptoEmiDocReembolso: reimbursement.ptoEmiDocReembolso,
        secuencialDocReembolso: reimbursement.secuencialDocReembolso,
        fechaEmisionDocReembolso: fechaEmision,
        numeroautorizacionDocReembolso:
          reimbursement.numeroautorizacionDocReembolso,
        detalleReembolso: reimbursement.detalleReembolso,
        impuestos: dataAditionalImpuestos,
      }) &&
      thereIsReimbursement === false
    ) {
      if (
        typeof reimbursement !== "undefined" &&
        typeof reimbursement !== null
      ) {
        let id = uuid();
        setReimbursementDetail([
          ...reimbursementDetail,
          {
            ...reimbursement,
            id: id,
            fechaEmisionDocReembolso: fechaEmision,
            impuestos: dataAditionalImpuestos,
          },
        ]);

        setAdditionalInfoInvoice([
          ...additionalInfoInvoice,
          {
            id: id,
            nombre:
              codDocReimbursement +
              " " +
              reimbursement.estabDocReembolso +
              "-" +
              reimbursement.ptoEmiDocReembolso +
              "-" +
              reimbursement.secuencialDocReembolso +
              " - " +
              foundProvider.nombres,
            valor: reimbursement.detalleReembolso,
          },
        ]);
      }
      setProduct({
        descuentoProducto: "",
        cantidadProducto: "1",
        isDescuentoPorcentaje: "false",
      });
      setFoundProduct({});
      setFoundProvider({});
      setDataAditional([]);
      setDataAditionalImpuestos([]);
      setReimbursement([]);
      setFetchProvider([]);
      setIsThereProvider(false);
      setFoundProvider([]);
      handleClose();
      enqueueSnackbar(`Detalle de reembolso agregado al comprobante`, {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        autoHideDuration: 4000,
      });
    }
  };

  const handleChangeCheckDesc = (event) => {
    setIsDescuentoPorcentaje(event.target.value);
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { error, value, keyWordError } = validationReimbursement.onChange(
      event.target.name,
      event.target.value
    );

    switch (event.target.name) {
      case "codPais":
        if (
          keyWordError !== "isNotDecimal" &&
          keyWordError !== "isLong" &&
          checkInputs.countDecimals(event.target.value.toString().trim()) <= 6
        ) {
          setReimbursement({
            ...reimbursement,
            [event.target.name]: event.target.value,
          });
        }
        setErrors({ ...errors, [event.target.name]: error });
        break;
      case "estabDocReembolso":
        if (
          keyWordError !== "isNotNumeric" &&
          keyWordError !== "isLong" &&
          checkInputs.countDecimals(event.target.value.toString().trim()) <= 3
        ) {
          setReimbursement({
            ...reimbursement,
            [event.target.name]: event.target.value,
          });
        }
        setErrors({ ...errors, [event.target.name]: error });
        break;
      case "ptoEmiDocReembolso":
        if (
          keyWordError !== "isNotNumeric" &&
          keyWordError !== "isLong" &&
          checkInputs.countDecimals(event.target.value.toString().trim()) <= 3
        ) {
          setReimbursement({
            ...reimbursement,
            [event.target.name]: event.target.value,
          });
        }
        setErrors({ ...errors, [event.target.name]: error });
        break;
      case "secuencialDocReembolso":
        if (
          keyWordError !== "isNotNumeric" &&
          keyWordError !== "isLong" &&
          checkInputs.countDecimals(event.target.value.toString().trim()) <= 9
        ) {
          setReimbursement({
            ...reimbursement,
            [event.target.name]: event.target.value,
          });
        }
        setErrors({ ...errors, [event.target.name]: error });
        break;
      case "numeroautorizacionDocReembolso":
        if (
          keyWordError !== "isNotNumeric" &&
          keyWordError !== "isLong" &&
          checkInputs.countDecimals(event.target.value.toString().trim()) <= 49
          //   &&
          // typeof searchInvoices(event.target.value) === "undefined"
        ) {
          setReimbursement({
            ...reimbursement,
            [event.target.name]: event.target.value,
          });
        }
        setErrors({ ...errors, [event.target.name]: error });
        break;
      case "detalleReembolso":
        setReimbursement({
          ...reimbursement,
          [event.target.name]: event.target.value,
        });
        setErrors({ ...errors, [event.target.name]: error });
        break;
      default:
        break;
    }
  };

  const handleChangeCheck = (event) => {
    setIsAdditional(event.target.checked);
  };

  function closeAlert() {
    setMsg({ show: false });
  }

  const handleCheckExistsInvoice = async (e) => {
    e.preventDefault();
    try {
      const flag = await searchInvoices(e.target.value);
      console.log(flag);
      if (flag) {
        setErrors({
          ...errors,
          numeroautorizacionDocReembolso:
            "No se permite ingresar una factura de la misma empresa!",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const searchInvoices = async (params) => {
    try {
      const { data } = await httpRepo.get(
        `/comprobantes/autorizados/busqueda/validar/comprobante/${
          userToken ? getRUCEmpresa(userToken) : ""
        }?numeroAutorizacion=${params}`
      );
      // console.log(data[0]);
      if (typeof data[0] === "undefined") {
        setThereIsReimbursement(false);
        return false;
      } else {
        setThereIsReimbursement(true);
        return true;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  // método para la búsqueda
  const searchProviders = async (params) => {
    await clientBackend
      .get(
        `/admin/proveedores/buscar/activos/${
          userToken ? getRUCEmpresa(userToken) : ""
        }?parametroBusqueda=${params}`
      )
      .then((response) => {
        if (response.status === 200) {
          if (Array.isArray(response.data)) {
            if (response.data.length > 0) {
              // console.log(response.data);
              setFetchProvider(response.data);
              setIsThereProvider(false);
            } else {
              setFetchProvider([
                {
                  idAsignacionEmpresaCliente: "",
                  // descripcion:
                  //   "No se encontró datos con el argumento ingresado",
                },
              ]);
              setIsThereProvider(true);
            }
          }
          setOpen(true);
        }
      })
      .catch((exception) => {
        if (exception.response) {
          setOptions([
            {
              codigoPrincipal: "",
              descripcion: "No se encontró datos con el argumento ingresado",
            },
          ]);
          setExistProduct(true);
        } else {
          setOptions([
            {
              codigoPrincipal: "",
              descripcion: "Error en la red al consultar el proveedor.",
            },
          ]);
        }
      });
  };

  const validateDecimal = (numberValor) => {
    let splitList = numberValor.split(".");
    if (splitList.length > 2) {
      enqueueSnackbar(
        `El dato ingresado es erróneo utilice punto (.) para separar decimales`,
        {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          autoHideDuration: 4000,
        }
      );
      return false;
    } else if (splitList.length > 1) {
      if (splitList[1].length > 2) {
        enqueueSnackbar(
          `Los datos numéricos debe tener máximo 2 decimales Ej, 12.43`,
          {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            autoHideDuration: 4000,
          }
        );
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const clearData = () => {
    // setReimbursement("")
    setEmtyCodigo(true);
    setEmty(true);
    setEmtyPrecio(true);
    setProduct({
      descuentoProducto: "",
      cantidadProducto: "1",
      isDescuentoPorcentaje: "false",
    });
    setIce("");
    setFoundProduct({
      codigoAuxiliar: "",
      codigoPrincipal: "",
      descripcion: "",
      estado: "",
      idProducto: "",
      precioUnitario: "",
    });
    setDataAditional([]);
    setOptions([]);
    setIsAdditional(false);
    setErrors({});
  };

  const updateView = () => {};

  const handleBugs = () => {
    console.log(reimbursement);
    console.log(dataAditionalImpuestos);
    console.log("TOTAL:");
    console.log(reimbursementDetail);
  };

  return (
    <Fragment>
      <Grid container justify="center" className={classes.divButton}>
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          Agregar Detalle de Reembolso
        </Button>
      </Grid>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="max-width-dialog-title">
          Nuevo detalle de reembolso
        </DialogTitle>
        <DialogContent>
          <form>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                >
                  {/* <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleBugs}
                  >
                    Anti bugs
                  </Button> */}
                  <Grid item xs={12} md={12}>
                    <label>
                      Los campos marcados con ({" "}
                      <font color={palette.error.main}> *</font> ) son
                      obligatorios:
                    </label>
                  </Grid>
                  {msgError.show ? (
                    <Alert
                      tipo={msgError.type}
                      mensaje={msgError.msg}
                      mesajeSubrayado={null}
                      titulo={null}
                      mostrar={msgError.show}
                      cerrarAlerta={closeAlert}
                    />
                  ) : null}
                  {isThereProvider ? (
                    <Fragment>
                      <Grid item xs={12} md={12} className={classes.divForm}>
                        <Divider />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        align="center"
                        className={classes.divForm}
                      >
                        <AddProviderBtn updateView={updateView} />
                        {/* <AddProductsBtn updateView={updateView} /> */}
                      </Grid>
                      <Grid item xs={12} md={12} className={classes.divForm}>
                        <Divider />
                      </Grid>
                    </Fragment>
                  ) : null}

                  <Grid item xs={12} md={4} className={classes.divForm}>
                    <label>
                      Proveedor:
                      <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={8} className={classes.divInput}>
                    <Autocomplete
                      id="idAsignacionEmpresaCliente"
                      name="idAsignacionEmpresaCliente"
                      onChange={(e, value) => {
                        if (value !== null) {
                          // console.log(value);
                          // setFoundProduct(value);
                          setFoundProvider(value);
                          setReimbursement({
                            ...reimbursement,
                            provider: value,
                            idAsignacionEmpresaCliente:
                              value.idAsignacionEmpresaCliente,
                          });
                          setErrors({});
                        } else {
                          setFoundProvider({
                            idAsignacionEmpresaCliente: "",
                            nombres: "",
                            direccion: "",
                            correo: "",
                            telefono: "",
                            tipo: "",
                            estado: "",
                            fechaRegistro: "",
                            idCliente: "",
                          });
                        }
                        setEmtyCodigo(true);
                      }}
                      open={openAutocomplete}
                      onOpen={() => {
                        setOpenAutocomplete(true);
                      }}
                      onClose={() => {
                        setOpenAutocomplete(false);
                      }}
                      // getOptionDisabled={(option) =>
                      //   option.descripcion.includes(
                      //     "No se encontró datos con el argumento ingresado"
                      //   ) ||
                      //   option.descripcion.includes(
                      //     "Error en la red al consultar el producto."
                      //   )
                      // }
                      // getOptionSelected={(option, value) =>
                      //   option.codigoPrincipal.trim() ===
                      //   value.codigoPrincipal.trim()
                      // }
                      getOptionLabel={(option) =>
                        `${option.nombres}-${option.cliente?.idCliente}`
                      }
                      options={fetchProvider}
                      noOptionsText={
                        "No se encontró datos con el argumento ingresado"
                      }
                      loading={fetchProvider.length === 0}
                      loadingText="Ingrese al menos 1 caracter"
                      renderInput={(params) => (
                        <TextField
                          className={styleInput(
                            errors.idAsignacionEmpresaCliente,
                            reimbursement.idAsignacionEmpresaCliente
                          )}
                          onChange={(event) => {
                            if (event.target.value.trim().length >= 1) {
                              searchProviders(event.target.value.trim());
                            }
                          }}
                          {...params}
                          label=""
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <Fragment>
                                {loading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                    <small style={{ color: "#818181" }}>
                      También puede buscar por la CI/RUC.
                    </small>
                    {errors.idAsignacionEmpresaCliente ? (
                      <p className={classesApp.errorLetters}>
                        {errors.idAsignacionEmpresaCliente}
                      </p>
                    ) : null}
                  </Grid>
                  {foundProvider.nombres !== "" && (
                    <>
                      <Grid item xs={12} md={4} className={classes.divForm}>
                        <label>Nombre del proveedor:</label>
                      </Grid>
                      <Grid item xs={12} md={8} className={classes.divInput}>
                        <input
                          className={styleInput(false, true)}
                          type="text"
                          disabled
                          value={foundProvider.nombres}
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12} md={4} className={classes.divForm}>
                    <label>
                      Tipo documento reembolso:
                      <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={8} className={classes.divInput}>
                    <Autocomplete
                      disablePortal
                      // id="reimbursementDocumentType"
                      name="codDocReembolso"
                      options={reimbursementDocumentType}
                      sx={{ width: 300 }}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          switch (newValue.DESCRIPTION) {
                            case "Factura":
                              setCodDocReimbursement("FAC");
                              break;
                            case "Nota o boleta de venta":
                              setCodDocReimbursement("BDV");
                              break;
                            case "Liquidación de compra de Bienes o Prestación de servicios":
                              setCodDocReimbursement("LCP");
                              break;
                            case "Nota de crédito":
                              setCodDocReimbursement("NDC");
                              break;
                            case "Nota de débito":
                              setCodDocReimbursement("NDB");
                              break;
                            case "Guías de Remisión":
                              setCodDocReimbursement("GDR");
                              break;
                            case "Comprobante de Retención":
                              setCodDocReimbursement("CDR");
                              break;
                            case "Boletos o entradas a espectáculos públicos":
                              setCodDocReimbursement("BEP");
                              break;
                            case "Tiquetes o vales emitidos por máquinas registradoras":
                              setCodDocReimbursement("TMR");
                              break;
                            case "Pasajes expedidos por empresas de aviación":
                              setCodDocReimbursement("PEA");
                              break;
                            case "Documentos emitidos por instituciones financieras":
                              setCodDocReimbursement("DIF");
                              break;
                            case "Comprobante de venta emitido en el Exterior":
                              setCodDocReimbursement("CEE");
                              break;
                            case "Formulario Único de Exportación (FUE) o Declaración Aduanera Única (DAU) o Declaración Andina de Valor (DAV)":
                              setCodDocReimbursement("FUE");
                              break;
                            case "Documentos autorizados utilizados en ventas excepto N/C N/D":
                              setCodDocReimbursement("DAE");
                              break;
                            case "Comprobantes de Pago de Cuotas o Aportes":
                              setCodDocReimbursement("CPC");
                              break;
                            case "Documentos por Servicios Administrativos emitidos por Inst. del Estado":
                              setCodDocReimbursement("DSA");
                              break;
                            case "Carta de Porte Aéreo":
                              setCodDocReimbursement("CPA");
                              break;
                            case "RECAP":
                              setCodDocReimbursement("REC");
                              break;
                            case "Nota de Crédito TC":
                              setCodDocReimbursement("CTC");
                              break;
                            case "Nota de Débito TC":
                              setCodDocReimbursement("DTC");
                              break;
                            case "Comprobante de venta emitido por reembolso":
                              setCodDocReimbursement("CVR");
                              break;
                            case "Documento retención presuntiva y retención emitida por propio vendedor o por intermediario":
                              setCodDocReimbursement("DPE");
                              break;
                            case "Liquidación para Explotación y Exploración de Hidrocarburos":
                              setCodDocReimbursement("LEH");
                              break;
                            case "Comprobante de Contribuciones y Aportes":
                              setCodDocReimbursement("CCA");
                              break;
                            case "Liquidación por reclamos de aseguradoras":
                              setCodDocReimbursement("LRA");
                              break;
                            case "Nota de Crédito por Reembolso Emitida por Intermediario":
                              setCodDocReimbursement("NCI");
                              break;
                            case "Nota de Débito por Reembolso Emitida por Intermediario":
                              setCodDocReimbursement("NDI");
                              break;
                            case "Proveedor Directo de Exportador Bajo Régimen Especial":
                              setCodDocReimbursement("PEE");
                              break;
                            case "A Inst. Estado y Empr. Públicas que percibe ingreso exento de Imp. Renta":
                              setCodDocReimbursement("PIA");
                              break;
                            case "N/C A Inst. Estado y Empr. Públicas que percibe ingreso exento de Imp. Renta":
                              setCodDocReimbursement("PNC");
                              break;
                            case "N/D A Inst. Estado y Empr. Públicas que percibe ingreso exento de Imp. Renta":
                              setCodDocReimbursement("PND");
                              break;
                            case "Liquidación de compra de Bienes Muebles Usados":
                              setCodDocReimbursement("LBM");
                              break;
                            case "Liquidación de compra de vehículos usados":
                              setCodDocReimbursement("LVU");
                              break;
                            case "Acta Entrega-Recepción PET":
                              setCodDocReimbursement("ERP");
                              break;
                            case "Factura operadora transporte / socio":
                              setCodDocReimbursement("FOT");
                              break;
                            case "Comprobante socio a operadora de transporte":
                              setCodDocReimbursement("CSO");
                              break;
                            case "Nota de  crédito  operadora transporte / socio":
                              setCodDocReimbursement("NCO");
                              break;
                            case "Nota de  débito  operadora transporte / socio":
                              setCodDocReimbursement("NDO");
                              break;
                            case "Nota de  débito  operadora transporte / socio":
                              setCodDocReimbursement("NDO");
                              break;
                            case "Liquidación de compra RISE de bienes o prestación de servicios":
                              setCodDocReimbursement("LRB");
                              break;
                            default:
                              break;
                          }
                          setReimbursement({
                            ...reimbursement,
                            reimbursementTypeDocument: newValue,
                            codDocReembolso: newValue.CODE,
                          });
                          setErrors({});
                        }
                      }}
                      getOptionLabel={(option) =>
                        `${option.CODE} - ${option.DESCRIPTION}`
                      }
                      renderInput={(params) => (
                        <TextField
                          className={styleInput(
                            errors.codDocReembolso,
                            reimbursement.codDocReembolso
                          )}
                          {...params}
                          label=""
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <Fragment>
                                {loading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                    {errors.codDocReembolso ? (
                      <p className={classesApp.errorLetters}>
                        {errors.codDocReembolso}
                      </p>
                    ) : null}
                  </Grid>

                  <Grid item xs={12} md={4} className={classes.divForm}>
                    <label>
                      Tipo proveedor: <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={8} className={classes.divInput}>
                    <Autocomplete
                      disablePortal
                      id="tipoProveedorReembolso"
                      name="tipoProveedorReembolso"
                      options={providerType}
                      sx={{ width: 300 }}
                      onChange={(event, newValue) => {
                        setReimbursement({
                          ...reimbursement,
                          providerType: newValue,
                          tipoProveedorReembolso: newValue.CODE,
                        });
                        setErrors({});
                      }}
                      getOptionLabel={(option) =>
                        `${option.CODE} - ${option.DESCRIPTION}`
                      }
                      renderInput={(params) => (
                        <TextField
                          className={styleInput(
                            errors.tipoProveedorReembolso,
                            reimbursement.tipoProveedorReembolso
                          )}
                          {...params}
                          label=""
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <Fragment>
                                {loading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                    {errors.tipoProveedorReembolso ? (
                      <p className={classesApp.errorLetters}>
                        {errors.tipoProveedorReembolso}
                      </p>
                    ) : null}
                  </Grid>

                  {/* <Grid item xs={12} md={4} className={classes.divForm}>
                    <label>
                      País pago proveedor:
                      <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={8} className={classes.divInput}>
                    <input
                      className={styleInput(
                        errors.codPais,
                        reimbursement.codPais
                      )}
                      type="number"
                      placeholder="Ej. 593"
                      name="codPais"
                      value={reimbursement.codPais}
                      onChange={handleChange}
                      autoComplete="off"
                    />
                    {errors.codPais ? (
                      <p className={classesApp.errorLetters}>
                        {errors.codPais}
                      </p>
                    ) : null}
                  </Grid> */}

                  <Grid item xs={12} md={4} className={classes.divForm}>
                    <label>
                      País pago proveedor:{" "}
                      <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={8} className={classes.divInput}>
                    <Autocomplete
                      disablePortal
                      id="codPais"
                      name="codPais"
                      options={tipoRegi01Type}
                      sx={{ width: 300 }}
                      onChange={(event, newValue) => {
                        setReimbursement({
                          ...reimbursement,
                          codPais: newValue.CODE,
                          // tipoProveedorReembolso: newValue.CODE,
                        });
                        setErrors({});
                      }}
                      getOptionLabel={(option) =>
                        `${option.CODE} - ${option.DESCRIPTION}`
                      }
                      renderInput={(params) => (
                        <TextField
                          className={styleInput(
                            errors.codPais,
                            reimbursement.codPais
                          )}
                          {...params}
                          label=""
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <Fragment>
                                {loading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                    {errors.codPais ? (
                      <p className={classesApp.errorLetters}>
                        {errors.codPais}
                      </p>
                    ) : null}
                  </Grid>

                  <Grid item xs={12} md={4} className={classes.divForm}>
                    <label>
                      Establecimiento Doc. Reembolso:
                      <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={8} className={classes.divInput}>
                    <input
                      className={styleInput(
                        errors.estabDocReembolso,
                        reimbursement.estabDocReembolso
                      )}
                      type="number"
                      placeholder="Ej. 001"
                      name="estabDocReembolso"
                      value={reimbursement.estabDocReembolso}
                      onChange={handleChange}
                      autoComplete="off"
                    />
                    {errors.estabDocReembolso ? (
                      <p className={classesApp.errorLetters}>
                        {errors.estabDocReembolso}
                      </p>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={12} md={4} className={classes.divForm}>
                    <label>
                      Punto de emisión:
                      <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={8} className={classes.divInput}>
                    <input
                      className={styleInput(
                        errors.ptoEmiDocReembolso,
                        reimbursement.ptoEmiDocReembolso
                      )}
                      type="number"
                      placeholder="Ej. 001"
                      name="ptoEmiDocReembolso"
                      value={reimbursement.ptoEmiDocReembolso}
                      onChange={handleChange}
                      autoComplete="off"
                    />
                    {errors.ptoEmiDocReembolso ? (
                      <p className={classesApp.errorLetters}>
                        {errors.ptoEmiDocReembolso}
                      </p>
                    ) : null}
                  </Grid>

                  <Grid item xs={12} md={4} className={classes.divForm}>
                    <label>
                      Secuencial: <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={8} className={classes.divInput}>
                    <input
                      className={styleInput(
                        errors.secuencialDocReembolso,
                        reimbursement.secuencialDocReembolso
                      )}
                      type="number"
                      placeholder="Ej. 00000001"
                      name="secuencialDocReembolso"
                      value={reimbursement.secuencialDocReembolso}
                      onChange={handleChange}
                      autoComplete="off"
                    />
                    {errors.secuencialDocReembolso ? (
                      <p className={classesApp.errorLetters}>
                        {errors.secuencialDocReembolso}
                      </p>
                    ) : null}
                  </Grid>

                  <Grid item xs={12} md={4} className={classes.divForm}>
                    <label>Fecha emisión:</label>
                  </Grid>
                  <Grid item xs={12} md={8} className={classes.divInput}>
                    <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      locale={esLocale}
                    >
                      <DatePicker
                        className={
                          fechaEmision
                            ? "form-control  is-valid"
                            : "form-control "
                        }
                        disableToolbar
                        autoOk
                        name="fechaEmisionDocReembolso"
                        disableFuture
                        // minDate={dateToday}
                        variant="dialog"
                        inputVariant="outlined"
                        okLabel="Seleccionar"
                        cancelLabel="Cancelar"
                        format="dd-MM-yyyy"
                        margin="normal"
                        value={fechaEmision}
                        onChange={setFechaEmision}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={12} md={4} className={classes.divForm}>
                    <label>
                      Número autorización:
                      <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={8} className={classes.divInput}>
                    <input
                      className={styleInput(
                        errors.numeroautorizacionDocReembolso,
                        reimbursement.numeroautorizacionDocReembolso
                      )}
                      type="number"
                      placeholder="Ingresar al menos 10 dígitos"
                      name="numeroautorizacionDocReembolso"
                      value={reimbursement.numeroautorizacionDocReembolso}
                      onChange={(e) => {
                        handleChange(e);
                        handleCheckExistsInvoice(e);
                      }}
                      autoComplete="off"
                    />
                    {errors.numeroautorizacionDocReembolso ? (
                      <p className={classesApp.errorLetters}>
                        {errors.numeroautorizacionDocReembolso}
                      </p>
                    ) : null}
                  </Grid>

                  <Grid item xs={12} md={4} className={classes.divForm}>
                    <label>
                      Descripción:
                      <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={8} className={classes.divInput}>
                    <textarea
                      className={styleInput(
                        errors.detalleReembolso,
                        reimbursement.detalleReembolso
                      )}
                      type="text"
                      rows={foundProvider.nombres !== "" ? "6" : "4"}
                      placeholder="Ingresar el detalle del reembolso"
                      name="detalleReembolso"
                      value={reimbursement.detalleReembolso}
                      onChange={handleChange}
                      autoComplete="off"
                    />
                    {errors.detalleReembolso ? (
                      <p className={classesApp.errorLetters}>
                        {errors.detalleReembolso}
                      </p>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={12}>
                <Divider />
              </Grid>

              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item xs={8} md={8}>
                  {/* {dataAditionalImpuestos.length<0 ? (
                      <p className={classesApp.errorLetters}>
                        {errors.impuestos}
                      </p>
                    ) : null} */}

                  <EditTableImpuestos
                    dataAditionalImpuestos={dataAditionalImpuestos}
                    setDataAditionalImpuestos={setDataAditionalImpuestos}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} md={12}>
                <Divider />
              </Grid>

              <Grid item xs={12} md={12} className={classes.divButton}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddReimbursement}
                  className={classesGlobales.button}
                >
                  Agregar
                </Button>

                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleClose}
                  className={classesGlobales.button}
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      <Backdrop className={classes.backdrop} open={openLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
};

export default AddDetailReimbursement;
