import checkInputs from "utils/checkInputs";

const validations = {
  editVoucherAts: function validationEditVoucherAts(updateVoucher) {
    const errors = {};
    if (!checkInputs.notNull(updateVoucher.codigoSustento)) {
      errors.codigoSustento = "El código sustento es necesario.";
    } else if (!checkInputs.isNumeric(updateVoucher.codigoSustento)) {
      errors.codigoSustento = "El codigo sustento solo contienen números.";
    } else if (updateVoucher.codigoSustento.length > 2) {
      errors.codigoSustento = "No ingrese más de 2 dígitos.";
    }

    return errors;
  },
};

export default validations;
