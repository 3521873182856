import React, { useState, Fragment } from "react";
import {
  makeStyles,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
} from "@material-ui/core";
import { styleInput } from "configurations/styles/theme";

import { useSnackbar } from "notistack";
import uuid from "react-uuid";

import TextError from "componets/TextError";
import { validationsDetailDispatcher } from "../validations";
import TextRequired from "componets/TextRequired";
import TextRequiredAsterisks from "componets/TextRequiredAsterisks";
import checkInputs from "utils/checkInputs";
import { numDecimales } from "configurations/constant";

const useStyles = makeStyles((theme) => ({
  spaceAddDetail: {
    margin: "10px",
  }
}));

const AddDetailDispatcher = ({
  detalleGuiaRemision,
  setDetalleGuiaRemision,
  viewDetalle,
  setViewDetalle,
}) => {
  const [product, setProduct] = useState({
    id: uuid(),
    codigoInterno: "",
    codigoAdicional: "",
    cantidad: 0,
    descripcion: "",
    detallesAdicionales: [],
  });



  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleChange = (event) => {
    event.preventDefault();

    const { error, value, keyWordError } = validationsDetailDispatcher.onChange(
      event.target.name,
      event.target.value
    );

    if (event.target.name === "codigoInterno") {
      if (keyWordError !== "isNotNumeric" && keyWordError !== "isLong") {
        setProduct({
          ...product,
          [event.target.name]: event.target.value,
        });
      }
      setErrors({
        ...errors,
        [event.target.name]: error,
      });
    }
    if (event.target.name === "descripcion") {
      if (error === null) {
        setProduct({
          ...product,
          [event.target.name]: event.target.value,
        });
      }
      setErrors({
        ...errors,
        [event.target.name]: error,
      });
    }
    if (event.target.name === "cantidad") {
      if (keyWordError !== "isNotNumeric" && checkInputs.countDecimals(event.target.value) <= numDecimales && checkInputs.countDigit(event.target.value) <= 18) {
        setProduct({
          ...product,
          [event.target.name]: event.target.value,
        });
      }
      setErrors({
        ...errors,
        [event.target.name]: error,
      });
    }
    if (event.target.name === "codigoAdicional") {
      setProduct({
        ...product,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleAddProduct = (event) => {
    let newErros = validationsDetailDispatcher.onSubmit(product);
    if (Object.keys(newErros).length > 0) {
      setErrors({ ...errors, ...newErros });
    } else {
      event.preventDefault();
      enqueueSnackbar(`Detalle agregado`, {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        autoHideDuration: 4000,
      });
      setDetalleGuiaRemision([...detalleGuiaRemision, product]);
      if (viewDetalle === 1) {
        setViewDetalle(2);
      } else if (viewDetalle === 2) {
        setViewDetalle(1);
      }
      handleClose()
      clearObjectProduct()
    }
  };

  const handleClose = () => {
    setOpen(false);
    clearObjectProduct()
  };

  const clearObjectProduct = () => {
    setProduct({
      id: uuid(),
      codigoInterno: "",
      codigoAdicional: "",
      cantidad: 0,
      descripcion: "",
      detallesAdicionales: [],
    })
  }


  return (
    <Fragment>
      <Grid
        container
        direction="row"
        justify="center"
        align="center"
        alignItems="center"
        spacing={3}
        className={classes.spaceAddDetail}
      >
        <Grid item md={4}>
          <Button variant="outlined" color="primary" onClick={handleClickOpen}>
            Agregar detalle
          </Button>
        </Grid>
      </Grid>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Agregar detalle</DialogTitle>
        <DialogContent>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={1}
            >
              <Grid
                item
                xs={12}
                justify="center"
                aria-controls="panel1a-content"
              >
              </Grid>

              <Grid item md={12}>
                <TextRequiredAsterisks />
              </Grid>
              <Grid item md={12}>
                <Divider></Divider>
              </Grid>
              <Grid item xs={2} className={classes.divForm}>
                <TextRequired text={"Código interno"}></TextRequired>
              </Grid>
              <Grid item xs={4} className={classes.divInput}>
                <input
                  type="text"
                  maxLength="25"
                  placeholder="Ej. 022044"
                  name="codigoInterno"
                  value={product.codigoInterno}
                  className={styleInput(
                    errors.codigoInterno,
                    product.codigoInterno
                  )}
                  onChange={handleChange}
                />
                <TextError text={errors.codigoInterno}></TextError>
              </Grid>

              <Grid item xs={2} className={classes.divForm}>
                <label>Código adicional:</label>
              </Grid>
              <Grid item xs={4} className={classes.divInput}>
                <input
                  type="text"
                  maxLength="12"
                  placeholder="Ej. 550"
                  name="codigoAdicional"
                  value={product.codigoAdicional}
                  onChange={handleChange}
                  className={styleInput(
                    errors.codigoAdicional,
                    product.codigoAdicional
                  )}
                />
              </Grid>

              <Grid item xs={2} className={classes.divForm}>
                <TextRequired text={"Descripción"}></TextRequired>
              </Grid>
              <Grid item xs={4} className={classes.divInput}>
                <input
                  type="text"
                  placeholder="Ej. Ordenador"
                  name="descripcion"
                  value={product.descripcion}
                  onChange={handleChange}
                  className={styleInput(
                    errors.descripcion,
                    product.descripcion
                  )}
                />
                <TextError text={errors.descripcion}></TextError>
              </Grid>

              <Grid item xs={2} className={classes.divForm}>
                <TextRequired text={"Cantidad"}></TextRequired>
              </Grid>
              <Grid item xs={4} className={classes.divInput}>
                <input
                  type='text'
                  placeholder="Ej. 45"
                  name="cantidad"
                  autoComplete="off"
                  value={product.cantidad}
                  onChange={handleChange}
                  className={styleInput(errors.cantidad, product.cantidad)}
                />
                <TextError text={errors.cantidad}></TextError>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            align="center"
            spacing={2}
          >
            <Grid item md={12}>
              <Divider></Divider>
            </Grid>

            <Grid item xs={2} className={classes.divButton}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddProduct}
              >
                Agregar detalle
              </Button>
            </Grid>

            <Grid item xs={2} className={classes.divButton}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleClose}
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default AddDetailDispatcher;
