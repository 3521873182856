// import React, { Fragment, useState } from "react";
// import {
//   Button,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   Grid,
//   makeStyles,
//   CircularProgress,
//   FormControl,
//   FormControlLabel,
//   NativeSelect,
//   withStyles,
//   InputBase,
//   TextField,
//   Checkbox,
//   Divider,
//   Radio,
//   RadioGroup,
// } from "@material-ui/core";
// import { palette, useStylesGlobal } from "configurations/styles/theme";
// import { Alert, Autocomplete } from "@material-ui/lab";
// import { useSelector } from "react-redux";
// import { useSnackbar } from "notistack";
// import { useStylesApp } from "hooks/useStyles";
// import { valorIva } from "../Forms/typeVoucher";
// import { valorICE } from "../Forms/typeVoucher";
// import EditTable from "widgets/Tables/EditTable";
// import { getRUCEmpresa } from "redux/actions/userActions";

// import validations, { styleInput } from "./validations";
// import { searchProduct_WebService, serviceCalculationValues } from "./services";
// import AddProductsBtn from "views/administración/productsServices/Buttons/AddProduct";

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     marginTop: theme.spacing(3),
//     minWidth: 120,
//     display: "flex"
//   },
//   radio: {
//     display: "-webkit-box",
//     marginTop: "0px",
//   },
//   radioLable: {
//     color: palette.primary.main,
//     display: "flex",
//   },
//   radioButton: {
//     marginTop: "0px",
//     paddingTop: "0px",
//   },
//   divForm: {
//     marginBottom: "1%",
//   },
//   divInput: {
//     marginBottom: "1%",
//     paddingRight: "1%",
//     paddingLeft: "0.5%",
//   },
//   divButton: {
//     paddingLeft: "3%",
//     paddingRight: "3%",
//     textAlign: "center",
//     display: "flex",
//     justifyContent: "space-around",
//     marginTop: "1%",
//     marginBottom: "1%",
//   },
//   backdrop: {
//     zIndex: theme.zIndex.modal + 999,
//     color: palette.primary.main,
//   },
// }));
// const BootstrapInput = withStyles((theme) => ({
//   root: {
//     "label + &": {
//       marginTop: theme.spacing(0),
//     },
//   },
//   input: {
//     borderRadius: 4,
//     position: "relative",
//     backgroundColor: theme.palette.background.paper,
//     border: "1px solid #ced4da",
//     fontSize: 16,
//     padding: "10px 26px 10px 12px",
//     transition: theme.transitions.create(["border-color", "box-shadow"]),
//     // Use the system font instead of the default Roboto font.
//     fontFamily: [
//       "-apple-system",
//       "BlinkMacSystemFont",
//       '"Segoe UI"',
//       "Roboto",
//       '"Helvetica Neue"',
//       "Arial",
//       "sans-serif",
//       '"Apple Color Emoji"',
//       '"Segoe UI Emoji"',
//       '"Segoe UI Symbol"',
//     ].join(","),
//     "&:focus": {
//       borderRadius: 4,
//       borderColor: "#80bdff",
//       boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
//     },
//   },
// }))(InputBase);

// const initProduct = {
//   precioUnitario: "0.00",
//   descuentoProducto: "",
//   cantidadProducto: "1",
//   codigoAuxiliar: "",
//   descripcion: "",
// };

// const ListICE = [{ CODIGO: "", NOMBRE: " Ninguno -" }, ...valorICE]

// const AddDetail = ({
//   txtBtn = "Agregar Detalle",
//   onSubmit,
//   onOpen = () => true,
// }) => {
//   //estilos
//   const classes = useStyles();
//   const classesGlobales = useStylesGlobal();
//   const classesApp = useStylesApp();

//   //manejo de dialogos
//   const [open, setOpen] = useState(false);
//   const [msgError, setMsg] = useState({ show: false, msg: null, type: null });
//   const { enqueueSnackbar } = useSnackbar();

//   const userToken = useSelector((state) => state.user.tk);

//   //gestion datos del formulario
//   const [product, setProduct] = useState(initProduct);
//   const [iva, setIva] = useState("2");
//   const [ice, setIce] = useState("");
//   const [discountCheck, setDiscountCheck] = useState(false);
//   const [dataAditional, setDataAditional] = useState([]);

//   //gestions autocomplete - buscador de productos
//   const [options, setOptions] = useState([]);
//   const loading = false && options.length === 0;

//   //validar inputs vacios
//   const [isAditional, setIsAdditional] = useState(false);

//   //manage form
//   const [errors, setErrors] = useState({});
//   const [existProduct, setExistProduct] = useState(false);

//   const handleClickOpen = () => {
//     const permitOpen = onOpen();
//     setProduct(initProduct);
//     setErrors({});
//     if (permitOpen) setOpen(true);
//   };

//   const handleClose = () => {
//     clearData();
//     setOpen(false);
//   };

//   // const validateDetailData = (data) => {
//   //   let newErros = validationAddDetail.onSubmit(
//   //     {
//   //       codigoPrincipal: data.codigoPrincipal,
//   //       cantidadProducto: data.cantidadProducto,
//   //       precioUnitario: data.precioUnitario,
//   //     }
//   //   );
//   //   if (Object.entries(newErros).length > 0) {
//   //     setErrors(newErros)
//   //     return false;
//   //   } else {
//   //     return true;
//   //   }
//   // }

//   const handleAddProduct = () => {
//     // if (validateDetailData({
//     //   codigoPrincipal: options.codigoPrincipal,
//     //   cantidadProducto: product.cantidadProducto,
//     //   precioUnitario: product.precioUnitario,
//     // })) {
//     const newErrors = validations.submitDetail(product, discountCheck);
//     setErrors(newErrors);
//     if (Object.keys(newErrors).length === 0) {
//       if (typeof onSubmit === "function") {
//         onSubmit(serviceCalculationValues(product, iva, ice, dataAditional, discountCheck));
//         handleClose();
//         enqueueSnackbar(`Detalle agregado al comprobante`, {
//           variant: "success",
//           anchorOrigin: {
//             vertical: "top",
//             horizontal: "right",
//           },
//           autoHideDuration: 4000,
//         });
//       }
//     }
//   // }
//   };

  


//   const handleChange = (nameInput) => (event, newValue) => {
//     const newError = validations.onChange(nameInput, event.target.value);
//     validations.onSave({
//       error: newError,
//       callbackSaveObj: handleSaveProduct,
//       nameInput,
//       valueInput: event.target.value || newValue,
//     });

//     setErrors({
//       ...errors,
//       [nameInput]: newError,
//     });
//   };

//   const handleSaveProduct = (nameInput, valueInput) => {
//     if (nameInput !== "codigoPrincipal") {
//       setProduct({
//         ...product,
//         [nameInput]: valueInput,
//       });
//     } else {
//       setProduct(valueInput);
//     }
//   };

//   const handleChangeCheck = (event) => {
//     setIsAdditional(event.target.checked);
//   };

//   function closeAlert() {
//     setMsg({ show: false });
//   }

//   //método para la busqueda
//   const searchProducts = async (params) => {
//     searchProduct_WebService(
//       setOptions,
//       `/admin/productos/buscar/${
//         userToken ? getRUCEmpresa(userToken) : ""
//       }?parametroBusqueda=${params}`,
//       params,
//       setExistProduct
//     );
//   };

//   const clearData = () => {
//     setProduct(initProduct);
//     setIce("");
//     setDataAditional([]);
//   };

//   const updateView = () => {};

//   const handleChangeRadioDiscount = (event) => {
//     setDiscountCheck(event.target.value === "P");
//   };
  
//   return (
//     <Fragment>
//       <Grid container justify="center" className={classes.divButton}>
//         <Button variant="outlined" color="primary" onClick={handleClickOpen}>
//           {txtBtn}
//         </Button>
//       </Grid>
//       <Dialog
//         fullWidth
//         maxWidth="lg"
//         open={open}
//         onClose={handleClose}
//         disableBackdropClick
//         disableEscapeKeyDown
//       >
//         <DialogTitle id="max-width-dialog-title">Nuevo detalle</DialogTitle>
//         <DialogContent>
//           <form>
//             <Grid container spacing={1}>
//               <Grid item xs={12} md={6}>
//                 <Grid
//                   container
//                   direction="row"
//                   justify="flex-start"
//                   alignItems="center"
//                 >
//                   {msgError.show ? (
//                     <Alert
//                       tipo={msgError.type}
//                       mensaje={msgError.msg}
//                       mesajeSubrayado={null}
//                       titulo={null}
//                       mostrar={msgError.show}
//                       cerrarAlerta={closeAlert}
//                     />
//                   ) : null}
//                   <Grid item xs={12} md={12}>
//                     <label>
//                       Los campos marcados con ({" "}
//                       <font color={palette.error.main}> *</font> ) son
//                       obligatorios:
//                     </label>
//                   </Grid>

//                   {existProduct ? (
//                     <Fragment>
//                       <Grid item xs={12} md={12} className={classes.divForm}>
//                         <Divider />
//                       </Grid>
//                       <Grid
//                         item
//                         xs={12}
//                         md={12}
//                         align="center"
//                         className={classes.divForm}
//                       >
//                         <AddProductsBtn updateView={updateView} />
//                       </Grid>
//                       <Grid item xs={12} md={12} className={classes.divForm}>
//                         <Divider />
//                       </Grid>
//                     </Fragment>
//                   ) : null}

//                   <Grid item xs={12} md={3} className={classes.divForm}>
//                     <label>
//                       Código Principal:{" "}
//                       <font color={palette.error.main}>*</font>
//                     </label>
//                   </Grid>

//                   <Grid item xs={12} md={9} className={classes.divInput}>
//                     <Autocomplete
//                       onChange={handleChange("codigoPrincipal")}
//                       getOptionSelected={(option, value) =>
//                         option.codigoPrincipal.trim() ===
//                         value.codigoPrincipal.trim()
//                       }
//                       getOptionDisabled={(option) =>
//                         option.descripcion.includes(
//                           "No se encontró datos con el argumento ingresado"
//                         ) ||
//                         option.descripcion.includes(
//                           "Error en la red al consultar el producto."
//                         )
//                       }
//                       getOptionLabel={(option) =>
//                         `${option.codigoPrincipal}-${option.descripcion}`
//                       }
//                       options={options}
//                       noOptionsText={
//                         "No se encontró datos con el argumento ingresado"
//                       }
//                       loading={options.length === 0}
//                       loadingText="Ingrese al menos 1 caracter"
//                       renderInput={(params) => (
//                         <TextField
//                           error={errors.codigoPrincipal ? true : false}
//                           onChange={(event) => {
//                             if (
//                               event.target.value.trim().length >= 1 &&
//                               event.target.value.trim().length <= 50
//                             ) {
//                               searchProducts(event.target.value.trim());
//                             }
//                           }}
//                           {...params}
//                           label=""
//                           variant="outlined"
//                           InputProps={{
//                             ...params.InputProps,
//                             endAdornment: (
//                               <React.Fragment>
//                                 {loading ? (
//                                   <CircularProgress color="inherit" size={20} />
//                                 ) : null}
//                                 {params.InputProps.endAdornment}
//                               </React.Fragment>
//                             ),
//                           }}
//                         />
//                       )}
//                     />
//                     {errors.codigoPrincipal ? (
//                       <p className={classesApp.errorLetters}>
//                         {errors.codigoPrincipal}
//                       </p>
//                     ) : null}
//                   </Grid>

//                   <Grid item xs={12} md={3} className={classes.divForm}>
//                     <label>Código Auxiliar:</label>
//                   </Grid>
//                   <Grid item xs={12} md={9} className={classes.divInput}>
//                     <input
//                       type="text"
//                       disabled
//                       value={product.codigoAuxiliar}
//                       autoComplete="off"
//                       className={styleInput(null, product.codigoAuxiliar)}
//                     />
//                   </Grid>
//                   <Grid item xs={12} md={3} className={classes.divForm}>
//                     <label>
//                       Cantidad: <font color={palette.error.main}>*</font>
//                     </label>
//                   </Grid>
//                   <Grid item xs={12} md={9} className={classes.divInput}>
//                     <input
//                       className={styleInput(
//                         errors.cantidadProducto,
//                         product.cantidadProducto
//                       )}
//                       type="text"
//                       value={product.cantidadProducto}
//                       onChange={handleChange("cantidadProducto")}
//                       autoComplete="off"
//                     />
//                     {errors.cantidadProducto ? (
//                       <p className={classesApp.errorLetters}>
//                         {errors.cantidadProducto}
//                       </p>
//                     ) : null}
//                   </Grid>
//                   <Grid item xs={12} md={3} className={classes.divForm}>
//                     <label>Descripción:</label>
//                   </Grid>
//                   <Grid item xs={12} md={9} className={classes.divInput}>
//                     <input
//                       className={styleInput(null, product.descripcion)}
//                       type="text"
//                       disabled
//                       value={product.descripcion}
//                     />
//                   </Grid>
//                   <Grid item xs={12} md={3} className={classes.divForm}>
//                     <label>
//                       Precio Unitario: <font color={palette.error.main}>*</font>
//                     </label>
//                   </Grid>
//                   <Grid item xs={12} md={9} className={classes.divInput}>
//                     <input
//                       className={styleInput(
//                         errors.precioUnitario,
//                         product.precioUnitario
//                       )}
//                       type="text"
//                       placeholder="Ej. 12.00"
//                       name="precioUnitario"
//                       value={product.precioUnitario}
//                       onChange={handleChange("precioUnitario")}
//                       autoComplete="off"
//                     />
//                     {errors.precioUnitario ? (
//                       <p className={classesApp.errorLetters}>
//                         {errors.precioUnitario}
//                       </p>
//                     ) : null}
//                   </Grid>

//                   <Grid item xs={12} md={3} className={classes.divForm}>
//                     <label>Descuento: </label>
//                   </Grid>
//                   <Grid item xs={12} md={4} className={classes.divInput}>
//                     <input
//                       className={styleInput(
//                         errors.descuentoProducto,
//                         product.descuentoProducto
//                       )}
//                       type="text"
//                       placeholder="Ej. 1"
//                       name="descuentoProducto"
//                       value={product.descuentoProducto}
//                       onChange={handleChange("descuentoProducto")}
//                       autoComplete="off"
//                       onBlur={handleChange("descuentoProducto")}
//                     />
//                     {errors.descuentoProducto ? (
//                       <p className={classesApp.errorLetters}>
//                         {errors.descuentoProducto}
//                       </p>
//                     ) : null}
//                   </Grid>
//                   <Grid item xs={12} md={5} className={classes.divInput}>
//                     <FormControl
//                       component="fieldset"
//                       className={classes.formControl}
//                     >
//                       <RadioGroup
//                         className={classes.radio}
//                         value={discountCheck? "P": "V"}
//                         onChange={handleChangeRadioDiscount}
//                       >
//                         <FormControlLabel
//                           value="V"
//                           control={
//                             <Radio
//                               color="primary"
//                               className={classes.radioButton}
//                             />
//                           }
//                           label="Valor"
//                           labelPlacement="start"
//                           className={classes.radioLable}
//                         />
//                         <FormControlLabel
//                           value="P"
//                           control={
//                             <Radio
//                               color="primary"
//                               className={classes.radioButton}
//                             />
//                           }
//                           label="Porcentaje"
//                           labelPlacement="start"
//                           className={classes.radioLable}
//                         />
//                       </RadioGroup>
//                     </FormControl>
//                   </Grid>
//                   <Grid item xs={12} md={3} className={classes.divForm}>
//                     <label>
//                       IVA: <font color={palette.error.main}>*</font>
//                     </label>
//                   </Grid>
//                   <Grid item xs={12} md={9} className={classes.divInput}>
//                     <FormControl className={classesGlobales.select}>
//                       <NativeSelect
//                         value={iva}
//                         onChange={(event) => setIva(event.target.value)}
//                         input={<BootstrapInput />}
//                       >
//                         <option aria-label="None" disabled>
//                           --Seleccione--
//                         </option>
//                         {valorIva.map((iva) => (
//                           <option key={iva.CODIGO} value={iva.CODIGO}>
//                             {iva.NOMBRE}
//                           </option>
//                         ))}
//                       </NativeSelect>
//                     </FormControl>
//                   </Grid>

//                   <Grid item xs={12} md={3} className={classes.divForm}>
//                     <label>ICE:</label>
//                   </Grid>
//                   <Grid item xs={12} md={9} className={classes.divInput}>
//                     <FormControl className={classes.margin}>
//                       <NativeSelect
//                         value={ice}
//                         onChange={(event) => setIce(event.target.value)}
//                         input={<BootstrapInput />}
//                       >
//                         <option aria-label="None" disabled>
//                           --Seleccione--
//                         </option>
//                         {ListICE.map((ice) => (
//                           <option key={ice.CODIGO} value={ice.CODIGO}>
//                             {`${ice.CODIGO} - ${ice.NOMBRE}`}
//                           </option>
//                         ))}
//                       </NativeSelect>
//                     </FormControl>
//                   </Grid>
//                 </Grid>
//               </Grid>
//               <Grid item xs={12} md={6}>
//                 <Grid
//                   container
//                   item
//                   xs={12}
//                   md={12}
//                   justify="center"
//                   alignItems="center"
//                 >
//                   <FormControlLabel
//                     control={
//                       <Checkbox
//                         checked={isAditional}
//                         onChange={handleChangeCheck}
//                         name="isAditional"
//                         color="primary"
//                       />
//                     }
//                     label="Desea agregar información adicional al detalle?"
//                   />
//                 </Grid>
//                 {isAditional ? (
//                   <Grid
//                     container
//                     xs={12}
//                     md={12}
//                     justify="center"
//                     alignItems="center"
//                   >
//                     <EditTable
//                       dataAditional={dataAditional}
//                       setDataAditional={setDataAditional}
//                     />
//                   </Grid>
//                 ) : null}
//               </Grid>

//               <Grid item xs={12} md={12} className={classes.divButton}>
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   onClick={handleAddProduct}
//                   className={classesGlobales.button}
//                 >
//                   Agregar
//                 </Button>

//                 <Button
//                   variant="outlined"
//                   color="secondary"
//                   onClick={handleClose}
//                   className={classesGlobales.button}
//                 >
//                   Cancelar
//                 </Button>
//               </Grid>
//             </Grid>
//           </form>
//         </DialogContent>
//       </Dialog>
//     </Fragment>
//   );
// };

// export default AddDetail;

import React, { Fragment, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  makeStyles,
  CircularProgress,
  FormControl,
  FormControlLabel,
  NativeSelect,
  withStyles,
  InputBase,
  TextField,
  Checkbox,
  Divider,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { palette, useStylesGlobal } from "configurations/styles/theme";
import { Alert, Autocomplete } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useStylesApp } from "hooks/useStyles";
import { valorIva } from "../Forms/typeVoucher";
import { valorICE } from "../Forms/typeVoucher";
import EditTable from "widgets/Tables/EditTable";
import { getRUCEmpresa } from "redux/actions/userActions";

import validations, { styleInput } from "./validations";
import { searchProduct_WebService, serviceCalculationValues } from "./services";
import AddProductsBtn from "views/administración/productsServices/Buttons/AddProduct";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(3),
    minWidth: 120,
    display: "flex"
  },
  radio: {
    display: "-webkit-box",
    marginTop: "0px",
  },
  radioLable: {
    color: palette.primary.main,
    display: "flex",
  },
  radioButton: {
    marginTop: "0px",
    paddingTop: "0px",
  },
  divForm: {
    marginBottom: "1%",
  },
  divInput: {
    marginBottom: "1%",
    paddingRight: "1%",
    paddingLeft: "0.5%",
  },
  divButton: {
    paddingLeft: "3%",
    paddingRight: "3%",
    textAlign: "center",
    display: "flex",
    justifyContent: "space-around",
    marginTop: "1%",
    marginBottom: "1%",
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 999,
    color: palette.primary.main,
  },
}));
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(0),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const initProduct = {
  precioUnitario: "0.00",
  descuentoProducto: "",
  cantidadProducto: "1",
  codigoAuxiliar: "",
  descripcion: "",
};

const ListICE = [{ CODIGO: "", NOMBRE: " Ninguno -" }, ...valorICE]

const AddDetail = ({
  txtBtn = "Agregar Detalle",
  onSubmit,
  onOpen = () => true,
}) => {
  //estilos
  const classes = useStyles();
  const classesGlobales = useStylesGlobal();
  const classesApp = useStylesApp();

  //manejo de dialogos
  const [open, setOpen] = useState(false);
  const [msgError, setMsg] = useState({ show: false, msg: null, type: null });
  const { enqueueSnackbar } = useSnackbar();

  const userToken = useSelector((state) => state.user.tk);

  //gestion datos del formulario
  const [product, setProduct] = useState(initProduct);
  const [iva, setIva] = useState("4");
  const [ice, setIce] = useState("");
  const [discountCheck, setDiscountCheck] = useState(false);
  const [dataAditional, setDataAditional] = useState([]);

  //gestions autocomplete - buscador de productos
  const [options, setOptions] = useState([]);
  const loading = false && options.length === 0;

  //validar inputs vacios
  const [isAditional, setIsAdditional] = useState(false);

  //manage form
  const [errors, setErrors] = useState({});
  const [existProduct, setExistProduct] = useState(false);

  const handleClickOpen = () => {
    const permitOpen = onOpen();
    setProduct(initProduct);
    setErrors({});
    if (permitOpen) setOpen(true);
  };

  const handleClose = () => {
    clearData();
    setOpen(false);
  };

  const handleAddProduct = () => {
    const newErrors = validations.submitDetail(product, discountCheck);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      if (typeof onSubmit === "function") {
        onSubmit(serviceCalculationValues(product, iva, ice, dataAditional, discountCheck));
        handleClose();
        enqueueSnackbar(`Detalle agregado al comprobante`, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          autoHideDuration: 4000,
        });
      }
    }
  };

  const handleChange = (nameInput) => (event, newValue) => {
    const newError = validations.onChange(nameInput, event.target.value);
    validations.onSave({
      error: newError,
      callbackSaveObj: handleSaveProduct,
      nameInput,
      valueInput: event.target.value || newValue,
    });

    setErrors({
      ...errors,
      [nameInput]: newError,
    });
  };

  const handleSaveProduct = (nameInput, valueInput) => {
    if (nameInput !== "codigoPrincipal") {
      setProduct({
        ...product,
        [nameInput]: valueInput,
      });
    } else {
      setProduct(valueInput);
    }
  };

  const handleChangeCheck = (event) => {
    setIsAdditional(event.target.checked);
  };

  function closeAlert() {
    setMsg({ show: false });
  }

  //método para la busqueda
  const searchProducts = async (params) => {
    searchProduct_WebService(
      setOptions,
      `/admin/productos/buscar/activos/${
        userToken ? getRUCEmpresa(userToken) : ""
      }?parametroBusqueda=${params}`,
      params,
      setExistProduct
    );
  };

  const clearData = () => {
    setProduct(initProduct);
    setIce("");
    setDataAditional([]);
  };

  const updateView = () => {};

  const handleChangeRadioDiscount = (event) => {
    setDiscountCheck(event.target.value === "P");
  };
  
  return (
    <Fragment>
      <Grid container justify="center" className={classes.divButton}>
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          {txtBtn}
        </Button>
      </Grid>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="max-width-dialog-title">Nuevo detalle</DialogTitle>
        <DialogContent>
          <form>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                >
                  {msgError.show ? (
                    <Alert
                      tipo={msgError.type}
                      mensaje={msgError.msg}
                      mesajeSubrayado={null}
                      titulo={null}
                      mostrar={msgError.show}
                      cerrarAlerta={closeAlert}
                    />
                  ) : null}
                  <Grid item xs={12} md={12}>
                    <label>
                      Los campos marcados con ({" "}
                      <font color={palette.error.main}> *</font> ) son
                      obligatorios:
                    </label>
                  </Grid>

                  {existProduct ? (
                    <Fragment>
                      <Grid item xs={12} md={12} className={classes.divForm}>
                        <Divider />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        align="center"
                        className={classes.divForm}
                      >
                        <AddProductsBtn updateView={updateView} />
                      </Grid>
                      <Grid item xs={12} md={12} className={classes.divForm}>
                        <Divider />
                      </Grid>
                    </Fragment>
                  ) : null}

                  <Grid item xs={12} md={3} className={classes.divForm}>
                    <label>
                      Código Principal:{" "}
                      <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>

                  <Grid item xs={12} md={9} className={classes.divInput}>
                    <Autocomplete
                      onChange={handleChange("codigoPrincipal")}
                      getOptionSelected={(option, value) =>
                        option.codigoPrincipal.trim() ===
                        value.codigoPrincipal.trim()
                      }
                      getOptionDisabled={(option) =>
                        option.descripcion.includes(
                          "No se encontró datos con el argumento ingresado"
                        ) ||
                        option.descripcion.includes(
                          "Error en la red al consultar el producto."
                        )
                      }
                      getOptionLabel={(option) =>
                        `${option.codigoPrincipal}-${option.descripcion}`
                      }
                      options={options}
                      noOptionsText={
                        "No se encontró datos con el argumento ingresado"
                      }
                      loading={options.length === 0}
                      loadingText="Ingrese al menos 1 caracter"
                      renderInput={(params) => (
                        <TextField
                          error={errors.codigoPrincipal ? true : false}
                          onChange={(event) => {
                            if (
                              event.target.value.trim().length >= 1 &&
                              event.target.value.trim().length <= 50
                            ) {
                              searchProducts(event.target.value.trim());
                            }
                          }}
                          {...params}
                          label=""
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                    {errors.codigoPrincipal ? (
                      <p className={classesApp.errorLetters}>
                        {errors.codigoPrincipal}
                      </p>
                    ) : null}
                  </Grid>

                  <Grid item xs={12} md={3} className={classes.divForm}>
                    <label>Código Auxiliar:</label>
                  </Grid>
                  <Grid item xs={12} md={9} className={classes.divInput}>
                    <input
                      type="text"
                      disabled
                      value={product.codigoAuxiliar}
                      autoComplete="off"
                      className={styleInput(null, product.codigoAuxiliar)}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} className={classes.divForm}>
                    <label>
                      Cantidad: <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={9} className={classes.divInput}>
                    <input
                      className={styleInput(
                        errors.cantidadProducto,
                        product.cantidadProducto
                      )}
                      type="text"
                      value={product.cantidadProducto}
                      onChange={handleChange("cantidadProducto")}
                      autoComplete="off"
                    />
                    {errors.cantidadProducto ? (
                      <p className={classesApp.errorLetters}>
                        {errors.cantidadProducto}
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} md={3} className={classes.divForm}>
                    <label>Descripción:</label>
                  </Grid>
                  <Grid item xs={12} md={9} className={classes.divInput}>
                    <input
                      className={styleInput(null, product.descripcion)}
                      type="text"
                      disabled
                      value={product.descripcion}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} className={classes.divForm}>
                    <label>
                      Precio Unitario: <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={9} className={classes.divInput}>
                    <input
                      className={styleInput(
                        errors.precioUnitario,
                        product.precioUnitario
                      )}
                      type="text"
                      placeholder="Ej. 12.00"
                      name="precioUnitario"
                      value={product.precioUnitario}
                      onChange={handleChange("precioUnitario")}
                      autoComplete="off"
                    />
                    {errors.precioUnitario ? (
                      <p className={classesApp.errorLetters}>
                        {errors.precioUnitario}
                      </p>
                    ) : null}
                  </Grid>

                  <Grid item xs={12} md={3} className={classes.divForm}>
                    <label>Descuento: </label>
                  </Grid>
                  <Grid item xs={12} md={4} className={classes.divInput}>
                    <input
                      className={styleInput(
                        errors.descuentoProducto,
                        product.descuentoProducto
                      )}
                      type="text"
                      placeholder="Ej. 1"
                      name="descuentoProducto"
                      value={product.descuentoProducto}
                      onChange={handleChange("descuentoProducto")}
                      autoComplete="off"
                      onBlur={handleChange("descuentoProducto")}
                    />
                    {errors.descuentoProducto ? (
                      <p className={classesApp.errorLetters}>
                        {errors.descuentoProducto}
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} md={5} className={classes.divInput}>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <RadioGroup
                        className={classes.radio}
                        value={discountCheck? "P": "V"}
                        onChange={handleChangeRadioDiscount}
                      >
                        <FormControlLabel
                          value="V"
                          control={
                            <Radio
                              color="primary"
                              className={classes.radioButton}
                            />
                          }
                          label="Valor"
                          labelPlacement="start"
                          className={classes.radioLable}
                        />
                        <FormControlLabel
                          value="P"
                          control={
                            <Radio
                              color="primary"
                              className={classes.radioButton}
                            />
                          }
                          label="Porcentaje"
                          labelPlacement="start"
                          className={classes.radioLable}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3} className={classes.divForm}>
                    <label>
                      IVA: <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={9} className={classes.divInput}>
                    <FormControl className={classesGlobales.select}>
                      <NativeSelect
                        value={iva}
                        onChange={(event) => setIva(event.target.value)}
                        input={<BootstrapInput />}
                      >
                        <option aria-label="None" disabled>
                          --Seleccione--
                        </option>
                        {valorIva.map((iva) => (
                          <option key={iva.CODIGO} value={iva.CODIGO}>
                            {iva.NOMBRE}
                          </option>
                        ))}
                      </NativeSelect>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={3} className={classes.divForm}>
                    <label>ICE:</label>
                  </Grid>
                  <Grid item xs={12} md={9} className={classes.divInput}>
                    <FormControl className={classes.margin}>
                      <NativeSelect
                        value={ice}
                        onChange={(event) => setIce(event.target.value)}
                        input={<BootstrapInput />}
                      >
                        <option aria-label="None" disabled>
                          --Seleccione--
                        </option>
                        {ListICE.map((ice) => (
                          <option key={ice.CODIGO} value={ice.CODIGO}>
                            {`${ice.CODIGO} - ${ice.NOMBRE}`}
                          </option>
                        ))}
                      </NativeSelect>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid
                  container
                  item
                  xs={12}
                  md={12}
                  justify="center"
                  alignItems="center"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isAditional}
                        onChange={handleChangeCheck}
                        name="isAditional"
                        color="primary"
                      />
                    }
                    label="Desea agregar información adicional al detalle?"
                  />
                </Grid>
                {isAditional ? (
                  <Grid
                    container
                    xs={12}
                    md={12}
                    justify="center"
                    alignItems="center"
                  >
                    <EditTable
                      dataAditional={dataAditional}
                      setDataAditional={setDataAditional}
                    />
                  </Grid>
                ) : null}
              </Grid>

              <Grid item xs={12} md={12} className={classes.divButton}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddProduct}
                  className={classesGlobales.button}
                >
                  Agregar
                </Button>

                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleClose}
                  className={classesGlobales.button}
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default AddDetail;
