import { Backdrop, Button, Card, CardHeader, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, Grid, IconButton, makeStyles, Radio, RadioGroup } from '@material-ui/core';
import { palette, useStylesGlobal } from 'configurations/styles/theme';
import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined';
import { useSnackbar } from 'notistack';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import OneToOne from 'views/facturacion/Vouchers/Add/OneToOne';
import SomeVoucher from 'views/facturacion/Vouchers/Add/SomeVoucher';

const useStyles = makeStyles((theme) => ({
    IconButton: {
        marginTop: "0%",
        marginBottom: "0%",
        padding: "0px",
    },
    textFieldComboBox: {
        minWidth: "75%",
        maxWidth: "95%",
    },
    cardContent: {
        width: "94%",
        height: "300px",
        margin: "3%",
    },
    fragment: {
        padding: "1px",
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    backdrop: {
        zIndex: theme.zIndex.modal + 1,
        color: palette.primary.main,
    },
}));

const UpVouchersIssuedBtn = ({ data }) => {

    const classes = useStyles();
    const classesGlobales = useStylesGlobal();
    const classesGlobal = useStylesGlobal();
    const [open, setOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [value, setValue] = React.useState("uno");
    var fileSaver = require("file-saver");

    const handleOpenClick = () => {
        setOpenDialog(true);
    }
    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    }

    return (

        <div>
            <IconButton
                aria-label="delete"
                className={classes.IconButton}
                variant="outlined"
                color="secondary"
                onClick={handleOpenClick}
            >
                <PublishOutlinedIcon
                    style={{ color: palette.primary.main }}
                />
            </IconButton>

            <Dialog fullWidth maxWidth="lg" open={openDialog} onClose={handleCloseDialog}
            >
                <DialogTitle id="max-width-dialog-title">Subir Comprobantes Recibidos:<strong> {data.razonSocialempresa} </strong></DialogTitle>
                <DialogContent>
                    <Fragment >
                        <Card className={classes.cardContent}>
                            <CardHeader
                                className={classesGlobales.cabecera}
                                title={"Carga de comprobantes"}
                                avatar={<CloudUploadIcon></CloudUploadIcon>}
                            />
                            <Grid>
                                <Divider className={classes.divider} />
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                spacing={2}
                            >
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        row
                                        aria-label="position"
                                        name="position"
                                        defaultValue="top"
                                        value={value}
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel
                                            value="uno"
                                            control={<Radio color="primary" />}
                                            label="Uno a uno"
                                            labelPlacement="start"
                                        />
                                        <FormControlLabel
                                            value="varios"
                                            control={<Radio color="primary" />}
                                            label="Varios"
                                            labelPlacement="start"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid>
                                <Divider className={classes.divider} />
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                spacing={2}
                            >
                                {value === "uno" ? (
                                    <OneToOne data={data.idempresa}></OneToOne>
                                ) : (
                                    <SomeVoucher data={data.idempresa}></SomeVoucher>
                                )}
                            </Grid>
                            <Grid>
                                <Divider className={classes.divider} />
                            </Grid>
                        </Card>

                        <Grid container spacing={3} justify="center">
                            <Grid item md={12} xs={12}>
                                <Divider></Divider>
                            </Grid>

                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleCloseDialog}
                                className={classesGlobal.marginButton}
                            >
                                Cancelar
                    </Button>
                        </Grid>
                    </Fragment>
                </DialogContent>


            </Dialog>


            <Backdrop open={open} className={classes.backdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>


    );
}

export default UpVouchersIssuedBtn;