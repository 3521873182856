import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Backdrop,
  CircularProgress,
  Divider,
} from "@material-ui/core";
//Notificacion errores https://iamhosseindhv.com/notistack/demos#variants
import { useSnackbar } from "notistack";
//componentes del sistema
import { API_POST_SAVE_USER } from "../apis";
import { palette, useStylesGlobal } from "configurations/styles/theme";
import { useSelector } from "react-redux";
import { getRUCEmpresa } from "redux/actions/userActions";
import clientBackend from "configurations/axios";
import Alert from "widgets/alert";
import useForm from "hooks/useFrom";
import validations from "../validations";
import { useStylesApp } from "hooks/useStyles";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { ContactlessOutlined } from "@material-ui/icons";
import { validationEmail } from "utils/checkInputs";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(0),
    minWidth: 120,
  },
  radio: {
    display: "-webkit-box",
    marginTop: "0px",
  },
  radioLable: {
    color: palette.primary.main,
    display: "flex",
  },
  radioButton: {
    marginTop: "0px",
    paddingTop: "0px",
  },
  divForm: {
    marginBottom: "1%",
  },
  divInput: {
    marginBottom: "1%",
    paddingRight: "1%",
    paddingLeft: "0.5%",
  },
  divButton: {
    paddingLeft: "3%",
    paddingRight: "3%",
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 999,
    color: palette.primary.main,
  },
}));

const AddUserBtn = ({ updateView }) => {
  const classes = useStyles();
  const classesApp = useStylesApp();
  const classesGlobal = useStylesGlobal();
  const [openLoader, setOpenLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [pasaporteCheck, setPasaporteCheck] = useState("C");
  const userToken = useSelector((state) => state.user.tk);
  const [msgError, setMsg] = useState({ show: false, msg: null, type: null });
  const { enqueueSnackbar } = useSnackbar();
  const [user, setUser] = useState({
    idEmpresa: userToken ? getRUCEmpresa(userToken) : "0",
  });

  const existError = () => {
    setOpenLoader(false);
  };

  const handleSave = async () => {
    await clientBackend
      .post(API_POST_SAVE_USER, user)
      .then((response) => {
        if (response.status === 200) {
          enqueueSnackbar("Usuario Agregado.", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            autoHideDuration: 10000,
          });
          updateView();
          setOpenLoader(false);
          setOpen(false);
        }
      })
      .catch((exception) => {
        if (exception.response) {
          if (exception.response.status === 400) {
            setMsg({ show: true, msg: "No se pudo almacenar el usuario." });
          } else if (exception.response.status === 404) {
            setMsg({
              show: true,
              msg: "No se encontró la empresa enviada para relacionarla con el usuario.",
            });
          } else if (exception.response.status === 409) {
            if (
              typeof exception.response.data === "object" &&
              exception.response.data !== null
            ) {
              if (
                typeof exception.response.data.apierror === "object" &&
                exception.response.data.apierror !== null
              ) {
                setMsg({
                  show: true,
                  msg:
                    exception.response.data.apierror.mensajeSistema ||
                    "La Identificación o el Correo ya se encuentra registrado a  otro usuario  del sistema",
                });
              } else {
                setMsg({
                  show: true,
                  msg: "La Identificación o el Correo ya se encuentra registrado a  otro usuario  del sistema",
                });
              }
            } else {
              setMsg({
                show: true,
                msg: "La Identificación o el Correo ya se encuentra registrado a  otro usuario  del sistema",
              });
            }
          } else {
            setMsg({
              show: true,
              msg: "Error al registrar el usuario. Contáctese con el administrador del Sistema.",
            });
          }
        } else {
          setMsg({
            show: true,
            msg: "Existen problemas en la red. Contáctese con el administrador del Sistema.",
          });
        }
        setOpenLoader(false);
      });
  };

  const { errors, handleSubmit, getObject } = useForm(
    handleSave,
    validations.addUser,
    existError
  );

  function closeAlert() {
    setMsg({ show: false });
  }

  const handleClickOpen = () => {
    setOpen(true);
    setUser({
      idEmpresa: userToken ? getRUCEmpresa(userToken) : "0",
      identificacionUsuario: "",
      correoPrincipalUsuario: "",
      nombreUsuario: "",
      telefonoPrincipalUsuario: "",
    });
    setMsg({ show: false, msg: null, type: null });
    errors.identificacionUsuario = null;
    errors.nombreUsuario = null;
    errors.correoPrincipalUsuario = null;
    errors.telefonoPrincipalUsuario = null;
    errors.direccionUsuario = null;
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeCheck = (event) => {
    setPasaporteCheck(event.target.value);
    getObject({
      ...user,
      typeDocumente: event.target.value,
    });
  };

  const handleChange = (event) => {
    let value = event.target.value;
    let re;
    switch (event.target.name) {
      case "identificacionUsuario":
        re = /^[\w|\\.|\\_|\\-]*$/;
        break;
      case "correoPrincipalUsuario":
        //re =/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        //re = /^[\w|\\.|\\_|\\$|\\&|\\*|\\+|\\=|\\-|\\@]*$/;
        re = /^([a-z0-9_\.-@]+)@([\da-z\.-]+)\.([a-z\.]{4,6})$/;
        console.log(value);
        break;
      case "telefonoPrincipalUsuario":
        re = /^[\w|\\.|\\_|\\(|\\)|\s|\\-]*$/;
        break;
      case "nombreUsuario":
        re = /^.*$/;
        break;
      case "direccionUsuario":
        re = /^.*$/;
        break;
    }

    if (event.target.name !== "correoPrincipalUsuario") {
      if (re.test(value)) {
        setUser({
          ...user,
          [event.target.name]: value,
        });
        getObject({
          ...user,
          [event.target.name]: value,
          typeDocumente: pasaporteCheck,
        });
      }
    } else {
      if (!re.test(value)) {
        setUser({
          ...user,
          [event.target.name]: value,
        });
        getObject({
          ...user,
          [event.target.name]: value,
          typeDocumente: pasaporteCheck,
        });
      }
    }
  };

  return (
    <Fragment>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        <PersonAddIcon
          style={{ color: palette.primary.contrastText, paddingRight: "3%" }}
          fontSize="small"
        />{" "}
        Agregar Usuario
      </Button>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="max-width-dialog-title">Nuevo Usuario</DialogTitle>
        <DialogContent>
          <form
            onSubmit={(event) => {
              setOpenLoader(true);
              handleSubmit(event);
            }}
          >
            <Grid
              container
              spacing={1}
              direction="row"
              justify="flex-start"
              alignItems="center"
            >
              <Grid item xs={12} md={12}>
                <label>
                  Los campos marcados con ({" "}
                  <font color={palette.error.main}> *</font> ) son obligatorios:
                </label>
              </Grid>
              {msgError.show ? (
                <Alert
                  tipo={msgError.type}
                  mensaje={msgError.msg}
                  mesajeSubrayado={null}
                  titulo={null}
                  mostrar={msgError.show}
                  cerrarAlerta={closeAlert}
                />
              ) : null}
              <Grid item xs={12} md={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={2} md={2} className={classes.divForm}>
                <label>
                  Identificación: <font color={palette.error.main}>*</font>
                </label>
              </Grid>
              <Grid item xs={12} sm={6} md={4} className={classes.divInput}>
                <input
                  type="text"
                  className={
                    errors.identificacionUsuario
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  placeholder="Ej. 1737199872"
                  name="identificacionUsuario"
                  value={user.identificacionUsuario}
                  onChange={handleChange}
                  autoComplete="off"
                  maxLength={30}
                  minLength={8}
                />
                {errors.identificacionUsuario ? (
                  <p className={classesApp.errorLetters}>
                    {errors.identificacionUsuario}
                  </p>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={4} md={6} className={classes.divForm}>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <RadioGroup
                    aria-label="tipoPersonaEmpresa"
                    name="pasaporteCheck"
                    className={classes.radio}
                    required
                    value={pasaporteCheck}
                    onChange={handleChangeCheck}
                  >
                    <FormControlLabel
                      value="P"
                      control={
                        <Radio
                          color="primary"
                          className={classes.radioButton}
                        />
                      }
                      label="Pasaporte"
                      labelPlacement="start"
                      className={classes.radioLable}
                    />
                    <FormControlLabel
                      value="C"
                      control={
                        <Radio
                          color="primary"
                          className={classes.radioButton}
                        />
                      }
                      label="Cédula/RUC"
                      labelPlacement="start"
                      className={classes.radioLable}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2} md={1} className={classes.divForm}>
                <label>
                  Nombre: <font color={palette.error.main}>*</font>
                </label>
              </Grid>
              <Grid item xs={12} sm={9} md={5} className={classes.divInput}>
                <input
                  type="text"
                  className={
                    errors.nombreUsuario
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  placeholder="Ej. Juan Mauricio Pérez Calle"
                  name="nombreUsuario"
                  value={user.nombreUsuario}
                  onChange={handleChange}
                  autoComplete="off"
                  maxLength={300}
                />
                {errors.nombreUsuario ? (
                  <p className={classesApp.errorLetters}>
                    {errors.nombreUsuario}
                  </p>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={2} md={1} className={classes.divForm}>
                <label>
                  Correo: <font color={palette.error.main}>*</font>
                </label>
              </Grid>
              <Grid item xs={12} sm={9} md={5} className={classes.divInput}>
                <input
                  type="text"
                  className={
                    errors.correoPrincipalUsuario
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  placeholder="Ej. user@ejemplo.com"
                  name="correoPrincipalUsuario"
                  value={user.correoPrincipalUsuario}
                  onChange={handleChange}
                  autoComplete="off"
                  maxLength={100}
                />
                {errors.correoPrincipalUsuario ? (
                  <p className={classesApp.errorLetters}>
                    {errors.correoPrincipalUsuario}
                  </p>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={2} md={1} className={classes.divForm}>
                <label>Teléfono:</label>
              </Grid>
              <Grid item xs={12} sm={9} md={5} className={classes.divInput}>
                <input
                  type="text"
                  className={
                    errors.telefonoPrincipalUsuario
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  placeholder="Ej. 3824290"
                  name="telefonoPrincipalUsuario"
                  value={user.telefonoPrincipalUsuario}
                  onChange={handleChange}
                  autoComplete="off"
                  maxLength={50}
                />
                {errors.telefonoPrincipalUsuario ? (
                  <p className={classesApp.errorLetters}>
                    {errors.telefonoPrincipalUsuario}
                  </p>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={2} md={1} className={classes.divForm}>
                <label>Dirección:</label>
              </Grid>
              <Grid item xs={12} sm={9} md={5} className={classes.divInput}>
                <input
                  type="text"
                  className={
                    errors.direccionUsuario
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  placeholder="Ej. Av. República y Pradera"
                  name="direccionUsuario"
                  value={user.direccionUsuario}
                  onChange={handleChange}
                  autoComplete="off"
                  maxLength={300}
                />
                {errors.direccionUsuario ? (
                  <p className={classesApp.errorLetters}>
                    {errors.direccionUsuario}
                  </p>
                ) : null}
              </Grid>
              <Grid container spacing={3} justify="center">
                <Grid item md={12} xs={12}>
                  <Divider></Divider>
                </Grid>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={classesGlobal.marginButton}
                >
                  Agregar
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleClose}
                  className={classesGlobal.marginButton}
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      <Backdrop className={classes.backdrop} open={openLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
};

export default AddUserBtn;
