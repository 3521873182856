import React, { useState } from "react";
import { Formik } from "formik";
import { Button, Grid, TextField } from "@material-ui/core";
import TextRequired from "componets/TextRequired";
import checkDocuments from "utils/checkDocument";
import TextError from "componets/TextError";
import { makeStyles } from "@material-ui/core";
import TextSimple from "componets/TextSimple";
import { Autocomplete } from "@material-ui/lab";
import { typeInvoice } from "views/facturacion/Forms/typeVoucher";
import InputMask from "react-input-mask";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  inputError: {
    border: "1px solid red",
  },
}));
const Addressee = () => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [addressee, setAddressee] = useState({
    codDocSustento: "",
    fechaEmisionDocSustento: new Date(),
  });

  const [detalleGuiaRemision, setDetalleGuiaRemision] = useState([]);

  const validateFields = (values) => {
    const errors = {};
    const re = /^[0-9]*$/;
    if (!values.identificacionDestinatario) {
      errors.identificacionDestinatario = "La identificación es necesaria";
    } else if (values.identificacionDestinatario.length < 10 || values.identificacionDestinatario.length>10 ) {
      errors.identificacionDestinatario =
        "la indentificación pude tener solo 10 caracteres";
    } else if (
      !checkDocuments.validarCedula(values.identificacionDestinatario)
    ) {
      errors.identificacionDestinatario = "La identificación  es invalida";
    }
    if (!values.razonSocialDestinatario) {
      errors.razonSocialDestinatario = "La razón social es necesaria";
    }
    if (!values.dirDestinatario) {
      errors.dirDestinatario = "La dirección  es necesaria";
    }
    if (!values.motivoTraslado) {
      errors.motivoTraslado = "El motivo traslado es necesaria";
    }
    if (!values.numDocSustento) {
      errors.numDocSustento = "EL número de Doc. sustento es necesario";
    }
    if (!values.numAutDocSustento) {
      errors.numAutDocSustento = "Ingrese el número de Aut. Doc. sustento";
    }
    if (!values.codigoInterno) {
      errors.codigoInterno = "Ingrese el código interno";
    }
    if (!values.descripcion) {
      errors.descripcion = "Ingrese la descripción";
    }
    if (!values.cantidad) {
      errors.cantidad = "Ingrese la cantidad";
    } else if (!re.test(values.cantidad)) {
      errors.cantidad = "La cantidad solo debe ser entera";
    }
    return errors;
  };

  const validateFieldsProducts = (values) => {
    const errors = {};
    const re = /^[0-9]*$/;
    if (!values.codigoInterno) {
      errors.codigoInterno = "Ingrese el código interno";
    }
    if (!values.descripcion) {
      errors.descripcion = "Ingrese la descripción";
    }
    if (!values.cantidad) {
      errors.cantidad = "Ingrese la cantidad";
    } else if (!re.test(values.cantidad)) {
      errors.cantidad = "La cantidad solo debe ser entera";
    }
    return errors;
  };

  const styleInput = (error, value) => {
    if (value || error) {
      if (error) {
        return "form-control is-invalid";
      } else {
        return "form-control is-valid";
      }
    } else {
      return "form-control";
    }
  };

  const handleChangeDate = (name) => (event) => {
    setAddressee({ ...addressee, [name]: event });
  };

  return (
    <div>
      <Grid container justify="flex-start" spacing={2}>
        <Grid item md={5} xs={12}>
          <Formik
            initialValues={{
              identificacionDestinatario: "",
              razonSocialDestinatario: "",
            }}
            validate={validateFields}
            onSubmit={(values) => {
              setAddressee({ ...addressee, values });
              if (
                typeof detalleGuiaRemision === "undefined" ||
                detalleGuiaRemision.length === 0
              ) {
                enqueueSnackbar(`Ingrese al menos un detalle`, {
                  variant: "warning",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                  autoHideDuration: 4000,
                });
              } 
            }}
          >
            {({ errors, handleSubmit, handleChange, values }) => (
              <form onSubmit={handleSubmit}>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item md={4} xs={12}>
                    <TextRequired text={"Identificación"} />
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <input
                      type="text"
                      placeholder="Ej. 1234567890"
                      name="identificacionDestinatario"
                      autoComplete="off"
                      value={values.identificacionDestinatario}
                      onChange={handleChange}
                      className={styleInput(
                        errors.identificacionDestinatario,
                        values.identificacionDestinatario
                      )}
                    />
                    <TextError
                      text={errors.identificacionDestinatario}
                    ></TextError>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextRequired text={"Razón social"} />
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <input
                      type="text"
                      placeholder="Ej. Empresa S.A."
                      name="razonSocialDestinatario"
                      autoComplete="off"
                      value={values.razonSocialDestinatario}
                      onChange={handleChange}
                      className={styleInput(
                        errors.razonSocialDestinatario,
                        values.razonSocialDestinatario
                      )}
                    />
                    <TextError
                      text={errors.razonSocialDestinatario}
                    ></TextError>
                  </Grid>

                  <Grid item md={4} xs={12}>
                    <TextRequired text={"Dirección"} />
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <input
                      type="text"
                      placeholder="Ej. Calle 1 y Calle 2"
                      name="dirDestinatario"
                      autoComplete="off"
                      value={values.dirDestinatario}
                      onChange={handleChange}
                      className={styleInput(
                        errors.dirDestinatario,
                        values.dirDestinatario
                      )}
                    />
                    <TextError text={errors.dirDestinatario}></TextError>
                  </Grid>

                  <Grid item md={4} xs={12}>
                    <TextRequired text={"Motivo traslado"} />
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <input
                      type="text"
                      placeholder="Ej. Venta"
                      name="motivoTraslado"
                      autoComplete="off"
                      value={values.motivoTraslado}
                      onChange={handleChange}
                      className={styleInput(
                        errors.motivoTraslado,
                        values.motivoTraslado
                      )}
                    />
                    <TextError text={errors.motivoTraslado}></TextError>
                  </Grid>

                  <Grid item md={4} xs={12}>
                    <TextSimple text={"Doc. Aduanero"} />
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <input
                      type="text"
                      name="docAduaneroUnico"
                      autoComplete="off"
                      value={values.docAduaneroUnico}
                      onChange={handleChange}
                      className={styleInput(
                        errors.docAduaneroUnico,
                        values.docAduaneroUnico
                      )}
                    />
                    <TextError text={errors.docAduaneroUnico}></TextError>
                  </Grid>

                  <Grid item md={4} xs={12}>
                    <TextSimple text={"Código establecimiento destino"} />
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <InputMask
                      type="text"
                      placeholder="Ej. 001"
                      mask="999"
                      name="codEstabDestino"
                      autoComplete="off"
                      value={values.codEstabDestino}
                      onChange={handleChange}
                      className={styleInput(
                        errors.codEstabDestino,
                        values.codEstabDestino
                      )}
                    />
                    <TextError text={errors.codEstabDestino}></TextError>
                  </Grid>

                  <Grid item md={4} xs={12}>
                    <TextSimple text={"Ruta"} />
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <input
                      type="text"
                      placeholder="Ej. Norte"
                      name="ruta"
                      autoComplete="off"
                      value={values.ruta}
                      onChange={handleChange}
                      className={styleInput(errors.ruta, values.ruta)}
                    />
                    <TextError text={errors.ruta}></TextError>
                  </Grid>

                  <Grid item md={4} xs={12}>
                    <TextRequired text={"Tipo Doc. sustento"} />
                  </Grid>

                  <Grid item md={8} xs={12}>
                    <Autocomplete
                      id="combo-box-demo"
                      name="codDocSustento"
                      value={values.codDocSustento}
                      options={typeInvoice}
                      onChange={(event, newValue) => {
                        //setDocSustento(newValue);
                        if (newValue) {
                          setAddressee({
                            ...addressee,
                            ["codDocSustento"]: newValue.CODIGO,
                          });
                        }
                      }}
                      getOptionLabel={(option) =>
                        `${option.CODIGO}-${option.NOMBRE}`
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="form-control"
                          variant="outlined"
                          placeholder="Seleccione"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextRequired text={"Número Doc. sustento"} />
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <InputMask
                      type="text"
                      mask="999-999-999999999"
                      placeholder="Ej. 001-001-000000012"
                      name="numDocSustento"
                      autoComplete="off"
                      value={values.numDocSustento}
                      onChange={handleChange}
                      className={styleInput(
                        errors.numDocSustento,
                        values.numDocSustento
                      )}
                    />
                    <TextError text={errors.numDocSustento}></TextError>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextRequired text={"Num. Autorización Doc. sustento"} />
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <input
                      type="text"
                      autoComplete="off"
                      name="numAutDocSustento"
                      value={values.numAutDocSustento}
                      onChange={handleChange}
                      className={styleInput(
                        errors.numAutDocSustento,
                        values.numAutDocSustento
                      )}
                    />
                    <TextError text={errors.numAutDocSustento}></TextError>
                  </Grid>

                  <Grid item md={4} xs={12}>
                    <TextRequired text={"Fecha emisión Doc. sustento"} />
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      locale={esLocale}
                    >
                      <KeyboardDatePicker
                        className={"form-control "}
                        autoOk
                        variant="inline"
                        disableFuture
                        inputVariant="outlined"
                        invalidDateMessage="Formato inválido."
                        format="dd-MM-yyyy"
                        InputAdornmentProps={{ position: "start" }}
                        name="fechaEmisionDocSustento"
                        value={addressee.fechaEmisionDocSustento}
                        onChange={handleChangeDate("fechaEmisionDocSustento")}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>

                <Button type="submit">Guardar</Button>
              </form>
            )}
          </Formik>
        </Grid>
        <Grid item md={5} xs={12}>
          <Formik
            initialValues={{
              codigoInterno: "",
              codigoAdicional: "",
            }}
            validate={validateFieldsProducts}
            onSubmit={(values) => {
              console.log(values);
              console.log(addressee);
            }}
          >
            {({ errors, handleSubmit, handleChange, values }) => (
              <form onSubmit={handleSubmit}>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  spacing={1}
                >
                  <Grid item md={4} xs={12}>
                    <TextRequired text={"Código interno"} />
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <input
                      type="text"
                      autoComplete="off"
                      name="codigoInterno"
                      placeholder="Ej. 022044"
                      value={values.codigoInterno}
                      onChange={handleChange}
                      className={styleInput(
                        errors.codigoInterno,
                        values.codigoInterno
                      )}
                    />
                    <TextError text={errors.codigoInterno}></TextError>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextSimple text={"Código adicional"} />
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <input
                      type="text"
                      autoComplete="off"
                      placeholder="Ej. 550"
                      name="codigoAdicional"
                      value={values.codigoAdicional}
                      onChange={handleChange}
                      className={styleInput(
                        errors.codigoAdicional,
                        values.codigoAdicional
                      )}
                    />
                    <TextError text={errors.codigoAdicional}></TextError>
                  </Grid>

                  <Grid item md={4} xs={12}>
                    <TextRequired text={"Descripción"} />
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <input
                      type="text"
                      autoComplete="off"
                      placeholder="Ej. Ordenador"
                      name="descripcion"
                      value={values.descripcion}
                      onChange={handleChange}
                      className={styleInput(
                        errors.descripcion,
                        values.descripcion
                      )}
                    />
                    <TextError text={errors.descripcion}></TextError>
                  </Grid>

                  <Grid item md={4} xs={12}>
                    <TextRequired text={"Cantidad"} />
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <input
                      type="text"
                      autoComplete="off"
                      placeholder="Ej. Ordenador"
                      name="cantidad"
                      value={values.cantidad}
                      onChange={handleChange}
                      className={styleInput(errors.cantidad, values.cantidad)}
                    />
                    <TextError text={errors.cantidad}></TextError>
                  </Grid>
                </Grid>

                <Button type="submit">Guardar Producto</Button>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </div>
  );
};

export default Addressee;
