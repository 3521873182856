// import React, { Fragment } from 'react';
// import { Grid, makeStyles, CircularProgress, Dialog, DialogTitle, DialogContent, Button, Backdrop, Avatar } from '@material-ui/core';
// import { palette, msmSwalExito, msmSwalError } from 'configurations/styles/theme';
// import { httpCore, clientBackendPDF } from 'configurations/axios'
// import { getRUCEmpresa, getSub } from 'redux/actions/userActions';
// import { useSelector, useDispatch } from 'react-redux';
// import SaveIcon from '@material-ui/icons/Save';
// import pdfIcon from 'assets/icons/pdf.png'
// import { useSnackbar } from 'notistack';
// import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
// import { ConfirmAlert, iconsAlert, ErrorsAlert } from 'utils/AlertsCustom';
// import { selectedResourceAction } from "redux/actions/resourcesActions";
// import { checkPreRequirements } from 'widgets/menu/preRedirect';
// import { getNumberVoucherSuccess } from 'redux/actions/numberVouchersAction';

// const useStyles = makeStyles((theme) => ({
//     root: {
//         flexGrow: 1,
//         padding: theme.spacing(2),
//         textAlign: 'center',
//         color: theme.palette.text.secondary,
//         margin: theme.spacing(1),
//         display: 'flex',
//         flexWrap: 'wrap',
//         marginBottom: '3%',
//         borderStyle: 'solid',
//         boxShadow: "5px 3px 3px #aaaaaa",
//         borderRadius: '8px',
//         justifyContent: 'center',
//         borderWidth: '1px'
//     },
//     formControl: {
//         marginTop: theme.spacing(0),
//         minWidth: 120,

//     },
//     divForm: {
//         marginBottom: '1%',
//         textAlign: 'justify',
//     },

//     backdrop: {
//         zIndex: theme.zIndex.modal + 999,
//         color: palette.primary.main,
//     },
//     haedClient: {
//         backgroundColor: palette.primary.main,
//         width: '10%',
//         marginBottom: '2%',
//         display: 'flex',
//         flexWrap: 'wrap',
//         marginBottom: '1%',
//         borderStyle: 'solid',
//         borderBottomWidth: '5px',
//         borderRadius: '4px',
//     },
//     msgDialog: {
//         padding: "2%"
//     },
//     btnReport: {
//         borderRadius: "8px",
//         borderStyle: "dashed",
//         borderWidth: "1px",
//         borderColor: palette.primary.main,
//         color: palette.primary.main,
//         marginTop: "1%",
//         marginRight: "1%"
//     },
//     small: {
//         width: theme.spacing(4),
//         height: theme.spacing(4),
//     },
//     divButton: {
//         paddingLeft: '3%',
//         paddingRight: '3%'
//     }
// }));

// const typeValidations = {

//     GENERATE_RIDE: 1,
//     SIGNED: 2
// }

// const ElectronicVoucherOptions = ({ clearAllData, fechaEmision, selectedMonth, selectedYear, additionalInfoInvoice, impuestoRetencions, idCliente }) => {

//     const classes = useStyles();
//     const tokenUser = useSelector(state => state.user.tk);
//     const [open, setOpen] = React.useState(false);
//     const [openLoader, setOpenLoader] = React.useState(false);
//     const { enqueueSnackbar } = useSnackbar();
//     const [errors, setErrors] = React.useState({});
//     const dispatch = useDispatch();
//     const selectedResource = (resource) => dispatch(selectedResourceAction(resource));

//     const getRetention = () => {
//         if (validation()) {
//             let infoAdicional = [];
//             if (idCliente.idAsignacionEmpresaCliente !== 0) {
//                 infoAdicional.push({ nombre: 'correo', valor: idCliente.correo })
//                 if (additionalInfoInvoice.length > 0) {
//                     Array.prototype.push.apply(infoAdicional, additionalInfoInvoice);
//                 }
//             } else {
//                 if (additionalInfoInvoice.length > 0) {
//                     Array.prototype.push.apply(infoAdicional, additionalInfoInvoice);
//                 }
//             }
//             return ({
//                 anioEjercicioFiscal: selectedYear.getFullYear(),
//                 //anioEjercicioFiscal: selectedYear.toString().substring(10, 15),
//                 fechaEmision: fechaEmision.toISOString(),
//                 //fechaEmision:fechaEmision.toISOString().substring(0, 10),   
//                 idAsigancionEmpresaUsuario: Number(getSub(tokenUser)),
//                 idAsignacionEmpresaCliente: idCliente.idAsignacionEmpresaCliente,
//                 impuestoRetencions: impuestoRetencions,
//                 infromacionAdicional: infoAdicional,
//                 mesEjercicioFiscal: (selectedMonth.getMonth() + 1) < 10 ? '0'.concat((selectedMonth.getMonth() + 1)) : selectedMonth.getMonth() + 1,
//                 rucEmpresa: getRUCEmpresa(tokenUser)

//             });

//         } else {
//             console.log(errors)
//             return null;
//         }
//     }

//     //para generar la factura
//     const generateRetention = async () => {
//         let perchase = getRetention();
//         if (perchase !== null) {
//             await httpCore.post(`/generacion/comprobantes/ebox/retencion`, perchase).then(
//                 response => {
//                     if (response.status === 200) {
//                         clearAllData();
//                         //para accctualizar el contador de comprobantes
//                         dispatch(getNumberVoucherSuccess(response.numeroComprobantesDisponibles));
//                         setOpenLoader(false);
//                         selectedResource({
//                             "id": -4,
//                             "nameMenu": "Verificando pre-requisitos",
//                             "titlePage": "Verificando pre-requisitos",
//                             titleTab: "Inicio",
//                             "menuPadre": ""
//                         })
//                         msmSwalExito(
//                             "Comprobante generado",
//                             `En breve será enviado al SRI para su autorización.`,
//                             { show: true, text: "Ver Comprobantes Pendientes" }
//                         ).then((response) => {
//                             if (response.isDismissed) {
//                                 selectedResource({
//                                     "id": "PEN",
//                                     "nameMenu": "Pendientes",
//                                     "titlePage": "Pendientes",
//                                     titleTab: "Comp. Pendientes",
//                                     "menuPadre": "REP"
//                                 })
//                             }
//                         });

//                     }

//                 }
//             ).catch(
//                 exception => {
//                     if (exception.response) {

//                         msmSwalError(exception.response.data.apierror ? exception.response.data.apierror.mensaje ? exception.response.data.apierror.mensaje : 'Error al generar el comprobante.' : 'Error al generar el comprobante.')
//                     } else {

//                         msmSwalError('Existen problemas en la red. Contáctese con el administrador del Sistema.')
//                     }

//                     setOpenLoader(false);
//                 }
//             );
//         }
//     }

//     const generateRide = async () => {
//         let perchase = getRetention();
//         if (perchase !== null) {
//             return await clientBackendPDF.post(`/core/generacion/comprobantes/ebox/ride/retencion`, perchase).then(
//                 response => {
//                     //Create a Blob from the PDF Stream
//                     const file = new Blob(
//                         [response.data],
//                         { type: 'application/pdf' });
//                     //Build a URL from the file
//                     const fileURL = URL.createObjectURL(file);
//                     setOpenLoader(false);
//                     //Open the URL on new Window
//                     window.open(fileURL);

//                 }
//             ).catch(
//                 exception => {
//                     if (exception.response) {
//                         enqueueSnackbar(`Error al generar el pdf.`, {
//                             variant: 'error',
//                             anchorOrigin: {
//                                 vertical: 'top',
//                                 horizontal: 'right',
//                             },
//                             autoHideDuration: 4000,
//                         });
//                         console.error('error', { exception });
//                     } else {
//                         enqueueSnackbar(`Existen problemas en la red. Contáctese con el administrador del Sistema.`, {
//                             variant: 'error',
//                             anchorOrigin: {
//                                 vertical: 'top',
//                                 horizontal: 'right',
//                             },
//                             autoHideDuration: 4000,
//                         });
//                         console.log({ exception });
//                     }
//                     setOpenLoader(false);
//                 }
//             )
//         }
//     }

//     const validation = () => {
//         let result = true;
//         const errors = {};
//         if (Object.entries(idCliente).length === 0) {

//             errors.cliente = 'Ingrese el proveedor'

//             result = false;
//         }
//         if (impuestoRetencions.length < 1) {

//             errors.impuesto = 'Ingrese al menos 1 impuesto'

//             result = false;
//         }
//         ErrorsAlert(errors)
//         return result;
//     }

//     const validatePrerequisities = (flagType) => () => {
//         const tk = tokenUser ? tokenUser : "";
//         if (tk !== "") {
//             setOpenLoader(true);
//             checkPreRequirements(getSub(tk), getRUCEmpresa(tk), dispatch)
//                 .then((newErrorsCompany) => {
//                     if (Array.isArray(newErrorsCompany)) {
//                         if (newErrorsCompany.length === 0) {
//                             if (flagType === typeValidations.GENERATE_RIDE) {
//                                 generateRide();
//                             } else {
//                                 generateRetention()
//                             }
//                         } else {
//                             selectedResource({
//                                 "id": -4,
//                                 "nameMenu": "Verificando pre-requisitos",
//                                 "titlePage": "Verificando pre-requisitos",
//                                 titleTab: "Inicio",
//                                 "menuPadre": ""
//                             })
//                         }
//                     }
//                 })
//                 .catch(() => {
//                     //setShowLoader(false);
//                     //setOpenLoader(false);
//                 }).finally(()=>{setOpenLoader(false)})
//         }
//     }


//     const clearForm = () => {
//         ConfirmAlert(
//             `¿Está seguro de limpiar los datos del formulario?`,
//             "",
//             iconsAlert.question,
//             true,
//             "Sí",
//             "Cancelar",
//             () => {
//                 clearAllData();
//             },
//             () => {

//             }
//         );
//     }

//     return (
//         <Fragment >

//             <div className={classes.root}  >
//                 <Grid item xs={12} md={4} align="center" className={classes.divButton} >
//                     <Button onClick={validatePrerequisities(typeValidations.GENERATE_RIDE)} variant="text" className={classes.btnReport} fullWidth>
//                         <Avatar variant="square" src={pdfIcon} className={classes.small} />
//                             Visualizar RIDE
//                     </Button>
//                 </Grid>
//                 <Grid item xs={12} md={4} align="center" className={classes.divButton}>
//                     <Button onClick={validatePrerequisities(typeValidations.SIGNED)} variant="text" className={classes.btnReport} fullWidth>
//                         <SaveIcon style={{ color: palette.info.main }} fontSize="large" />
//                             Firmar y autorizar
//                      </Button>
//                 </Grid>
//                 <Grid item xs={12} md={4} align="center">
//                     <Button
//                         onClick={clearForm}
//                         variant="text"
//                         className={classes.btnReport}
//                         fullWidth
//                     >
//                         <DeleteSweepIcon style={{ color: palette.error.main, marginRight: "10px" }} fontSize="large" />
//                         Limpiar Formulario
//                     </Button>
//                 </Grid>


//                 <Backdrop className={classes.backdrop} open={openLoader} >
//                     <CircularProgress color="primary" />
//                 </Backdrop>
//             </div>



//         </Fragment>

//     );
// }

// export default ElectronicVoucherOptions;






import React, { Fragment, useState } from "react";
import {
  Grid,
  makeStyles,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Backdrop,
  Avatar,
} from "@material-ui/core";
import {
  palette,
  msmSwalExito,
  msmSwalError,
} from "configurations/styles/theme";
import { httpCore, clientBackendPDF } from "configurations/axios";
import { getRUCEmpresa, getSub } from "redux/actions/userActions";
import { useSelector, useDispatch } from "react-redux";
import SaveIcon from "@material-ui/icons/Save";
import pdfIcon from "assets/icons/pdf.png";
import { useSnackbar } from "notistack";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import { ConfirmAlert, iconsAlert, ErrorsAlert } from "utils/AlertsCustom";
import { selectedResourceAction } from "redux/actions/resourcesActions";
import { checkPreRequirements } from "widgets/menu/preRedirect";
import { getNumberVoucherSuccess } from "redux/actions/numberVouchersAction";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    margin: theme.spacing(1),
    display: "flex",
    flexWrap: "wrap",
    marginBottom: "3%",
    borderStyle: "solid",
    boxShadow: "5px 3px 3px #aaaaaa",
    borderRadius: "8px",
    justifyContent: "center",
    borderWidth: "1px",
  },
  formControl: {
    marginTop: theme.spacing(0),
    minWidth: 120,
  },
  divForm: {
    marginBottom: "1%",
    textAlign: "justify",
  },

  backdrop: {
    zIndex: theme.zIndex.modal,
    // color: palette.primary.main,
  },
  haedClient: {
    backgroundColor: palette.primary.main,
    width: "10%",
    marginBottom: "2%",
    display: "flex",
    flexWrap: "wrap",
    marginBottom: "1%",
    borderStyle: "solid",
    borderBottomWidth: "5px",
    borderRadius: "4px",
  },
  msgDialog: {
    padding: "2%",
  },
  btnReport: {
    borderRadius: "8px",
    borderStyle: "dashed",
    borderWidth: "1px",
    borderColor: palette.primary.main,
    color: palette.primary.main,
    marginTop: "1%",
    marginRight: "1%",
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  divButton: {
    paddingLeft: "3%",
    paddingRight: "3%",
  },
}));

const ElectronicVoucherOptions = ({
  clearAllData,
  fechaEmision,
  selectedMonth,
  selectedYear,
  additionalInfoInvoice,
  docsSustento,
  idCliente,
}) => {
  const classes = useStyles();
  const tokenUser = useSelector((state) => state.user.tk);
  const [open, setOpen] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const selectedResource = (resource) =>
    dispatch(selectedResourceAction(resource));

  const getRetention = () => {
    if (validation()) {
      let infoAdicional = [];
      if (idCliente.idAsignacionEmpresaCliente !== 0) {
        infoAdicional.push({ nombre: "correo", valor: idCliente.correo });
        if (additionalInfoInvoice.length > 0) {
          Array.prototype.push.apply(infoAdicional, additionalInfoInvoice);
        }
      } else {
        if (additionalInfoInvoice.length > 0) {
          Array.prototype.push.apply(infoAdicional, additionalInfoInvoice);
        }
      }
      return {
        anioEjercicioFiscal: selectedYear.getFullYear(),
        //anioEjercicioFiscal: selectedYear.toString().substring(10, 15),
        fechaEmision: fechaEmision.toISOString(),
        //fechaEmision:fechaEmision.toISOString().substring(0, 10),
        idAsigancionEmpresaUsuario: Number(getSub(tokenUser)),
        idAsignacionEmpresaCliente: idCliente.idAsignacionEmpresaCliente,
        docsSustento: docsSustento,
        infromacionAdicional: infoAdicional,
        mesEjercicioFiscal:
          selectedMonth.getMonth() + 1 < 10
            ? "0".concat(selectedMonth.getMonth() + 1)
            : selectedMonth.getMonth() + 1,
        rucEmpresa: getRUCEmpresa(tokenUser),
      };
    } else {
      console.log(errors);
      return null;
    }
  };

  //para generar la factura
  const generateRetention = async () => {
    let perchase = getRetention();
    if (perchase !== null) {
      await httpCore
        .post(`/generacion/comprobantes/ebox/retencion`, perchase)
        .then((response) => {
          if (response.status === 200) {
            clearAllData();
            //para accctualizar el contador de comprobantes
            dispatch(
              getNumberVoucherSuccess(response.numeroComprobantesDisponibles)
            );
            setOpenLoader(false);
            selectedResource({
              id: -4,
              nameMenu: "Verificando pre-requisitos",
              titlePage: "Verificando pre-requisitos",
              titleTab: "Inicio",
              menuPadre: "",
            });
            msmSwalExito(
              "Comprobante generado",
              `En breve será enviado al SRI para su autorización.`,
              { show: true, text: "Ver Comprobantes Pendientes" }
            ).then((response) => {
              if (response.isDismissed) {
                selectedResource({
                  id: "PEN",
                  nameMenu: "Pendientes",
                  titlePage: "Pendientes",
                  titleTab: "Comp. Pendientes",
                  menuPadre: "REP",
                });
              }
            });
          }
        })
        .catch((exception) => {
          if (exception.response) {
            msmSwalError(
              exception.response.data.apierror
                ? exception.response.data.apierror.mensaje
                  ? exception.response.data.apierror.mensaje
                  : "Error al generar el comprobante."
                : "Error al generar el comprobante."
            );
          } else {
            msmSwalError(
              "Existen problemas en la red. Contáctese con el administrador del Sistema."
            );
          }

          setOpenLoader(false);
        });
    }
  };
  const [resultAux, setResultAux] = useState(true);
  const generateRide = async () => {
    let perchase = getRetention();
    console.log(perchase);
    if (perchase !== null) {
      return await clientBackendPDF
        .post(`/core/generacion/comprobantes/ebox/ride/retencion`, perchase)
        .then((response) => {
          //Create a Blob from the PDF Stream
          const file = new Blob([response.data], { type: "application/pdf" });
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          setOpenLoader(false);
          //Open the URL on new Window
          window.open(fileURL);
        })
        .catch((exception) => {
          if (exception.response) {
            enqueueSnackbar(`Error al generar el pdf.`, {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              autoHideDuration: 4000,
            });
            console.error("error", { exception });
          } else {
            enqueueSnackbar(
              `Existen problemas en la red. Contáctese con el administrador del Sistema.`,
              {
                variant: "error",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
                autoHideDuration: 4000,
              }
            );
            console.log({ exception });
          }
          setOpenLoader(false);
        });
    }
  };

  const validation = () => {
    let result = true;
    // setResultAux(result);
    const errors = {};
    if (Object.entries(idCliente).length === 0) {
      errors.cliente = "Ingrese el proveedor";
      result = false;
      // setResultAux(result);
    }
    if (docsSustento.length < 1) {
      errors.docsSustento = "Ingrese al menos 1 documento sustento";
      result = false;
      // setResultAux(result);
    }
    ErrorsAlert(errors);
    setOpenLoader(false);
    // if (docsSustento.length >= 1 && Object.entries(idCliente).length === 1) {
    //   setResultAux(true);
    // }
    return result;
  };

  const validatePrerequisities = (ride) => {
    const tk = tokenUser ? tokenUser : "";
    if (tk !== "") {
      setOpenLoader(true);
      checkPreRequirements(getSub(tk), getRUCEmpresa(tk), dispatch)
        .then((newErrorsCompany) => {
          if (Array.isArray(newErrorsCompany)) {
            if (newErrorsCompany.length === 0) {
              if (ride) {
                generateRide();
              } else {
                generateRetention();
              }
            } else {
              selectedResource({
                id: -4,
                nameMenu: "Verificando pre-requisitos",
                titlePage: "Verificando pre-requisitos",
                titleTab: "Inicio",
                menuPadre: "",
              });
            }
          }
        })
        .catch(() => {
          //setShowLoader(false);
          setOpenLoader(false);
        });
    }
  };

  const clearForm = () => {
    ConfirmAlert(
      `¿Está seguro de limpiar los datos del formulario?`,
      "",
      iconsAlert.question,
      true,
      "Sí",
      "Cancelar",
      () => {
        clearAllData();
      },
      () => {}
    );
  };

  return (
    <Fragment>
      <div className={classes.root}>
        <Grid item xs={12} md={4} align="center" className={classes.divButton}>
          <Button
            onClick={() => validatePrerequisities(true)}
            variant="text"
            className={classes.btnReport}
            fullWidth
          >
            <Avatar variant="square" src={pdfIcon} className={classes.small} />
            Visualizar RIDE
          </Button>
        </Grid>
        <Grid item xs={12} md={4} align="center" className={classes.divButton}>
          <Button
            onClick={() => validatePrerequisities(false)}
            variant="text"
            className={classes.btnReport}
            fullWidth
          >
            <SaveIcon style={{ color: palette.info.main }} fontSize="large" />
            Firmar y autorizar
          </Button>
        </Grid>
        <Grid item xs={12} md={4} align="center">
          <Button
            onClick={clearForm}
            variant="text"
            className={classes.btnReport}
            fullWidth
          >
            <DeleteSweepIcon
              style={{ color: palette.error.main, marginRight: "10px" }}
              fontSize="large"
            />
            Limpiar Formulario
          </Button>
        </Grid>
        
        <Backdrop className={classes.backdrop} open={openLoader}>
          <CircularProgress color="primary" />
        </Backdrop>
      </div>
    </Fragment>
  );
};

export default ElectronicVoucherOptions;
