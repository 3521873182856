// import checkInputs from "utils/checkInputs";
// import checkDocument from "utils/checkDocument";
// import { numDecimales, numDecimalesDescuento } from "configurations/constant";
// import bigDecimal from "js-big-decimal";

// const validate = (nameInput, value) => {
//   const response = { value: value, error: null, keyWordError: null };
//   switch (nameInput) {
//     case "descuentoProducto":
//       if (checkInputs.countLenth(value) > 14) {
//         response.keyWordError = "isLong";
//         response.error = "No ingrese más de 14 dígitos.";
//       } else if (!checkInputs.isDecimal(value)) {
//         response.keyWordError = "isNotNumeric";
//         response.error = "Solo puede ingresar números.";
//       } else if (
//         checkInputs.countDecimals(value.toString()) > numDecimalesDescuento
//       ) {
//         response.keyWordError = "isLongDecimal";
//         response.error =
//           "Solo debe ingresar cantidades con 2 decimales como máximo, separados por 1 punto (.) .";
//       }

//       return response;
//     case "precioUnitario":
//     case "cantidadProducto":
//       if (checkInputs.countLenth(value) > 14) {
//         response.keyWordError = "isLong";
//         response.error = "No ingrese más de 14 dígitos.";
//       } else if (!checkInputs.isDecimal(value.toString())) {
//         response.keyWordError = "isNotDecimal";
//         response.error = "Solo puede ingresar números.";
//       } else if (checkInputs.countDecimals(value.toString()) > numDecimales) {
//         response.keyWordError = "isLongDecimal";
//         response.error =
//           "Solo debe ingresar cantidades con 6 decimales como máximo, separados por 1 punto (.) .";
//       }
//       return response;
//     default:
//       return response;
//   }
// };

// const validateReimbursement = (nameInput, value) => {
//   const response = { value: value, error: null, keyWordError: null };
//   switch (nameInput) {
//     case "idAsignacionEmpresaCliente":
//       if (!checkInputs.isDecimal(value)) {
//         response.keyWordError = "isNotNumeric";
//         response.error = "";
//       }
//       return response;

//     case "codDocReembolso":
//       // if (checkInputs.countLenth(value)>0) {
//       //     response.keyWordError = "isNotEmpty";
//       //     response.error = "";
//       //   }
//       return response;
//     case "tipoProveedorReembolso":
//       return response;
//     case "codPais":
//       return response;
//     case "estabDocReembolso":
//       return response;
//     case "ptoEmiDocReembolso":
//       return response;
//     case "secuencialDocReembolso":
//       return response;
//     case "fechaEmisionDocReembolso":
//       return response;
//     case "numeroautorizacionDocReembolso":
//       return response;

//     case "impuestos":
//       // if (value.length === 0) {
//       response.keyWordError = "isEmpty";
//       response.error = "";
//       // }
//       return response;
//     default:
//       return response;
//   }
// };

// //codigoPrincipal
// export const validationVoucher = {
//   onChange: function checkOnChange(nameInput, value) {
//     const response = { value: value, error: null, keyWordError: null };

//     switch (nameInput) {
//       case "codigoPrincipal":
//       case "codigoAuxiliar":
//         if (checkInputs.countLenth(value) > 25) {
//           response.keyWordError = "isLong";
//           response.error = "Solo puede ingresar 25 caracteres";
//         }
//         return response;
//       case "descuentoProducto":
//         if (checkInputs.countLenth(value) > 14) {
//           response.keyWordError = "isLong";
//           response.error = "";
//         } else if (!checkInputs.isDecimal(value)) {
//           response.keyWordError = "isNotDecimal";
//           response.error = "Solo puede ingresar números.";
//         } else if (!(Number(value.toString().trim()) >= 0)) {
//           response.keyWordError = "isNotDecimal";
//           response.error =
//             "Solo debe ingresar cantidades con 2 decimales como máximo, separados por 1 punto (.) .";
//         }

//         return response;
//       case "precioUnitario":
//       case "cantidadProducto":
//         if (checkInputs.countLenth(value) > 14) {
//           response.keyWordError = "isLong";
//           response.error = "";
//         } else if (!checkInputs.isDecimal(value)) {
//           response.keyWordError = "isNotDecimal";
//           response.error = "Solo puede ingresar números.";
//         } else if (!(Number(value.toString().trim()) >= 0)) {
//           response.keyWordError = "isNotDecimal";
//           response.error =
//             "Solo debe ingresar cantidades con 6 decimales como máximo, separados por 1 punto (.) .";
//         }
//         return response;
//       default:
//         return response;
//     }
//   },
//   onSubmit: function checkOnSubmit(data) {
//     const errors = {};
//     if (checkInputs.notNull(data.codigoPrincipal)) {
//       let validateLocal = validate("codigoPrincipal", data.codigoPrincipal);
//       if (validateLocal.error) {
//         errors.codigoPrincipal = validateLocal.error;
//       }
//     } else {
//       errors.codigoPrincipal = "Este campo no puede esatr vacío";
//     }
//     if (!checkInputs.notNull(data.descripcion)) {
//       errors.descripcion = "Este campo no puede esatr vacío";
//     }
//     if (checkInputs.notNull(data.cantidadProducto)) {
//       let validateLocal = validate("cantidadProducto", data.cantidadProducto);
//       if (validateLocal.error) {
//         errors.cantidadProducto = validateLocal.error;
//       }
//     } else {
//       errors.cantidadProducto = "Ingrese la cantidad";
//     }
//     if (checkInputs.notNull(data.precioUnitario)) {
//       let validateLocal = validate("precioUnitario", data.precioUnitario);
//       if (validateLocal.error) {
//         errors.precioUnitario = validateLocal.error;
//       }
//     } else {
//       errors.precioUnitario = "Ingrese el precio";
//     }
//     return errors;
//   },
// };

// export const validationReimbursement = {
//   onChange: function checkOnChange(nameInput, value) {
//     const response = { value: value, error: null, keyWordError: null };

//     switch (nameInput) {
//       case "codPais":
//       case "estabDocReembolso":
//       case "ptoEmiDocReembolso":
//         if (checkInputs.countLenth(value) > 3) {
//           response.keyWordError = "isLong";
//           response.error = "";
//         } else if (!checkInputs.isDecimal(value)) {
//           response.keyWordError = "isNotDecimal";
//           response.error = "Solo puede ingresar números.";
//         } else if (!(Number(value.toString().trim()) >= 0)) {
//           response.keyWordError = "isNotDecimal";
//           response.error =
//             "Solo debe ingresar cantidades con 6 decimales como máximo, separados por 1 punto (.) .";
//         }
//         return response;

//       case "secuencialDocReembolso":
//         if (checkInputs.countLenth(value) > 9) {
//           response.keyWordError = "isLong";
//           response.error = "";
//         } else if (!checkInputs.isDecimal(value)) {
//           response.keyWordError = "isNotDecimal";
//           response.error = "Solo puede ingresar números.";
//         } else if (!(Number(value.toString().trim()) >= 0)) {
//           response.keyWordError = "isNotDecimal";
//           response.error =
//             "Solo debe ingresar cantidades con 2 decimales como máximo, separados por 1 punto (.) .";
//         }
//         return response;

//       case "numeroautorizacionDocReembolso":
//         if (checkInputs.countLenth(value) > 49) {
//           response.keyWordError = "isLong";
//           response.error = "";
//         } else if (!checkInputs.isDecimal(value)) {
//           response.keyWordError = "isNotDecimal";
//           response.error = "Solo puede ingresar números.";
//         } else if (!(Number(value.toString().trim()) >= 0)) {
//           response.keyWordError = "isNotDecimal";
//           response.error =
//             "Solo debe ingresar cantidades con 2 decimales como máximo, separados por 1 punto (.) .";
//         }
//         return response;

//       default:
//         return response;
//     }
//   },
//   onSubmit: function checkOnSubmit({
//     idAsignacionEmpresaCliente,
//     codDocReembolso,
//     tipoProveedorReembolso,
//     codPais,
//     estabDocReembolso,
//     ptoEmiDocReembolso,
//     secuencialDocReembolso,
//     fechaEmisionDocReembolso,
//     numeroautorizacionDocReembolso,
//     impuestos,
//   }) {
//     const errors = {};
//     if (checkInputs.notNull(idAsignacionEmpresaCliente)) {
//       let validateLocal = validateReimbursement(
//         "idAsignacionEmpresaCliente",
//         idAsignacionEmpresaCliente
//       );
//       if (validateLocal.error) {
//         errors.idAsignacionEmpresaCliente = validateLocal.error;
//       }
//     } else {
//       errors.idAsignacionEmpresaCliente = "Este campo no puede estar vacío";
//     }

//     if (checkInputs.notNull(codDocReembolso)) {
//       let validateLocal = validateReimbursement(
//         "codDocReembolso",
//         codDocReembolso
//       );
//       if (validateLocal.error) {
//         errors.codDocReembolso = validateLocal.error;
//       }
//     } else {
//       errors.codDocReembolso = "Este campo no puede estar vacío";
//     }

//     if (checkInputs.notNull(tipoProveedorReembolso)) {
//       let validateLocal = validateReimbursement(
//         "tipoProveedorReembolso",
//         tipoProveedorReembolso
//       );
//       if (validateLocal.error) {
//         errors.tipoProveedorReembolso = validateLocal.error;
//       }
//     } else {
//       errors.tipoProveedorReembolso = "Este campo no puede estar vacío";
//     }

//     if (checkInputs.notNull(codPais)) {
//       let validateLocal = validateReimbursement("codPais", codPais);
//       if (validateLocal.error) {
//         errors.codPais = validateLocal.error;
//       }
//     } else {
//       errors.codPais = "Este campo no puede estar vacío";
//     }

//     if (checkInputs.notNull(estabDocReembolso)) {
//       let validateLocal = validateReimbursement(
//         "estabDocReembolso",
//         estabDocReembolso
//       );
//       if (validateLocal.error) {
//         errors.estabDocReembolso = validateLocal.error;
//       }
//     } else {
//       errors.estabDocReembolso = "Este campo no puede estar vacío";
//     }

//     if (checkInputs.notNull(ptoEmiDocReembolso)) {
//       let validateLocal = validateReimbursement(
//         "ptoEmiDocReembolso",
//         ptoEmiDocReembolso
//       );
//       if (validateLocal.error) {
//         errors.ptoEmiDocReembolso = validateLocal.error;
//       }
//     } else {
//       errors.ptoEmiDocReembolso = "Este campo no puede estar vacío";
//     }

//     if (checkInputs.notNull(secuencialDocReembolso)) {
//       let validateLocal = validateReimbursement(
//         "secuencialDocReembolso",
//         secuencialDocReembolso
//       );
//       if (validateLocal.error) {
//         errors.secuencialDocReembolso = validateLocal.error;
//       }
//     } else {
//       errors.secuencialDocReembolso = "Este campo no puede estar vacío";
//     }

//     if (checkInputs.notNull(fechaEmisionDocReembolso)) {
//       let validateLocal = validateReimbursement(
//         "fechaEmisionDocReembolso",
//         fechaEmisionDocReembolso
//       );
//       if (validateLocal.error) {
//         errors.fechaEmisionDocReembolso = validateLocal.error;
//       }
//     } else {
//       errors.fechaEmisionDocReembolso = "Este campo no puede estar vacío";
//     }

//     if (checkInputs.notNull(numeroautorizacionDocReembolso)) {
//       let validateLocal = validateReimbursement(
//         "numeroautorizacionDocReembolso",
//         numeroautorizacionDocReembolso
//       );
//       if (validateLocal.error) {
//         errors.numeroautorizacionDocReembolso = validateLocal.error;
//       }
//     } else {
//       errors.numeroautorizacionDocReembolso = "Este campo no puede estar vacío";
//     }

//     if (impuestos.length !== 0) {
//       let validateLocal = validateReimbursement("impuestos", impuestos);
//       if (validateLocal.error) {
//         errors.impuestos = validateLocal.error;
//       }
//     } else {
//       errors.impuestos = "La tabla impuestos no puede estar vacío";
//     }

//     // if (checkInputs.notNull(data.codigoPrincipal)) {
//     //   let validateLocal = validate("codigoPrincipal", data.codigoPrincipal);
//     //   if (validateLocal.error) {
//     //     errors.codigoPrincipal = validateLocal.error;
//     //   }
//     // } else {
//     //   errors.codigoPrincipal = "Este campo no puede esatr vacío";
//     // }
//     // if (!checkInputs.notNull(data.descripcion)) {
//     //   errors.descripcion = "Este campo no puede esatr vacío";
//     // }
//     // if (checkInputs.notNull(data.cantidadProducto)) {
//     //   let validateLocal = validate("cantidadProducto", data.cantidadProducto);
//     //   if (validateLocal.error) {
//     //     errors.cantidadProducto = validateLocal.error;
//     //   }
//     // } else {
//     //   errors.cantidadProducto = "Ingrese la cantidad";
//     // }
//     // if (checkInputs.notNull(data.precioUnitario)) {
//     //   let validateLocal = validate("precioUnitario", data.precioUnitario);
//     //   if (validateLocal.error) {
//     //     errors.precioUnitario = validateLocal.error;
//     //   }
//     // } else {
//     //   errors.precioUnitario = "Ingrese el precio";
//     // }

//     return errors;
//   },
// };

// export const calculateDiscount = (prodDetail) => {
//   if (
//     prodDetail.isDescuentoPorcentaje === "false" ||
//     prodDetail.isDescuentoPorcentaje === false
//   ) {
//     return Number(prodDetail.descuentoProducto);
//   } else {
//     let price = new bigDecimal(prodDetail.precioUnitario);
//     let count = new bigDecimal(prodDetail.cantidadProducto);
//     let porcent = new bigDecimal(prodDetail.descuentoProducto).divide(
//       new bigDecimal(100)
//     );
//     return Number(price.multiply(count).multiply(porcent).value);
//   }
// };

// export const validationAddPayment = {
//   onSubmit: function checkOnSubmit(data) {
//       const errors = {};
//       if (checkInputs.notNull(data.formaPago)) {
//           let validateLocal = validate('formaPago', data.formaPago);
//           if (validateLocal.error) {
//               errors.forma = validateLocal.error;
//           }
//       } else {
//           errors.formaPago = 'Seleccione la forma de pago';
//       }
//       if (checkInputs.notNull(data.unidadTiempo)) {
//           let validateLocal = validate('unidadTiempo', data.unidadTiempo);
//           if (validateLocal.error) {
//               errors.unidadTiempo = validateLocal.error;
//           }
//       } else {
//           errors.unidadTiempo = 'Seleccione la unidad de tiempo';
//       }
//       if (checkInputs.notNull(data.plazo)) {
//           let validateLocal = validate('plazo', data.plazo);
//           if (validateLocal.error) {
//               errors.plazo = validateLocal.error;
//           }
//       } else {
//           errors.plazo = 'Ingrese el plazo';
//       }
//       if (checkInputs.notNull(data.total)) {
//           let validateLocal = validate('total', data.total);
//           if (validateLocal.error) {
//               errors.total = validateLocal.error;
//           }
//       } else {
//           errors.total = 'Ingrese el valor a pagar';
//       }

//       return errors;
//   },

// }

import checkInputs from "utils/checkInputs";
import checkDocument from "utils/checkDocument";
import { numDecimales, numDecimalesDescuento } from "configurations/constant";
import bigDecimal from "js-big-decimal";

const validate = (nameInput, value) => {
  const response = { value: value, error: null, keyWordError: null };
  switch (nameInput) {
    case "descuentoProducto":
      if (checkInputs.countLenth(value) > 14) {
        response.keyWordError = "isLong";
        response.error = "No ingrese más de 14 dígitos.";
      } else if (!checkInputs.isDecimal(value)) {
        response.keyWordError = "isNotNumeric";
        response.error = "Solo puede ingresar números.";
      } else if (
        checkInputs.countDecimals(value.toString()) > numDecimalesDescuento
      ) {
        response.keyWordError = "isLongDecimal";
        response.error =
          "Solo debe ingresar cantidades con 2 decimales como máximo, separados por 1 punto (.) .";
      }

      return response;
    case "precioUnitario":
    case "cantidadProducto":
      if (checkInputs.countLenth(value) > 14) {
        response.keyWordError = "isLong";
        response.error = "No ingrese más de 14 dígitos.";
      } else if (!checkInputs.isDecimal(value.toString())) {
        response.keyWordError = "isNotDecimal";
        response.error = "Solo puede ingresar números.";
      } else if (checkInputs.countDecimals(value.toString()) > numDecimales) {
        response.keyWordError = "isLongDecimal";
        response.error =
          "Solo debe ingresar cantidades con 6 decimales como máximo, separados por 1 punto (.) .";
      }
      return response;
    default:
      return response;
  }
};

const validateReimbursement = (nameInput, value) => {
  const response = { value: value, error: null, keyWordError: null };
  switch (nameInput) {
    case "idAsignacionEmpresaCliente":
      if (!checkInputs.isDecimal(value)) {
        response.keyWordError = "isNotNumeric";
        response.error = "";
      }
      return response;

    case "codDocReembolso":
      // if (checkInputs.countLenth(value)>0) {
      //     response.keyWordError = "isNotEmpty";
      //     response.error = "";
      //   }
      return response;
    case "tipoProveedorReembolso":
      return response;
    case "codPais":
      return response;
    case "estabDocReembolso":
      return response;
    case "ptoEmiDocReembolso":
      return response;
    case "secuencialDocReembolso":
      return response;
    case "fechaEmisionDocReembolso":
      return response;
    case "numeroautorizacionDocReembolso":
      return response;
    case "detalleReembolso":
      return response;
    case "impuestos":
      // if (value.length === 0) {
      response.keyWordError = "isEmpty";
      response.error = "";
      // }
      return response;

    default:
      return response;
  }
};

//codigoPrincipal
export const validationVoucher = {
  onChange: function checkOnChange(nameInput, value) {
    const response = { value: value, error: null, keyWordError: null };

    switch (nameInput) {
      case "codigoPrincipal":
      case "codigoAuxiliar":
        if (checkInputs.countLenth(value) > 25) {
          response.keyWordError = "isLong";
          response.error = "Solo puede ingresar 25 caracteres";
        }
        return response;
      case "descuentoProducto":
        if (checkInputs.countLenth(value) > 14) {
          response.keyWordError = "isLong";
          response.error = "";
        } else if (!checkInputs.isDecimal(value)) {
          response.keyWordError = "isNotDecimal";
          response.error = "Solo puede ingresar números.";
        } else if (!(Number(value.toString().trim()) >= 0)) {
          response.keyWordError = "isNotDecimal";
          response.error =
            "Solo debe ingresar cantidades con 2 decimales como máximo, separados por 1 punto (.) .";
        }

        return response;
      case "precioUnitario":
      case "cantidadProducto":
        if (checkInputs.countLenth(value) > 14) {
          response.keyWordError = "isLong";
          response.error = "";
        } else if (!checkInputs.isDecimal(value)) {
          response.keyWordError = "isNotDecimal";
          response.error = "Solo puede ingresar números.";
        } else if (!(Number(value.toString().trim()) >= 0)) {
          response.keyWordError = "isNotDecimal";
          response.error =
            "Solo debe ingresar cantidades con 6 decimales como máximo, separados por 1 punto (.) .";
        }
        return response;
      default:
        return response;
    }
  },
  onSubmit: function checkOnSubmit(data) {
    const errors = {};
    if (checkInputs.notNull(data.codigoPrincipal)) {
      let validateLocal = validate("codigoPrincipal", data.codigoPrincipal);
      if (validateLocal.error) {
        errors.codigoPrincipal = validateLocal.error;
      }
    } else {
      errors.codigoPrincipal = "Este campo no puede esatr vacío";
    }
    if (!checkInputs.notNull(data.descripcion)) {
      errors.descripcion = "Este campo no puede esatr vacío";
    }
    if (checkInputs.notNull(data.cantidadProducto)) {
      let validateLocal = validate("cantidadProducto", data.cantidadProducto);
      if (validateLocal.error) {
        errors.cantidadProducto = validateLocal.error;
      }
    } else {
      errors.cantidadProducto = "Ingrese la cantidad";
    }
    if (checkInputs.notNull(data.precioUnitario)) {
      let validateLocal = validate("precioUnitario", data.precioUnitario);
      if (validateLocal.error) {
        errors.precioUnitario = validateLocal.error;
      }
    } else {
      errors.precioUnitario = "Ingrese el precio";
    }
    return errors;
  },
};

export const validationReimbursement = {
  onChange: function checkOnChange(nameInput, value) {
    const response = { value: value, error: null, keyWordError: null };

    switch (nameInput) {
      case "codPais":
      case "estabDocReembolso":
      case "ptoEmiDocReembolso":
        if (checkInputs.countLenth(value) > 3) {
          response.keyWordError = "isLong";
          response.error = "";
        } else if (!checkInputs.isDecimal(value)) {
          response.keyWordError = "isNotDecimal";
          response.error = "Solo puede ingresar números.";
        } else if (!(Number(value.toString().trim()) >= 0)) {
          response.keyWordError = "isNotDecimal";
          response.error =
            "Solo debe ingresar cantidades con 6 decimales como máximo, separados por 1 punto (.) .";
        }
        return response;

      case "secuencialDocReembolso":
        if (checkInputs.countLenth(value) > 9) {
          response.keyWordError = "isLong";
          response.error = "";
        } else if (!checkInputs.isDecimal(value)) {
          response.keyWordError = "isNotDecimal";
          response.error = "Solo puede ingresar números.";
        } else if (!(Number(value.toString().trim()) >= 0)) {
          response.keyWordError = "isNotDecimal";
          response.error =
            "Solo debe ingresar cantidades con 2 decimales como máximo, separados por 1 punto (.) .";
        }
        return response;

      case "numeroautorizacionDocReembolso":
        if (checkInputs.countLenth(value) > 49) {
          response.keyWordError = "isLong";
          response.error = "";
        } else if (!checkInputs.isDecimal(value)) {
          response.keyWordError = "isNotDecimal";
          response.error = "Solo puede ingresar números.";
        } else if (!(Number(value.toString().trim()) >= 0)) {
          response.keyWordError = "isNotDecimal";
          response.error =
            "Solo debe ingresar cantidades con 2 decimales como máximo, separados por 1 punto (.) .";
        }
        return response;

      // case "detalleReembolso":
      //   if (checkInputs.countLenth(value) > 49) {
      //     response.keyWordError = "isLong";
      //     response.error = "";
      //   } else if (!checkInputs.isDecimal(value)) {
      //     response.keyWordError = "isNotDecimal";
      //     response.error = "Solo puede ingresar números.";
      //   } else if (!(Number(value.toString().trim()) >= 0)) {
      //     response.keyWordError = "isNotDecimal";
      //     response.error =
      //       "Solo debe ingresar cantidades con 2 decimales como máximo, separados por 1 punto (.) .";
      //   }
      //   return response;

      default:
        return response;
    }
  },
  onSubmit: function checkOnSubmit({
    idAsignacionEmpresaCliente,
    codDocReembolso,
    tipoProveedorReembolso,
    codPais,
    estabDocReembolso,
    ptoEmiDocReembolso,
    secuencialDocReembolso,
    fechaEmisionDocReembolso,
    numeroautorizacionDocReembolso,
    detalleReembolso,
    impuestos,
  }) {
    const errors = {};
    if (checkInputs.notNull(idAsignacionEmpresaCliente)) {
      let validateLocal = validateReimbursement(
        "idAsignacionEmpresaCliente",
        idAsignacionEmpresaCliente
      );
      if (validateLocal.error) {
        errors.idAsignacionEmpresaCliente = validateLocal.error;
      }
    } else {
      errors.idAsignacionEmpresaCliente = "Este campo no puede estar vacío";
    }

    if (checkInputs.notNull(codDocReembolso)) {
      let validateLocal = validateReimbursement(
        "codDocReembolso",
        codDocReembolso
      );
      if (validateLocal.error) {
        errors.codDocReembolso = validateLocal.error;
      }
    } else {
      errors.codDocReembolso = "Este campo no puede estar vacío";
    }

    if (checkInputs.notNull(tipoProveedorReembolso)) {
      let validateLocal = validateReimbursement(
        "tipoProveedorReembolso",
        tipoProveedorReembolso
      );
      if (validateLocal.error) {
        errors.tipoProveedorReembolso = validateLocal.error;
      }
    } else {
      errors.tipoProveedorReembolso = "Este campo no puede estar vacío";
    }

    if (checkInputs.notNull(codPais)) {
      let validateLocal = validateReimbursement("codPais", codPais);
      if (validateLocal.error) {
        errors.codPais = validateLocal.error;
      }
    } else {
      errors.codPais = "Este campo no puede estar vacío";
    }

    if (checkInputs.notNull(estabDocReembolso)) {
      let validateLocal = validateReimbursement(
        "estabDocReembolso",
        estabDocReembolso
      );
      if (validateLocal.error) {
        errors.estabDocReembolso = validateLocal.error;
      }
    } else {
      errors.estabDocReembolso = "Este campo no puede estar vacío";
    }

    if (checkInputs.notNull(ptoEmiDocReembolso)) {
      let validateLocal = validateReimbursement(
        "ptoEmiDocReembolso",
        ptoEmiDocReembolso
      );
      if (validateLocal.error) {
        errors.ptoEmiDocReembolso = validateLocal.error;
      }
    } else {
      errors.ptoEmiDocReembolso = "Este campo no puede estar vacío";
    }

    if (checkInputs.notNull(secuencialDocReembolso)) {
      let validateLocal = validateReimbursement(
        "secuencialDocReembolso",
        secuencialDocReembolso
      );
      if (validateLocal.error) {
        errors.secuencialDocReembolso = validateLocal.error;
      }
    } else {
      errors.secuencialDocReembolso = "Este campo no puede estar vacío";
    }

    if (checkInputs.notNull(fechaEmisionDocReembolso)) {
      let validateLocal = validateReimbursement(
        "fechaEmisionDocReembolso",
        fechaEmisionDocReembolso
      );
      if (validateLocal.error) {
        errors.fechaEmisionDocReembolso = validateLocal.error;
      }
    } else {
      errors.fechaEmisionDocReembolso = "Este campo no puede estar vacío";
    }

    if (checkInputs.notNull(numeroautorizacionDocReembolso)) {
      let validateLocal = validateReimbursement(
        "numeroautorizacionDocReembolso",
        numeroautorizacionDocReembolso
      );
      if (validateLocal.error) {
        errors.numeroautorizacionDocReembolso = validateLocal.error;
      }
    } else {
      errors.numeroautorizacionDocReembolso = "Este campo no puede estar vacío";
    }

    if (checkInputs.notNull(detalleReembolso)) {
      let validateLocal = validateReimbursement(
        "detalleReembolso",
        detalleReembolso
      );
      if (validateLocal.error) {
        errors.detalleReembolso = validateLocal.error;
      }
    } else {
      errors.detalleReembolso = "Este campo no puede estar vacío";
    }

    if (impuestos.length !== 0) {
      let validateLocal = validateReimbursement("impuestos", impuestos);
      if (validateLocal.error) {
        errors.impuestos = validateLocal.error;
      }
    } else {
      errors.impuestos = "La tabla impuestos no puede estar vacío";
    }

    // if (checkInputs.notNull(data.codigoPrincipal)) {
    //   let validateLocal = validate("codigoPrincipal", data.codigoPrincipal);
    //   if (validateLocal.error) {
    //     errors.codigoPrincipal = validateLocal.error;
    //   }
    // } else {
    //   errors.codigoPrincipal = "Este campo no puede esatr vacío";
    // }
    // if (!checkInputs.notNull(data.descripcion)) {
    //   errors.descripcion = "Este campo no puede esatr vacío";
    // }
    // if (checkInputs.notNull(data.cantidadProducto)) {
    //   let validateLocal = validate("cantidadProducto", data.cantidadProducto);
    //   if (validateLocal.error) {
    //     errors.cantidadProducto = validateLocal.error;
    //   }
    // } else {
    //   errors.cantidadProducto = "Ingrese la cantidad";
    // }
    // if (checkInputs.notNull(data.precioUnitario)) {
    //   let validateLocal = validate("precioUnitario", data.precioUnitario);
    //   if (validateLocal.error) {
    //     errors.precioUnitario = validateLocal.error;
    //   }
    // } else {
    //   errors.precioUnitario = "Ingrese el precio";
    // }

    return errors;
  },
};

export const calculateDiscount = (prodDetail) => {
  if (
    prodDetail.isDescuentoPorcentaje === "false" ||
    prodDetail.isDescuentoPorcentaje === false
  ) {
    return Number(prodDetail.descuentoProducto);
  } else {
    let price = new bigDecimal(prodDetail.precioUnitario);
    let count = new bigDecimal(prodDetail.cantidadProducto);
    let porcent = new bigDecimal(prodDetail.descuentoProducto).divide(
      new bigDecimal(100)
    );
    return Number(price.multiply(count).multiply(porcent).value);
  }
};

export const validationAddPayment = {
  onSubmit: function checkOnSubmit(data) {
    const errors = {};
    if (checkInputs.notNull(data.formaPago)) {
      let validateLocal = validate("formaPago", data.formaPago);
      if (validateLocal.error) {
        errors.forma = validateLocal.error;
      }
    } else {
      errors.formaPago = "Seleccione la forma de pago";
    }
    if (checkInputs.notNull(data.unidadTiempo)) {
      let validateLocal = validate("unidadTiempo", data.unidadTiempo);
      if (validateLocal.error) {
        errors.unidadTiempo = validateLocal.error;
      }
    } else {
      errors.unidadTiempo = "Seleccione la unidad de tiempo";
    }
    if (checkInputs.notNull(data.plazo)) {
      let validateLocal = validate("plazo", data.plazo);
      if (validateLocal.error) {
        errors.plazo = validateLocal.error;
      }
    } else {
      errors.plazo = "Ingrese el plazo";
    }
    if (checkInputs.notNull(data.total)) {
      let validateLocal = validate("total", data.total);
      if (validateLocal.error) {
        errors.total = validateLocal.error;
      }
    } else {
      errors.total = "Ingrese el valor a pagar";
    }

    return errors;
  },
};
