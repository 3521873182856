import React, { Fragment, useState } from "react";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Grid,
  Button,
  Backdrop,
  CircularProgress,
  Divider,
  Select,
  FormControl,
  MenuItem,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
//Notificacion errores https://iamhosseindhv.com/notistack/demos#variants
import { useSnackbar } from "notistack";
//componentes del sistema
import { API_GET_ALL_PROFILES, API_PUT_UPDATE_PROFILE } from "../apis";
import { palette, useStylesGlobal } from "configurations/styles/theme";
import { useSelector } from "react-redux";
import { getRUCEmpresa } from "redux/actions/userActions";
import clientBackend from "configurations/axios";
import Alert from "widgets/alert";
import uuid from "react-uuid";

const useStyles = makeStyles((theme) => ({
  IconButton: {
    marginTop: "0%",
    marginBottom: "0%",
    padding: "0px",
  },
  divContent: {
    padding: "2%",
    height: "210px",
  },
  textFieldComboBox: {
    minWidth: "75%",
    maxWidth: "90%",
  },
  divButton: {
    paddingRight: "3%",

  },
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: palette.primary.main,
  },
  rows: {
    marginTop: "1%",
    marginBottom: "0.5%",
  },
  formControl: {
    minWidth: "100%",
    maxWidth: "100%",
  },
}));

const initialProfile = (userProfile) => {
  console.log(userProfile);
  switch (userProfile.nombre) {
    case 'ADMCNPC':
    case "ADM":
    case "ADMEMAGIC":
      return "administrador";
    case 'CREADOR':
      return 'creador';
    case "INT":
    case "INTEMAGIC":
      return "interno";
    default:
      return userProfile === undefined ? "" : userProfile.rol.toLowerCase();
  }
};

/**
 * @author Dany Lasso
 */
const EditProfileBtn = ({ user, updateView }) => {
  const [open, setOpen] = useState(false);
  const classesGlobal = useStylesGlobal();
  const [openLoader, setOpenLoader] = useState(false);
  const [profile, setProfile] = useState(
    initialProfile(user.asignacionPerfilUsuarios[0].perfil)
  );
  const [companyProfiles, setCompProfile] = useState([]);
  const [msgError, setMsg] = useState({ show: false, msg: null });
  const userToken = useSelector((state) => state.user.tk);
  const classes = useStyles();
  const [loadProfil, setLoadProfile] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = () => {
    setOpen(true);
    setLoadProfile(true);
    setCompProfile([]);
    setMsg({ show: false, msg: null });
  };

  function closeAlert() {
    setMsg({
      show: false,
    });
  }
  console.log(user);
  const handleClose = () => {
    setOpen(false);
  };

  const getProfiles = async (token) => {
    setLoadProfile(false);
    if (open) {
      await clientBackend
        .get(API_GET_ALL_PROFILES + getRUCEmpresa(token))
        .then((result) => {
          if (result.status === 200) {
            const profiles = result.data.map((item) => {
              item.key = uuid();
              switch (item.perfil.nombre) {
                case "ADMIN":
                case "ADM":
                case "ADMEMAGIC":
                  item.perfil.nombre = "administrador";
                  item.label = "administrador";
                  break;
                case "CREADOR":
                  item.perfil.nombre = "creador";
                  item.label = "creador";
                  break;
                case "INT":
                case "INTEMAGIC":
                  item.perfil.nombre = "interno";
                  item.label = "interno";
                  break;
                default:
                  item.perfil.nombre = item.perfil.rol;
                  item.label = item.perfil.rol.toLowerCase();
                  break;
              }
              return item;
            });
            setCompProfile([...profiles]);
          }
        })
        .catch((exception) => {
          setMsg({
            show: true,
            msg: "Ocurrió un problema al cargar los perfiles.",
          });
        });
    }
  };

  const handleChangeProfile = (event) => {
    setProfile(companyProfiles.find(item => item.key === event.target.value));
  };

  const renderProfile = () => {
    if (companyProfiles.length === 0 && userToken && loadProfil) {
      getProfiles(userToken);
      return <CircularProgress color="inherit" size={15} />;
    }
    return (
      <FormControl className={classes.formControl}>
        <Select
          value={profile.key}
          variant="filled"
          onChange={handleChangeProfile}
        >
          {companyProfiles.map((item) => (
            <MenuItem value={item.key} key={item.key}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const updateProfile = async () => {
    if (typeof profile === "object" && profile !== null) {
      if (typeof profile.perfil === "object") {
        setOpenLoader(true);
        if (
          profile.perfil.idPerfil ===
          user.asignacionPerfilUsuarios[0].perfil.idPerfil
        ) {
          enqueueSnackbar("Perfil Editado", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            autoHideDuration: 15000,
          });
          setOpen(false);
          setOpenLoader(false);
        } else {
          await clientBackend
            .put(
              API_PUT_UPDATE_PROFILE +
              user.idAsignacionEmpresaUsuario +
              "/" +
              profile.perfil.idPerfil
            )
            .then((result) => {
              if (result.status === 200) {
                enqueueSnackbar("Perfil Editado", {
                  variant: "success",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                  autoHideDuration: 10000,
                });
              }
              updateView();
              setOpen(false);
              setOpenLoader(false);
            })
            .catch((exception) => {
              if (exception.response) {
                if (exception.response.status === 404) {
                  setMsg({
                    show: true,
                    msg: "No se encontró la asignacion del perfil al usuario.",
                  });
                } else if (exception.response.status === 400) {
                  setMsg({
                    show: true,
                    msg:
                      "No se pudo actualizar la asignacion del perfil al usuario.",
                  });
                } else {
                  setMsg({
                    show: true,
                    msg:
                      "Error al actualizar el perfil. Contáctese con el administrador del Sistema.",
                  });
                }
              } else {
                setMsg({
                  show: true,
                  msg:
                    "Existen problemas en la red. Contáctese con el administrador del Sistema.",
                });
              }

              setOpenLoader(false);
            });
        }
      } else {
        setMsg({
          show: true,
          msg: "Aún no selecciona el nuevo perfil.",
        });
      }
    } else {
      setMsg({
        show: true,
        msg: "Aún no selecciona el nuevo perfil.",
      });
    }
  };



  return (
    <Fragment>
      <IconButton
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={handleClickOpen}
        className={classes.IconButton}
      >
        <AccountCircleIcon style={{ color: palette.primary.main }} />
      </IconButton>
      <Dialog
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="max-width-dialog-title">
          Editar el Perfil del Usuario
        </DialogTitle>
        <DialogContent className={classes.divContent}>
          <from>
            <Grid container spacing={3}>
              {msgError.show ? (
                <Alert
                  tipo="error"
                  mensaje={msgError.msg}
                  mesajeSubrayado={null}
                  titulo={null}
                  mostrar={msgError.show}
                  cerrarAlerta={closeAlert}
                />
              ) : null}
              <Grid
                container
                xs={12}
                justify="center"
                className={classes.rows}
                spacing={1}
              >
                <Grid item xs={10} md={3}>
                  Perfil actual:
                </Grid>
                <Grid item xs={10} md={8}>
                  <input
                    value={initialProfile(
                      user.asignacionPerfilUsuarios[0].perfil
                    )}
                    disabled
                    className="form-control is-valid"
                  />
                </Grid>
              </Grid>
              <Grid
                container
                xs={12}
                justify="center"
                className={classes.rows}
                spacing={1}
              >
                <Grid item xs={10} md={3}>
                  <label>Nuevo Perfil:</label>
                </Grid>
                <Grid item xs={10} md={8}>
                  {renderProfile()}
                </Grid>
              </Grid>

              <Grid
                container
                spacing={3}
                justify="center"
                className={classes.rows}
              >
                <Grid item md={12} xs={12}>
                  <Divider></Divider>
                </Grid>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={updateProfile}
                  className={classesGlobal.marginButton}
                >
                  Guardar
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleClose}
                  className={classesGlobal.marginButton}
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </from>
        </DialogContent>
      </Dialog>
      <Backdrop className={classes.backdrop} open={openLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
};

export default EditProfileBtn;
