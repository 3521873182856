import checkInputs from 'utils/checkInputs';
import {stringToNumber} from "utils/parseInput";

const validations = {
    editSecuencial: function validationEditSecuencial(updatedSecuencial) {
        const errors = {};
        if(!checkInputs.notNull(updatedSecuencial.codigoEstablecimiento)){
            errors.codigoEstablecimiento = 'El código del establecimiento es necesario.';
        }else if(!checkInputs.isNumeric(updatedSecuencial.codigoEstablecimiento)){
            errors.codigoEstablecimiento = 'Solo ingrese números.';
        } else if(updatedSecuencial.codigoEstablecimiento.length > 300){
            errors.codigoEstablecimiento = 'No ingrese más de 300 caracteres.';
        }
    

        if(!checkInputs.notNull(updatedSecuencial.puntoEmision)){
            errors.puntoEmision = 'El punto de emisión es necesario.';
        }else if(!checkInputs.isNumeric(updatedSecuencial.puntoEmision)){
            errors.puntoEmision = 'Solo ingrese números.';
        } else if(updatedSecuencial.puntoEmision.length > 300){
            errors.puntoEmision = 'No ingrese más de 300 caracteres.';
        }

        if(!checkInputs.notNull(updatedSecuencial.direccion)){
            errors.direccion = 'La dirección es necesaria.';
        }else if(!checkInputs.isAlphanumeric(updatedSecuencial.direccion)){
            errors.direccion = 'No ingrese caracteres especiales.';
        }else if(updatedSecuencial.direccion.length > 300){
            errors.direccion = 'No ingrese más de 300 caracteres.';
        }

        return errors;
    },
    addUser: function validationAddUser(secuencial) {
        const errors = {};
        if(typeof secuencial.codigoEstablecimiento === 'undefined'){
            errors.codigoEstablecimiento = 'El código del establecimiento es necesario.';
        }else if(!checkInputs.notNull(secuencial.codigoEstablecimiento)){
            errors.codigoEstablecimiento = 'El código del establecimiento es necesario.';
        }else if(!checkInputs.isNumeric(secuencial.codigoEstablecimiento)){
            errors.codigoEstablecimiento = 'Solo ingrese números.';
        }else if(secuencial.codigoEstablecimiento.length > 300){
            errors.codigoEstablecimiento = 'No ingrese más de 300 caracteres.';
        }

        if(typeof secuencial.puntoEmision === 'undefined'){
            errors.puntoEmision = 'El punto de emisión es necesario.';
        }else if(!checkInputs.notNull(secuencial.puntoEmision)){
            errors.puntoEmision = 'El punto de emisión es necesario.';
        }else if(!checkInputs.isNumeric(secuencial.puntoEmision)){
            errors.puntoEmision = 'Solo ingrese números.';
        }else if(secuencial.puntoEmision.length > 300){
            errors.puntoEmision = 'No ingrese más de 300 caracteres.';
        }
     
        if(typeof secuencial.direccion === 'undefined'){
            errors.direccion = 'La dirección es necesaria.';
        }else if(!checkInputs.notNull(secuencial.direccion)){
            errors.direccion = 'La dirección es necesaria.';
        }else if(!checkInputs.isAlphanumeric(secuencial.direccion)){
            errors.direccion = 'No ingrese caracteres especiales.';
        }else if(secuencial.direccion.length > 300){
            errors.direccion = 'No ingrese más de 300 caracteres.';
        }
        
        return errors;
    },
    handleNumSecuencial: function handleChangeSecuencial(numSecuencial) {
        let newNumSecuencial = stringToNumber(numSecuencial);
       
        if(newNumSecuencial === 0){
            return null;
        }else if(newNumSecuencial < 10){
            return "00" + newNumSecuencial;
        }else if(newNumSecuencial < 100){
            return "0" + newNumSecuencial;
        }else{
            return "" + newNumSecuencial;
        }
    }
}

export default validations;