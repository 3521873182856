import React, { useState } from "react";
import {
  makeStyles,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Divider,
  CardHeader,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormControl,
} from "@material-ui/core";
import {
  palette,
  useStylesGlobal,
  styleInput,
} from "configurations/styles/theme";
import { Autocomplete } from "@material-ui/lab";
import AdditionalInfoProdBtn from "../Buttons/AdditionalInfoProdBtn";
import { useStylesApp } from "hooks/useStyles";
import DeleteProductGuide from "../Buttons/DeleteProductGuide";
import tableStyle from "configurations/styles/table";
//Notificacion errores https://iamhosseindhv.com/notistack/demos#variants
import { useSnackbar } from "notistack";
import AssignmentIcon from "@material-ui/icons/Assignment";
import uuid from "react-uuid";
import TableDataSimplePag from "widgets/Tables/TableDataSimplePag";
import TableDataSimple from "widgets/Tables/TableDataSimple";
import InputMask from "react-input-mask";
import { typeInvoice } from "views/facturacion/Forms/typeVoucher";
//Validations
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { validations } from "../validations";
import AddDetailDispatcher from "./AddDetailDispatcher";
import TextRequiredAsterisks from "componets/TextRequiredAsterisks";
import EditDetailDispatcher from "../Buttons/EditDetailDispatcher";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    margin: theme.spacing(1),
    display: "flex",
    flexWrap: "wrap",
    marginBottom: "1%",
    borderStyle: "solid",
    boxShadow: "5px 3px 3px #aaaaaa",
    borderRadius: "8px",
    marginTop: "auto",
    borderWidth: "2px",
  },
  formControl: {
    marginTop: theme.spacing(0),
    minWidth: 120,
  },
  radio: {
    display: "-webkit-box",
    marginTop: "0px",
  },
  radioLable: {
    color: palette.primary.main,
    display: "flex",
  },
  radioButton: {
    marginTop: "0px",
    paddingTop: "0px",
  },
  divForm: {
    marginBottom: "1%",
    textAlign: "justify",
    color: theme.palette.text.primary,
  },
  divInput: {
    marginBottom: "1%",
    paddingRight: "1%",
    paddingLeft: "0.5%",
    textAlign: "justify",
  },
  divButton: {
    paddingLeft: "3%",
    paddingRight: "3%",
    textAlign: "center",
    display: "flex",
    justifyContent: "space-around",
    marginTop: "1%",
    marginBottom: "1%",
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 999,
    color: palette.primary.main,
  },
  haedClient: {
    backgroundColor: palette.primary.main,
    width: "10%",
    display: "flex",
    flexWrap: "wrap",
    borderStyle: "solid",
    borderBottomWidth: "5px",
    borderRadius: "8px",
  },
  headAddressee: {
    textAlign: "center",
  },
}));

const Addresse = ({
  viewAddressee,
  setViewAddressee,
  destinatarios,
  setDestinatarios,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const classesGlobales = useStylesGlobal();
  const classesApp = useStylesApp();
  const { enqueueSnackbar } = useSnackbar();
  const [openLoader, setOpenLoader] = useState(false);
  const [pasaporteCheck, setPasaporteCheck] = useState("C");

  //const [dataAditional, setDataAditional] = useState([]);

  const [viewDetalle, setViewDetalle] = useState(1);
  const [detalleGuiaRemision, setDetalleGuiaRemision] = useState([]);

  const [errors, setErrors] = useState({});

  const columns = [
    {
      title: "Cod. interno",
      field: "codigoInterno",
      cellStyle: tableStyle.cellTable,
    },
    {
      title: "Cod. adicional",
      field: "codigoAdicional",
      cellStyle: tableStyle.cellTable,
    },
    { title: "Cantidad", field: "cantidad", cellStyle: tableStyle.cellTable },
    {
      title: "Descripción",
      field: "descripcion",
      cellStyle: tableStyle.cellTable,
    },
    {
      title: "Inf. adicional",
      cellStyle: tableStyle.cellTable,
      width: "10%",
      render: (rowData) => (
        <AdditionalInfoProdBtn
          data={rowData}
          detalleGuiaRemision={detalleGuiaRemision}
          viewDetalle={viewDetalle}
          setViewDetalle={setViewDetalle}
        />
      ),
    },

    {
      title: "Editar",
      field: "",
      cellStyle: tableStyle.cellTable,
      width: "10%",
      render: (rowData) => (
        <EditDetailDispatcher
          data={rowData}
          detalleGuiaRemision={detalleGuiaRemision}
          viewDetalle={viewDetalle}
          setViewDetalle={setViewDetalle}
        />
      ),
    },
    {
      title: "Eliminar",
      cellStyle: tableStyle.cellTable,
      width: "10%",
      render: (rowData) => (
        <DeleteProductGuide
          data={rowData}
          detalleGuiaRemision={detalleGuiaRemision}
          setDetalleGuiaRemision={setDetalleGuiaRemision}
          viewDetalle={viewDetalle}
          setViewDetalle={setViewDetalle}
        />
      ),
    },
  ];

  const [addressee, setAddressee] = useState({
    id: uuid(),
    identificacionDestinatario: "",
    razonSocialDestinatario: "",
    dirDestinatario: "",
    motivoTraslado: "",
    docAduaneroUnico: "",
    codEstabDestino: "",
    ruta: "",
    codDocSustento: "",
    numDocSustento: "",
    numAutDocSustento: "",
    fechaEmisionDocSustento: null,
    detalleGuiaRemision: [],
  });
  const [emptyAddressee, setEmptyAddressee] = useState({
    identificacion: true,
    cedula: true,
    razon: true,
    direccion: true,
    motivo: true,
    numDocSustento: true,
    codEstabDestino: true,
    numAutDocSustento: true,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setEmptyAddressee({
      identificacion: true,
      razon: true,
      direccion: true,
      motivo: true,
      cedula: true,
      numDocSustento: true,
      codEstabDestino: true,
      numAutDocSustento: true,
    });

    setAddressee({
      identificacionDestinatario: "",
      razonSocialDestinatario: "",
      dirDestinatario: "",
      motivoTraslado: "",
      docAduaneroUnico: "",
      codEstabDestino: "",
      ruta: "",
      codDocSustento: "",
      numDocSustento: "",
      numAutDocSustento: "",
      fechaEmisionDocSustento: null,
      detalleGuiaRemision: [],
    });
    setDetalleGuiaRemision([]);
    setEmptyAddressee({
      identificacion: true,
      razon: true,
      direccion: true,
      motivo: true,
      cedula: true,
      numDocSustento: true,
      codEstabDestino: true,
      numAutDocSustento: true,
    });

    setOpen(false);
  };

  const handleChangeAddressee = (event) => {
    event.preventDefault();
    const re = /^[0-9\b.]+$/;
    const { error, value, keyWordError } = validations.onChange(
      event.target.name,
      event.target.value,
      pasaporteCheck
    );
    setAddressee({ ...addressee, [event.target.name]: value });
    if (event.target.name === "identificacionDestinatario") {
      if (keyWordError !== "isNotNumeric" && keyWordError !== "isLong") {
        setAddressee({
          ...addressee,
          [event.target.name]: value,
        });
      }

      setErrors({ ...errors, [event.target.name]: error });
    }
    if (event.target.name === "razonSocialDestinatario") {
      if (error === null) {
        setAddressee({ ...addressee, [event.target.name]: value });
      }
      setErrors({
        ...errors,
        [event.target.name]: error,
      });
    }
    if (event.target.name === "dirDestinatario") {
      if (error === null) {
        setAddressee({ ...addressee, [event.target.name]: value });
      }
      setErrors({
        ...errors,
        [event.target.name]: error,
      });
    }
    if (event.target.name === "motivoTraslado") {
      if (error === null) {
        setAddressee({ ...addressee, [event.target.name]: value });
      }
      setErrors({
        ...errors,
        [event.target.name]: error,
      });
    }
    if (event.target.name === "numDocSustento") {
      if (error === null) {
        setAddressee({ ...addressee, [event.target.name]: value });
      }
      setErrors({
        ...errors,
        [event.target.name]: error,
      });
    }
    if (event.target.name === "numAutDocSustento") {
      if (keyWordError !== "isNotNumeric" && keyWordError !== "isLong") {
        setAddressee({ ...addressee, [event.target.name]: value });
      }
      setErrors({
        ...errors,
        [event.target.name]: error,
      });

      if (
        !(
          addressee.numAutDocSustento.length === 9 ||
          addressee.numAutDocSustento.length === 49
        )
      ) {
        setErrors({
          ...errors,
          [event.target.name]: "Solo puede ingresar 10 o 49",
        });
      }
    }

    if (errors.codDocSustento) {
      setErrors({
        ...errors,
        ["codDocSustento"]: null,
      });
    }

    if (event.target.name === "docAduaneroUnico") {
      if (event.target.value === '' || re.test(event.target.value)) {
        setAddressee({ ...addressee, [event.target.name]: value });
      }
    }

    if (event.target.name === "ruta") {
      setAddressee({ ...addressee, [event.target.name]: value });
    }
    // if (event.target.name === "codEstabDestino") {
    //   setAddressee({ ...addressee, [event.target.name]: value });
    // }
    if (event.target.name === "codEstabDestino") {
      if (error === null) {
        setAddressee({ ...addressee, [event.target.name]: value });
      }
      setErrors({
        ...errors,
        [event.target.name]: error,
      });
    }

  };

  const handleChangeDate = (name) => (event) => {
    setAddressee({
      ...addressee,
      [name]: event,
    });
  };

  const handleChangeCheck = (event) => {
    setPasaporteCheck(event.target.value);
    setAddressee({
      ...addressee,
      [event.target.name]: event.target.value,
    });
  };

  const handleAddAddressee = (event) => {
    event.preventDefault();

    let newErros = validations.onSubmit(addressee);

    if (Object.keys(newErros).length > 0) {
      setErrors({ ...errors, ...newErros });
    } else if (
      typeof detalleGuiaRemision === "undefined" ||
      detalleGuiaRemision.length === 0
    ) {
      enqueueSnackbar(`Ingrese al menos un detalle`, {
        variant: "warning",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        autoHideDuration: 4000,
      });
    } else {
      enqueueSnackbar(`Destinatario agregado`, {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        autoHideDuration: 4000,
      });
      setDestinatarios([
        ...destinatarios,
        {
          ...addressee,
          detalleGuiaRemision: detalleGuiaRemision,
        },
      ]);
      clearForm();
    }
  };

  const clearForm = () => {
    setAddressee({
      id: uuid(),
      identificacionDestinatario: "",
      razonSocialDestinatario: "",
      dirDestinatario: "",
      motivoTraslado: "",
      docAduaneroUnico: "",
      codEstabDestino: "",
      ruta: "",
      codDocSustento: "",
      numDocSustento: "",
      numAutDocSustento: "",
      fechaEmisionDocSustento: "",
      detalleGuiaRemision: [],
    });
    setEmptyAddressee({
      identificacion: true,
      razon: true,
      direccion: true,
      motivo: true,
      cedula: true,
      numDocSustento: true,
      codEstabDestino: true,
    });
    setDetalleGuiaRemision([]);
    handleClose();
    if (viewAddressee === 1) {
      setViewAddressee(2);
    } else if (viewAddressee === 2) {
      setViewAddressee(1);
    }
  };

  return (
    <>
      <div className={classesGlobales.rootDiv}>
        <Grid container xs={12} justify="center">
          <CardHeader
            className={classesGlobales.cabecera}
            title={"Destinatarios"}
            avatar={<AssignmentIcon></AssignmentIcon>}
          />
        </Grid>

        <Grid item xs={12} justify="center" className={classes.divButton}>
          <Button variant="outlined" color="primary" onClick={handleClickOpen}>
            Agregar Destinatario
          </Button>
        </Grid>

        <Dialog
          fullWidth
          maxWidth="xl"
          open={open}
          onClose={handleClose}
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">
            Nuevo destinatario
          </DialogTitle>
          <DialogContent>
            <form
              onSubmit={(event) => {
                setOpenLoader(true);
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid
                      item
                      xs={12}
                      className={classes.haedClient}
                      style={{ color: palette.primary.contrastText }}
                      justify="center"
                      aria-controls="panel1a-content"
                    >
                      <label>DESTINATARIO</label>
                    </Grid>
                    <Grid item xs={12} className={classes.headAddressee}>
                      <TextRequiredAsterisks />
                    </Grid>
                    <Grid item md={12}>
                      <Divider></Divider>
                    </Grid>

                    <Grid item md={2} xs={12} className={classes.divForm}>
                      <label>
                        Identificación:{" "}
                        <font color={palette.error.main}>*</font>
                      </label>
                    </Grid>
                    <Grid item md={4} xs={12} className={classes.divInput}>
                      <input
                        type="text"
                        className={
                          errors.identificacionDestinatario
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="Ej. 1737199872"
                        name="identificacionDestinatario"
                        value={addressee.identificacionDestinatario}
                        onChange={handleChangeAddressee}
                        autoComplete="off"
                      />
                      {errors.identificacionDestinatario ? (
                        <p className={classesApp.errorLetters}>
                          {errors.identificacionDestinatario}
                        </p>
                      ) : null}
                    </Grid>
                    <Grid item md={6} xs={12} className={classes.divForm}>
                      <FormControl
                        component="fieldset"
                        className={classes.formControl}
                      >
                        <RadioGroup
                          aria-label="tipoDocumento"
                          name="pasaporteCheck"
                          className={classes.radio}
                          required
                          value={pasaporteCheck}
                          onChange={handleChangeCheck}
                        >
                          <FormControlLabel
                            value="P"
                            control={
                              <Radio
                                color="primary"
                                className={classes.radioButton}
                              />
                            }
                            label="Pasaporte"
                            labelPlacement="start"
                            className={classes.radioLable}
                          />
                          <FormControlLabel
                            value="C"
                            control={
                              <Radio
                                color="primary"
                                className={classes.radioButton}
                              />
                            }
                            label="Cédula/RUC"
                            labelPlacement="start"
                            className={classes.radioLable}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item md={12}>
                      <Divider></Divider>
                    </Grid>

                    <Grid item xs={2} className={classes.divForm}>
                      <label>
                        Razón social: <font color={palette.error.main}>*</font>
                      </label>
                    </Grid>
                    <Grid item xs={4} className={classes.divInput}>
                      <input
                        type="text"
                        placeholder="Ej. Empresa S.A."
                        name="razonSocialDestinatario"
                        value={addressee.razonSocialDestinatario}
                        onChange={handleChangeAddressee}
                        autoComplete="off"
                        className={styleInput(
                          errors.razonSocialDestinatario,
                          addressee.razonSocialDestinatario
                        )}
                      />
                      {errors.razonSocialDestinatario ? (
                        <p className={classesApp.errorLetters}>
                          {errors.razonSocialDestinatario}
                        </p>
                      ) : null}
                    </Grid>
                    <Grid item xs={2} className={classes.divForm}>
                      <label>
                        Dirección: <font color={palette.error.main}>*</font>
                      </label>
                    </Grid>
                    <Grid item xs={4} className={classes.divInput}>
                      <input
                        type="text"
                        placeholder="Ej. Calle 1 y Calle 2"
                        name="dirDestinatario"
                        value={addressee.dirDestinatario}
                        onChange={handleChangeAddressee}
                        autoComplete="off"
                        className={styleInput(
                          errors.dirDestinatario,
                          addressee.dirDestinatario
                        )}
                      />
                      {errors.dirDestinatario ? (
                        <p className={classesApp.errorLetters}>
                          {errors.dirDestinatario}
                        </p>
                      ) : null}
                    </Grid>
                    <Grid item xs={2} className={classes.divForm}>
                      <label>
                        Motivo traslado:
                        <font color={palette.error.main}> *</font>
                      </label>
                    </Grid>
                    <Grid item xs={4} className={classes.divInput}>
                      <input
                        type="text"
                        placeholder="Ej. Venta"
                        name="motivoTraslado"
                        value={addressee.motivoTraslado}
                        onChange={handleChangeAddressee}
                        autoComplete="off"
                        className={styleInput(
                          errors.motivoTraslado,
                          addressee.motivoTraslado
                        )}
                      />
                      {errors.motivoTraslado ? (
                        <p className={classesApp.errorLetters}>
                          {errors.motivoTraslado}
                        </p>
                      ) : null}
                    </Grid>
                    <Grid item xs={2} className={classes.divForm}>
                      <label>Doc. Aduanero:</label>
                    </Grid>
                    <Grid item xs={4} className={classes.divInput}>
                      <input
                        type="text"
                        maxLength="13"
                        name="docAduaneroUnico"
                        value={addressee ? addressee.docAduaneroUnico : ""}
                        onChange={handleChangeAddressee}
                        autoComplete="off"
                        className={
                          addressee
                            ? addressee.docAduaneroUnico.trim() !== ""
                              ? "form-control  is-valid"
                              : "form-control"
                            : "form-control"
                        }
                      />
                    </Grid>
                    <Grid item xs={2} className={classes.divForm}>
                      <label>Código establecimiento destino:</label>
                    </Grid> 
                    <Grid item xs={4} className={classes.divInput}>
                      <InputMask
                        type="text"
                        placeholder="Ej. 001"
                        mask="999"
                        name="codEstabDestino"
                        value={addressee.codEstabDestino}
                        onChange={handleChangeAddressee}
                        autoComplete="off"
                        className={
                          addressee
                            ? addressee.codEstabDestino.trim() !== ""
                              ? "form-control  is-valid"
                              : "form-control"
                            : "form-control"
                        }
                      />
                      {errors.codEstabDestino ? (
                        <p className={classesApp.errorLetters}>
                          {errors.codEstabDestino}
                        </p>
                      ) : null}
                    </Grid>
                    <Grid item xs={2} className={classes.divForm}>
                      <label>Ruta:</label>
                    </Grid>
                    <Grid item xs={4} className={classes.divInput}>
                      <input
                        type="text"
                        placeholder="Ej. Norte"
                        name="ruta"
                        value={addressee ? addressee.ruta : ""}
                        onChange={handleChangeAddressee}
                        autoComplete="off"
                        className={
                          addressee
                            ? addressee.ruta.trim() !== ""
                              ? "form-control  is-valid"
                              : "form-control"
                            : "form-control"
                        }
                      />
                    </Grid>

                    <Grid item xs={2} className={classes.divForm}>
                      <label>
                        Tipo Doc. sustento:{" "}
                      </label>
                    </Grid>
                    <Grid item xs={4}>
                      <Autocomplete
                        clearText={"Eliminar"}
                        disableClearable={false}
                        noOptionsText={
                          "Con el argumento ingresado no se encontraron datos"
                        }
                        id="combo-box-demo"
                        options={typeInvoice}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setAddressee({
                              ...addressee,
                              ["codDocSustento"]: newValue.CODIGO,
                            });
                          }
                        }}
                        getOptionLabel={(option) =>
                          `${option.CODIGO}-${option.NOMBRE}`
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="form-control"
                            variant="outlined"
                            placeholder="Seleccione"
                            error={errors.codDocSustento ? true : false}
                          />
                        )}
                      />
                      {errors.codDocSustento ? (
                        <p className={classesApp.errorLetters}>
                          {errors.codDocSustento}
                        </p>
                      ) : null}
                    </Grid>
                    <Grid item xs={2} className={classes.divForm}>
                      <label>
                        Número Doc. sustento:{" "}
                      </label>
                    </Grid>
                    <Grid item xs={4} className={classes.divInput}>
                      <InputMask
                        type="text"
                        mask="999-999-999999999"
                        placeholder="Ej. 001-001-000000012"
                        name="numDocSustento"
                        value={addressee.numDocSustento}
                        onChange={handleChangeAddressee}
                        autoComplete="off"
                        className={styleInput(
                          errors.numDocSustento,
                          addressee.numDocSustento
                        )}
                      />
                      {errors.numDocSustento ? (
                        <p className={classesApp.errorLetters}>
                          {errors.numDocSustento}
                        </p>
                      ) : null}
                    </Grid>

                    <Grid item xs={2} className={classes.divForm}>
                      <label>
                        Num. Autorización Doc. sustento:{" "}
                      </label>
                    </Grid>
                    <Grid item xs={4} className={classes.divInput}>
                      <input
                        type="text"
                        name="numAutDocSustento"
                        value={addressee.numAutDocSustento}
                        onChange={handleChangeAddressee}
                        autoComplete="off"
                        className={styleInput(
                          errors.numAutDocSustento,
                          addressee.numAutDocSustento
                        )}
                      />
                      {errors.numAutDocSustento ? (
                        <p className={classesApp.errorLetters}>
                          {errors.numAutDocSustento}
                        </p>
                      ) : null}
                    </Grid>

                    <Grid item xs={2} className={classes.divForm}>
                      <label>
                        Fecha emisión Doc. sustento:{" "}
                      </label>
                    </Grid>
                    <Grid item xs={4} className={classes.divInput}>
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={esLocale}
                      >
                        <KeyboardDatePicker
                          className={"form-control"}
                          autoOk
                          disableFuture
                          variant="inline"
                          inputVariant="outlined"
                          placeholder='Ingrese una fecha si es necesario'
                          invalidDateMessage="Formato inválido."
                          format="dd-MM-yyyy"
                          InputAdornmentProps={{ position: "start" }}
                          name="fechaEmisionDocSustento"
                          value={addressee.fechaEmisionDocSustento}
                          onChange={handleChangeDate("fechaEmisionDocSustento")}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  align="center"
                >
                  <Grid item md={12}>
                    <Divider></Divider>
                  </Grid>

                  <AddDetailDispatcher
                    detalleGuiaRemision={detalleGuiaRemision}
                    setDetalleGuiaRemision={setDetalleGuiaRemision}
                    setViewDetalle={setViewDetalle}
                    viewDetalle={viewDetalle}
                  ></AddDetailDispatcher>

                  <Grid item xs={12}>
                    {detalleGuiaRemision.length > 0 ? (
                      viewDetalle === 1 ? (
                        <TableDataSimplePag
                          title=""
                          columns={columns}
                          data={detalleGuiaRemision}
                          buscar={false}
                        />
                      ) : (
                          <TableDataSimple
                            title=""
                            columns={columns}
                            data={detalleGuiaRemision}
                            buscar={false}
                          />
                        )
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                align="center"
                spacing={2}
              >
                <Grid item md={12}>
                  <Divider></Divider>
                </Grid>

                <Grid item xs={2} className={classes.divButton}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddAddressee}
                  >
                    Agregar destinatario
                  </Button>
                </Grid>

                <Grid item xs={2} className={classes.divButton}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleClose}
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default Addresse;
