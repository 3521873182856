import React, { useState, useEffect, Fragment } from 'react';
import MaterialTable from 'material-table';
import TablePagination from '@material-ui/core/TablePagination';
import { palette } from 'configurations/styles/theme';



const TableDataSimple = ({ columns, data, acciones, seleccionados, seleccion }) => {


  const headerColor = palette.primary.main;
  const letterHeaderColor = palette.primary.main;

  const [state, setState] = React.useState({
    columns: columns,
  });

  const [tamanioData, setTamanioData] = useState(null)
  const [datosLocales, setDatosLocales] = useState(null)

  useEffect(() => {

    setDatosLocales(data ? data : [])
    setTamanioData(data ? data.length ? data.length : 0 : 0)

  }, [data])


  const renderizarTabla = () => {
    return (
      <MaterialTable
        title=''
        columns={state.columns}
        data={datosLocales}
        actions={acciones}
        localization={{
          header: {
            actions: 'Acciones'
          },
          body: {
            editRow: {
              deleteText: 'Esta seguro de eliminar esta factura ?',
              saveTooltip: 'Guardar cambios',
              cancelTooltip: 'Cancelar cambios'
            },
            editTooltip: 'Editar factura',
            deleteTooltip: 'Eliminar factura'
          },
          pagination: {
            labelRowsSelect: "Filas por página",
            labelDisplayedRows: "{from}-{to} de {count}"
          }

        }}
        options={{
          search: false,
          selection: seleccion,
          actionsColumnIndex: -1,
          pageSize: tamanioData,
          showTextRowsSelected: false,
          headerStyle: {
            backgroundColor: headerColor,
            color: '#FFFFFF',
            borderStyle: 'solid',
            borderColor: letterHeaderColor,
            borderWidth: "0px 0px 0px 0px",
            padding: '0px',
            alignContent: 'center',
            textAlign: 'center'
          },
          rowStyle: {
            borderStyle: 'solid',
            borderColor: headerColor,
            borderWidth: '1px 0px ',
            alignContent: 'center',
            textAlign: 'center'
          },

        }}
        onSelectionChange={(rows) => {
          seleccionados(rows)
        }}
        components={{

          Pagination: props => {
            let nuevosProps = props;
            nuevosProps.classes.paginacion = {
              color: palette.primary.main,
            };
            return (
              <div container spacing={0} align='center' >
                <TablePagination {...nuevosProps} aria-label="paginacionComponent"
                  style={{
                    overflowX: "hidden",
                  }}
                />
              </div>
            );
          },

          Toolbar: props => (null),
        }}
      />
    )
  }

  return (
    <Fragment>
      {tamanioData ? renderizarTabla() : null}
    </Fragment>
  );

}

export default TableDataSimple;