// import React, { useState } from "react";
// import { useSelector } from "react-redux";
// import { makeStyles, Grid, Paper, CardHeader } from "@material-ui/core";
// import { palette, useStylesGlobal } from "configurations/styles/theme";
// import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
// import DateFnsUtils from "@date-io/date-fns";
// import esLocale from "date-fns/locale/es";
// import AddTax from "./AddTax";
// import tableStyle from "configurations/styles/table";
// import AdditionalInfo from "views/facturacion/Vouchers/invoices/Forms/AdditionalInfo";
// import ElectronicVoucherOptions from "./ElectronicVoucherOptions";
// import { getSub } from "redux/actions/userActions";
// import clientBackend from "configurations/axios";
// import DeleteTaxBtn from "../Buttons/DeleteTaxBtn";
// import AssignmentIcon from "@material-ui/icons/Assignment";
// import TableDataSimplePag from "widgets/Tables/TableDataSimplePag";
// import TableDataSimple from "widgets/Tables/TableDataSimple";
// import EditTaxBtn from "../Buttons/EditTaxBtn";

// import AddSupportDoc from "./AddSupportDoc";

// import DeleteDocSupportBtn from "../Buttons/DeleteDocSupportBtn";
// import EditDocSupportBtn from "../Buttons/EditDocSupportBtn";
// import InfoImpuestosBtn from "../Buttons/InfoImpuestosBtn";
// import InfoPagosBtn from "../Buttons/InfoPagosBtn";
// import InfoRetencionesBtn from "../Buttons/InfoRetencionesBtn";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//     padding: theme.spacing(2),
//     textAlign: "center",
//     color: theme.palette.text.primary,
//     margin: theme.spacing(1),
//     display: "flex",
//     flexWrap: "wrap",
//     marginBottom: "1%",
//     borderStyle: "solid",
//     boxShadow: "5px 3px 3px #aaaaaa",
//     borderRadius: "8px",
//     borderWidth: "2px",
//   },
//   formControl: {
//     marginTop: theme.spacing(0),
//     minWidth: 120,
//   },
//   radio: {
//     display: "-webkit-box",
//     marginTop: "0px",
//   },
//   radioLable: {
//     color: palette.primary.main,
//     display: "flex",
//   },
//   radioButton: {
//     marginTop: "0px",
//     paddingTop: "0px",
//   },
//   divForm: {
//     marginBottom: "1%",
//     textAlign: "justify",
//   },
//   divInput: {
//     marginBottom: "1%",
//     paddingRight: "1%",
//     paddingLeft: "0.5%",
//     textAlign: "justify",
//   },
//   divButton: {
//     paddingLeft: "3%",
//     paddingRight: "3%",
//   },
//   backdrop: {
//     zIndex: theme.zIndex.modal + 999,
//     color: palette.primary.main,
//   },
//   haedClient: {
//     backgroundColor: palette.primary.main,
//     width: "10%",
//     display: "flex",
//     flexWrap: "wrap",
//     borderStyle: "solid",
//     borderBottomWidth: "5px",
//     borderRadius: "8px",
//   },
//   paper: {
//     padding: theme.spacing(1),
//     margin: "auto",
//     maxWidth: 800,
//     borderRadius: "6px",
//     borderStyle: "solid",
//     borderWidth: "1px",
//     marginTop: "10px",
//   },
// }));

// const RetentionData = ({
//   setIdAsgCliente,
//   idAsgCliente,
//   setFoundClient,
//   setOptions,
// }) => {
//   //para el los datos del la empresa
//   const tokenUser = useSelector((state) => state.user.tk);
//   const classes = useStyles();
//   const classesGlobales = useStylesGlobal();
//   //para la fecha
//   const dateToday = new Date();
//   dateToday.setDate(dateToday.getDate() - 30);
//   const [update, setUpdate] = useState(1);

//   //State para el detalle de la vista
//   const [docSustentoDetail, setDocSustentoDetail] = useState([]);
//   //State para la lista de impuestos
//   const [docsSustento, setDocsSustento] = useState([]);

//   const getNumeration = async () => {
//     await clientBackend
//       .get(
//         `/asignacion/secuencial/usuario/secuencial/${
//           tokenUser ? getSub(tokenUser) : ""
//         }/07`
//       )
//       .then((response) => {
//         if (response.status === 200) {
//           setNumeracionSecuencial(response.data);
//           return response.data;
//         }
//       })
//       .catch((exception) => {
//         console.log({ exception });
//         return exception;
//       });
//   };
//   //para la numeración del comprobante
//   const [numeracionSecuencial, setNumeracionSecuencial] = useState(
//     getNumeration(tokenUser)
//   );

//   //para la fecha del comprobante
//   const [fechaEmision, setFechaEmision] = useState(new Date());
//   const [selectedMonth, setSelectedMonth] = useState(new Date());
//   const [selectedYear, setSelectedYear] = useState(new Date());
//   //State para el detalle de la vista
//   const [taxDetail, setTaxDetail] = useState([]);
//   //State para la lista de impuestos
//   const [impuestoRetencions, setImpuestoRetencions] = useState([]);
//   const [additionalInfoInvoice, setAdditionalInfoInvoice] = useState([]);
//   //para visualización del detalle
//   const [viewDetalle, setViewDetalle] = useState(1);
// //   const columns = [
// //     {
// //       title: "Codigo Impuesto",
// //       field: `codigoImpuesto`,
// //       cellStyle: tableStyle.cellTable,
// //       width: "10%",
// //     },
// //     {
// //       title: "Porcentaje a Retener %",
// //       field: "porcentaje",
// //       cellStyle: tableStyle.cellTable,
// //       width: "10%",
// //       render: (rowData) => Number(rowData.porcentaje).toFixed(2),
// //     },
// //     {
// //       title: "Documento Sustento",
// //       field: "numeroDocSustento",
// //       cellStyle: tableStyle.cellTable,
// //       width: "10%",
// //     },
// //     {
// //       title: "Base Imponible",
// //       field: "baseImponible",
// //       cellStyle: tableStyle.cellTable,
// //       width: "10%",
// //       render: (rowData) => Number(rowData.baseImponible).toFixed(2),
// //     },
// //     {
// //       title: "Valor Retenido",
// //       field: "valorRetenido",
// //       cellStyle: tableStyle.cellTable,
// //       width: "10%",
// //       render: (rowData) => Number(rowData.valorRetenido).toFixed(2),
// //     },
// //     {
// //       title: "Editar",
// //       field: "",
// //       cellStyle: tableStyle.cellTable,
// //       width: "10%",
// //       render: (rowData) => (
// //         <EditTaxBtn
// //           data={rowData}
// //           impuestoRetencions={impuestoRetencions}
// //           setImpuestoRetencions={setImpuestoRetencions}
// //           taxDetail={taxDetail}
// //           setTaxDetail={setTaxDetail}
// //           viewDetalle={viewDetalle}
// //           setViewDetalle={setViewDetalle}
// //         />
// //       ),
// //     },
// //     {
// //       title: "Eliminar",
// //       cellStyle: tableStyle.cellTable,
// //       width: "10%",
// //       render: (rowData) => (
// //         <DeleteTaxBtn
// //           impuestoRetencions={impuestoRetencions}
// //           setImpuestoRetencions={setImpuestoRetencions}
// //           taxDetail={taxDetail}
// //           setTaxDetail={setTaxDetail}
// //           viewDetalle={viewDetalle}
// //           setViewDetalle={setViewDetalle}
// //           data={rowData}
// //         />
// //       ),
// //     },
// //   ];


// const columns = [
//     {
//       title: "Código",
//       field: `codigoDocSustento`,
//       cellStyle: tableStyle.cellTable,
//       width: "10%",
//     },
//     {
//       title: "Número",
//       field: "numeroDocSustento",
//       cellStyle: tableStyle.cellTable,
//       width: "10%",
//     },
//     {
//       title: "Tipo",
//       field: "codSustento",
//       cellStyle: tableStyle.cellTable,
//       width: "10%",
//     },
//     {
//       title: "Impuestos",
//       cellStyle: tableStyle.cellTable,
//       width: "10%",
//       render: (rowData) => <InfoImpuestosBtn data={rowData} />,
//       filtering: false,
//       editable: "never",
//     },
//     {
//       title: "Retenciones",
//       cellStyle: tableStyle.cellTable,
//       width: "10%",
//       render: (rowData) => <InfoRetencionesBtn data={rowData} />,
//       filtering: false,
//       editable: "never",
//     },
//     {
//       title: "Pagos",
//       cellStyle: tableStyle.cellTable,
//       width: "10%",
//       render: (rowData) => <InfoPagosBtn data={rowData} />,
//       filtering: false,
//       editable: "never",
//     },
//     {
//       title: "Editar",
//       field: "",
//       cellStyle: tableStyle.cellTable,
//       width: "10%",
//       render: (rowData) => (
//         <>
//           {console.log(rowData)}
//           <EditDocSupportBtn
//             data={rowData}
//             docsSustento={docsSustento}
//             setDocsSustento={setDocsSustento}
//             docSustentoDetail={docSustentoDetail}
//             setDocSustentoDetail={setDocSustentoDetail}
//             viewDetalle={viewDetalle}
//             setViewDetalle={setViewDetalle}
//           />
//         </>
//       ),
//     },
//     {
//       title: "Eliminar",
//       cellStyle: tableStyle.cellTable,
//       width: "10%",
//       render: (rowData) => (
//         <DeleteDocSupportBtn
//           docsSustento={docsSustento}
//           setDocsSustento={setDocsSustento}
//           docSustentoDetail={docSustentoDetail}
//           setDocSustentoDetail={setDocSustentoDetail}
//           viewDetalle={viewDetalle}
//           setViewDetalle={setViewDetalle}
//           data={rowData}
//         />
//       ),
//     },
//   ];

//   const requestConfig = {
//     page: 0,
//     elementos: 5,
//   };

//   const clearAllData = () => {
//     setIdAsgCliente({});
//     setImpuestoRetencions([]);
//     setTaxDetail([]);
//     setAdditionalInfoInvoice([]);
//     setNumeracionSecuencial("");
//     setIdAsgCliente({});
//     setFoundClient({});
//     setOptions([]);
//   };

//   return (
//     <>
//       <div className={classesGlobales.rootDiv}>
//         <Grid container xs={12} md={12} justify="center">
//           <CardHeader
//             className={classesGlobales.cabecera}
//             title={"Datos Comprobante de Retención"}
//             avatar={<AssignmentIcon></AssignmentIcon>}
//           />
//         </Grid>

//         <Paper className={classes.paper}>
//           <Grid container maxWidth="lg" spacing={1}>
//             <Grid item xs={12} md={2} className={classes.divForm}>
//               <label>
//                 Numeración: <font color={palette.error.main}>*</font>
//               </label>
//             </Grid>
//             <Grid item xs={12} md={4}>
//               <input
//                 className={
//                   numeracionSecuencial
//                     ? "form-control  is-valid"
//                     : "form-control "
//                 }
//                 type="text"
//                 disabled
//                 name="secuencial"
//                 value={numeracionSecuencial}
//               />
//             </Grid>

//             <Grid item xs={12} md={2} className={classes.divForm}>
//               <label>
//                 Fecha: <font color={palette.error.main}>*</font>
//               </label>
//             </Grid>
//             <Grid item xs={12} md={4}>
//               <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
//                 <DatePicker
//                   className={
//                     fechaEmision ? "form-control  is-valid" : "form-control "
//                   }
//                   disableToolbar
//                   autoOk
//                   disableFuture
//                   placeholder="Ej. 20-01-2020"
//                   minDate={dateToday}
//                   InputAdornmentProps={{ position: "start" }}
//                   variant="dialog"
//                   inputVariant="outlined"
//                   cancelLabel="Cancelar"
//                   okLabel="Seleccionar"
//                   format="dd-MM-yyyy"
//                   margin="normal"
//                   id="date-picker-inline"
//                   value={fechaEmision ? fechaEmision : ""}
//                   onChange={(event) => setFechaEmision(event)}
//                 />
//               </MuiPickersUtilsProvider>
//             </Grid>
//           </Grid>
//         </Paper>
//         <Paper className={classes.paper}>
//           <Grid container maxWidth="lg" spacing={1}>
//             <Grid container justify="center">
//               <label>
//                 <strong> Ejercicio Fiscal</strong>
//               </label>
//             </Grid>
//             <Grid item xs={12} md={2} className={classes.divButton}>
//               <label>
//                 Mes: <font color={palette.error.main}>*</font>
//               </label>
//             </Grid>
//             <Grid item xs={12} md={4} className={classes.divInput}>
//               <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
//                 <DatePicker
//                   className={
//                     selectedMonth ? "form-control  is-valid" : "form-control "
//                   }
//                   disableToolbar
//                   autoOk
//                   InputAdornmentProps={{ position: "start" }}
//                   variant="dialog"
//                   inputVariant="outlined"
//                   cancelLabel="Cancelar"
//                   okLabel="Seleccionar"
//                   views={["month"]}
//                   format="MMMM"
//                   value={selectedMonth}
//                   onChange={setSelectedMonth}
//                 />
//               </MuiPickersUtilsProvider>
//             </Grid>

//             <Grid item xs={12} md={2} className={classes.divButton}>
//               <label>
//                 Año: <font color={palette.error.main}>*</font>
//               </label>
//             </Grid>
//             <Grid item xs={12} md={4} className={classes.divInput}>
//               <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
//                 <DatePicker
//                   className={
//                     selectedYear ? "form-control  is-valid" : "form-control "
//                   }
//                   disableToolbar
//                   disableFuture
//                   autoOk
//                   InputAdornmentProps={{ position: "start" }}
//                   variant="dialog"
//                   inputVariant="outlined"
//                   cancelLabel="Cancelar"
//                   okLabel="Seleccionar"
//                   views={["year"]}
//                   format="yyyy"
//                   value={selectedYear}
//                   onChange={setSelectedYear}
//                 />
//               </MuiPickersUtilsProvider>
//             </Grid>
//           </Grid>
//         </Paper>
//       </div>

//       <div className={classesGlobales.rootDiv}>
//         <Grid container xs={12} md={12} justify="center">
//           <CardHeader
//             className={classesGlobales.cabecera}
//             title={"Documentos Sustento"}
//             avatar={<AssignmentIcon></AssignmentIcon>}
//           />
//         </Grid>
//         <Grid item xs={12} md={12}>
//           <AddSupportDoc
//             docsSustento={docsSustento}
//             setDocsSustento={setDocsSustento}
//             viewDetalle={viewDetalle}
//             setViewDetalle={setViewDetalle}
//             docSustentoDetail={docSustentoDetail}
//             setDocSustentoDetail={setDocSustentoDetail}
//           />
//           {docsSustento.length > 0 ? (
//             <Grid
//               item
//               xs={12}
//               md={12}
//               className={classesGlobales.containerForm}
//             >
//               {viewDetalle === 1 ? (
//                 // 'example with viewDetail = 1'
//                 <TableDataSimplePag
//                   title=""
//                   columns={columns}
//                   data={docSustentoDetail}
//                   buscar={false}
//                 />
//               ) : (
//                 // 'example with whetever viewDetail'
//                 <TableDataSimple
//                   title=""
//                   columns={columns}
//                   data={docSustentoDetail}
//                   buscar={false}
//                 />
//               )}
//             </Grid>
//           ) : null}
//           {/* <AddTax
//                         impuestoRetencions={impuestoRetencions}
//                         setImpuestoRetencions={setImpuestoRetencions}
//                         viewDetalle={viewDetalle}
//                         setViewDetalle={setViewDetalle}
//                         taxDetail={taxDetail}
//                         setTaxDetail={setTaxDetail}
//                     />
//                     {impuestoRetencions.length > 0 ?
//                         <Grid item xs={12} md={12} className={classesGlobales.containerForm} >
//                             {viewDetalle === 1 ?
//                                 <TableDataSimplePag
//                                     title=""
//                                     columns={columns}
//                                     data={taxDetail}
//                                     buscar={false}
//                                 />
//                                 :
//                                 <TableDataSimple
//                                     title=""
//                                     columns={columns}
//                                     data={taxDetail}
//                                     buscar={false}
//                                 />
//                             }
//                         </Grid>
//                         :
//                         null
//                     } */}
//           <Grid container>
//             <Grid xs={12} md={6} className={classesGlobales.containerForm}>
//               <AdditionalInfo
//                 additionalInfoInvoice={additionalInfoInvoice}
//                 setAdditionalInfoInvoice={setAdditionalInfoInvoice}
//               />
//             </Grid>
//             <Grid xs={12} md={6}>
//               <ElectronicVoucherOptions
//                 clearAllData={clearAllData}
//                 fechaEmision={fechaEmision}
//                 selectedMonth={selectedMonth}
//                 selectedYear={selectedYear}
//                 impuestoRetencions={impuestoRetencions}
//                 additionalInfoInvoice={additionalInfoInvoice}
//                 idCliente={idAsgCliente}
//               />
//             </Grid>
//           </Grid>
//         </Grid>
//       </div>
//     </>
//   );
// };

// export default RetentionData;



import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles, Grid, Paper, CardHeader } from "@material-ui/core";
import { palette, useStylesGlobal } from "configurations/styles/theme";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import AddTax from "./AddTax";
import AddSupportDoc from "./AddSupportDoc";
import tableStyle from "configurations/styles/table";
import AdditionalInfo from "views/facturacion/Vouchers/invoices/Forms/AdditionalInfo";
import ElectronicVoucherOptions from "./ElectronicVoucherOptions";
import { getSub } from "redux/actions/userActions";
import clientBackend from "configurations/axios";
import DeleteDocSupportBtn from "../Buttons/DeleteDocSupportBtn";
import AssignmentIcon from "@material-ui/icons/Assignment";
import TableDataSimplePag from "widgets/Tables/TableDataSimplePag";
import TableDataSimple from "widgets/Tables/TableDataSimple";
import EditDocSupportBtn from "../Buttons/EditDocSupportBtn";
import InfoImpuestosBtn from "../Buttons/InfoImpuestosBtn";
import InfoPagosBtn from "../Buttons/InfoPagosBtn";
import InfoRetencionesBtn from "../Buttons/InfoRetencionesBtn";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.primary,
    margin: theme.spacing(1),
    display: "flex",
    flexWrap: "wrap",
    marginBottom: "1%",
    borderStyle: "solid",
    boxShadow: "5px 3px 3px #aaaaaa",
    borderRadius: "8px",
    borderWidth: "2px",
  },
  formControl: {
    marginTop: theme.spacing(0),
    minWidth: 120,
  },
  radio: {
    display: "-webkit-box",
    marginTop: "0px",
  },
  radioLable: {
    color: palette.primary.main,
    display: "flex",
  },
  radioButton: {
    marginTop: "0px",
    paddingTop: "0px",
  },
  divForm: {
    marginBottom: "1%",
    textAlign: "justify",
  },
  divInput: {
    marginBottom: "1%",
    paddingRight: "1%",
    paddingLeft: "0.5%",
    textAlign: "justify",
  },
  divButton: {
    paddingLeft: "3%",
    paddingRight: "3%",
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 999,
    color: palette.primary.main,
  },
  haedClient: {
    backgroundColor: palette.primary.main,
    width: "10%",
    display: "flex",
    flexWrap: "wrap",
    borderStyle: "solid",
    borderBottomWidth: "5px",
    borderRadius: "8px",
  },
  paper: {
    padding: theme.spacing(1),
    margin: "auto",
    maxWidth: 800,
    borderRadius: "6px",
    borderStyle: "solid",
    borderWidth: "1px",
    marginTop: "10px",
  },
}));

const RetentionData = ({
  setIdAsgCliente,
  idAsgCliente,
  setFoundClient,
  setOptions,
}) => {
  //para el los datos del la empresa
  const tokenUser = useSelector((state) => state.user.tk);
  const classes = useStyles();
  const classesGlobales = useStylesGlobal();
  //para la fecha
  const dateToday = new Date();
  dateToday.setDate(dateToday.getDate() - 90);
  const [update, setUpdate] = useState(1);

  const getNumeration = async () => {
    await clientBackend
      .get(
        `/asignacion/secuencial/usuario/secuencial/${
          tokenUser ? getSub(tokenUser) : ""
        }/07`
      )
      .then((response) => {
        if (response.status === 200) {
          setNumeracionSecuencial(response.data);
          return response.data;
        }
      })
      .catch((exception) => {
        console.log({ exception });
        return exception;
      });
  };
  //para la numeración del comprobante
  const [numeracionSecuencial, setNumeracionSecuencial] = useState(
    getNumeration(tokenUser)
  );

  //para la fecha del comprobante
  const [fechaEmision, setFechaEmision] = useState(new Date());
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [selectedYear, setSelectedYear] = useState(new Date());
  //State para el detalle de la vista
  const [docSustentoDetail, setDocSustentoDetail] = useState([]);
  //State para la lista de impuestos
  const [docsSustento, setDocsSustento] = useState([]);
  const [impuestoRetencions, setImpuestoRetencions] = useState([]);
  const [impuestos, setImpuestos] = useState([]);
  const [pagos, setPagos] = useState([]);
  const [taxDetail, setTaxDetail] = useState([]);
  const [additionalInfoInvoice, setAdditionalInfoInvoice] = useState([]);
  //para visualización del detalle
  const [viewDetalle, setViewDetalle] = useState(1);

  // Auxiliar const
  const [btn, setBtn] = useState(false);

  const columns = [
    {
      title: "Código",
      field: `codigoDocSustento`,
      cellStyle: tableStyle.cellTable,
      width: "10%",
    },
    {
      title: "Número",
      field: "numeroDocSustento",
      cellStyle: tableStyle.cellTable,
      width: "10%",
    },
    {
      title: "Tipo",
      field: "codSustento",
      cellStyle: tableStyle.cellTable,
      width: "10%",
    },
    {
      title: "Impuestos",
      cellStyle: tableStyle.cellTable,
      width: "10%",
      render: (rowData) => <InfoImpuestosBtn data={rowData} />,
      filtering: false,
      editable: "never",
    },
    {
      title: "Retenciones",
      cellStyle: tableStyle.cellTable,
      width: "10%",
      render: (rowData) => <InfoRetencionesBtn data={rowData} />,
      filtering: false,
      editable: "never",
    },
    {
      title: "Pagos",
      cellStyle: tableStyle.cellTable,
      width: "10%",
      render: (rowData) => <InfoPagosBtn data={rowData} />,
      filtering: false,
      editable: "never",
    },
    {
      title: "Editar",
      field: "",
      cellStyle: tableStyle.cellTable,
      width: "10%",
      render: (rowData) => (
        <>
          {console.log(rowData)}
          <EditDocSupportBtn
            data={rowData}
            docsSustento={docsSustento}
            setDocsSustento={setDocsSustento}
            docSustentoDetail={docSustentoDetail}
            setDocSustentoDetail={setDocSustentoDetail}
            viewDetalle={viewDetalle}
            setViewDetalle={setViewDetalle}
          />
        </>
      ),
    },
    {
      title: "Eliminar",
      cellStyle: tableStyle.cellTable,
      width: "10%",
      render: (rowData) => (
        <DeleteDocSupportBtn
          docsSustento={docsSustento}
          setDocsSustento={setDocsSustento}
          docSustentoDetail={docSustentoDetail}
          setDocSustentoDetail={setDocSustentoDetail}
          viewDetalle={viewDetalle}
          setViewDetalle={setViewDetalle}
          data={rowData}
        />
      ),
    },
  ];

  const requestConfig = {
    page: 0,
    elementos: 5,
  };

  const clearAllData = () => {
    setIdAsgCliente({});
    setDocsSustento([]);
    setDocSustentoDetail([]);
    setAdditionalInfoInvoice([]);
    setNumeracionSecuencial("");
    setImpuestoRetencions([]);
    setPagos([]);
    setIdAsgCliente({});
    setFoundClient({});
    setOptions([]);
  };

  useEffect(() => {
    console.log(docsSustento);
  }, [btn]);

  return (
    <>
      <div className={classesGlobales.rootDiv}>
        <Grid container xs={12} md={12} justifyContent="center">
          <CardHeader
            className={classesGlobales.cabecera}
            title={"Datos Comprobante de Retención"}
            avatar={<AssignmentIcon></AssignmentIcon>}
          />
        </Grid>

        <Paper className={classes.paper}>
          <Grid container maxWidth="lg" spacing={1}>
            <Grid item xs={12} md={2} className={classes.divForm}>
              <label>
                Numeración: <font color={palette.error.main}>*</font>
              </label>
            </Grid>
            <Grid item xs={12} md={4}>
              <input
                className={
                  numeracionSecuencial
                    ? "form-control  is-valid"
                    : "form-control "
                }
                type="text"
                disabled
                name="secuencial"
                value={numeracionSecuencial}
              />
            </Grid>

            <Grid item xs={12} md={2} className={classes.divForm}>
              <label>
                Fecha: <font color={palette.error.main}>*</font>
              </label>
            </Grid>
            <Grid item xs={12} md={4}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                  className={
                    fechaEmision ? "form-control  is-valid" : "form-control "
                  }
                  disableToolbar
                  autoOk
                  disableFuture
                  placeholder="Ej. 20-01-2020"
                  minDate={dateToday}
                  InputAdornmentProps={{ position: "start" }}
                  variant="dialog"
                  inputVariant="outlined"
                  cancelLabel="Cancelar"
                  okLabel="Seleccionar"
                  format="dd-MM-yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  value={fechaEmision ? fechaEmision : ""}
                  onChange={(event) => setFechaEmision(event)}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </Paper>

        <Paper className={classes.paper}>
          <Grid container maxWidth="lg" spacing={1}>
            <Grid container justifyContent="center">
              <label>
                <strong> Ejercicio Fiscal</strong>
              </label>
            </Grid>
            <Grid item xs={12} md={2} className={classes.divButton}>
              <label>
                Mes: <font color={palette.error.main}>*</font>
              </label>
            </Grid>
            <Grid item xs={12} md={4} className={classes.divInput}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                  className={
                    selectedMonth ? "form-control  is-valid" : "form-control "
                  }
                  disableToolbar
                  autoOk
                  InputAdornmentProps={{ position: "start" }}
                  variant="dialog"
                  inputVariant="outlined"
                  cancelLabel="Cancelar"
                  okLabel="Seleccionar"
                  views={["month"]}
                  format="MMMM"
                  value={selectedMonth}
                  onChange={setSelectedMonth}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={12} md={2} className={classes.divButton}>
              <label>
                Año: <font color={palette.error.main}>*</font>
              </label>
            </Grid>
            <Grid item xs={12} md={4} className={classes.divInput}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                  className={
                    selectedYear ? "form-control  is-valid" : "form-control "
                  }
                  disableToolbar
                  disableFuture
                  autoOk
                  InputAdornmentProps={{ position: "start" }}
                  variant="dialog"
                  inputVariant="outlined"
                  cancelLabel="Cancelar"
                  okLabel="Seleccionar"
                  views={["year"]}
                  format="yyyy"
                  value={selectedYear}
                  onChange={setSelectedYear}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </Paper>
      </div>

      <div className={classesGlobales.rootDiv}>
        <Grid container xs={12} md={12} justifyContent="center">
          <CardHeader
            className={classesGlobales.cabecera}
            title="Documentos Sustento"
            avatar={<AssignmentIcon />}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          {/* <button onClick={() => setBtn(!btn)}>clic</button> */}
          <AddSupportDoc
            docsSustento={docsSustento}
            setDocsSustento={setDocsSustento}
            viewDetalle={viewDetalle}
            setViewDetalle={setViewDetalle}
            docSustentoDetail={docSustentoDetail}
            setDocSustentoDetail={setDocSustentoDetail}
          />
          {docsSustento.length > 0 ? (
            <Grid
              item
              xs={12}
              md={12}
              className={classesGlobales.containerForm}
            >
              {viewDetalle === 1 ? (
                // 'example with viewDetail = 1'
                <TableDataSimplePag
                  title=""
                  columns={columns}
                  data={docSustentoDetail}
                  buscar={false}
                />
              ) : (
                // 'example with whetever viewDetail'
                <TableDataSimple
                  title=""
                  columns={columns}
                  data={docSustentoDetail}
                  buscar={false}
                />
              )}
            </Grid>
          ) : null}
          {/* <AddTax
                        impuestoRetencions={impuestoRetencions}
                        setImpuestoRetencions={setImpuestoRetencions}
                        viewDetalle={viewDetalle}
                        setViewDetalle={setViewDetalle}
                        taxDetail={taxDetail}
                        setTaxDetail={setTaxDetail}
                    />
                    {impuestoRetencions.length > 0 ?
                        <Grid item xs={12} md={12} className={classesGlobales.containerForm} >
                            {viewDetalle === 1 ?
                                <TableDataSimplePag
                                    title=""
                                    columns={columns}
                                    data={taxDetail}
                                    buscar={false}
                                />
                                :
                                <TableDataSimple
                                    title=""
                                    columns={columns}
                                    data={taxDetail}
                                    buscar={false}
                                />
                            }
                        </Grid>
                        :
                        null
                    } */}
          <Grid container>
            <Grid xs={12} md={6} className={classesGlobales.containerForm}>
              <AdditionalInfo
                additionalInfoInvoice={additionalInfoInvoice}
                setAdditionalInfoInvoice={setAdditionalInfoInvoice}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <ElectronicVoucherOptions
                clearAllData={clearAllData}
                fechaEmision={fechaEmision}
                selectedMonth={selectedMonth}
                selectedYear={selectedYear}
                docsSustento={docsSustento}
                additionalInfoInvoice={additionalInfoInvoice}
                idCliente={idAsgCliente}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default RetentionData;
