import { Backdrop, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, makeStyles } from '@material-ui/core';
import React, { Fragment, useState } from 'react';
import BrokenImageOutlinedIcon from '@material-ui/icons/BrokenImageOutlined';
import { palette, useStylesGlobal } from 'configurations/styles/theme';
import ConfigurationCompany from '../Forms/ConfigurationCompany';


const useStyles = makeStyles((theme) => ({
    IconButton: {
        marginTop: "0%",
        marginBottom: "0%",
        padding: "0px",
    },
    textFieldComboBox: {
        minWidth: "75%",
        maxWidth: "95%",
    },
    backdrop: {
        zIndex: theme.zIndex.modal + 1,
        color: palette.primary.main,
    },
}));

const ConfigurationCompanyBtn = ({ data }) => {

    const classes = useStyles();
    const classesGlobal = useStylesGlobal();
    const [open, setOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenClick = () => {
        setOpenDialog(true);
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
    }

    return (
        <Fragment>
            <IconButton
                aria-label="delete"
                className={classes.IconButton}
                variant="outlined"
                color="secondary"
                onClick={handleOpenClick}
            >
                <BrokenImageOutlinedIcon
                    style={{ color: palette.primary.main }}
                />
            </IconButton>

            <Dialog fullWidth maxWidth="lg" open={openDialog} onClose={handleCloseDialog}
            >
                <DialogTitle id="max-width-dialog-title">Configuración de la Empresa:<strong> {data.razonSocialempresa} </strong></DialogTitle>
                <DialogContent>

                    <ConfigurationCompany rucEmpresa={data.idempresa}></ConfigurationCompany>

                    <Grid container spacing={3} justify="center">
                        <Grid item md={12} xs={12}>
                            <Divider></Divider>
                        </Grid>

                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleCloseDialog}
                            className={classesGlobal.marginButton}
                        >
                            Cancelar
                        </Button>
                    </Grid>

                </DialogContent>
            </Dialog>

            <Backdrop open={open} className={classes.backdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Fragment>
    );

}

export default ConfigurationCompanyBtn;