import React, { Fragment } from "react";
import {
  Grid,
  makeStyles,
  CircularProgress,
  Dialog,
  DialogContent,
  Button,
  Backdrop,
  Avatar,
  Divider,
} from "@material-ui/core";
import {
  palette,
  msmSwalExito,
  msmSwalError,
} from "configurations/styles/theme";
import { httpCore, clientBackendPDF } from "configurations/axios";
import { getRUCEmpresa, getSub } from "redux/actions/userActions";
import { useSelector, useDispatch } from "react-redux";
import SaveIcon from "@material-ui/icons/Save";
import pdfIcon from "assets/icons/pdf.png";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import {
  changeErrorLabelsActionGuide,
  clearReferralGuideAction,
} from "redux/actions/referralGuideActions";
import { useSnackbar } from "notistack";
import { validationsDispatcher } from "../validations";
import { ErrorsAlert } from "utils/AlertsCustom";
import { selectedResourceAction } from "redux/actions/resourcesActions";
import { checkPreRequirements } from "widgets/menu/preRedirect";
import { getNumberVoucherSuccess } from "redux/actions/numberVouchersAction";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    margin: theme.spacing(1),
    display: "flex",
    flexWrap: "wrap",
    marginBottom: "1%",
    borderStyle: "solid",
    boxShadow: "5px 3px 3px #aaaaaa",
    borderRadius: "8px",
    justifyContent: "center",
    borderWidth: "1px",
  },
  formControl: {
    marginTop: theme.spacing(0),
    minWidth: 120,
  },
  divForm: {
    marginBottom: "1%",
    textAlign: "justify",
  },

  backdrop: {
    zIndex: theme.zIndex.modal + 999,
    color: palette.primary.main,
  },
  haedClient: {
    backgroundColor: palette.primary.main,
    width: "10%",
    marginBottom: "2%",
    display: "flex",
    flexWrap: "wrap",
    marginBottom: "1%",
    borderStyle: "solid",
    borderBottomWidth: "5px",
    borderRadius: "8px",
  },
  msgDialog: {
    padding: "2%",
  },
  btnReport: {
    borderRadius: "8px",
    borderStyle: "dashed",
    borderWidth: "1px",
    borderColor: palette.primary.main,
    color: palette.primary.main,
    marginTop: "1%",
    marginRight: "1%",
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  divButton: {
    paddingLeft: "3%",
    paddingRight: "3%",
  },
}));

const ElectronicVoucherOptions = ({
  destinatarios,
  clearOnlyAllData,
  clearAllData,
  referralGuide,
  setReferralGuide,
  additionalInfoInvoice,
  rise,
}) => {
  const classes = useStyles();
  const classesView = useStyles();
  const tokenUser = useSelector((state) => state.user.tk);
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [openLoader, setOpenLoader] = React.useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const referralGuideRedux = useSelector((state) => state.referralGuide);
  const changeErrorLabelsGuide = (error) =>
    dispatch(changeErrorLabelsActionGuide(error));
  const selectedResource = (resource) => dispatch(selectedResourceAction(resource));

  const clearReferralGuide = () => dispatch(clearReferralGuideAction());

  const getReferralGuide = () => {
    if (validation()) {
      return {
        ...referralGuide,
        destinatarios: destinatarios,
        idAsigancionEmpresaUsuario: Number(getSub(tokenUser)),
        infromacionAdicional: additionalInfoInvoice,
        rise: rise,
        rucEmpresa: getRUCEmpresa(tokenUser),
      };
    } else {
      return null;
    }
  };

  const validation = () => {
    const errors = validationsDispatcher.onSubmit(referralGuideRedux);
    changeErrorLabelsGuide(errors);
    ErrorsAlert(errors);
    return Object.values(errors).length === 0;
  };

  const generateReferralGuide = async () => {
    let referral = getReferralGuide();
    if (destinatarios.length === 0) {
      let errorDesti = { error: "Agrege almenos un destinatario" };
      ErrorsAlert(errorDesti);
    } else {
      if (referral !== null) {
        setOpenLoader(true);
        await httpCore
          .post(`/generacion/comprobantes/ebox/guia/remision`, referral)
          .then((response) => {
            if (response.status === 200) {

              //para actualizar el contador de comprobantes
              dispatch(getNumberVoucherSuccess(response.numeroComprobantesDisponibles));
              clearOnlyAllData()
              setOpenLoader(false);
              clearReferralGuide();
              selectedResource({
                "id": -4,
                "nameMenu": "Verificando pre-requisitos",
                "titlePage": "Verificando pre-requisitos",
                titleTab: "Inicio",
                "menuPadre": ""
              })
              msmSwalExito(
                "Comprobante generado",
                `En breve será enviado al SRI para su autorización.`,
                { show: true, text: "Ver Comprobantes Pendientes" }
              ).then((response) => {
                if (response.isDismissed) {
                  selectedResource({
                    "id": "PEN",
                    "nameMenu": "Pendientes",
                    "titlePage": "Pendientes",
                    titleTab: "Comp. Pendientes",
                    "menuPadre": "REP"
                  })
                }
              });

            }

          })
          .catch((exception) => {
            if (exception.response) {
              msmSwalError("Error al generar el comprobante.");
              clearReferralGuide();
            } else {
              msmSwalError(
                "Existen problemas en la red. Contáctese con el administrador del Sistema."
              );
            }
            setOpenLoader(false);
          });
      }
    }
  };

  const generateRide = async () => {
    if (validation()) {
      if (!(destinatarios.length === 0)) {
        let referral = getReferralGuide();
        if (referral !== null) {
          setOpenLoader(true);
          return await clientBackendPDF
            .post(`/core/generacion/comprobantes/ebox/ride/guia/remision`, referral)
            .then((response) => {
              //Create a Blob from the PDF Stream
              const file = new Blob([response.data], {
                type: "application/pdf",
              });
              //Build a URL from the file
              const fileURL = URL.createObjectURL(file);
              msmSwalExito("RIDE generado.");
              setOpenLoader(false);
              //Open the URL on new Window
              window.open(fileURL);
            })
            .catch((exception) => {
              if (exception.response) {
                msmSwalError("Error al generar el pdf.");
              } else {
                msmSwalError(
                  "Existen problemas en la red. Contáctese con el administrador del Sistema."
                );
                console.log({ exception });
              }
              setOpenLoader(false);
            });
        }
      } else {
        let errorDesti = { error: "Agrege almenos un destinatario" };
        ErrorsAlert(errorDesti);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validatePrerequisities = (ride) => {
    const tk = tokenUser ? tokenUser : "";
    if (tk !== "") {
      setOpenLoader(true);
      checkPreRequirements(getSub(tk), getRUCEmpresa(tk), dispatch)
        .then((newErrorsCompany) => {
          console.log(newErrorsCompany)
          if (Array.isArray(newErrorsCompany)) {
            if (newErrorsCompany.length === 0) {
              if (ride) {
                generateRide();
              } else {
                generateReferralGuide()
              }
            } else {
              selectedResource({
                "id": -4,
                "nameMenu": "Verificando pre-requisitos",
                "titlePage": "Verificando pre-requisitos",
                titleTab: "Inicio",
                "menuPadre": ""
              })
            }
          }
        })
        .catch(() => {
          //setShowLoader(false);
          //setOpenLoader(false);
        }).finally(()=>{setOpenLoader(false)})
    }
  }

  return (
    <Fragment>
      <div className={classes.root} justifyContent="absolute">
        <Grid item xs={12} md={4} align="center" className={classes.divButton}>
          <Button
            onClick={() => validatePrerequisities(true)}
            variant="text"
            className={classes.btnReport}
            fullWidth
          >
            <Avatar variant="square" src={pdfIcon} className={classes.small} />
            Visualizar RIDE
          </Button>
        </Grid>
        <Grid itemxs={12} md={4} align="center" className={classes.divButton}>
          <Button
            onClick={() => validatePrerequisities(false)}
            variant="text"
            className={classes.btnReport}
            fullWidth
          >
            <SaveIcon style={{ color: palette.info.main }} fontSize="large" />
            Firmar y autorizar
          </Button>
        </Grid>
        <Grid item xs={12} md={4} align="center">
          <Button
            onClick={clearAllData}
            variant="text"
            className={classes.btnReport}
            fullWidth
          >
            <DeleteSweepIcon
              style={{ color: palette.error.main, marginRight: "10px" }}
              fontSize="large"
            />
            Limpiar Formulario
          </Button>
        </Grid>

        <Dialog maxWidth="sm" open={open} onClose={handleClose}>
          <DialogContent>
            <Grid container spacing={2} justify={"center"}>
              <Grid
                item
                xs={12}
                className={classesView.msgDialog}
                align="center"
              >
                {msg}
              </Grid>
              <Grid item xs={12} md={12}>
                <Divider></Divider>
              </Grid>
              <Grid item xs={12} md={4}>
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  onClick={handleClose}
                >
                  Cerrar
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        <Backdrop className={classes.backdrop} open={openLoader}>
          <CircularProgress color="primary" />
        </Backdrop>
      </div>
    </Fragment>
  );
};

export default ElectronicVoucherOptions;
