import React, { Fragment } from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  Typography,
  makeStyles,
  Divider,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";

const useStyles = makeStyles((theme) => ({
  large: {
    width: "200px",
    height: "60px",
  },
  welcome: {
    fontSize: "20px",
    textAlign: "center",
    fontWeight: "lighter",
  },
  newVersion: {
    fontSize: "16px",
    textAlign: "center",
    fontWeight: "bold",
  },
  legend: {
    textAlign: "center",
  },
  button: {
    textAlign: "center",
  },
}));

const MsmWelcome = ({ openMsm, handleCloseMsm }) => {
  const classes = useStyles();

  return (
    <Fragment>
      <Dialog fullWidth maxWidth="md" open={openMsm} onClose={handleCloseMsm}>
        <DialogContent>
          <Grid
            container
            justify="center"
            align="center"
            direction="row"
            spacing={2}
          >
            <img
              src="../facturar12020.png"
              alt="logo"
              className={classes.large}
            />
          </Grid>
          <br></br>
          <Divider></Divider>
          <br></br>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={2}
          >

            <Grid item md={12}>
              <Typography>
                Estimado cliente, debido al período de <strong> Reingeniería Digital del SRI</strong> (Servicio de Rentas Internas), según la Resolución Nro. NAC-DGERCGC20-00000074, que conllevará la indisponibilidad temporal de los servicios en línea y sistemas del Servicio de Rentas Internas, desde el <strong> 24 de diciembre de 2020</strong> al <strong>01 de enero de 2021 </strong> , ponemos en su conocimiento lo siguiente:
                <br />
                <br />
                Durante el período de tiempo indicado, el Sistema de Facturación Electrónica <strong>Ebox EC</strong> no podrá enviar los comprobantes electrónicos al SRI para su autorización, por lo cual, de manera automática, el sistema mantendrá los comprobantes en estado <strong>Pendiente</strong> (sin autorizar), hasta que el servicio del SRI se encuentre operativo nuevamente.
                <br />
                <br />
                Para consultar la lista de comprobantes electrónicos que se encuentran en espera de ser autorizados, se sugiere ir a la sección <strong>Repositorio de Comprobantes</strong>, opción <strong>Pendientes</strong>.
                <br />
                <br />
                <strong>Para conocer a más detalle la resolución, por favor ingresar a la siguiente URL:</strong>
                <br />
                <a href="https://www.sri.gob.ec/web/guest/detalle-noticias?idnoticia=786&marquesina=1" target="_blank">https://www.sri.gob.ec/web/guest/detalle-noticias?idnoticia=786&marquesina=1</a>
              </Typography>
            </Grid>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={2}
            ></Grid>

            <Grid item md={12} className={classes.button}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleCloseMsm}
              >
                Aceptar
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default MsmWelcome;
