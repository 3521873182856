import { useState, useCallback, useEffect } from "react";

import { debounce } from "throttle-debounce";
import service from "./services";

//redux
import { getRUCEmpresa } from "redux/actions/userActions";

export default function useClientForm(stateRedux, action, saveCache, costumerNull, tk) {
    const [data, setData] = useState(costumerNull);
    const [inputValue, setInputValue] = useState("");
    const [isSelectClient, setSelectedClient] = useState(false);
    const [existClient, setExistClient] = useState(false);
    const [optionsCostumers, setOptionsCostumers] = useState([
        {
            label: "Ingrese por lo menos 3 caracteres para iniciar la busqueda.",
            isDisabled: true,
        },
    ]);


    const getCostumers = useCallback(
        debounce(750, false, (param, ruc = "0") => {
            service
                .searchCostumer(param, ruc)
                .then((response) => {
                    if (response.status === 200) {
                        const newConstumers = response.data;

                        if (newConstumers.length > 0) {
                            for (let i = 0; i < newConstumers.length; i++) {
                                newConstumers[i].label =
                                    newConstumers[i].cliente.idCliente +
                                    " - " +
                                    newConstumers[i].nombres +
                                    " - " +
                                    newConstumers[i].correo;
                            }
                            setOptionsCostumers(newConstumers);
                        } else {
                            setOptionsCostumers([
                                {
                                    label: `No existen clientes con la identificación: ${param}`,
                                },
                            ]);
                            setExistClient(true)
                        }
                    } else {
                        setExistClient(true)
                    }
                })
                .catch((expection) => {
                    setOptionsCostumers([
                        {
                            label: `No existen clientes con la identificación: ${param}`,
                        },
                    ]);
                    setExistClient(true)
                });
        }),
        []
    );

    useEffect(() => {
        if (stateRedux.nombresCliente && stateRedux.correoCliente) {
            setData({
                nombres: stateRedux.nombresCliente,
                telefono: stateRedux.telefonoCliente,
                correo: stateRedux.correoCliente,
                direccion: stateRedux.direccionCliente,
            });
            setInputValue(
                stateRedux.nombresCliente + " - " + stateRedux.correoCliente
            );
            setExistClient(false)
        }
    }, [stateRedux]);

    const handleAutocompleteInputChange = (event, newInputValue) => {
        event.preventDefault();
        setInputValue(newInputValue);

        if (newInputValue.length > 0 && newInputValue.length < 100 && tk) {
            let token = tk ? tk : "";
            !isSelectClient && getCostumers(newInputValue, getRUCEmpresa(token));
        } else if (newInputValue.length === 0) {
            setOptionsCostumers([
                {
                    label: "Ingrese por lo menos 3 caracteres para iniciar la busqueda.",
                    isDisabled: true,
                },
            ]);
        }
    };

    const handleStyle = (nameInput) => {
        if (data) {
            switch (nameInput) {
                case "nombres":
                    if (data.nombres) {
                        return "form-control  is-valid";
                    }
                    return "form-control ";
                case "telefono":
                    if (data.telefono) {
                        return "form-control  is-valid";
                    }
                    return "form-control ";
                case "correo":
                    if (data.correo) {
                        return "form-control  is-valid";
                    }
                    return "form-control ";
                case "direccion":
                    if (data.direccion) {
                        return "form-control  is-valid";
                    }
                    return "form-control ";
                default:
                    break;
            }
        } else {
            return "form-control ";
        }
    };

    const handleFlagLoadingAutoComplete = () => {
        const aviableOptions = optionsCostumers.filter(
            (item) =>
                item.label !==
                "Ingrese por lo menos 3 caracteres para iniciar la busqueda."
        );
        return aviableOptions.length === 0;
    };

    const handleChangeAutocomplete = (event, newValue) => {
        if (newValue) {
            setSelectedClient(true);
            if (newValue.idAsignacionEmpresaCliente) {
                action(newValue.idAsignacionEmpresaCliente.toString());
            }
            setData(newValue);
            if (typeof saveCache === "function") {
                saveCache(newValue);
            }
        } else {
            setSelectedClient(false);
            action(null);
            setData(costumerNull);
            if (typeof saveCache === "function") {
                saveCache(costumerNull);
            }
        }
    }


    return {
        getCostumers,
        handleAutocompleteInputChange,
        handleStyle,
        handleFlagLoadingAutoComplete,
        handleChangeAutocomplete,
        inputValue,
        existClient,
        data,
        isSelectClient,
        optionsCostumers
    }
}