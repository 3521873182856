import checkInputs from "utils/checkInputs";
import { clientBackendZIP, httpCore } from "configurations/axios";
import { getSub, getRUCEmpresa } from "redux/actions/userActions";

const validate = (nameInput, value) => {
    const response = { value: value, error: null, keyWordError: null };
    return response;
}

export const validationPermission = async (userToken) => {
    await httpCore.get(`/generacion/comprobantes/ebox/validacion`, {
        "idAsignacionEmpresaUsuario": userToken ? getSub(userToken) : '0',
        "rucEmpresa": userToken ? getRUCEmpresa(userToken) : '',
    }).then(
        response => {
            if (response.status >= 200 && response.status <= 300) {
                return '';
            }
        }
    ).catch(
        exeption => {
            if (exeption.response) {
                console.log(exeption.response.data)
                return exeption.response.data;
            } else {
                return exeption
            }
        }
    );

}

export const getZip = async (setOpenLoader, idComprobantes) => {
    var fileSaver = require("file-saver");
    setOpenLoader(true);
    await clientBackendZIP.post('/utilidades/ebox/generar/zip', {
        idComprobantes: idComprobantes
    }).then(
        response => {
            if (response.status === 200) {
                var blob = new Blob([response.data], {
                    type:
                        "application/zip",
                });
                fileSaver.saveAs(blob, "EBOX-Comprobantes-Electronicos.zip");
                setOpenLoader(false);
                return true;
            }
        }
    ).catch((error) => {
        setOpenLoader(false);
        return false;

    })

}

export const validationAddRetention = {
    onSubmit: function checkOnSubmit(data) {
        const errors = {};
        if (checkInputs.notNull(data.impuesto)) {
            let validateLocal = validate('impuesto', data.impuesto);
            if (validateLocal.error) {
                errors.impuesto = validateLocal.error;
            }
        } else {
            errors.impuesto = 'Seleccione un código impuesto';
        }
        if (checkInputs.notNull(data.retencion)|| data.retencion.trim() === '-') {
            let validateLocal = validate('retencion', data.retencion);
            if (validateLocal.error) {
                errors.retencion = validateLocal.error;
            }
        } else {
            errors.retencion = 'Seleccione un código de retención';
        }
        if (checkInputs.notNull(data.codigo)) {
            let validateLocal = validate('codigo', data.codigo);
            if (validateLocal.error) {
                errors.codigo= validateLocal.error;
            }
        } else {
            errors.codigo = 'Ingrese el porcentaje a retener';
        }
        if (checkInputs.notNull(data.base)) {
            let validateLocal = validate('baseImponible', data.base);
            if (validateLocal.error) {
                errors.base = validateLocal.error;
            }
        } else {
            errors.base = 'Ingrese un dato válido';
        }
        if (checkInputs.notNull(data.documento)) {
            let validateLocal = validate('codigoDocSustento', data.documento);
            if (validateLocal.error) {
                errors.documento = validateLocal.error;
            }
        } else {
            errors.documento = 'Seleccione el tipo de documento sustento';
        }
        if (checkInputs.notNull(data.numero)) {
            let validateLocal = validate('numeroDocSustento', data.numero);
            if (validateLocal.error) {
                errors.numero = validateLocal.error;
            }
        } else {
            errors.numero = 'Ingrese el número de documento sustento válido';
        }

        return errors;
    },
        

}

