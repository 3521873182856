import checkInputs from "utils/checkInputs";
import { numDecimales, numDecimalesDescuento } from "configurations/constant";
const initProduct = {
  precioUnitario: "0.00",
  descuentoProducto: "0.00",
  cantidadProducto: "1",
  codigoAuxiliar: "",
  descripcion: "",
};

export const styleInput = (error, value) => {
  if (value || error) {
    if (error) {
      return "form-control is-invalid";
    } else {
      return "form-control is-valid";
    }
  } else {
    return "form-control";
  }
};



const validations = {
  quantity: function checkQuantity(value) {
    const response = { isValid: true, value: value };

    response.isValid =
      checkInputs.isNumeric(value) &&
      checkInputs.countLenth(value) < 14 &&
      checkInputs.countDecimals(value) <= numDecimales;

    return response;
  },
  price: function checkPrice(value) {
    const response = { isValid: true, value: value };
    response.isValid =
      checkInputs.isDecimal(value) &&
      checkInputs.countDecimals(value) <= numDecimales &&
      checkInputs.countLenth(value) < 14;

    return response;
  },
  identification: function checkIdentification(value) {
    const response = { isValid: true, value: value, error: null };

    response.isValid = checkInputs.isNumeric(value);

    return response;
  },
  submitDetail: function submitDetail(data, discountCheck) {
    const errors = {};
    if (!checkInputs.notNull(data.codigoPrincipal)) {
      errors.codigoPrincipal = "Seleccione un producto.";
    }

    if (!checkInputs.notNull(data.cantidadProducto)) {
      errors.cantidadProducto = "La cantidad es necesaria.";
    } else if (
      checkInputs.countDecimals(data.cantidadProducto) > numDecimales
    ) {
      errors.precioUnitario = `No ingrese más de ${numDecimales} decimales.`;
    } else if (checkInputs.countLenth(data.cantidadProducto) > 14) {
      errors.cantidadProducto = "No ingrese más de 14 dígitos.";
    }

    if (!checkInputs.notNull(data.precioUnitario)) {
      errors.precioUnitario = "El precio unitario es necesario.";
    } else if (!checkInputs.isDecimal(data.precioUnitario)) {
      errors.precioUnitario = "Solo ingrese números y/o el punto decimal.";
    } else if (checkInputs.countDecimals(data.precioUnitario) > numDecimales) {
      errors.precioUnitario = `No ingrese más de ${numDecimales} decimales.`;
    } else if (checkInputs.countLenth(data.precioUnitario) > 14) {
      errors.precioUnitario = "No ingrese más de 14 dígitos.";
    }

    if (checkInputs.notNull(data.descuentoProducto)) {
      if (!checkInputs.isDecimal(data.descuentoProducto)) {
        errors.descuentoProducto = "Solo ingrese números y/o el punto decimal.";
      } else if (
        checkInputs.countDecimals(data.descuentoProducto) >
        numDecimalesDescuento
      ) {
        errors.descuentoProducto = `No ingrese más de ${numDecimalesDescuento} decimales.`;
      } else if (checkInputs.countLenth(data.descuentoProducto) > 14) {
        errors.descuentoProducto = "No ingrese más de 14 dígitos.";
      }

      if(discountCheck){
        if(Number(data.descuentoProducto) > 100){
          errors.descuentoProducto = "No puede tener un descuento mayor al 100%.";
        }
      }else if(checkInputs.notNull(data.precioUnitario)){
        if(Number(data.descuentoProducto) > Number(data.precioUnitario)){
          errors.descuentoProducto = "No puede tener un descuento mayor al precio unitario.";
        }
      }
    }

    return errors;
  },
  onChange: function validationOnChange(name, value) {
    let error = null;
    switch (name) {
      case "codigoPrincipal":
        if (!checkInputs.notNull(value)) {
          error = "Seleccione un producto.";
        }
        return error;
      case "cantidadProducto":
        if (!checkInputs.isDecimal(value)) {
          error = "Solo ingrese números";
        } else if (checkInputs.countDecimals(value) > numDecimales) {
          error = `No ingrese más de ${numDecimales} decimales.`;
        }else if (checkInputs.countLenth(value) > 14) {
          error = "No ingrese más de 14 dígitos.";
        }

        return error;
      case "precioUnitario":
        if (!checkInputs.isDecimal(value)) {
          error = "Solo ingrese números y/o el punto decimal.";
        } else if (checkInputs.countDecimals(value) > numDecimales) {
          error = `No ingrese más de ${numDecimales} decimales.`;
        } else if (checkInputs.countLenth(value) > 14) {
          error = "No ingrese más de 14 dígitos.";
        }
        return error;
      case "descuentoProducto":
        if (!checkInputs.isDecimal(value)) {
          error = "Solo ingrese números y/o el punto decimal.";
        } else if (checkInputs.countDecimals(value) > numDecimalesDescuento) {
          error = `No ingrese más de ${numDecimalesDescuento} decimales.`;
        } else if (checkInputs.countLenth(value) > 14) {
          error = "No ingrese más de 14 dígitos.";
        } 
        return error;
      default:
        return error;
    }
  },
  onSave: function handleSave({
    error,
    callbackSaveObj,
    nameInput,
    valueInput,
  }) {
    switch (nameInput) {
      case "codigoPrincipal":
        if (valueInput !== null) {
          callbackSaveObj(nameInput, {
            ...valueInput,
            cantidadProducto: 1,
            descuentoProducto: "0.00",
          });
        } else {
          callbackSaveObj(nameInput, initProduct);
        }

        break;
      case "cantidadProducto":
      case "descripcionProducto":
      case "precioUnitario":
      case "descuentoProducto":
        if (error === null) {
          if (valueInput !== null && typeof valueInput !== "undefined") {
            callbackSaveObj(nameInput, valueInput);
          } else {
            callbackSaveObj(nameInput, "");
          }
        }
        break;
      default:
        break;
    }
  },
};

// export const validationAddDetail = {
//   onSubmit: function checkOnSubmit(data) {
//         const errors = {};
//         if (checkInputs.notNull(data.codigoPrincipal)) {
//             let validateLocal = validate('codigoPrincipal', data.codigoPrincipal);
//             if (validateLocal.error) {
//                 errors.codigoPrincipal = validateLocal.error;
//             }
//         } else {
//             errors.codigoPrincipal = 'Seleccione un producto';
//         }
//         if (checkInputs.notNull(data.cantidadProducto)) {
//             let validateLocal = validate('cantidadProducto', data.cantidadProducto);
//             if (validateLocal.error) {
//                 errors.cantidadProducto = validateLocal.error;
//             }
//         } else {
//             errors.cantidadProducto = 'Ingrese la cantidad';
//         }
//         if (checkInputs.notNull(data.precioUnitario)) {
//             let validateLocal = validate('precioUnitario', data.precioUnitario);
//             if (validateLocal.error) {
//                 errors.precioUnitario = validateLocal.error;
//             }
//         } else {
//             errors.precioUnitario = 'Ingrese el precio';
//         }

//         return errors;
//     },
      

// }

export default validations;
