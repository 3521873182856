import React, { Fragment, useState } from "react";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Grid,
  Button,
  Divider,
} from "@material-ui/core";

import InfoIcon from "@material-ui/icons/Info";
import { palette } from "../../../../configurations/styles/theme";

const useStyles = makeStyles((theme) => ({
  IconButton: {
    marginTop: "0%",
    marginBottom: "0%",
    padding: "0px",
  },
  containderInfoUser: {
    borderStyle: "solid",
    backgroundColor: palette.info.light,
    borderRadius: "3px",
    fontSize: "15px",
    padding: "2%",
    borderTopWidth: "0px",
    borderLeftWidth: "0px",
    borderRightWidth: "0px",
    borderBottomWidth: "1px",
    marginTop: "0.5%",
  },
  buttonClose: {
    width: "75%",
    marginBottom: "0.7%",
  },
}));
const InfoUserBtn = ({ user }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const buildDate = (date) => {
    const rebuildDate = new Date(date);
    let month = rebuildDate.getMonth() + 1;
    month = (month < 10) ? "0" + month : month;
    return `${rebuildDate.getDate()} / ${month} / ${rebuildDate.getFullYear()}`;
  };

  const parseProfile = (profile) => {
    switch (profile.nombre) {
      case "ADM":
        return "Administrador";
      case "INT":
        return "Interno";
      default:
        return profile.rol.toLowerCase();
    }
  };

  const parseStateUser = (stateUser) => {
    switch (stateUser) {
      case "ACT":
        return "Activo.";
      case "PRI":
        return "Aún no realiza el primer inicio de sesión.";
      case "DES":
        return "Inactivo.";
      case "BLO":
        return "Bloqueado.";
      default:
        return "Inactivo.";
    }
  };

  return (
    <Fragment>
      <IconButton
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={handleClickOpen}
        className={classes.IconButton}
      >
        <InfoIcon style={{ color: palette.primary.main }} />
      </IconButton>
      <Dialog
        open={open}
        maxWidth="lg"
        fullWidthparseStateUser
        onClose={handleClose}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="max-width-dialog-title">
          Detalle del Usuario
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={2} md={2} >
              Identificación:
            </Grid>

            <Grid item xs={12} sm={6} md={4} >
              <input
                disabled
                value={user.usuario.identificacionUsuario}
                className="form-control is-valid"
              />
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
              Nombre:
            </Grid>
            <Grid item xs={12} sm={10} md={4}>
              <input
                disabled
                value={user.nombreUsuario}
                className="form-control is-valid"
              />
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
              Nombre de usuario:
            </Grid>
            <Grid item xs={12} sm={10} md={4}>
              <input
                disabled
                value={user.nombreUsuarioAcceso}
                className="form-control is-valid"
              />
            </Grid>

            <Grid item xs={12} sm={2} md={2}>
              Correo principal:
            </Grid>
            <Grid item xs={12} sm={10} md={4}>
              <input
                disabled
                value={user.correoPrincipalUsuario}
                className="form-control is-valid"
              />
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
              Fecha registro:
            </Grid>
            <Grid item xs={12} sm={10} md={4}>
              <input
                disabled
                value={buildDate(user.fechaRegistro)}
                className="form-control is-valid"
              />
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
              Teléfono:
            </Grid>
            <Grid item xs={12} sm={10} md={4}>
              <input
                value={user.telefonoPrincipalUsuario}
                className={
                  user.telefonoPrincipalUsuario
                    ? "form-control is-valid"
                    : "form-control"
                }
                disabled={user.telefonoPrincipalUsuario ? false : true}
              />
            </Grid>

            <Grid item xs={12} sm={2} md={2}>
              Dirección:
            </Grid>
            <Grid item xs={12} sm={10} md={4}>
              <input
                value={user.direccionUsuario}
                className={
                  user.direccionUsuario
                    ? "form-control is-valid"
                    : "form-control"
                }
                disabled={user.direccionUsuario ? false : true}
              />
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
              Perfil:
            </Grid>
            <Grid item xs={12} sm={10} md={4}>
              <input
                disabled
                value={parseProfile(user.asignacionPerfilUsuarios[0].perfil)}
                className="form-control is-valid"
              />
            </Grid>

            <Grid item xs={12} sm={2} md={2}>
              Estado:
            </Grid>
            <Grid item xs={12} sm={10} md={4}>
              <input
                disabled
                value={parseStateUser(user.estadoUsuario)}
                className="form-control is-valid"
              />
            </Grid>
            <Grid container xs={12} justify="center" spacing={1}>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={6} md={3}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleClose}
                  className={classes.buttonClose}
                  fullWidth
                >
                  Cerrar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default InfoUserBtn;
