import React, { Fragment } from "react";
import { useStylesApp } from "hooks/useStyles";
import Grid from "@material-ui/core/Grid";

//componentes del sistema
import { useDispatch, useSelector } from "react-redux";
import { selectedResourceAction } from "redux/actions/resourcesActions";
import { getUserProfile, signOffUserAction } from "redux/actions/userActions";
import UseSecurity from "security/useSecurity";
import { palette } from "configurations/styles/theme";
import Menu from "../menu";

import UserInfoBtn from "./buttons/UserInfo";
import { IconbuttonDialog } from "widgets/Notifications";
import { useHistory } from "react-router-dom";
import { makeStyles, Avatar } from "@material-ui/core";
import BuyOptions from "./buttons/BuyOptions";

const useStyles = makeStyles((theme) => ({
  barra: {
    borderRight: "rgb(205 197 197) solid 1px",
    display: "flex",
    textAlign: "center",
    alignItems: "center",
  },
  large: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    marginTop: "0px",
  },
  titulo: {
    color: palette.primary.menuHeader,
    paddingLeft: 10,
  },
  containerTitle: {
    borderRight: "rgb(205 197 197) solid 1px",
    display: "flex",
    alignSelf: "center",
  },
  gridNumber: {
    paddingRight: '12px'
  }
}));

const Header = ({ title }) => {
  const classes = useStylesApp();
  const classesOwn = useStyles();
  const history = useHistory();
  const { isSingIn } = UseSecurity();
  const dispatch = useDispatch();
  const signOff = () => dispatch(signOffUserAction());
  const selectedResource = (resource) =>
    dispatch(selectedResourceAction(resource));

  const tokenUser = useSelector(state => state.user.tk);
  const userProfile = getUserProfile(tokenUser)

  const handleGetUserName = () => {
    if (!isSingIn()) {
      signOff();
      history.push("/");
      return null;
    }
  };

  const handleClickIconHome = () => {
    selectedResource({
      id: "INI",
      nameMenu: "Inicio",
      titlePage: "Inicio",
      titleTab: "Inicio",
      menuPadre: null,
    });
  };

  return (
    <Fragment>
      <header className={classes.header}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
        >
          <Grid item xs={3} md={1}>
            <Grid container direction="row">
              <Grid item xs={6} sm={6} className={classesOwn.barra}>
                <Menu />
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                justify="center"
                container
                className={classesOwn.barra}
              >
                <Avatar
                  alt="BigData C.A. Logo"
                  src="../faviconEbox.png"
                  className={classesOwn.large}
                  onClick={handleClickIconHome}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} md={10} className={classesOwn.containerTitle}>

            <Grid container direction="row" justify="space-between"
              alignItems="center">
              <Grid item xs={2} md={11} >
                <h6 className={classesOwn.titulo}>
                  {handleGetUserName()}
                  {title}
                </h6>
              </Grid>

            </Grid>
          </Grid>
          {userProfile ?
            <Fragment>
              <Grid
                md={1}
                xs={2}
                container
                direction="row-reverse"
                justify="center"
                alignContent="center"
                alignItems="center"
                spacing={1}
              >

                <Grid item xs={3} md={3}>
                  <UserInfoBtn />
                </Grid>
                <Grid item xs={2} md={3}>
                  <BuyOptions />
                </Grid>
                <Grid item xs={2} md={3}>
                  <IconbuttonDialog />
                </Grid>
                {/* <Grid item xs={2} md={3} >
                  <NumberVouchers />
                </Grid> */}
              </Grid>
            </Fragment>
            : <Fragment>

              <Grid
                md={1}
                xs={2}
                container
                direction="row-reverse"
                justify="center"
                alignContent="center"
                alignItems="center"
                spacing={1}
              >
                {/* <Grid item xs={4} md={4} >
                  <NumberVouchers />
                </Grid> */}
                <Grid item xs={4} md={4}>
                  <UserInfoBtn />
                </Grid>
                <Grid item xs={4} md={4}>
                  <IconbuttonDialog />
                </Grid>
              </Grid>
            </Fragment>}

        </Grid>
      </header>
      <br></br>
    </Fragment>
  );
};

export default Header;
