import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import TablePagination from '@material-ui/core/TablePagination';
import { palette } from "configurations/styles/theme";
import TableHeadVoucherAuthorized from "./header";
import { useTableAuthorizedIssuedVoucher, parseDate, parseDatetime } from "../Context/hook";
//componentes del sistema
import TablePaginationActions from 'widgets/Tables/Pagination';
import MailForwarding from '../Buttons/MailForwarding';
import XMLVoucherBtn from '../../Buttons/XMLVoucherBtn';
import PDFVoucherBtn from '../Buttons/PDFVoucherBtn';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },

    tableBodyRow: {
        borderStyle: "solid",
        borderWidth: "0px 0px 1px 0px",
        borderColor: palette.primary.main,
        padding: "0px"
    },
    paginacion: {
        color: 'black',
        fontSize: '12px',
        textAlign: "center",
        display: "flex",
        justifyContent: "center"
    },
}));



const TableVoucherAuthorized = () => {
    const classes = useStyles();

    const { rows, currentPage,
        handleChangePage,
        rowsPerPage,
        handleChangeRowsPerPage, totalElements,
        handleSelectAllClick, handleClick, selected,
        isSelected } = useTableAuthorizedIssuedVoucher();

    return (
        <Paper>
            <TableContainer >
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHeadVoucherAuthorized
                        numSelected={selected.length}
                        onSelectAllClick={handleSelectAllClick}
                        rowCount={rows.length}
                    />
                    <TableBody>
                        {rows.map((row, index) => {
                            const isItemSelected = isSelected(row.comprobanteElectronico.idComprobanteElectronico);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (<TableRow hover
                                onClick={(event) => handleClick(event, row.comprobanteElectronico.idComprobanteElectronico)}
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.comprobanteElectronico.idComprobanteElectronico}
                                selected={isItemSelected}
                            >
                                <TableCell padding="checkbox" classes={{ root: classes.tableBodyRow }}>
                                    <Checkbox
                                        checked={isItemSelected}
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </TableCell>
                                <TableCell component="th" classes={{ root: classes.tableBodyRow }} >
                                    {row.comprobanteElectronico.codigoEstablecimiento}- {row.comprobanteElectronico.puntoEmision} - {row.comprobanteElectronico.secuencial}
                                </TableCell>
                                <TableCell align="center" classes={{ root: classes.tableBodyRow }} > {parseDate(row.comprobanteElectronico.fechaEmision)}</TableCell>
                                <TableCell align="center" classes={{ root: classes.tableBodyRow }} >{parseDatetime(row.comprobanteElectronico.fechaAutorizacion)}</TableCell>
                                <TableCell align="justify" classes={{ root: classes.tableBodyRow }} >{row.receptor.razonSocial}</TableCell>
                                <TableCell align="center" classes={{ root: classes.tableBodyRow }} >{row.comprobanteElectronico.tipoComprobanteElectronico.nombreComprobante}</TableCell>
                                <TableCell align="center" classes={{ root: classes.tableBodyRow }} > <PDFVoucherBtn voucher={row} /> </TableCell>
                                <TableCell align="center" classes={{ root: classes.tableBodyRow }} > <XMLVoucherBtn voucher={row} /> </TableCell>
                                <TableCell align="center" classes={{ root: classes.tableBodyRow }} > <MailForwarding voucher={row} /> </TableCell>
                            </TableRow>)
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 15, 20]}
                component="div"
                count={totalElements}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage="Filas por página: "
                className={classes.paginacion}
                ActionsComponent={TablePaginationActions}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
            />
        </Paper>
    );
}

export default React.memo(TableVoucherAuthorized)