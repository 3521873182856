import React, { useState, useEffect, Fragment } from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Card,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
//redux
import UseSecurity from "security/useSecurity";
import { useDispatch } from "react-redux";
import { addResourceAction } from "redux/actions/resourcesActions";
import {
  processoFinishedAction,
  processoLoadingAction,
} from "redux/actions/processAsyncAction";
//componentes de la Vista
import AuthUser from "./authUser";
import Loader from "widgets/Loader";
import UseTitle from "hooks/useTitle";
//estilos
import "./login.css";
import { clientPublic } from "configurations/axios";
import { useSelector } from "react-redux";
import { signOffUserAction } from "redux/actions/userActions";
import ManualesModal from "./ManualesModal.js";
import CancelIcon from "@material-ui/icons/Cancel";
import "./login.css";
import MsmWelcome from "./MsmWelcome";

//import { logo } from 'assets/icons/logo.png'
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "2%",
  },
  registro: {
    textAlign: "center",
    justifyContent: "space-around",
  },
  formLogin: {
    padding: "1%",
    justifyContent: "center",
    display: "flex",
  },
  btnResetPass: {
    paddingLeft: "10%",
    paddingRight: "10%",
  },
  buttonLogin: {
    borderRadius: "20px",
    backgroundColor: "#00e676",
    color: "white",
    marginBottom: "20px",
  },
  titleLogin: {
    color: "black",
    fontFamily: "Arial",
    fontSize: "16px",
    fontWeight: "bold",
    textAlign: "center",
  },
  large: {
    width: "200px",
    height: "55px",
  },
  card: {
    padding: "2%",
    borderRadius: "0px 0px 0px 25px",
    width: "320px",
    height: "530px",
  },
  inputLogin: {
    borderRadius: "5%",
    width: "100%",
    marginBottom: "15px",
  },
  link: {
    fontSize: "13px",
    marginBottom: "20px",
    textDecoration: "none",
    display: "flex",
    justifyContent: "center",
  },
  questionLogin: {
    marginTop: "20px",
    textAlign: "center",
  },
  media: {
    height: 75,
  },
  input: {
    //position: 'relative',
    borderRadius: "10px",
    width: "100%",
    marginBottom: "15px",
  },
  butonRecovery: {
    marginBottom: "10px",
    marginTop: "15px",
  },
  rootCard: {
    maxWidth: 345,
  },
  rootImage: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const Login = () => {
  const classes = useStyles();
  const [user, setUser] = useState({});
  const [open, setOpen] = useState(false);
  const [openMsm, setOpenMsm] = useState(true);

  const [errorInputs, setErrorInputs] = useState(false);
  const [userRecovery, setUserRecovery] = useState();
  const [msgForgetPass, setmsgForgetPass] = useState({
    msg:
      "Ingrese el nombre del usuario con el que inicia sesión para recuperar  su contraseña.",
    type: "alert-primary",
  });
  const { isSingIn } = UseSecurity();
  const history = useHistory();
  const dispatch = useDispatch();
  UseTitle({ title: "Login" });
  const addResources = (dataUser) => dispatch(addResourceAction(dataUser));
  const processLoading = () => dispatch(processoLoadingAction());
  const processFinised = () => dispatch(processoFinishedAction());
  const clearProcess = () => dispatch(signOffUserAction());
  const error = useSelector((state) => state.user.error);

  const [inputClave, setInputClave] = useState({
    error: false,
    helperText: null,
  });

  const handleChange = (event) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeUserRecovey = (event) => {
    setUserRecovery(event.target.value);
  };

  const handleAutentication = async (event) => {
    event.preventDefault();
    if (user.username.trim() !== "" && user.password.trim() !== "") {
      addResources(user);
    } else {
      setErrorInputs(true);
    }
  };

  const handleRedirectionRegister = () => {
    history.push("/registro");
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseMsm = () => {
    setOpenMsm(false);
  };

  const handleClose = () => {
    setUserRecovery("");
    setmsgForgetPass({
      msg:
        "Ingrese el nombre del usuario con el que inicia sesión para recuperar su contraseña.",
      type: "alert-primary",
    });
    setInputClave({
      error: false,
      helperText: null,
    });
    setOpen(false);
  };

  useEffect(() => {
    if (isSingIn()) {
      history.push("/facturacion");
    } else {
      clearProcess();
    }
  }, []);

  //Solo vale una ves
  const handleRestorePass = async () => {
    if (userRecovery !== null) {
      if (userRecovery === undefined) {
        setInputClave({
          error: true,
          helperText: "Ingrese el usuario",
        });
      } else {
        setInputClave({
          error: false,
          helperText: null,
        });

        processLoading();
        const nombreUsuarioAcceso = userRecovery;
        await clientPublic
          .post(
            "/reestablecer/clave?nombreUsuarioAcceso=" + nombreUsuarioAcceso
          )
          .then((response) => {
            if (response.status >= 200 && response.status <= 300) {
              setmsgForgetPass({
                msg:
                  "La información necesaria para poder iniciar sesión fue enviada a su correo: " +
                  response.data.correoPrincipalUsuario,
                type: "alert-success",
              });
            }
            processFinised();
          })
          .catch((exception) => {
            if (exception.response) {
              if (exception.response.status === 400) {
                setmsgForgetPass({
                  msg: "No se pudo reestablecer la contraseña.",
                  type: "alert-danger",
                });
              } else if (exception.response.status === 404) {
                setmsgForgetPass({
                  msg:
                    "No se encontró el usuario que desea recuperar la contraseña.",
                  type: "alert-danger",
                });
              } else {
                setmsgForgetPass({
                  msg:
                    "Error al recuperar la contraseña. Contáctese con el administrador del Sistema.",
                  type: "alert-danger",
                });
              }
            } else {
              setmsgForgetPass({
                msg:
                  "Existen problemas en la red. Contáctese con el administrador del Sistema.",
                type: "alert-danger",
              });
            }
            processFinised();
          });
      }
    }
  };
  return (
    <Fragment>
      <Loader />
      {/*<MsmWelcome
        openMsm={openMsm}
        handleCloseMsm={handleCloseMsm}
      ></MsmWelcome>*/}
      <Grid>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
          className={classes.root}
        >
          <Card className={classes.card}>
            <form onSubmit={handleAutentication}>
              <AuthUser />
              {errorInputs ? <p>Ingrese todos los campos</p> : null}
              <Grid
                container
                justify="center"
                align="center"
                direction="row"
                spacing={1}
              >
                <img
                  src="../facturar12020.png"
                  alt="logo"
                  className={classes.large}
                />
              </Grid>
              <br></br>
              <Divider></Divider>
              <br />
              {error ? (
                <div className="alert alert-danger" role="alert">
                  <label> {error}</label>
                </div>
              ) : null}
              <Grid item md={12}>
                <TextField
                  type="text"
                  classes={{
                    root: classes.input, // class name, e.g. `classes-nesting-root-x`
                  }}
                  onChange={handleChange}
                  required
                  name="username"
                  autoComplete="off"
                  value={user.username}
                  label="Usuario"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  type="password"
                  classes={{
                    root: classes.input, // class name, e.g. `classes-nesting-root-x`
                  }}
                  //className={classes.inputLogin}
                  onChange={handleChange}
                  required
                  name="password"
                  value={user.password}
                  label="Contraseña"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} className={classes.link}>
                <Link
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleClickOpen}
                  underline="always"
                >
                  ¿Olvidó su Contraseña?
                </Link>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.buttonLogin}
                  type="submit"
                  fullWidth
                >
                  Ingresar
                </Button>
              </Grid>
            </form>

            <ManualesModal></ManualesModal>
            <Grid item md={12}>
              <Typography className={classes.questionLogin}>
                ¿Aún no está en Ebox EC?
              </Typography>
            </Grid>

            <Grid item md={12} className={classes.registro}>
              <Link
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleRedirectionRegister}
                underline="always"
                className={classes.link}
              >
                Regístrarse
              </Link>
            </Grid>
          </Card>
        </Grid>
      </Grid>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        <DialogTitle>Recuperación de contraseña</DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={1}
          >
            <Grid item sm={12}>
              <div className={"alert " + msgForgetPass.type} role="alert">
                <label> {msgForgetPass.msg}</label>
              </div>
            </Grid>
            <Grid item sm={2}>
              <label>Usuario :</label>
            </Grid>
            <Grid item sm={10}>
              <TextField
                type="email"
                className="form-control"
                required
                name="emailUser"
                variant="outlined"
                placeholder="user@ejemplo.com"
                onChange={handleChangeUserRecovey}
                value={userRecovery}
                autoComplete="off"
                error={inputClave.error}
                helperText={inputClave.helperText}
              />
            </Grid>

            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={1}
              className={classes.butonRecovery}
            >
              <Grid item xs={12}>
                <Divider></Divider>
              </Grid>
              <Grid item sm={6}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleRestorePass}
                  disabled={msgForgetPass.type === "alert-success"}
                >
                  Recuperar contraseña
                </Button>
              </Grid>
              <Grid item sm={6}>
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  onClick={handleClose}
                >
                  <CancelIcon></CancelIcon>
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default Login;
