import React, { Fragment, useState } from 'react';
import {
    IconButton, Dialog, DialogTitle, DialogContent, makeStyles, Grid, Button,
    Backdrop, CircularProgress, Divider, FormControl, Radio, RadioGroup, FormControlLabel
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

//Notificacion errores https://iamhosseindhv.com/notistack/demos#variants
import { useSnackbar } from 'notistack';
//componentes del sistema
import { API_PUT_UPDATE_PROVIDERS } from "../apis";
import { palette, useStylesGlobal } from 'configurations/styles/theme';
import { httpReportes } from 'configurations/axios';
import Alert from 'widgets/alert';
import useForm from 'hooks/useFrom';
import validations from '../validations';
import { useStylesApp } from 'hooks/useStyles';

const useStyles = makeStyles(theme => ({
    formControl: {
        marginTop: theme.spacing(0),
        minWidth: 120,
    },
    radio: {
        display: '-webkit-box',
        marginTop: '0px'
    },
    radioLable: {
        color: palette.primary.main,
        display: 'flex'
    },
    radioButton: {
        marginTop: '0px',
        paddingTop: '0px'
    },
    divForm: {
        marginBottom: '1%',
    },
    IconButton: {
        marginTop: "0%",
        marginBottom: "0%",
        padding: "0px",
    },
    textFieldComboBox: {
        minWidth: '75%',
        maxWidth: '95%'
    },
    backdrop: {
        zIndex: theme.zIndex.modal + 1,
        color: palette.primary.main,
    },
}));

/**
 * @author {Dany_Lasso}
 * @param {Object} costumer
 */
const EditUserBtn = ({ costumer, updateView }) => {
    const [open, setOpen] = useState(false);
    const classesGlobal = useStylesGlobal();
    const [updateCostumer, serUpdateCostumer] = useState({
        codigoPais: costumer.codigoPais,
        codigoRegimen: costumer.codigoRegimen,
        convenioDobleTributacion: costumer.convenioDobleTributacion,
        idAsignacionEmpresaProveedor: costumer.id,
        pagoExterior: costumer.pagoExterior,
        pagoExteriorConNormativaLegal: costumer.pagoExteriorConNormativaLegal,
        paraisoFiscal: costumer.paraisoFiscal,
        parteRelacionada: costumer.parteRelacionada,
        razonSocial: costumer.cliente.nombres,
        rucEmpresa: costumer.empresa.id,
        rucProveedor: costumer.cliente.id,
        tipo: costumer.tipoProveedor,
        tipoRegimenFiscal: costumer.tipoRegimenFiscalExterior
    });
    const [openLoader, setOpenLoader] = useState(false);
    const classes = useStyles();
    const classesApp = useStylesApp();
    const { enqueueSnackbar } = useSnackbar();
    const [msgError, setMsg] = useState({ show: false, msg: null, type: null });

    const existError = () => { }

    const handleSave = async () => {
        setOpenLoader(true);
        await httpReportes.put(API_PUT_UPDATE_PROVIDERS, updateCostumer).then(
            response => {
                enqueueSnackbar("Proveedor editado.", {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    autoHideDuration: 10000,
                });
                updateView();
                setOpenLoader(false);
                setOpen(false);
            }
        ).catch(
            exception => {
                if (exception.response) {
                    if (exception.response.status === 400) {
                        setMsg({ show: true, msg: 'No se pudo actualizar el proveedor.' });
                    } else if (exception.response.status === 404) {
                        setMsg({ show: true, msg: 'No se encontró el proveedor a actualizar.' });
                    } else {
                        setMsg({ show: true, msg: 'Error al registrar el proveedor. Contáctese con el administrador del Sistema.' });
                    }
                } else {
                    setMsg({ show: true, msg: 'Existen problemas en la red. Contáctese con el administrador del Sistema.' });
                }
                setOpenLoader(false);
            }
        );
    }

    const { errors, handleSubmit, getObject } = useForm(handleSave, validations.editProvider, existError);

    const handleClickOpen = () => {
        setOpen(true);
        serUpdateCostumer({
            codigoPais: costumer.codigoPais,
            codigoRegimen: costumer.codigoRegimen,
            convenioDobleTributacion: costumer.convenioDobleTributacion,
            idAsignacionEmpresaProveedor: costumer.id,
            pagoExterior: costumer.pagoExterior,
            pagoExteriorConNormativaLegal: costumer.pagoExteriorConNormativaLegal,
            paraisoFiscal: costumer.paraisoFiscal,
            parteRelacionada: costumer.parteRelacionada,
            razonSocial: costumer.cliente.nombres,
            rucEmpresa: costumer.empresa.id,
            rucProveedor: costumer.cliente.id,
            tipo: costumer.tipoProveedor,
            tipoRegimenFiscal: costumer.tipoRegimenFiscalExterior
        });
        getObject({
            codigoPais: costumer.codigoPais,
            codigoRegimen: costumer.codigoRegimen,
            convenioDobleTributacion: costumer.convenioDobleTributacion,
            idAsignacionEmpresaProveedor: costumer.id,
            pagoExterior: costumer.pagoExterior,
            pagoExteriorConNormativaLegal: costumer.pagoExteriorConNormativaLegal,
            paraisoFiscal: costumer.paraisoFiscal,
            parteRelacionada: costumer.parteRelacionada,
            razonSocial: costumer.cliente.nombres,
            rucEmpresa: costumer.empresa.id,
            rucProveedor: costumer.cliente.id,
            tipo: costumer.tipoProveedor,
            tipoRegimenFiscal: costumer.tipoRegimenFiscalExterior
        });
        setMsg({ show: false, msg: null, type: null });
        errors.razonSocial = null;
        errors.correo = null;
        errors.direccion = null;
        errors.telefono = null;
    };

    const handleClose = () => {
        setOpen(false);
    };

    function closeAlert() { setMsg({ show: false }); }

    const handleChangeUser = event => {
        serUpdateCostumer({
            ...updateCostumer,
            [event.target.name]: event.target.value
        });

        getObject({
            ...updateCostumer,
            [event.target.name]: event.target.value,
        });
    }
    const handleChangeCheck = (event) => {
        serUpdateCostumer({
            ...updateCostumer,
            [event.target.name]: event.target.value === "S" ? true : false,
        });
        getObject({
            ...updateCostumer,
            [event.target.name]: event.target.value,
        });
    };


    return (
        <Fragment>
            <IconButton color="primary" aria-label="upload picture" component="span" onClick={handleClickOpen} className={classes.IconButton}>
                <EditIcon style={{ color: palette.primary.main }} />
            </IconButton>
            <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle id="max-width-dialog-title">Editar Proveedor</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={12}>
                                <label>Los campos marcados con (<font color={palette.error.main}> *</font> ) son obligatorios:</label>
                                {msgError.show ?
                                    <Alert
                                        tipo={msgError.type}
                                        mensaje={msgError.msg}
                                        mesajeSubrayado={null}
                                        titulo={null}
                                        mostrar={msgError.show}
                                        cerrarAlerta={closeAlert}
                                    /> : null
                                }
                            </Grid>
                            <Grid item xs={12} md={3} >
                                Identificación:
                            </Grid>
                            <Grid item xs={12} md={3} >
                                {updateCostumer.rucProveedor}
                            </Grid>

                            <Grid item xs={12} md={2} >
                                Nombre: <font color={palette.error.main}> *</font>
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.divInfoUser} >
                                <input type="text" className={errors.razonSocial ? 'form-control is-invalid' : 'form-control'}
                                    value={updateCostumer.razonSocial}
                                    name="razonSocial"
                                    onChange={handleChangeUser}
                                    autoComplete="off"
                                />
                                {errors.razonSocial ?
                                    <p className={classesApp.errorLetters}>{errors.razonSocial}</p>
                                    :
                                    null
                                }
                            </Grid>

                            <Grid item xs={12} md={3} className={classes.divlabel}>
                                Tipo: <font color={palette.error.main}> *</font>
                            </Grid>
                            <Grid item md={3} xs={12} className={classes.divInput}>
                                <select
                                    name="tipo"
                                    className={
                                        errors.tipo
                                            ? "form-control is-invalid"
                                            : "form-control"
                                    }
                                    value={updateCostumer.tipo}
                                    onChange={handleChangeUser}
                                >
                                    <option value="">--Seleccione--</option>
                                    <option value="01">Persona natural</option>
                                    <option value="02">Sociedad</option>
                                </select>
                                {errors.tipo ? (
                                    <p className={classesApp.errorLetters}>
                                        {errors.tipo}
                                    </p>
                                ) : null}
                            </Grid>

                            <Grid item xs={12} md={3} className={classes.divlabel}>
                                Tipo régimen fiscal exterior: <font color={palette.error.main}> *</font>
                            </Grid>
                            <Grid item md={3} xs={12} className={classes.divInput}>
                                <select
                                    name="tipoRegimenFiscal"
                                    className={
                                        errors.tipoRegimenFiscal
                                            ? "form-control is-invalid"
                                            : "form-control"
                                    }
                                    value={updateCostumer.tipoRegimenFiscal}
                                    onChange={handleChangeUser}
                                >
                                    <option value="">--Seleccione--</option>
                                    <option value="01">Régimen general</option>
                                    <option value="02">Paraíso fiscal</option>
                                    <option value="03">Régimen fiscal preferente o jurisdicción de menor imposición</option>
                                </select>
                                {errors.tipoRegimenFiscal ? (
                                    <p className={classesApp.errorLetters}>
                                        {errors.tipoRegimenFiscal}
                                    </p>
                                ) : null}
                            </Grid>
                            <Grid item xs={12} md={3} className={classes.divlabel}>
                                Código país: <font color={palette.error.main}> *</font>
                            </Grid>
                            <Grid item xs={12} md={3} className={classes.divInfoUser}>
                                <input type="text" className={errors.codigoPais ? 'form-control is-invalid' : 'form-control'}
                                    value={updateCostumer.codigoPais}
                                    name="codigoPais"
                                    onChange={handleChangeUser}
                                    autoComplete="off"
                                />
                                {errors.codigoPais ?
                                    <p className={classesApp.errorLetters}>{errors.codigoPais}</p>
                                    :
                                    null
                                }
                            </Grid>

                            <Grid item xs={12} md={3} className={classes.divlabel}>
                                Código régimen:
                            </Grid>
                            <Grid item xs={12} md={3} className={classes.divInfoUser}>
                                <input type="text" className={errors.codigoRegimen ? 'form-control is-invalid' : 'form-control'}
                                    value={updateCostumer.codigoRegimen}
                                    name="codigoRegimen"
                                    onChange={handleChangeUser}
                                    autoComplete="off"
                                />
                                {errors.codigoRegimen ?
                                    <p className={classesApp.errorLetters}>{errors.codigoRegimen}</p>
                                    :
                                    null
                                }
                            </Grid>

                            <Grid item xs={12} md={3} >
                                Convenio doble tributación:
                            </Grid>
                            <Grid item xs={12} md={3}   >
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <RadioGroup
                                        name="convenioDobleTributacion"
                                        className={classes.radio}
                                        value={updateCostumer.convenioDobleTributacion ? "S" : "N"}
                                        onChange={handleChangeCheck}
                                    >
                                        <FormControlLabel
                                            value="S"
                                            control={<Radio color="primary" className={classes.radioButton} />}
                                            label="Si"
                                            labelPlacement="start"
                                            className={classes.radioLable}
                                        />
                                        <FormControlLabel
                                            value="N"
                                            control={<Radio color="primary" className={classes.radioButton} />}
                                            label="No"
                                            labelPlacement="start"
                                            className={classes.radioLable}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={3} >
                                Pago exterior:
                            </Grid>
                            <Grid item xs={12} md={3}   >
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <RadioGroup
                                        name="pagoExterior"
                                        className={classes.radio}
                                        value={updateCostumer.pagoExterior ? "S" : "N"}
                                        onChange={handleChangeCheck}
                                    >
                                        <FormControlLabel
                                            value="S"
                                            control={<Radio color="primary" className={classes.radioButton} />}
                                            label="Si"
                                            labelPlacement="start"
                                            className={classes.radioLable}
                                        />
                                        <FormControlLabel
                                            value="N"
                                            control={<Radio color="primary" className={classes.radioButton} />}
                                            label="No"
                                            labelPlacement="start"
                                            className={classes.radioLable}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={3} >
                                Paraíso fiscal:
                            </Grid>
                            <Grid item xs={12} md={3}   >
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <RadioGroup
                                        name="paraisoFiscal"
                                        className={classes.radio}
                                        value={updateCostumer.paraisoFiscal ? "S" : "N"}
                                        onChange={handleChangeCheck}
                                    >
                                        <FormControlLabel
                                            value="S"
                                            control={<Radio color="primary" className={classes.radioButton} />}
                                            label="Si"
                                            labelPlacement="start"
                                            className={classes.radioLable}
                                        />
                                        <FormControlLabel
                                            value="N"
                                            control={<Radio color="primary" className={classes.radioButton} />}
                                            label="No"
                                            labelPlacement="start"
                                            className={classes.radioLable}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={3} >
                                Parte relacionada:
                            </Grid>
                            <Grid item xs={12} md={3}   >
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <RadioGroup
                                        name="parteRelacionada"
                                        className={classes.radio}
                                        value={updateCostumer.parteRelacionada ? "S" : "N"}
                                        onChange={handleChangeCheck}
                                    >
                                        <FormControlLabel
                                            value="S"
                                            control={<Radio color="primary" className={classes.radioButton} />}
                                            label="Si"
                                            labelPlacement="start"
                                            className={classes.radioLable}
                                        />
                                        <FormControlLabel
                                            value="N"
                                            control={<Radio color="primary" className={classes.radioButton} />}
                                            label="No"
                                            labelPlacement="start"
                                            className={classes.radioLable}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={3} >
                                Pago exterior con normativa legal:
                            </Grid>
                            <Grid item xs={12} md={3}   >
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <RadioGroup
                                        name="pagoExteriorConNormativaLegal"
                                        className={classes.radio}
                                        value={updateCostumer.pagoExteriorConNormativaLegal ? "S" : "N"}
                                        onChange={handleChangeCheck}
                                    >
                                        <FormControlLabel
                                            value="S"
                                            control={<Radio color="primary" className={classes.radioButton} />}
                                            label="Si"
                                            labelPlacement="start"
                                            className={classes.radioLable}
                                        />
                                        <FormControlLabel
                                            value="N"
                                            control={<Radio color="primary" className={classes.radioButton} />}
                                            label="No"
                                            labelPlacement="start"
                                            className={classes.radioLable}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                            <Grid container spacing={3} justify='center' >
                                <Grid item md={12} xs={12} >
                                    <Divider></Divider>
                                </Grid>
                                <Button variant="contained" color="primary" type="submit" className={classesGlobal.marginButton}>
                                    Guardar
                                </Button>
                                <Button variant="contained" color="secondary" onClick={handleClose}
                                    className={classesGlobal.marginButton}>
                                    Cancelar
                                </Button>
                            </Grid>

                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>
            <Backdrop className={classes.backdrop} open={openLoader} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Fragment>
    );
}

export default EditUserBtn;