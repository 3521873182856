import React, { Fragment, useState, useEffect } from "react";
import {
  Grid,
  makeStyles,
  CardHeader,
  Checkbox,
  Button,
  Card,
} from "@material-ui/core";
import {
  msmSwalExito,
  msmSwalInformacion,
  palette,
  useStylesGlobal,
} from "configurations/styles/theme";
import tableStyle from "configurations/styles/table";
import AddDetail from "../Buttons/AddDetail";
import AddDetailReimbursementPayment from "../Buttons/AddDetailReimbursementPayment";
import AddDetailReimbursement from "../Vouchers/invoices/Forms/AddDetailReimbursement";
import Calculations from "./Calculations";
import bigDecimal from "js-big-decimal";
import InfoTaxesBtn from "../Vouchers/invoices/Buttons/InfoTaxesBtn";
import InfoAdditionalBtn from "../Vouchers/invoices/Buttons/InfoAdditionalBtn";
import InfoAdditionalReimbursementBtn from "../Vouchers/invoices/Buttons/InfoAdditionalReimbursementBtn";
import DeleteProductDetail from "../Vouchers/invoices/Buttons/DeleteProductDatailBtn";
import DeleteReimbursementDetailBtn from "../Vouchers/invoices/Buttons/DeleteReimbursementDetailBtn";
import EditDetail from "../Buttons/EditDetail";
import clientBackend from "configurations/axios";
import { useSelector } from "react-redux";
import { getSub } from "redux/actions/userActions";
import PaymentMethods from "../Vouchers/invoices/Forms/PaymentMethods";
import AdditionalInfo from "../Vouchers/invoices/Forms/AdditionalInfo";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import ElectronicVoucherOptions from "../Vouchers/invoices/Forms/ElectronicVoucherOptions";
import AssignmentIcon from "@material-ui/icons/Assignment";
import TableDataSimple from "widgets/Tables/TableDataSimple";
import TableDataSimplePag from "widgets/Tables/TableDataSimplePag";
import checkInputs from "utils/checkInputs";
import { calculateDiscount } from "../Vouchers/invoices/validations";
import InfoImpuestosBtn from "../Vouchers/Retention/Buttons/InfoImpuestosBtn";
import EditReimbursementDetailBtn from "../Vouchers/invoices/Buttons/EditReimbursementDetailBtn";
import { ivaCodigo, ivaValores } from "./typeVoucher";

const headerColor = palette.primary.main;
const letterHeaderColor = palette.primary.main;
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.primary,
    margin: theme.spacing(1),
    display: "flex",
    flexWrap: "wrap",
    marginBottom: "1%",
    borderStyle: "solid",
    boxShadow: "5px 3px 3px #aaaaaa",
    borderRadius: "8px",
    borderWidth: "2px",
  },
  formControl: {
    marginTop: theme.spacing(0),
    minWidth: 120,
  },
  radio: {
    display: "-webkit-box",
    marginTop: "0px",
  },
  radioLable: {
    color: palette.primary.main,
    display: "flex",
  },
  radioButton: {
    marginTop: "0px",
    paddingTop: "0px",
  },
  divForm: {
    marginBottom: "0%",
    textAlign: "justify",
  },
  divInput: {
    marginBottom: "0%",
    paddingRight: "0%",
    paddingLeft: "0%",
    textAlign: "justify",
  },
  divButton: {
    paddingLeft: "3%",
    paddingRight: "3%",
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 999,
    color: palette.primary.main,
  },
  haedClient: {
    backgroundColor: palette.primary.main,
    width: "10%",
    display: "flex",
    flexWrap: "wrap",
    borderStyle: "solid",
    borderBottomWidth: "5px",
    borderRadius: "8px",
  },
  formClient: {
    padding: "10px",
  },
  tableHeader: {
    backgroundColor: headerColor,
    color: "#FFFFFF",
    borderStyle: "solid",
    borderColor: letterHeaderColor,
    borderWidth: "0px 0px 0px 0px",
    padding: "0px",
    alignContent: "center",
    textAlign: "center",
    fontFamily: ["Arial", "sans-serif"].join(","),
    fontSize: 12,
    color: "white",
  },
  tableRow: {
    borderStyle: "solid",
    borderColor: headerColor,
    borderWidth: "1px 0px ",
    alignContent: "center",
    textAlign: "center",
  },
  tableCell: {
    padding: "0px",
  },
}));

const InvoiceData = ({
  setIdAsgCliente,
  idAsgCliente,
  setFoundClient,
  setOptions,
}) => {
  //para la fecha
  const dateToday = new Date();
  const maxDate = new Date();
  dateToday.setDate(dateToday.getDate() - 30);
  maxDate.setDate(dateToday.getDate() + 30);
  const tokenUser = useSelector((state) => state.user.tk);
  const classes = useStyles();
  const classesGlobales = useStylesGlobal();
  //para los cálculos
  const [totals, setTotals] = useState({
    subTotal_8: 0,
    subTotal_12: 0,
    subTotal_5: 0,
    subtotal_15: 0,
    subTotal_0: 0,
    subTotal_noIva: 0,
    totalDescuento: 0,
    subTotalSinImpuestos: 0,
    totalIce: 0,
    totalPropina: 0,
    totalIva_8: 0,
    totalIva_12: 0,
    totalIva_5: 0,
    titalIva_15: 0,
    totalFactura: 0,
  });
  //para visualización del detalle
  const [viewDetalle, setViewDetalle] = useState(1);
  //para visualización del detalle reembolso
  const [viewDetalleReimbursement, setViewDetalleReimbursement] = useState(1);
  //Para la vista del cálculo
  const [viewCalculations, setViewCalculations] = useState(0);
  //State para la búsqueda
  const [productsDetalle, setProductsDetalle] = useState([]);
  //para la fecha del comprobante
  const [fechaEmision, setFechaEmision] = useState(new Date());

  const [flag, setFlag] = useState(false);

  const [additionalInfoInvoiceEdited, setAdditionalInfoInvoiceEdited] =
    useState({});

  const [numeracionSecuencial, setNumeracionSecuencial] = useState("");

  // Reimbursement
  const [isReimbursement, setIsReimbursement] = useState(false);
  const [reimbursementDetail, setReimbursementDetail] = useState([]);
  const [reimbursementDetailEdited, setReimbursementDetailEdited] = useState(
    {}
  );
  const [totalReimbursement, setTotalReimbursement] = useState({
    totalBaseImponibleReembolso: 0,
    totalImpuestoReembolso: 0,
    totalComprobantesReembolso: 0,
  });

  const getDiscount = (prodDetail) => {
    let discount = calculateDiscount(prodDetail);
    return discount.toFixed(2);
  };

  const columns = [
    {
      title: "Cantidad",
      cellStyle: tableStyle.cellTable,
      width: "10%",
      filtering: false,
      editable: "never",
      render: (rowData) => <RoundNumber data={rowData.cantidadProducto} />,
    },
    {
      title: "Descripción",
      field: "descripcion",
      cellStyle: tableStyle.cellLeft,
      width: "30%",
      filtering: false,
      editable: "never",
    },
    {
      title: "Valor unitario",
      field: "precioUnitario",
      cellStyle: tableStyle.cellTable,
      width: "10%",
      filtering: false,
      editable: "never",
      render: (rowData) => <RoundNumber data={rowData.precioUnitario} />,
    },
    {
      title: "Descuento",
      field: "descuentoProducto",
      cellStyle: tableStyle.cellTable,
      width: "10%",
      filtering: false,
      editable: "never",
      render: getDiscount,
    },
    {
      title: "Total",
      field: "total",
      cellStyle: tableStyle.cellTable,
      width: "10%",
      filtering: false,
      editable: "never",
      render: (rowData) => Number(rowData.total).toFixed(2),
    },
    {
      title: "Det. Adicionales",
      cellStyle: tableStyle.cellTable,
      width: "10%",
      render: (rowData) => <InfoAdditionalBtn data={rowData} />,
      filtering: false,
      editable: "never",
    },
    {
      title: "Impuestos",
      cellStyle: tableStyle.cellTable,
      width: "10%",
      render: (rowData) => <InfoTaxesBtn data={rowData} />,
      filtering: false,
      editable: "never",
    },
    {
      title: "Editar",
      field: "",
      cellStyle: tableStyle.cellTable,
      width: "10%",
      render: (rowData) => (
        <EditDetail
          data={rowData}
          productsDetalle={productsDetalle}
          setProductsDetalle={setProductsDetalle}
          setViewDetalle={setViewDetalle}
          viewDetalle={viewDetalle}
          detalles={detalles}
        />
      ),
      filtering: false,
      editable: "never",
    },
    {
      title: "Eliminar",
      cellStyle: tableStyle.cellTable,
      width: "10%",
      render: (rowData) => (
        <DeleteProductDetail
          productsDetalle={productsDetalle}
          setProductsDetalle={setProductsDetalle}
          data={rowData}
          setViewDetalle={setViewDetalle}
          viewDetalle={viewDetalle}
          detalles={detalles}
          setDetalles={setDetalles}
        />
      ),
      filtering: false,
      editable: "never",
    },
  ];

  const getTotalTaxesInfoForEachOne = (taxesArray) => {
    const taxesAux = taxesArray;
    let sumBaseImponible = 0;
    let sumImpuesto = 0;
    sumBaseImponible += taxesAux.reduce(
      (acc, currElm) => acc + currElm.baseImponible,
      0
    );
    sumImpuesto += taxesAux.reduce(
      (acc, currElm) => acc + currElm.valorImpuesto,
      0
    );
    return +sumBaseImponible.toFixed(2) + +sumImpuesto.toFixed(2);
  };

  const RoundNumber = (data) => {
    if (checkInputs.countDecimals(data.data) >= 2) {
      return parseFloat(data.data).toFixed(2);
    } else {
      return data.data;
    }
  };
  //para lo spagos
  const [pagos, setPagos] = useState([]);

  //para la información adicional del la factura
  const [additionalInfoInvoice, setAdditionalInfoInvoice] = useState([]);

  //para los detalles de la Factura
  const [detalles, setDetalles] = useState([]);

  //Objeto para crear la factura
  const [factura, setFactura] = useState({});

  const getNumeration = async () => {
    await clientBackend
      .get(
        `/asignacion/secuencial/usuario/secuencial/${
          tokenUser ? getSub(tokenUser) : null
        }/01`
      )
      .then((response) => {
        if (response.status === 200) {
          setNumeracionSecuencial(response.data);
          return response.data;
        }
      })
      .catch((exception) => {
        console.log({ exception });
        return exception;
      });
  };

  const addCalculs = () => {
    if (productsDetalle.length > 0) {
      let subTotIva_8 = productsDetalle.reduce(function (subTotIva_8, product) {
        if (product.impuestoIva[0].CODIGO === ivaCodigo.IVA_DIF) {
          return (
            Number(subTotIva_8) +
            Number(product.total) +
            Number(product.totalIceProducto)
          );
        } else {
          return subTotIva_8;
        }
      }, 0);

      let subTotIva_12 = new bigDecimal("0");
      productsDetalle.forEach((pr) => {
        if (pr.impuestoIva[0].CODIGO ===ivaCodigo.IVA_12) {
          let prTotal = new bigDecimal(pr.total);
          prTotal = prTotal.round(2, bigDecimal.RoundingModes.HALF_UP);
          let prIceTotal = new bigDecimal(pr.totalIceProducto);
          subTotIva_12 = subTotIva_12.add(prTotal).add(prIceTotal);
        }
      });

      let subTotIva_15 = productsDetalle.reduce(function (
        subTotIva_15,
        product
      ) {
        if (product.impuestoIva[0].CODIGO === ivaCodigo.IVA_15) {
          return (
            Number(subTotIva_15) +
            Number(product.total) +
            Number(product.totalIceProducto)
          );
        } else {
          return subTotIva_15;
        }
      },
      0);

      let subTotIva_5 = productsDetalle.reduce(function (subTotIva_5, product) {
        if (product.impuestoIva[0].CODIGO === ivaCodigo.IVA_5) {
          return (
            Number(subTotIva_5) +
            Number(product.total) +
            Number(product.totalIceProducto)
          );
        } else {
          return subTotIva_5;
        }
      }, 0);

      let subTotIva_0 = productsDetalle.reduce(function (subTotIva_0, product) {
        if (product.impuestoIva[0].CODIGO === ivaCodigo.IVA_0) {
          return (
            Number(subTotIva_0) +
            Number(product.total) +
            Number(product.totalIceProducto)
          );
        } else {
          return subTotIva_0;
        }
      }, 0);

      let subTotNoIva = productsDetalle.reduce(function (subTotNoIva, product) {
        if (product.impuestoIva[0].CODIGO === ivaCodigo.IVA_NO) {
          return (
            Number(subTotNoIva) +
            Number(product.total) +
            Number(product.totalIceProducto)
          );
        } else {
          return subTotNoIva;
        }
      }, 0);

      let totDescuento = productsDetalle.reduce(function (
        totalDescuento,
        product
      ) {
        let valorDiscount = calculateDiscount(product);
        valorDiscount = bigDecimal.round(
          valorDiscount,
          2,
          bigDecimal.RoundingModes.HALF_UP
        );
        return totalDescuento + Number(valorDiscount);
      },
      0);

      let subTotSinImpuestos = new bigDecimal("0");

      productsDetalle.forEach((pr) => {
        let prTotal = new bigDecimal(pr.total);
        prTotal = prTotal.round(2, bigDecimal.RoundingModes.HALF_UP);
        subTotSinImpuestos = subTotSinImpuestos.add(prTotal);
      });

      let totIce = productsDetalle.reduce(function (totIce, product) {
        if (product.impuestoIce.length > 0) {
          return totIce + (product.total * product.impuestoIce[0].VALOR) / 100;
        } else {
          return totIce;
        }
      }, 0);

      let totIva_8 = bigDecimal.multiply(subTotIva_8.toString(), ivaValores.IVA_8);
      totIva_8 = bigDecimal.round(
        totIva_8,
        2,
        bigDecimal.RoundingModes.HALF_UP
      );

      let totIva_12 = bigDecimal.multiply(subTotIva_12.getValue(), ivaValores.IVA_12);
      totIva_12 = bigDecimal.round(
        totIva_12,
        2,
        bigDecimal.RoundingModes.HALF_UP
      );

      let totIva_15 = bigDecimal.multiply(subTotIva_15.toString(), ivaValores.IVA_15);
      totIva_15 = bigDecimal.round(
        totIva_15,
        2,
        bigDecimal.RoundingModes.HALF_UP
      );

      let totIva_5 = bigDecimal.multiply(subTotIva_5.toString(), ivaValores.IVA_5);
      totIva_5 = bigDecimal.round(
        totIva_5,
        2,
        bigDecimal.RoundingModes.HALF_UP
      ); 
      console.log("totIva_5", totIva_5);
      console.log("subTotIva_5", subTotIva_5);

      let totFactura =
        Number(subTotIva_8) +
        Number(totIva_8) +
        Number(subTotIva_12.getValue()) +
        Number(totIva_12) +
        Number(subTotIva_5) +
        Number(totIva_5) +
        Number(subTotIva_15) +
        Number(totIva_15) +
        Number(subTotIva_0) +
        Number(subTotNoIva);

      setTotals({
        //...totals,
        subTotal_8: Number(
          bigDecimal.round(subTotIva_8, 2, bigDecimal.RoundingModes.HALF_UP)
        ),
        subTotal_12: bigDecimal.round(
          subTotIva_12.getValue(),
          2,
          bigDecimal.RoundingModes.HALF_UP
        ),
        subTotal_5: Number(
          bigDecimal.round(subTotIva_5, 2, bigDecimal.RoundingModes.HALF_UP)
        ),
        subtotal_15: Number(
          bigDecimal.round(subTotIva_15, 2, bigDecimal.RoundingModes.HALF_UP)
        ),
        subTotal_0: Number(
          bigDecimal.round(subTotIva_0, 2, bigDecimal.RoundingModes.HALF_UP)
        ),
        subTotal_noIva: Number(
          bigDecimal.round(
            Number(subTotNoIva),
            2,
            bigDecimal.RoundingModes.HALF_UP
          )
        ),
        totalDescuento: Number(
          bigDecimal.round(totDescuento, 2, bigDecimal.RoundingModes.HALF_UP)
        ),
        subTotalSinImpuestos: Number(
          bigDecimal.round(
            subTotSinImpuestos.getValue(),
            2,
            bigDecimal.RoundingModes.HALF_UP
          )
        ),
        totalIce: Number(
          bigDecimal.round(totIce, 2, bigDecimal.RoundingModes.HALF_UP)
        ),
        totalIva_8: Number(
          bigDecimal.round(totIva_8, 2, bigDecimal.RoundingModes.HALF_UP)
        ),
        totalIva_12: Number(
          bigDecimal.round(totIva_12, 2, bigDecimal.RoundingModes.HALF_UP)
        ),
        totalIva_5: Number(
          bigDecimal.round(totIva_5, 2, bigDecimal.RoundingModes.HALF_UP)
        ),
        totalIva_15: Number(
          bigDecimal.round(totIva_15, 2, bigDecimal.RoundingModes.HALF_UP)
        ),
        totalPropina: Number(
          bigDecimal.round(
            totals.totalPropina,
            2,
            bigDecimal.RoundingModes.HALF_UP
          )
        ),
        totalFactura: Number(
          bigDecimal.round(totFactura, 2, bigDecimal.RoundingModes.HALF_UP)
        ),
      });
    }
  };

  const clearAllData = () => {
    setTotals({
      subTotal_8: 0,
      subTotal_12: 0,
      subTotal_5: 0,
      subtotal_15: 0,
      subTotal_0: 0,
      subTotal_noIva: 0,
      totalDescuento: 0,
      subTotalSinImpuestos: 0,
      totalIce: 0,
      totalPropina: 0,
      totalIva_8: 0,
      totalIva_12: 0,
      totalIva_5: 0,
      titalIva_15: 0,
      totalFactura: 0,
    });

    setProductsDetalle([]);
    setDetalles([]);
    setPagos([]);
    setAdditionalInfoInvoice([]);
    setNumeracionSecuencial("");
    setIdAsgCliente({});
    setFoundClient({});
    setOptions([]);
    setFechaEmision(new Date());
  };

  const getTotalTaxesInfo = () => {
    const taxesAux = [];
    try {
      if (reimbursementDetail.length > 0) {
        for (const taxObj of reimbursementDetail) {
          taxesAux.push(taxObj.impuestos);
        }
      }
      let sumBaseImponible = 0;
      let sumImpuesto = 0;
      let sumTotal = 0;
      for (let i = 0; i < taxesAux.length; i++) {
        sumBaseImponible += taxesAux[i].reduce(
          (acc, currElm) => acc + currElm.baseImponible,
          0
        );
        sumImpuesto += taxesAux[i].reduce(
          (acc, currElm) => acc + currElm.valorImpuesto,
          0
        );
      }
      sumTotal = +sumBaseImponible + +sumImpuesto;
      setTotalReimbursement({
        totalBaseImponibleReembolso: +sumBaseImponible.toFixed(2),
        totalImpuestoReembolso: +sumImpuesto.toFixed(2),
        totalComprobantesReembolso: sumTotal.toFixed(2),
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (productsDetalle.length > 0) {
      addCalculs();
      setViewCalculations(1);
    } else {
      addCalculs();
      setViewCalculations(0);
    }
    if (numeracionSecuencial === "") {
      getNumeration();
    }
    getTotalTaxesInfo();
  }, [
    productsDetalle,
    viewDetalle,
    pagos,
    numeracionSecuencial,
    isReimbursement,
    reimbursementDetail,
  ]);

  const [countAux, setCountAux] = useState(0);

  useEffect(() => {
    if (isReimbursement && countAux === 0) {
      setCountAux(1);
      msmSwalInformacion(
        "Sr. Usuario, esta configuración solo permite la creación de reembolsos"
      );
    }
    setFlag(false);
    getTotalTaxesInfo();
  }, [flag, isReimbursement, reimbursementDetail]);

  const handleDebug = () => {
    console.log(totalReimbursement.totalComprobantesReembolso);
    console.log(reimbursementDetail);
    console.log(additionalInfoInvoice);
    console.log("Other additional Info");
    console.log(additionalInfoInvoiceEdited);
  };

  return (
    <Fragment>
      <div className={classesGlobales.rootDiv}>
        <Grid container xs={12} md={12} justifyContent="center">
          <CardHeader
            className={classesGlobales.cabecera}
            title={"Datos Factura"}
            avatar={<AssignmentIcon></AssignmentIcon>}
          />
        </Grid>
        <Grid container spacing={1} className={classesGlobales.containerForm}>
          <Grid item xs={12} md={2} className={classes.divForm}>
            <label>
              Numeración: <font color={palette.error.main}>*</font>
            </label>
          </Grid>
          <Grid item xs={12} md={4} className={classes.divInput}>
            <input
              className={
                numeracionSecuencial
                  ? "form-control  is-valid"
                  : "form-control "
              }
              type="text"
              disabled
              placeholder="001-001-000000223"
              name="secuencial"
              value={numeracionSecuencial}
            />
          </Grid>
          <Grid item xs={12} md={2} className={classes.divForm}>
            <label>
              Fecha: <font color={palette.error.main}>*</font>
            </label>
          </Grid>
          <Grid item xs={12} md={4} className={classes.divInput}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <DatePicker
                className={
                  fechaEmision ? "form-control  is-valid" : "form-control "
                }
                disableToolbar
                autoOk
                disableFuture
                minDate={dateToday}
                variant="dialog"
                inputVariant="outlined"
                cancelLabel="Cancelar"
                okLabel="Seleccionar"
                format="dd-MM-yyyy"
                margin="normal"
                name="fechaEmision"
                value={fechaEmision}
                onChange={setFechaEmision}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item>
            <label>Quiere asignar reembolso?</label>
          </Grid>
          <Grid item>
            <Checkbox
              onChange={() => {
                setIsReimbursement(!isReimbursement);
              }}
            />
          </Grid>
        </Grid>
      </div>

      {isReimbursement ? (
        <div className={classesGlobales.rootDiv}>
          <Grid container xs={12} md={12}>
            <CardHeader
              className={classesGlobales.cabecera}
              title={"Reembolsos"}
              avatar={<AssignmentIcon></AssignmentIcon>}
            />
          </Grid>
          {/* <Fragment>
            <Button onClick={handleDebug}>ANTI BUGS</Button>
          </Fragment> */}
          <AddDetailReimbursement
            reimbursementDetail={reimbursementDetail}
            setReimbursementDetail={setReimbursementDetail}
            detalles={detalles}
            setDetalles={setDetalles}
            additionalInfoInvoice={additionalInfoInvoice}
            setAdditionalInfoInvoice={setAdditionalInfoInvoice}
          />
          {reimbursementDetail.length > 0 ? (
            <Grid
              item
              xs={12}
              md={12}
              className={classesGlobales.containerForm}
            >
              <Card>
                <table style={{ width: "100%" }}>
                  <thead>
                    <tr className={classes.tableHeader}>
                      <th>Nombre Proveedor</th>
                      <th>Código</th>
                      <th>Tipo Proveedor</th>
                      <th>Total</th>
                      <th>Fecha de emisión</th>
                      <th>Descripción</th>
                      <th>Impuestos</th>
                      <th>Editar</th>
                      <th>Eliminar</th>
                    </tr>
                  </thead>
                  {reimbursementDetail.map((reimbursement) => {
                    return (
                      <tbody>
                        <tr className={classes.tableRow}>
                          <td className={classes.tableCell}>
                            {reimbursement.provider.nombres}
                          </td>
                          <td className={classes.tableCell}>
                            {
                              reimbursement.reimbursementTypeDocument
                                .DESCRIPTION
                            }
                          </td>
                          <td className={classes.tableCell}>
                            {reimbursement.providerType.DESCRIPTION}
                          </td>
                          <td className={classes.tableCell}>
                            {getTotalTaxesInfoForEachOne(
                              reimbursement.impuestos
                            ).toFixed(2)}
                          </td>
                          <td className={classes.tableCell}>
                            {reimbursement.fechaEmisionDocReembolso.toLocaleDateString()}
                          </td>
                          <td className={classes.tableCell}>
                            <InfoAdditionalReimbursementBtn
                              data={reimbursement}
                            />
                          </td>
                          <td className={classes.tableCell}>
                            <InfoImpuestosBtn data={reimbursement} />
                          </td>
                          <td className={classes.tableCell}>
                            <EditReimbursementDetailBtn
                              data={reimbursement}
                              reimbursementDetail={reimbursementDetail}
                              reimbursementDetailEdited={
                                reimbursementDetailEdited
                              }
                              setReimbursementDetailEdited={
                                setReimbursementDetailEdited
                              }
                              setFlag={setFlag}
                              additionalInfoInvoice={additionalInfoInvoice}
                              setAdditionalInfoInvoice={
                                setAdditionalInfoInvoice
                              }
                              additionalInfoInvoiceEdited={
                                additionalInfoInvoiceEdited
                              }
                              setAdditionalInfoInvoiceEdited={
                                setAdditionalInfoInvoiceEdited
                              }
                              viewDetalleReimbursement={
                                viewDetalleReimbursement
                              }
                              setViewDetalleReimbursement={
                                setViewDetalleReimbursement
                              }
                            />
                          </td>
                          <td className={classes.tableCell}>
                            <DeleteReimbursementDetailBtn
                              reimbursementDetail={reimbursementDetail}
                              setReimbursementDetail={setReimbursementDetail}
                              data={reimbursement}
                              detalles={detalles}
                              setDetalles={setDetalles}
                              additionalInfoInvoice={additionalInfoInvoice}
                              setAdditionalInfoInvoice={
                                setAdditionalInfoInvoice
                              }
                            />
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                </table>
              </Card>
              <br></br>
              <Grid
                container
                justifyContent="center"
                fullWidth
                maxWidth="xs"
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  md={10}
                  className={classes.totalLabel}
                  align="center"
                >
                  <strong>
                    Total Reembolsos:{" "}
                    <RoundNumber
                      data={totalReimbursement.totalComprobantesReembolso}
                    />
                  </strong>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </div>
      ) : null}

      <div className={classesGlobales.rootDiv}>
        <Grid container xs={12} md={12}>
          <CardHeader
            className={classesGlobales.cabecera}
            title={"Detalles"}
            avatar={<AssignmentIcon></AssignmentIcon>}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          {isReimbursement ? (
            <>
              {reimbursementDetail.length > 0 ? (
                <AddDetailReimbursementPayment
                  productsDetalle={productsDetalle}
                  setProductsDetalle={setProductsDetalle}
                  viewDetalle={viewDetalle}
                  setViewDetalle={setViewDetalle}
                  detalles={detalles}
                  setDetalles={setDetalles}
                  reimbursementCounter={reimbursementDetail.length}
                  totalPayment={totalReimbursement.totalComprobantesReembolso}
                />
              ) : (
                <Grid
                  container
                  justifyContent="center"
                  fullWidth
                  maxWidth="xs"
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    md={10}
                    className={classes.totalLabel}
                    align="center"
                    style={{ color: "red" }}
                  >
                    Debes ingresar un sustento reembolso para habilitar este
                    campo
                  </Grid>
                </Grid>
              )}
            </>
          ) : (
            <AddDetail
              productsDetalle={productsDetalle}
              setProductsDetalle={setProductsDetalle}
              viewDetalle={viewDetalle}
              setViewDetalle={setViewDetalle}
              detalles={detalles}
              setDetalles={setDetalles}
            />
          )}

          {productsDetalle.length > 0 ? (
            <Grid
              item
              xs={12}
              md={12}
              className={classesGlobales.containerForm}
            >
              {viewDetalle === 1 ? (
                <TableDataSimplePag
                  title=""
                  columns={columns}
                  data={productsDetalle}
                  buscar={false}
                ></TableDataSimplePag>
              ) : (
                <TableDataSimple
                  title=""
                  columns={columns}
                  data={productsDetalle}
                  buscar={false}
                ></TableDataSimple>
              )}
            </Grid>
          ) : null}
        </Grid>

        {viewCalculations === 1 ? (
          <Fragment>
            <Calculations totals={totals} setTotals={setTotals} />
            <Grid container>
              <Grid xs={12} md={!isReimbursement ? 6 : 12}>
                <PaymentMethods
                  totals={totals}
                  pagos={pagos}
                  setPagos={setPagos}
                  productsDetalle={productsDetalle}
                  setProductsDetalle={setProductsDetalle}
                />
              </Grid>
              {!isReimbursement ? (
                <Grid xs={12} md={6}>
                  <AdditionalInfo
                    additionalInfoInvoice={additionalInfoInvoice}
                    setAdditionalInfoInvoice={setAdditionalInfoInvoice}
                  />
                </Grid>
              ) : null}
            </Grid>
            <ElectronicVoucherOptions
              clearAllData={clearAllData}
              fechaEmision={fechaEmision}
              factura={factura}
              setFactura={setFactura}
              detalles={detalles}
              pagos={pagos}
              additionalInfoInvoice={additionalInfoInvoice}
              propina={totals.totalPropina}
              idCliente={idAsgCliente}
              totals={totals}
              reimbursementDetail={reimbursementDetail}
              totalReembolso={totalReimbursement}
              // totalReembolso={getTotalTaxesInfo()}
              reembolso={isReimbursement}
            />
          </Fragment>
        ) : null}
      </div>
    </Fragment>
  );
};

export default InvoiceData;
