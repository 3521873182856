import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  makeStyles,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import { msmSwalExito, msmSwalError, msmSwalInformacion } from "configurations/styles/theme";
import { httpCore } from "configurations/axios";
import { getRUCEmpresa, getSub } from "redux/actions/userActions";
import { useSelector } from "react-redux";

import TextError from "componets/TextError";
import ValidateKey from "./ValidateKey";
const useStyles = makeStyles((theme) => ({
  input: {
    width: "350px",
  },
  backdrop: {
    zIndex: "3000",
    color: "#fff",
  },
}));

const OneToOne = ({ data }) => {
  const classes = useStyles();
  const [claveValorSimple, setClaveValorSimple] = useState(null);
  const [actualizar, setActualizar] = useState(false);
  const [inputClave, setInputClave] = useState({
    error: false,
    helperText: null,
  });
  const [open, setOpen] = React.useState(false);
  const tokenUser = useSelector((state) => state.user.tk);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    inicializarInput();
    setActualizar(true);
  }, [actualizar]);

  const obtenerValorClaveSimple = (event) => {
    event.preventDefault();
    const { error, value, keyWordError } = ValidateKey.onChange(
      event.target.name,
      event.target.value
    );
    if (event.target.name === "claveDeAcceso") {
      if (keyWordError !== "isNotNumeric" && keyWordError !== "isLong") {
        setClaveValorSimple(value);
      }
      setErrors({
        ...errors,
        [event.target.name]: error,
      });
    }
  };

  const inicializar = "";

  const inicializarInput = () => {
    setClaveValorSimple(inicializar);
  };

  async function verificarClave() {
    if (claveValorSimple !== null && claveValorSimple.toString().trim() !== '') {
      if (claveValorSimple.length < 49) {
        setInputClave({
          error: true,
          helperText: "La clave debe tener 49 caracteres.",
        });
      } else {
        setOpen(true);
        setInputClave({
          error: false,
          helperText: null,
        });
        setActualizar(false);

        return await httpCore
          .get(
            `/comprobante/recibido/ebox/${claveValorSimple}/${data ? data : ""
            }`
          )
          .then(() => {
            setOpen(false);
            msmSwalExito(`Clave ${claveValorSimple}  almacenada con éxito  `);

          })
          .catch((error) => {
            if (error.response) {
              setOpen(false);
              if (error.response.status === 409) {
                msmSwalInformacion(`Ya existe un comprobante registrado con la clave de acceso ${claveValorSimple}`);
                setErrors({
                  claveDeAcceso: null,
                });
              } else {
                msmSwalError(error.response.data.apierror ? error.response.data.apierror.mensaje : `Existió un error al guardar la clave  ${claveValorSimple}`);
                setErrors({
                  claveDeAcceso: null,
                });
              }
            } else {
              setOpen(false);
            }
          });
      }
    } else {
      setErrors({
        claveDeAcceso: 'La clave debe tener 49 caracteres.',
      });
    }
  }
  return (
    <div>
      <Grid
        container
        justify="center"
        align="center"
        direction="row"
        spacing={2}
      >
        <Grid item md={12}>
          <Typography aling="center" color="primary" variant="subtitle2">
            Ingrese la clave de acceso del comprobante para descargarlo y
            guardarlo en la plataforma
          </Typography>
        </Grid>
        <Backdrop open={open} className={classes.backdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <Grid item md={12}>
          <TextField
            id="outlined-basic"
            className={classes.input}
            label="Clave de acceso"
            name="claveDeAcceso"
            variant="outlined"
            onChange={obtenerValorClaveSimple}
            error={errors.claveDeAcceso ? true : false}
            value={claveValorSimple}
          />
          <TextError text={errors.claveDeAcceso ? errors.claveDeAcceso : ''} />
        </Grid>
        <Grid item md={12}>
          <Button variant="outlined" color="primary" onClick={verificarClave}>
            <SaveIcon /> Guardar
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default OneToOne;
